import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// import _ from 'lodash'

import BlockerActions, { BlockerTypes } from '../Redux/BlockerRedux'

export function * blockerCheckRequest (api, getToken) {
  let action = yield take(BlockerTypes.BLOCKER_CHECK_REQUEST)
  while (action !== END) {
    yield fork(blockerCheckRequestAPI, api, getToken, action)
    action = yield take(BlockerTypes.BLOCKER_CHECK_REQUEST)
  }
}

export function * blockerCheckRequestAPI (api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.blockerCheck, token)
    if (response.ok) {
      return yield put(BlockerActions.blockerCheckSuccess(response.data.data))
    } else {
      return yield put(BlockerActions.blockerCheckFailure(response.data.data))
    }
  } catch (err) {
    return yield put(BlockerActions.blockerCheckFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * blockerAddRequest (api, getToken) {
  let action = yield take(BlockerTypes.BLOCKER_ADD_REQUEST)
  while (action !== END) {
    yield fork(blockerAddRequestAPI, api, getToken, action)
    action = yield take(BlockerTypes.BLOCKER_ADD_REQUEST)
  }
}

export function * blockerAddRequestAPI (api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    if (data !== '') {
      const response = yield call(api.blockerAdd, data, token)
      if (response.ok) {
        return yield put(BlockerActions.blockerAddSuccess(response.data.message))
      } else {
        return yield put(BlockerActions.blockerAddFailure(response.data.error.message))
      }
    }
  } catch (err) {
    return yield put(BlockerActions.blockerAddFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
