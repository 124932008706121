import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '../flex';
import { Text } from '../typhography';
import Padded from '../padded';

// Image Import
import homeIcon from './_assets/img/home.svg';
import homeActiveIcon from './_assets/img/home-active.svg';
import transactionIcon from './_assets/img/transaction.svg';
import transactionActiveIcon from './_assets/img/transaction-active.svg';
import accountIcon from './_assets/img/account.svg';
import accountActiveIcon from './_assets/img/account-active.svg';
import catalogIcon from './_assets/img/catalog.svg';
import keranjangIconActive from './_assets/img/cart-active.svg';
import keranjangIcon from './_assets/img/cart.svg';

const TabbarIconStyled = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: 'pointer';
`;

const TabbarStyled = styled.div`
  position: relative;
  z-index: 2;
`;

const CustomNav = styled.div`
  position: absolute;
  bottom: 16px;
  left: -10px;
  padding: 8px 8px 0 8px;
  background: #fff;
  box-shadow: 0px -1px 4px rgb(0 0 0 / 10%);
  border-radius: 20px;
`;

const Tabs = () => (
  <Flex justify="space-around">
    <TabbarItem
      path="/home"
      img={homeIcon}
      imgActive={homeActiveIcon}
      text="Beranda"
      style={{
        height: 23,
      }}
    />
    <TabbarItem
      path="/transaction"
      img={transactionIcon}
      imgActive={transactionActiveIcon}
      text="Transaksi"
      style={{
        height: 23,
      }}
    />
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <CustomNav>
        <TabbarItem
          path="/store"
          img={catalogIcon}
          imgActive={accountActiveIcon}
            // text="Katalog"
          style={{
            height: 40,
            possition: 'absolute',
          }}
        />
      </CustomNav>
      <Text size={10} color="grey">Katalog</Text>
    </div>

    <TabbarItem
      path="/shopping-cart"
      img={keranjangIcon}
      imgActive={keranjangIconActive}
      text="Keranjang"
      style={{
        height: 23,
      }}
    />
    <TabbarItem
      path="/account"
      img={accountIcon}
      imgActive={accountActiveIcon}
      text="Akun"
      style={{
        height: 23,
      }}
    />
  </Flex>
);

const TabbarItem = (props: {
  img: string;
  imgActive: string;
  text?: string;
  path: string;
  style: object;
}) => {
  const history = useHistory();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(history.location.pathname == props.path);
  }, [history.location.pathname]);

  if (active) {
    return (
      <TabbarIconStyled>
        <img style={props.style} src={props.imgActive} alt={props.text} />
        <Text marginTop="4px" color="eggBlue" size={10}>
          {props.text}
        </Text>
      </TabbarIconStyled>
    );
  }

  const handleRoute = () => {
    history.push(props.path);
  };

  return (
    <>
      <TabbarIconStyled onClick={handleRoute}>
        <img style={props.style} src={props.img} alt={props.text} />
        <Text marginTop="4px" color="grey" size={10}>
          {props.text}
        </Text>
      </TabbarIconStyled>
    </>
  );
};

const Tabbar = () => (
  <TabbarStyled>
    <Padded shadow top="11px" bottom="7px">
      <Tabs />
    </Padded>
  </TabbarStyled>
);

export default Tabbar;
