import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// import _ from 'lodash'

import FreeOngkirActions, { FreeOngkirTypes } from '../Redux/FreeOngkirRedux'

export function * freeOngkirRequest(api, getToken) {
    let action = yield take(FreeOngkirTypes.FREE_ONGKIR_REQUEST)
    while (action !== END) {
      yield fork(freeOngkirRequestAPI, api, getToken, action)
      action = yield take(FreeOngkirTypes.FREE_ONGKIR_REQUEST)
    }
  }
  
export function * freeOngkirRequestAPI(api, getToken) {
    try {
        const token = yield call(getToken)
        const response = yield call(api.getGratisOngkir, token)
        if (response.ok) {
            return yield put(FreeOngkirActions.freeOngkirSuccess(response.data.data))
        } else {
            return yield put(FreeOngkirActions.freeOngkirFailure(response.data.message))
        }
    } catch (err) {
        return yield put(FreeOngkirActions.freeOngkirFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
    }
}