import React, { Component } from 'react';
import './w-toolbar.scss';
import { Spin, Badge } from 'antd';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Plugins } from '@capacitor/core';
import arrowBackwardIcon from '../../../static/images/navigations/arrow-backward.svg';
import arrowBackwardWhiteIcon from '../../../static/images/navigations/arrow-backward-white.svg';
import deleteIcon from '../../../static/images/w-delete-purple.svg';
import deleteIconWhite from '../../../static/images/inbox/w-delete.svg';

import Logo from '../../../static/images/logo/woobiz_search.svg';
import Chat2 from '../../../static/images/w-chat.svg';
import Chat from '../../../static/images/w-chat-2.svg';
import Cart from '../../../static/images/w-shopping-cart.svg';
import CartWhite from '../../../static/images/w-shopping-cart-white.svg';
import Home from '../../../static/images/w-home-2.svg';
import NotificationIcon from '../../../static/images/w-notification.svg';
import NotificationWhiteIcon from '../../../static/images/w-notification-white.svg';

// Redux
import MailboxActions, { mailboxUnreadSuccess } from '../../../Redux/MailboxRedux';
import { getSafely } from '../../../Utils';
import { Text } from 'shared/components/typhography';

const { App, Toast } = Plugins;

class WToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatLoading: true,
      chatUnreadCount: 0,
      inbox: null,
      fixed: false,
      inboxLoading: true,
    };
  }

  componentDidMount() {
    const counter = setInterval(() => {
      if (!_.isUndefined(window.fcWidget)) {
        if (window.fcWidget.isInitialized()) {
          this.setState({
            chatLoading: false,
          });
        }

        window.fcWidget.on('widget:loaded', (resp) => {
          this.setState({
            chatLoading: false,
          });
        });

        window.fcWidget.on('unreadCount:notify', (resp) => {
          this.setState({
            chatUnreadCount: resp.count,
          });
        });

        clearInterval(counter);
      }
    }, 200);

    if (this.props.fixed || false) {
      window.addEventListener('scroll', this.handleScroll);
    }

    this.props.mailboxUnreadRequest();

    if (this.props.onBack) {
      if (window.hardwareBackListener !== undefined) {
        window.hardwareBackListener.remove();
      }

      window.hardwareBackListener = App.addListener('backButton', () => {
        this.props.onBack();
      });
    }
  }

  componentWillUnmount() {
    if (window.hardwareBackListener !== undefined) {
      window.hardwareBackListener.remove();
    }
    window.hardwareBackListener = App.addListener('backButton', () => {
      if (
        window.location.pathname === '/'
        || window.location.pathname === '/boarding'
      ) {
        if (window.doubleTapExit < 1) {
          window.doubleTapExit += 1;
          Toast.show({ text: 'Tekan lagi untuk keluar Aplikasi' });
          setTimeout(() => {
            window.doubleTapExit = 0;
          }, 2000);
        } else {
          App.exitApp();
        }
      } else {
        window.history.back();
      }
    });

    if (this.props.fixed || false) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    try {
      const holder = document
        .querySelector('.w-toolbar-holder')
        .getBoundingClientRect();

      if (holder.top < 0) {
        if (this.state.fixed === false) {
          this.setState({ fixed: true });
        }
      } else if (this.state.fixed === true) {
        this.setState({ fixed: false });
      }
    } catch (error) {
      // Error happen
    }
  };

  badgeCount() {
    const badgeCount = [];
    let totalBadge;

    const itemsPayload = getSafely(
      ['data', 'items', 0, 'details'],
      this.props.tempCartState,
      [],
    );

    itemsPayload.forEach((element) => {
      badgeCount.push(element.qty_ordered); // Push them into one array first

      totalBadge = badgeCount.reduce(
        (firstValue, restValue) => firstValue + restValue,
        0,
      );
    });
    return totalBadge;
  }

  cartActionButton() {
    const CartIcon = _.isEqual(this.props.background, 'transparent')
      ? CartWhite
      : Cart;

    return (
      <div className="icon-group">
        <Badge offset={[-4, 6]} count={this.badgeCount()} overflowCount={9}>
          <img
            className="cursor-pointer"
            src={CartIcon}
            alt="cart"
            width={23}
            height={28}
            onClick={() => {
              this.props.history.push('/shopping-cart');
            }}
          />
        </Badge>
        <span className="icon-label">Keranjang</span>
      </div>
    );
  }

  chatActionButton() {
    return (
      <div className="icon-group">
        <Spin size="small" spinning={this.state.chatLoading}>
          <img
            src={Chat}
            width={23}
            height={28}
            alt="chat"
            count={this.state.chatUnreadCount}
            onClick={() => {
              window.fcWidget.open();
              window.fcWidget.show();
            }}
          />
        </Spin>
        <span className="icon-label">Bantuan</span>
      </div>
    );
  }

  chatActionButton2() {
    return (
      <div className="icon-group">
        <Spin size="small" spinning={this.state.chatLoading}>
          <img
            src={Chat2}
            width={23}
            alt="chat"
            height={28}
            count={this.state.chatUnreadCount}
            onClick={() => {
              window.fcWidget.open();
              window.fcWidget.show();
            }}
          />
        </Spin>
        <span className="icon-label">Bantuan</span>
      </div>
    );
  }

  inboxButton() {
    const { mailboxUnreadState } = this.props;
    const payload = mailboxUnreadState?.data?.total_data || 0;

    const NotifIcon = _.isEqual(this.props.background, 'transparent')
      ? NotificationWhiteIcon
      : NotificationIcon;

    return (
      <div
        onClick={() => this.props.history.push('/account/mailbox')}
        className="icon-group"
      >
        <div className="action-button" style={{ cursor: 'pointer' }}>
          <Badge offset={[-4, 6]} count={payload} overflowCount={9}>
            <img src={NotifIcon} width={23} height={28} alt="notif" />
          </Badge>
        </div>
        <span className="icon-label">Notifikasi</span>
      </div>
    );
  }

  homeButton() {
    return (
      <div
        onClick={() => this.props.history.push('/home')}
        className="icon-group"
      >
        <div className="action-button" style={{ cursor: 'pointer' }}>
          <img src={Home} height={28} width={23} alt="..." />
        </div>
        <span className="icon-label">Beranda</span>
      </div>
    );
  }

  renderInboxButton = () => {
    let response = '';

    // Check chat render back icon
    if (this.props.showNotif) {
      response = this.inboxButton();
    }

    // Check if need balancer
    if (_.isEmpty(response)) {
      response = (
        <>
          {response}
          <div className="w-back-balancer" />
        </>
      );
    }

    if (this.props.end) {
      response += this.props.end;
    }

    return response;
  };

  renderHome = () => {
    let response = '';

    // Check chat render back icon
    if (this.props.showHome) {
      response = this.homeButton();
    }

    // Check if need balancer
    if (_.isEmpty(response)) {
      response = (
        <>
          {response}
          <div className="w-back-balancer" />
        </>
      );
    }

    if (this.props.end) {
      response += this.props.end;
    }

    return response;
  };

  deleteInboxButton(color) {
    const { onDelete } = this.props;

    return (
      <div type="button w-delete">
        <img
          src={color}
          style={{ cursor: 'pointer' }}
          alt="delete-icon"
          onClick={onDelete}
        />
      </div>
    );
  }

  backActionButton(color) {
    // check if theres props back button
    let onBack = () => {};
    if (_.isFunction(this.props.onBack)) {
      onBack = this.props.onBack;
    } else if (this.props.history.length === 1) {
      onBack = () => {
        this.props.history.replace('/');
      };
    } else if (this.props.history.location.pathname === '/store/edit') {
      onBack = () => {
        this.props.history.replace('/store/catalogs');
      };
    } else {
      onBack = () => {
        this.props.history.goBack();
      };
    }

    return (
      <div className="w-back">
        <img src={color} alt="arrow-back-icon" onClick={() => onBack()} />
      </div>
    );
  }

  renderToolbarContent() {
    if (this.props.showSearch) {
      return (
        <div style={{ cursor: 'pointer' }}>
          <div
            onClick={() => this.props.history.push('/search')}
            className="toolbar-search"
          >
            <i className="search-icon fa fa-search fa-md" aria-hidden="true" />
            <Text color="grey">Cari barang...</Text>
          </div>
        </div>
      );
    }
    return this.props.children;
  }

  renderSearchInput() {}

  renderStart() {
    let response = '';

    if (this.props.backButton) {
      if (_.isEqual(this.props.background, 'transparent')) {
        response = this.backActionButton(arrowBackwardWhiteIcon);
      } else {
        response = this.backActionButton(arrowBackwardIcon);
      }
    }

    if (this.props.start) {
      response = (
        <>
          {response}
          {' '}
          {this.props.start}
        </>
      );
    }

    return response;
  }

  renderEnd() {
    let response = '';

    // Check chat render back icon
    if (this.props.showHome) {
      response = response = (
        <>
          {response}
          {this.renderHome()}
        </>
      );
    }

    if (this.props.showCart) {
      response = (
        <>
          {response}
          {this.cartActionButton()}
        </>
      );
    }

    if (this.props.showChat) {
      response = response = (
        <>
          {response}
          {this.chatActionButton()}
        </>
      );
      // response = this.chatActionButton();
    }

    if (this.props.showChat2) {
      response = response = (
        <>
          {response}
          {this.chatActionButton2()}
        </>
      );
      // response = this.chatActionButton2();
    }

    if (this.props.showNotif) {
      response = response = (
        <>
          {response}
          {this.renderInboxButton()}
        </>
      );
    }

    if (this.props.deleteInbox) {
      if (_.isEqual(this.state.fixed, true)) {
        response = this.deleteInboxButton(deleteIcon);
      } else {
        response = this.deleteInboxButton(deleteIconWhite);
      }
    }

    // Check if need balancer
    if (_.isEmpty(response) && _.isEmpty(this.props.end)) {
      response = (
        <>
          {response}
          <div className="w-back-balancer" />
        </>
      );
    }

    if (this.props.end) {
      response = (
        <>
          {response}
          {this.props.end}
        </>
      );
    }

    return response;
  }

  renderFooter() {
    if (this.props.footer) {
      return this.props.footer;
    }
  }

  render() {
    const { props } = this;
    const wtoolbardom = document.querySelector('.w-toolbar');
    let wtoolbarHeight = 'auto';
    if ((this.props.fixed || false) && wtoolbardom != null) {
      wtoolbarHeight = `${wtoolbardom.clientHeight}px`;
    }

    return (
      <div>
        <div className="w-toolbar-holder" style={{ height: wtoolbarHeight }}>
          <div
            className={
              `w-toolbar ${
                props.backButton ? 'with-back-button' : ''
              }${props.background ? ` bg-${props.background}` : ''
              }${this.state.fixed
                ? ' w-toolbar-fixed fixed-position-state'
                : ''
              }${this.props.shadow ? ' shadow' : ''}`
            }
          >
            <div {...this.props} className="w-toolbar-main">
              {this.renderStart()}
              <div className="w-toolbar-content">
                {this.renderToolbarContent()}
              </div>
              {this.renderEnd()}
            </div>

            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mailboxUnreadState: state.mailboxUnreadState,
  tempCartState: state.tempCartState,
});

const mapDispatchToProps = (dispatch) => ({
  mailboxUnreadRequest: () => dispatch(MailboxActions.mailboxUnreadRequest()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WToolbar),
);
