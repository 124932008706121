const ChangeImageAndName = (string) => {
  if (string === 'BMRI' || string === 'MANDIRI') return ['Virtual Account Bank Mandiri', 'https://res.cloudinary.com/rintiz/image/upload/v1585540713/bank/mandiri.svg']
  else if (string === 'BNIN' || string === 'BNI') return ['Virtual Account BNI', 'https://res.cloudinary.com/rintiz/image/upload/v1585540714/bank/bni.svg']
  else if (string === 'BRIN' || string === 'BRI') return ['Virtual Account BRI', 'https://res.cloudinary.com/rintiz/image/upload/v1585540713/bank/bri.svg']
  else if (string === 'BNIA' || string === 'CIMB') return ['Virtual Account CIMB Niaga', 'https://res.cloudinary.com/rintiz/image/upload/v1585540713/bank/cimb.svg']
  else if (string === 'BBBA' || string === 'PERMATA') return ['Virtual Account Permata', 'https://res.cloudinary.com/rintiz/image/upload/v1585540715/bank/permata.svg']
  else if (string === 'BDIN' || string === 'DANAMON') return ['Virtual Account Danamon', 'https://res.cloudinary.com/rintiz/image/upload/v1585540715/bank/danamon.svg']
  else if (string === 'BCA' || string === 'BCA') return ['Virtual Account BCA', 'https://res.cloudinary.com/rintiz/image/upload/v1585540954/bank/bca.svg']
  else if (string === 'ALMA' || string === 'ALFAMART') return ['Alfamart', 'https://res.cloudinary.com/rintiz/image/upload/v1585541097/bank/Alfamart_logo.svg']
  else if (string === 'DANA' || string === 'DANA') return ['DANA', 'https://res.cloudinary.com/rintiz/image/upload/v1591069837/bank/logo_dana.png']
  else if (string === 'LINKAJA' || string === 'LINKAJA') return ['LINKAJA', 'https://res.cloudinary.com/rintiz/image/upload/c_thumb,w_200,g_face/v1591925003/bank/logo_linkaja.png']
  else if (string === 'OVO' || string === 'OVO') return ['OVO', 'https://res.cloudinary.com/rintiz/image/upload/v1591237866/bank/Logo_ovo.png']
  else if (string === 'CREDIT_CARD' || string === 'CREDIT_CARD') return ['Kartu Kredit', 'https://res.cloudinary.com/devwoobiz/image/upload/v1611725742/native-assets/w-creditcard_ris0eh.svg']
  else if (string === 'COD' || string === 'COD') return ['Bayar di Tempat', 'https://res.cloudinary.com/rintiz/image/upload/v1615349316/bank/w-cod-woobiz.svg']
  else if (string === 'GOPAY' || string === 'GOPAY') return ['GOPAY', 'https://res.cloudinary.com/rintiz/image/upload/v1634493475/bank/logo_gopay.png']
  else if (string === 'bank_transfer_manual') return ['Bank Transfer BNI', 'https://res.cloudinary.com/rintiz/image/upload/v1585540714/bank/bni.svg']
  else return ['empty', 'https://via.placeholder.com/40x40']
}

const ChangeBankName = (string) => {
  if (string === 'BMRI' || string === 'MANDIRI') return ['Virtual Account Bank Mandiri']
  else if (string === 'BNIN' || string === 'BNI') return ['Virtual Account BNI']
  else if (string === 'BRIN' || string === 'BRI') return ['Virtual Account BRI']
  else if (string === 'BNIA' || string === 'CIMB') return ['Virtual Account CIMB Niaga']
  else if (string === 'BBBA' || string === 'PERMATA') return ['Virtual Account Permata']
  else if (string === 'BDIN' || string === 'DANAMON') return ['Virtual Account Danamon']
  else if (string === 'BCA' || string === 'BCA') return ['Virtual Account BCA']
  else if (string === 'ALMA' || string === 'ALFAMART') return ['Kode Pembayaran Alfamart']
  else if (string === 'DANA' || string === 'DANA') return ['DANA']
  else if (string === 'LINKAJA' || string === 'LINKAJA') return ['LINKAJA']
  else if (string === 'OVO' || string === 'OVO') return ['OVO']
  else if (string === 'CREDIT_CARD' || string === 'CREDIT_CARD') return ['Kartu Kredit']
  else if (string === 'COD' || string === 'COD') return ['Bayar di Tempat']
  else if (string === 'bank_transfer_manual') return ['Bank Transfer BNI']
  else return ['empty', 'https://via.placeholder.com/40x40']
}

export {
  ChangeImageAndName,
  ChangeBankName
}
