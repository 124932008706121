import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'

// Redux Stuffs
import ProductDetailActions, { ProductDetailTypes } from '../Redux/ProductDetailRedux'
import ProductSpecialActions, { ProductSpecialTypes } from '../Redux/ProductSpecialRedux'
import ProductSelectionActions, { ProductSelectionTypes } from '../Redux/ProductSelectionRedux'
import ProductRecommendationActions, { ProductRecommendationTypes } from '../Redux/ProductRecommendationRedux'
import ProductDetailCommissionActions, { ProductDetailCommissionTypes } from '../Redux/ProductDetailCommissionRedux'
import ProductDetailCommissionTierListActions, { ProductDetailCommissionTierListTypes } from '../Redux/ProductDetailCommissionTierListRedux'
// import ProductSetExplanationActions, { ProductSetExplanationTypes } from '../Redux/ProductSetExplanationRedux'
import ProductDetailViewActions, { ProductDetailViewTypes } from '../Redux/ProductDetailViewRedux'
import ProductCategoryActions, { ProductCategoryTypes } from '../Redux/ProductCategoryRedux'
import ProductUnder300Actions, { ProductUnder300Types } from '../Redux/ProductUnder300Redux'
import ProductUnder200Actions, { ProductUnder200Types } from '../Redux/ProductUnder200Redux'
import ProductUnder100Actions, { ProductUnder100Types } from '../Redux/ProductUnder100Redux'
import BannerCollectionActions, { BannerCollectionTypes } from '../Redux/bannerCollectionRedux'
import ProductMostSharedActions, { ProductMostSharedTypes } from '../Redux/ProductMostSharedRedux'
import ProductDetailCollectionMostSharedActions, { ProductDetailCollectionMostSharedTypes } from '../Redux/ProductDetailCollectionMostSharedRedux'
import RecommendationProductInfiniteAction from './../Redux/Reducers/ProductRecommendationInfinte'

const getUserState = state => state.userState

export function* productDetailRequest(api, getToken) {
  let action = yield take(ProductDetailTypes.PRODUCT_DETAIL_REQUEST)
  while (action !== END) {
    yield fork(productDetailRequestAPI, api, getToken, action)
    action = yield take(ProductDetailTypes.PRODUCT_DETAIL_REQUEST)
  }
}

export function* productDetailRequestAPI(api, getToken, { sku }) {
  try {
    if (sku !== '') {
      const token = yield call(getToken)
      const response = yield call(api.getProductDetail, sku, token)
      if (response.ok) {
        return yield put(ProductDetailActions.productDetailSuccess(response.data.data))
      } else {
        return yield put(ProductDetailActions.productDetailFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(ProductDetailActions.productDetailFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productDetailViewRequest(api) {
  let action = yield take(ProductDetailViewTypes.PRODUCT_DETAIL_VIEW_REQUEST)
  while (action !== END) {
    yield fork(productDetailViewRequestAPI, api, action)
    action = yield take(ProductDetailViewTypes.PRODUCT_DETAIL_VIEW_REQUEST)
  }
}

export function* productDetailViewRequestAPI(api, { sku }) {
  try {
    if (sku !== '') {
      const response = yield call(api.getProductDetailView, sku)
      if (response.ok) {
        return yield put(ProductDetailViewActions.productDetailViewSuccess(response.data.data))
      } else {
        return yield put(ProductDetailViewActions.productDetailViewFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(ProductDetailViewActions.productDetailFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productSpecialRequest(api, getToken) {
  let action = yield take(ProductSpecialTypes.PRODUCT_SPECIAL_REQUEST)
  while (action !== END) {
    yield fork(productSpecialRequestAPI, api, getToken, action)
    action = yield take(ProductSpecialTypes.PRODUCT_SPECIAL_REQUEST)
  }
}

export function* productSpecialRequestAPI(api, getToken, { page, limit, qty, sort }) {
  try {
    const token = yield call(getToken)
    const data = {
      page,
      limit,
      qty,
      ob: sort
    }

    const response = yield call(api.getProductSpecial, data, token)
    if (response.ok) {
      return yield put(ProductSpecialActions.productSpecialSuccess(response.data.data))
    } else {
      return yield put(ProductSpecialActions.productSpecialFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductSpecialActions.productSpecialFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productSelectionRequest(api, getToken) {
  let action = yield take(ProductSelectionTypes.PRODUCT_SELECTION_REQUEST)
  while (action !== END) {
    yield fork(productSelectionRequestAPI, api, getToken, action)
    action = yield take(ProductSelectionTypes.PRODUCT_SELECTION_REQUEST)
  }
}

export function* productSelectionRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getCollection, token)
    if (response.ok) {
      return yield put(ProductSelectionActions.productSelectionSuccess(response.data.data))
    } else {
      return yield put(ProductSelectionActions.productSelectionFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductSelectionActions.productSelectionFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productCategoryRequest(api, getToken) {
  let action = yield take(ProductCategoryTypes.PRODUCT_CATEGORY_REQUEST)
  while (action !== END) {
    yield fork(productCategoryAPI, api, getToken, action)
    action = yield take(ProductCategoryTypes.PRODUCT_CATEGORY_REQUEST)
  }
}

export function* productCategoryAPI(api, getToken, { categoryID, page }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getProductCategory, categoryID, page, token)
    if (response.ok) {
      return yield put(ProductCategoryActions.productCategorySuccess(response.data.data))
    } else {
      return yield put(ProductCategoryActions.productCategoryFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductCategoryActions.productCategoryFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productRecommendationRequest(api, getToken) {
  let action = yield take(ProductRecommendationTypes.PRODUCT_RECOMMENDATION_REQUEST)
  while (action !== END) {
    yield fork(productRecommendationAPI, api, getToken, action)
    action = yield take(ProductRecommendationTypes.PRODUCT_RECOMMENDATION_REQUEST)
  }
}

export function* productRecommendationAPI(api, getToken, { limit, page }) {
  try {
    const token = yield call(getToken)
    const data = {
      limit,
      page
    }

    const response = yield call(api.getProductRecommendation, data, token)
    if (response.ok) {
      yield put(RecommendationProductInfiniteAction.recommendationProductInfiniteAdd(page, response.data.data.list))
      return yield put(ProductRecommendationActions.productRecommendationSuccess(response.data.data))
    } else {
      return yield put(ProductRecommendationActions.productRecommendationFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductRecommendationActions.productRecommendationFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productCommissionRequest(api, getToken) {
  let action = yield take(ProductDetailCommissionTypes.PRODUCT_COMMISSION_REQUEST)
  while (action !== END) {
    yield fork(productCommissionRequestAPI, api, getToken, action)
    action = yield take(ProductDetailCommissionTypes.PRODUCT_COMMISSION_REQUEST)
  }
}

export function* productCommissionRequestAPI(api, getToken, { sku, qty }) {
  try {
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    const data = {
      agent_id: agentId,
      sku,
      qty
    }

    const response = yield call(api.getProductDetailCommission, data, token)
    if (response.ok) {
      return yield put(ProductDetailCommissionActions.productCommissionSuccess(response.data.data))
    } else {
      return yield put(ProductDetailCommissionActions.productCommissionFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductDetailCommissionActions.productCommissionFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

// export function* productSetExplanationRequest(api, getToken) {
//   let action = yield take(ProductSetExplanationTypes.PRODUCT_SET_EXPLANATION_REQUEST)
//   while (action !== END) {
//     yield fork(productSetExplanationRequestAPI, api, getToken, action)
//     action = yield take(ProductSetExplanationTypes.PRODUCT_SET_EXPLANATION_REQUEST)
//   }
// }

// export function* productSetExplanationRequestAPI(api, getToken, { sku }) {
//   try {
//     const token = yield call(getToken)

//     const response = yield call(api.getProductSetExplanation, sku, token)
//     if (response.ok) {
//       return yield put(ProductSetExplanationActions.productSetExplanationSuccess(response.data.data))
//     } else {
//       return yield put(ProductSetExplanationActions.productSetExplanationFailure(response.data.message))
//     }
//   } catch (err) {
//     return yield put(ProductSetExplanationActions.productSetExplanationFailure('Terjadi kendala saat melakukan pencarian'))
//   }
// }

export function* productCommissionTierListRequest(api, getToken) {
  let action = yield take(ProductDetailCommissionTierListTypes.PRODUCT_COMMISSION_TIER_LIST_REQUEST)
  while (action !== END) {
    yield fork(productCommissionTierListRequestAPI, api, getToken, action)
    action = yield take(ProductDetailCommissionTierListTypes.PRODUCT_COMMISSION_TIER_LIST_REQUEST)
  }
}

export function* productCommissionTierListRequestAPI(api, getToken, { sku }) {
  try {
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    const data = {
      agent_id: agentId,
      sku
    }

    const response = yield call(api.getProductDetailCommissionTierList, data, token)
    if (response.ok) {
      return yield put(ProductDetailCommissionTierListActions.productCommissionTierListSuccess(response.data.data))
    } else {
      return yield put(ProductDetailCommissionTierListActions.productCommissionTierListFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductDetailCommissionTierListActions.productCommissionTierListFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productUnder300Request(api, getToken) {
  let action = yield take(ProductUnder300Types.PRODUCT_UNDER300_REQUEST)
  while (action !== END) {
    yield fork(productUnder300RequestAPI, api, getToken, action)
    action = yield take(ProductUnder300Types.PRODUCT_UNDER300_REQUEST)
  }
}

export function* productUnder300RequestAPI(api, getToken, {page, limit, qty, sort}) {
  try {
    const token = yield call(getToken)
    const data = {
      page,
      limit,
      qty,
      pmax: 299999,
      pmin: 100000,
      ob: sort
    }
    const response = yield call(api.getProductFilterPrice, data, token)
    if (response.ok) {
      return yield put(ProductUnder300Actions.productUnder300Success(response.data.data))
    } else {
      return yield put(ProductUnder300Actions.productUnder300Failure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductUnder300Actions.productUnder300Failure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productUnder200Request(api, getToken) {
  let action = yield take(ProductUnder200Types.PRODUCT_UNDER200_REQUEST)
  while (action !== END) {
    yield fork(productUnder200RequestAPI, api, getToken, action)
    action = yield take(ProductUnder200Types.PRODUCT_UNDER200_REQUEST)
  }
}

export function* productUnder200RequestAPI(api, getToken, {page, limit, qty, sort}) {
  try {
    const token = yield call(getToken)
    const data = {
      page,
      limit,
      qty,
      pmax: 199999,
      pmin: 100000,
      ob: sort
    }
    const response = yield call(api.getProductFilterPrice, data, token)
    if (response.ok) {
      return yield put(ProductUnder200Actions.productUnder200Success(response.data.data))
    } else {
      return yield put(ProductUnder200Actions.productUnder200Failure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductUnder200Actions.productUnder200Failure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productUnder100Request(api, getToken) {
  let action = yield take(ProductUnder100Types.PRODUCT_UNDER100_REQUEST)
  while (action !== END) {
    yield fork(productUnder100RequestAPI, api, getToken, action)
    action = yield take(ProductUnder100Types.PRODUCT_UNDER100_REQUEST)
  }
}

export function* productUnder100RequestAPI(api, getToken, {page, limit, qty, sort}) {
  try {
    const token = yield call(getToken)
    const data = {
      page,
      limit,
      qty,
      pmax: 99999,
      pmin: 1,
      ob: sort
    }
    const response = yield call(api.getProductFilterPrice, data, token)
    if (response.ok) {
      return yield put(ProductUnder100Actions.productUnder100Success(response.data.data))
    } else {
      return yield put(ProductUnder100Actions.productUnder100Failure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductUnder100Actions.productUnder100Failure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function* productMostSharedRequest(api, getToken) {
  let action = yield take(ProductMostSharedTypes.PRODUCT_MOST_SHARED_REQUEST)
  while (action !== END) {
    yield fork(productMostSharedRequestAPI, api, getToken, action)
    action = yield take(ProductMostSharedTypes.PRODUCT_MOST_SHARED_REQUEST)
  }
}

export function* productMostSharedRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getMostSharedCollection, token)
    if (response.ok) {
      return yield put(ProductMostSharedActions.productMostSharedSuccess(response.data.data))
    } else {
      return yield put(ProductMostSharedActions.productMostSharedFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductMostSharedActions.productMostSharedFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

// Banner Collection
export function* bannerCollectionRequest(api, getToken) {
  let action = yield take(BannerCollectionTypes.BANNER_COLLECTION_REQUEST)
  while (action !== END) {
    yield fork(bannerCollectionRequestAPI, api, getToken, action)
    action = yield take(BannerCollectionTypes.BANNER_COLLECTION_REQUEST)
  }
}

export function* bannerCollectionRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.bannerShowCollection, token)
    if (response.ok) {
      return yield put(BannerCollectionActions.bannerCollectionSuccess(response.data.data))
    } else {
      return yield put(BannerCollectionActions.bannerCollectionFailure(response.data.message))
    }
  } catch (err) {
    return yield put(BannerCollectionActions.bannerCollectionFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

//  Detail collection

export function* productDetailCollectionMostSharedRequest(api, getToken) {
  let action = yield take(ProductDetailCollectionMostSharedTypes.PRODUCT_DETAIL_COLLECTION_MOST_SHARED_REQUEST)
  while (action !== END) {
    yield fork(productDetailCollectionMostSharedRequestAPI, api, getToken, action)
    action = yield take(ProductDetailCollectionMostSharedTypes.PRODUCT_DETAIL_COLLECTION_MOST_SHARED_REQUEST)
  }
}

export function* productDetailCollectionMostSharedRequestAPI(api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getDetailCollection, data, token)
    if (response.ok) {
      return yield put(ProductDetailCollectionMostSharedActions.productDetailCollectionMostSharedSuccess(response.data.data))
    } else {
      return yield put(ProductDetailCollectionMostSharedActions.productDetailCollectionMostSharedFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductDetailCollectionMostSharedActions.productDetailCollectionMostSharedFailure('Terjadi kendala saat melakukan pencarian'))
  }
}
