import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import ProductReducer from './../Reducers/ProductReducer'

export function * calculateShippingCostRequest (api, getToken) {
  let action = yield take(ProductReducer.Types.CALCULATE_SHIPPING_COST_REQUEST)
  while (action !== END) {
    yield fork(calculateShippingCostRequestAPI, api, getToken, action)
    action = yield take(ProductReducer.Types.CALCULATE_SHIPPING_COST_REQUEST)
  }
}

export function * calculateShippingCostRequestAPI (api, getToken, { subdistrict, weight }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.calculateShippingCost, token, subdistrict, weight)
    if (response.ok) {
      return yield put(ProductReducer.Creators.calculateShippingCostSuccess(response.data.data))
    } else {
      return yield put(ProductReducer.Creators.calculateShippingCostFailure(response.data.message))
    }
  } catch (err) {
    return yield put(ProductReducer.Creators.calculateShippingCostFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
