// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  removeFriendCodeRequest: ['friendCode'],
  removeFriendCodeSuccess: ['payload'],
  removeFriendCodeFailure: ['err']
})

export const CouponTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// APPLY FRIEND CODE
//   export const applyFriendCodeRequest = (state) =>
//   state.merge({ fetching: true, success: false, err: null })

//   export const applyFriendCodeSuccess = (state, { payload }) =>
//   state.merge({
//     fetching: false,
//     data: payload,
//     success: true
//   })

//   export const applyFriendCodeFailure = (state, { err }) =>
//   state.merge({
//     fetching: false,
//     success: false,
//     err: err
//   })

// REMOVE FRIEND CODE
export const removeFriendCodeRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const removeFriendCodeSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const removeFriendCodeFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  // [Types.APPLY_FRIEND_CODE_REQUEST]: applyFriendCodeRequest,
  // [Types.APPLY_FRIEND_CODE_SUCCESS]: applyFriendCodeSuccess,
  // [Types.APPLY_FRIEND_CODE_FAILURE]: applyFriendCodeFailure,
  [Types.REMOVE_FRIEND_CODE_REQUEST]: removeFriendCodeRequest,
  [Types.REMOVE_FRIEND_CODE_SUCCESS]: removeFriendCodeSuccess,
  [Types.REMOVE_FRIEND_CODE_FAILURE]: removeFriendCodeFailure
})
