// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userBonusLevelRequest: null,
  userBonusLevelSuccess: ['payload'],
  userBonusLevelFailure: ['err']
})

export const UserBonusLevelTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const userBonusLevelRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userBonusLevelSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userBonusLevelFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_BONUS_LEVEL_REQUEST]: userBonusLevelRequest,
  [Types.USER_BONUS_LEVEL_SUCCESS]: userBonusLevelSuccess,
  [Types.USER_BONUS_LEVEL_FAILURE]: userBonusLevelFailure
})