import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { UpperCase, NumberWithCommas } from '../Utils'

// Redux
import { connect } from 'react-redux'
import UserCommisionActions from '../Redux/UserCommisionRedux'
import UserKYCActions from '../Redux/UserKYCRedux'
import UserBankAccountActions from '../Redux/UserBankAccountRedux'

// Illustration
// import favoriteIcon from '../static/images/w-favorite.svg'
// import pointsIcon from '../static/images/w-points.svg'
import walletIcon from '../static/images/illustrations/saldo.png'
import settlementIcon from '../static/images/illustrations/cairkan.png'
import carIcon from '../static/images/w-car.svg'
import moreInfoIcon from '../static/images/More.svg'

// Antd & Styled
import { Row, Col, Modal, message, Spin } from 'antd'
import { TextSmall, Block, ButtonWhiteShadow, ButtonPrimaryShadow } from './Styled'
import { DividerVertical, DividerLight } from './Styled/Divider'
import WToolbar from './../shared/components/w-toolbar/w-toolbar'

class GeneralHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userCommisionState: undefined,
      modalVisible: false,
      onProgressKYCDialog: false
    }
  }

  minifyName = name => {
    if (!_.isEmpty(name)) {
      if (name.length > 7) {
        return UpperCase(name.substring(0, 7).toLowerCase() + '...')
      } else {
        return UpperCase(name)
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {}
    if (
      !_.isEqual(nextProps.userCommisionState, prevState.userCommisionState) &&
      !_.isEmpty(nextProps.userCommisionState)
    ) {
      updated = { ...updated, userCommisionState: nextProps.userCommisionState }
    }

    if (
      !_.isEqual(nextProps.userState, prevState.userState) &&
      !_.isEmpty(nextProps.userState)
    ) {
      updated = { ...updated, userState: nextProps.userState }
    }

    if (!_.isEmpty(updated)) {
      return updated
    }
    return null
  }

  withdrawRequest = () => {
    const { userKYCState, history, userBankAccountState } = this.props
    const { userCommisionState } = this.state
    const KYCpayload = !userKYCState.fetching && userKYCState.success && !_.isEmpty(userKYCState.data) ? userKYCState.data : {}

    if (!userKYCState.data.verified && KYCpayload.status !== 'pending') {
      this.setState({
        modalVisible: true
      })
    } else if (KYCpayload.status === 'pending') {
      this.setState({
        onProgressKYCDialog: true
      })
    } else if (userKYCState.data.verified && _.isEmpty(userCommisionState.data)) {
      message.warning(
        'Komisi kamu kosong, ayo mulai jualan',
        3,
        message.config({ maxCount: 3 })
      )
    } else if (userKYCState.data.verified) {
      history.push('/withdrawal')
    } else if (_.isEmpty(userBankAccountState.data)) {
      message.warning(
        'Silahkan lengkapi nomor rekening',
        3,
        message.config({ maxCount: 3 })
      )
    }
  }

  componentDidMount() {
    this.props.userCommisionRequest()
    this.props.userKYCRequest()
    this.props.bankAccountListRequest()
  }

  render() {
    const { userCommisionState } = this.state
    const { history } = this.props
    const commisionPayload =
      !userCommisionState.fetching &&
        userCommisionState.success &&
        !_.isEmpty(userCommisionState.data)
        ? userCommisionState.data
        : {}

    return (
      <div>
        {/* <Row
          type='flex'
          justify='space-around'
          align='middle'
          id='fixed-width'
          style={{ backgroundColor: '#e6e9f0', cursor: "pointer", zIndex: 99 }}
          className='fixed-top'
          onClick={() => history.push('/gratis-ongkir')}
        >

          <Col>
            <img src={carIcon} alt='car' height='10px' />
            <TextSmall>
              &nbsp; Gratis Ongkir* 1-3 Hari Pengiriman &nbsp;
            </TextSmall>
            <img src={moreInfoIcon} alt='car' height='13px' />
          </Col>
        </Row> */}

        <div className='wrapper-img'>

          <div style={{ padding: "15%", background: "#6838b2" }}>
            {/* <img src={Image} width='100%' alt='...' /> */}
          </div>

          <Block
            position='absolute'
            marginTop='-25%'
            width='100%'
          >
            <div>
              <WToolbar background="transparent" showCart showSearch showChat2 showNotif />
              {/* <SearchBarViewHeader /> */}
            </div>
          </Block>

          <div>
            <Block position='absolute' marginTop='-10%' width='100%'>
              <Block paddingLeft='20px' paddingRight='20px'>
                <Block
                  backgroundColor='#FFF'
                  borderRadius='12px'
                  boxShadow='0 1px 4px 0 rgba(0, 0, 0, 0.1)'
                >
                  <div className='p-3'>
                    <Row align='middle' justify='space-between' type='flex'>

                      <Col span={4}>
                        <div>
                          <img src={walletIcon} height='35' alt='wallet-icon' />
                        </div>
                      </Col>

                      <Col span={14}>
                        <Row>

                          <Col>
                            {userCommisionState.fetching ? (
                              <span className='font-size-l'>
                                <Spin size='small' />
                              </span>
                            ) : !_.isEmpty(commisionPayload) ? (
                              <div>
                                <TextSmall increase boldest>
                                  Rp{' '}
                                  {NumberWithCommas(
                                    commisionPayload.commission_amount || 0
                                  )}
                                </TextSmall>
                              </div>
                            ) : (
                                  <div>
                                    <TextSmall increase boldest>
                                      Rp 0
                                </TextSmall>
                                  </div>
                                )}
                          </Col>

                          <Col>
                            <div style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/commission')}>
                              <TextSmall
                                color='#9b9b9b'
                                style={{ textDecoration: 'underline' }}
                              >
                                Riwayat Komisi
                              </TextSmall>
                            </div>
                          </Col>

                        </Row>
                      </Col>

                      <Col span={1} style={{ paddingLeft: '10px' }}>
                        <div>
                          <DividerVertical />
                        </div>
                      </Col>

                      <Col span={5} style={{ textAlign: 'center' }}>
                        <Row style={{ cursor: 'pointer' }} onClick={this.withdrawRequest}>

                          <Col>
                            <div>
                              <img src={settlementIcon} width='50%' alt='...' />
                            </div>
                          </Col>

                          <Col>
                            <div>
                              <TextSmall color='#633c97' boldest>
                                Cairkan
                              </TextSmall>
                            </div>
                          </Col>

                        </Row>
                      </Col>

                    </Row>
                  </div>

                  <div>
                    <DividerLight />
                  </div>

                  <div onClick={() => history.push('/history-commission')}>
                    <Block textAlign='center' padding='10px'>
                      <div>
                        <TextSmall color='#633c97' style={{ cursor: 'pointer' }}>
                          Lihat Bonus Mingguan
                        </TextSmall>
                      </div>
                    </Block>
                  </div>

                </Block>
              </Block>
            </Block>
          </div>
        </div>

        <div id='fixed-width'>
          <Modal
            closable={false}
            centered
            bodyStyle={{ textAlign: 'center', lineHeight: '15px' }}
            zIndex={2000}
            footer={null}
            width={317}
            visible={this.state.modalVisible}
          >
            <Block>
              <div>
                <TextSmall color='#000' increase boldest className='font-heading'>
                  Akun belum diverifikasi
                </TextSmall>
              </div>
              <Block paddingTop='8px'>
                <TextSmall>
                  untuk melanjutkan penarikan komisi, Anda harus melakukan
                  verifikasi Akun terlebih dahulu.
                </TextSmall>
              </Block>
              <Block paddingTop='16px'>
                <Row gutter={8} justify='space-between'>
                  <Col span={12}>
                    <ButtonWhiteShadow onClick={() => this.setState({ modalVisible: false })}>
                      <div>
                        <TextSmall
                          primary
                          increase
                          className='font-heading'
                          style={{ color: '#633c97' }}
                        >
                          BATAL
                        </TextSmall>
                      </div>
                    </ButtonWhiteShadow>
                  </Col>
                  <Col span={12}>
                    <ButtonPrimaryShadow onClick={() => this.props.history.push('/account/kyc-id-card')}>
                      <div>
                        <TextSmall
                          primary
                          increase
                          className='font-heading'
                          style={{ color: '#fff' }}
                        >
                          VERIFIKASI
                        </TextSmall>
                      </div>
                    </ButtonPrimaryShadow>
                  </Col>
                </Row>
              </Block>
            </Block>
          </Modal>
        </div>
        
        <Modal
          closable={false}
          centered
          bodyStyle={{ lineHeight: '15px' }}
          zIndex={2000}
          footer={null}
          width={317}
          visible={this.state.onProgressKYCDialog}
        >
          <div className='boldest kyc-dialog-title text-center'>
            Verifikasi Sedang Diproses
          </div>

          <div>
            <Block paddingTop='10px' paddingBottom="12px" className="text-center">
              <p>Proses verifikasi data mu sedang kita proses, harap menunggu ya!</p>
            </Block>
            <Block>
              <Row gutter={8} justify='space-between'>
                <Col span={12}>
                  <ButtonWhiteShadow onClick={() => { window.open('https://api.whatsapp.com/send?phone=6285293311728&text=Halo%20Admin!%20Saya%20ingin%20menanyakan%20tentang%20*Verifikasi%20Akun*%20saya', '_blank') }}>
                    <TextSmall increase className='font-heading'>KONTAK CS</TextSmall>
                  </ButtonWhiteShadow>
                </Col>
                <Col span={12}>
                  <ButtonPrimaryShadow onClick={() => this.setState({ onProgressKYCDialog: !this.state.onProgressKYCDialog })}>
                    <TextSmall increase className='font-heading'>TUTUP</TextSmall>
                  </ButtonPrimaryShadow>
                </Col>
              </Row>
            </Block>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userCommisionState: state.userCommisionState,
  userKYCState: state.userKYCState,
  userBankAccountState: state.userBankAccountState
})

const mapDispatchToProps = dispatch => ({
  userCommisionRequest: () =>
    dispatch(UserCommisionActions.userCommisionRequest()),
  userKYCRequest: () => dispatch(UserKYCActions.userKYCRequest()),
  bankAccountListRequest: () =>
    dispatch(UserBankAccountActions.bankAccountListRequest())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralHeader)
)
