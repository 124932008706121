import React, { Component } from 'react'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
// Style Component for bottom sheet
import { BottomsheetCue } from '../Components/Styled'

class BottomSheet extends Component {
  state = {
    visible: false
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return {
        visible: props.visible
      }
    } else {
      return null
    }
  }

  toggleVisibility = () => {
    this.props.toggleVisibility()
  }

  render() {
    // const close = this.props.close ?? false
    // const { customHeight } = this.props
    return (
      <SwipeableBottomSheet
        open={this.state.visible}
        onChange={() => this.toggleVisibility()}
        style={{
          zIndex: '10000000'
        }}
        bodyStyle={{
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px'
        }}
        {...this.props}
      >
        <div className={`col-xs-12 p-4`} id='fixed-width'>
          <div className='d-flex justify-content-center mb-3'>
            <BottomsheetCue />
          </div>
          {(this.props.close) ? (<div onClick={() => { this.toggleVisibility() }} className="text-right mt-n2">
            <span style={{
              cursor: "pointer",
              color: "#633c97",
            }}>Tutup</span>
          </div>) : ""}
          {this.props.children}
        </div>
      </SwipeableBottomSheet>
    )
  }
}
export default BottomSheet
