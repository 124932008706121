import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'
import _ from 'lodash'

// Catalog
import storeActions, { StoreTypes } from '../Redux/StoreRedux'

export function* storeRequest(api, getToken) {
  let action = yield take(StoreTypes.GET_STORE_REQUEST)
  while (action !== END) {
    yield fork(storeRequestAPI, api, getToken, action)
    action = yield take(StoreTypes.GET_STORE_REQUEST)
  }
}

export function * storeRequestAPI (api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.detailStore, token)
    if (response.ok) {
        return yield put(storeActions.getStoreSuccess(response.data.data))
    } else {
        return yield put(storeActions.getStoreFailure(response.data.data))
    }
  } catch (err) {
    return yield put(storeActions.getStoreFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}