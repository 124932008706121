// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userCommissionHistoryRequest: null,
  userCommissionHistorySuccess: ['payload'],
  userCommissionHistoryFailure: ['err']
})

export const UserCommissionHistoryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Commission History Data
export const userCommissionHistoryRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userCommissionHistorySuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userCommissionHistoryFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_COMMISSION_HISTORY_REQUEST]: userCommissionHistoryRequest,
  [Types.USER_COMMISSION_HISTORY_SUCCESS]: userCommissionHistorySuccess,
  [Types.USER_COMMISSION_HISTORY_FAILURE]: userCommissionHistoryFailure
})
