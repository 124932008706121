// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  init: null,
  salesOrderListItemRequest: ['orderNo'],
  salesOrderListItemSuccess: ['payload'],
  salesOrderListItemFailure: ['err']
})

export const SalesOrderListItemTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const request = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const success = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SALES_ORDER_LIST_ITEM_REQUEST]: request,
  [Types.SALES_ORDER_LIST_ITEM_SUCCESS]: success,
  [Types.SALES_ORDER_LIST_ITEM_FAILURE]: failure
})
