// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  socialShareDetailInit: null,
  socialShareDetailRequest: ['loc'],
  socialShareDetailSuccess: ['payload'],
  socialShareDetailFailure: ['err']
})

export const SocialShareDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const socialShareDetailInit = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false
  })

export const socialShareDetailRequest = (state) =>
  state.merge({
    fetching: true,
    success: false
  })

export const socialShareDetailSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
    err: null
  })

export const socialShareDetailFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SOCIAL_SHARE_DETAIL_INIT]: socialShareDetailInit,
  [Types.SOCIAL_SHARE_DETAIL_REQUEST]: socialShareDetailRequest,
  [Types.SOCIAL_SHARE_DETAIL_SUCCESS]: socialShareDetailSuccess,
  [Types.SOCIAL_SHARE_DETAIL_FAILURE]: socialShareDetailFailure
})
