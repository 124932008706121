import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

import salesOrderActions, { salesOrderTypes } from '../Redux/SalesOrderRedux'
import SalesOrderListItemActions, { SalesOrderListItemTypes } from '../Redux/SalesOrderListItem'
import SalesInvoiceBrandActions, { SalesInvoiceBrandTypes } from '../Redux/SalesInvoiceBrandRedux'
import SalesCompleteInvoiceActions, { salesCompleteInvoiceTypes } from '../Redux/SalesCompleteInvoice'

export function * salesOrderRequest (api, getToken) {
  let action = yield take(salesOrderTypes.SALES_ORDER_REQUEST)
  while (action !== END) {
    yield fork(salesOrderRequestAPI, api, getToken, action)
    action = yield take(salesOrderTypes.SALES_ORDER_REQUEST)
  }
}

export function * salesOrderRequestAPI (api, getToken, { statusOrder }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.salesOrderList, statusOrder, token)
    if (response.ok) {
      return yield put(salesOrderActions.salesOrderSuccess(response.data.data))
    } else {
      return yield put(salesOrderActions.salesOrderFailure(response.data.message))
    }
  } catch (err) {
    return yield put(salesOrderActions.salesOrderFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * salesOrderListItemRequest (api, getToken) {
  let action = yield take(SalesOrderListItemTypes.SALES_ORDER_LIST_ITEM_REQUEST)
  while (action !== END) {
    yield fork(salesOrderListItemRequestAPI, api, getToken, action)
    action = yield take(SalesOrderListItemTypes.SALES_ORDER_LIST_ITEM_REQUEST)
  }
}

export function * salesOrderListItemRequestAPI (api, getToken, { orderNo }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getSalesOrderFull, orderNo, token)
    if (response.ok) {
      return yield put(SalesOrderListItemActions.salesOrderListItemSuccess(response.data.data))
    } else {
      return yield put(SalesOrderListItemActions.salesOrderListItemFailure(response.data.message))
    }
  } catch (err) {
    return yield put(SalesOrderListItemActions.salesOrderListItemFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * salesInvoiceBrandRequest (api, getToken) {
  let action = yield take(SalesInvoiceBrandTypes.SALES_INVOICE_BRAND_REQUEST)
  while (action !== END) {
    yield fork(salesInvoiceBrandRequestAPI, api, getToken, action)
    action = yield take(SalesInvoiceBrandTypes.SALES_INVOICE_BRAND_REQUEST)
  }
}

export function * salesInvoiceBrandRequestAPI (api, getToken, { noInvoice }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getInvoiceBrand, noInvoice, token)
    if (response.ok) {
      return yield put(SalesInvoiceBrandActions.salesInvoiceBrandSuccess(response.data.data))
    } else {
      return yield put(SalesInvoiceBrandActions.salesInvoiceBrandFailure(response.data.message))
    }
  } catch (err) {
    return yield put(SalesInvoiceBrandActions.salesInvoiceBrandFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
export function * salesCompleteInvoiceRequest (api, getToken) {
  let action = yield take(salesCompleteInvoiceTypes.SALES_COMPLETE_INVOICE_REQUEST)
  while (action !== END) {
    yield fork(salesCompleteInvoiceRequestAPI, api, getToken, action)
    action = yield take(salesCompleteInvoiceTypes.SALES_COMPLETE_INVOICE_REQUEST)
  }
}

export function * salesCompleteInvoiceRequestAPI (api, getToken, { noInvoice }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.completeOrder, noInvoice, token)
    if (response.ok) {
      return yield put(SalesCompleteInvoiceActions.salesCompleteInvoiceSuccess(response.data.data))
    } else {
      return yield put(SalesCompleteInvoiceActions.salesCompleteInvoiceFailure(response.data.message))
    }
  } catch (err) {
    return yield put(SalesCompleteInvoiceActions.salesCompleteInvoiceFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
