import { parse } from 'date-fns'

export const SplitDateAndTime = (string) => {
  return getDateFormat(string)
}

export const dateFormatter = (date) => {
  // Format: Senin, 13 Mei 2019, 02:25 WIB
  let day = ['Minggu', 'Senin', 'Selasa', 'Rabu',
    'Kamis', 'Jumat', 'Sabtu']
  let month = ['Januari', 'Februari', 'Maret', 'April', 'Mei',
    'Juni', 'Juli', 'Agustus', 'September', 'Oktober',
    'November', 'Desember']
  let minutes = date.getMinutes().toString()

  return [`${day[date.getDay()]}`,
    `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`,
    `${date.getHours()}:${minutes.padStart(2, '0')} WIB`]
}

export const getDateFormat = (date) => {
  let updated = parse(date)
  return dateFormatter(updated)
}

export const getDeadline = (date) => {
  let updated = parse(date)
  updated.setDate(updated.getDate() + 1)
  return dateFormatter(updated)
}
