import { put, take, fork, cancel, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import SearchActions, { SearchTypes } from '../Redux/SearchRedux'

export function * searchRequest (api, getToken) {
  let lastTask
  while (true) {
    const action = yield take(SearchTypes.SEARCH_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(searchRequestAPI, api, getToken, action)
  }
}

export function * searchRequestAPI (api, getToken, { searchTerm, searchLimit, sort, pmin, pmax, category }) {
  try {
    if (searchTerm !== '') {
      const token = yield call(getToken)
      const data = {
        q: searchTerm,
        limit: searchLimit,
        ob: sort,
        pmin: pmin,
        pmax: pmax,
        pc: category
      }
      const response = yield call(api.searchProduct, data, token)
      if (response.ok) {
        return yield put(SearchActions.searchSuccess(response.data.data))
      } else {
        return yield put(SearchActions.searchFailure('Terjadi kendala saat melakukan pencarian'))
      }
    }
  } catch (err) {
    return yield put(SearchActions.searchFailure('Terjadi kendala saat melakukan pencarian'))
  }
}

export function * popularSearchRequest (api, getToken) {
  let action = yield take(SearchTypes.POPULAR_SEARCH_REQUEST)
  while (action !== END) {
    yield fork(popularSearchAPI, api, getToken, action)
    action = yield take(SearchTypes.POPULAR_SEARCH_REQUEST)
  }
}

export function * popularSearchAPI (api, getToken) {
  const token = yield call(getToken)
  const response = yield call(api.getPopularSearch, token)
  try {
    if (response.ok) {
      return yield put(SearchActions.popularSearchSuccess(response.data))
    } else {
      return yield put(SearchActions.popularSearchFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      SearchActions.popularSearchFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}
