import { useQuery } from 'react-query';
import Immutable from 'seamless-immutable';
import { api, token as getToken } from '../../Redux/Apis';
import { getSafely } from '../../Utils';

export const useRequestCatalogList = async () => {
  const limit = 6;
  // const { data, isFetching } = useQuery("catalog-list", async () => {
  try {
    const token = await getToken();
    const response = await api.getCatalog(token, limit);
    return Immutable(getSafely(['data', 'data', 'list'], response, []));
  } catch (error) {
    console.error(error);
  }
};
// return { data, isFetching };

export const useRequestCatalogListRQ = () => {
  const limit = 6;
  const { data, isFetching } = useQuery('catalog-list', async () => {
    try {
      const token = await getToken();
      const apiURL = api.getCatalog(token, limit);
      const response = await apiURL;
      return Immutable(getSafely(['data', 'data', 'list'], response, []));
    } catch (error) {
      console.error(error);
    }
  }, {
    staleTime: 6000,
  });
  return { data, isFetching };
};

export const UseCheckProductCatalog = async (payload) => {
  try {
    const token = await getToken();
    const response = await api.checkProductCatalog(token, payload);
    return Immutable(response);
  } catch (error) {
    console.error(error);
  }
};
