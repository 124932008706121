/* eslint-disable no-multi-assign */
/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import { PersistGate } from 'redux-persist/integration/react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import storage from 'localforage';
import { isEmpty } from 'lodash';
import PageLoader from 'shared/components/loader/page-laoder';
import Data from './legacy/Redux';
import { App } from './App';

// SCSS
import 'swiper/css';

import 'antd/dist/antd.css';
import 'font-awesome/scss/font-awesome.scss';
import 'bootstrap/scss/bootstrap.scss';
import './legacy/static/scss/app.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-owl-carousel2/lib/styles.css';
import config from './legacy/config';
import { getUrlParameter } from './legacy/Utils';

// Service Worker
// import * as serviceWorker from './serviceWorker'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
if (!config.consoleLog) {
  if (!window.console) window.console = {};
  console.log(
    '%cI know you can do better, join us at careers.smartrecruiters.com/woobiz',
    'color:#C23A2C;font-size:24px;',
  );
  console.log = console.warn = console.error = () => {};
}

// Check referrals
if (!isEmpty(getUrlParameter('ref'))) {
  storage.setItem('refcode', getUrlParameter('ref'));
}

// if (window.Capacitor.isNative) {
document.querySelector('body').classList.add('native-page');
// } else {
//   document.querySelector('body').classList.add('web-page');
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={Data.store}>
    <PersistGate loading={<PageLoader />} persistor={Data.persistor}>
      <ConnectedRouter history={Data.history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
