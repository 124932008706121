// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  init: null,
  changePaymentRequest: ['data'],
  changePaymentSuccess: ['payload'],
  changePaymentFailure: ['err']
})

export const ChangePaymentTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false,
    err: null
  })

export const changePaymentRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const changePaymentSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
})

export const changePaymentFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT]: init,
  [Types.CHANGE_PAYMENT_REQUEST]: changePaymentRequest,
  [Types.CHANGE_PAYMENT_SUCCESS]: changePaymentSuccess,
  [Types.CHANGE_PAYMENT_FAILURE]: changePaymentFailure,
})
