// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getStoreRequest: null,
  getStoreSuccess: ['payload'],
  getStoreFailure: ['err'],
})

export const StoreTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// GET STORE
export const getStoreRequest = (state) =>
state.merge({ fetching: true, success: false, err: null })

export const getStoreSuccess = (state, { payload }) =>
state.merge({
  fetching: false,
  data: payload,
  success: true
})

export const getStoreFailure = (state, { err }) =>
state.merge({
  fetching: false,
  success: false,
  err
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_STORE_REQUEST]: getStoreRequest,
  [Types.GET_STORE_SUCCESS]: getStoreSuccess,
  [Types.GET_STORE_FAILURE]: getStoreFailure,
})
