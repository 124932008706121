import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import MasterAddressActions, { MasterAddressTypes } from '../Redux/MasterAddressRedux'
import BankTransferListActions, { BankTransferListTypes } from '../Redux/BankTransferListRedux'

export function * provinceRequest (api, getToken, getCartId) {
  let action = yield take(MasterAddressTypes.PROVINCE_REQUEST)
  while (action !== END) {
    yield fork(provinceRequestAPI, api, getToken, getCartId, action)
    action = yield take(MasterAddressTypes.PROVINCE_REQUEST)
  }
}

export function * provinceRequestAPI (api, getToken, getCartId) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.getProvinces, token)
    if (response.ok) {
      return yield put(MasterAddressActions.provinceSuccess(response.data.data.provinces))
    } else {
      return yield put(MasterAddressActions.provinceFailure(response.data.message))
    }
  } catch (err) {
    return yield put(MasterAddressActions.provinceFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * cityRequest (api, getToken, getCartId) {
  let action = yield take(MasterAddressTypes.CITY_REQUEST)
  while (action !== END) {
    yield fork(cityRequestAPI, api, getToken, getCartId, action)
    action = yield take(MasterAddressTypes.CITY_REQUEST)
  }
}

export function * cityRequestAPI (api, getToken, getCartId, { provinceId }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.getCities, provinceId, token)
    if (response.ok) {
      return yield put(MasterAddressActions.citySuccess(response.data.data.cities))
    } else {
      return yield put(MasterAddressActions.cityFailure(response.data.message))
    }
  } catch (err) {
    return yield put(MasterAddressActions.cityFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * districtRequest (api, getToken, getCartId) {
  let action = yield take(MasterAddressTypes.DISTRICT_REQUEST)
  while (action !== END) {
    yield fork(districtRequestAPI, api, getToken, getCartId, action)
    action = yield take(MasterAddressTypes.DISTRICT_REQUEST)
  }
}

export function * districtRequestAPI (api, getToken, getCartId, { cityId }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.getDistricts, cityId, token)
    if (response.ok) {
      return yield put(MasterAddressActions.districtSuccess(response.data.data.districts))
    } else {
      return yield put(MasterAddressActions.districtFailure(response.data.message))
    }
  } catch (err) {
    return yield put(MasterAddressActions.districtFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Bank Transfer List
export function * bankTransferListRequest (api, getToken, getCartId) {
  let action = yield take(BankTransferListTypes.BANK_TRANSFER_LIST_REQUEST)
  while (action !== END) {
    yield fork(bankTransferListRequestAPI, api, getToken, getCartId, action)
    action = yield take(BankTransferListTypes.BANK_TRANSFER_LIST_REQUEST)
  }
}

export function * bankTransferListRequestAPI (api, getToken, getCartId) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.getBankTransferList, token)
    if (response.ok) {
      return yield put(BankTransferListActions.bankTransferListSuccess(response.data.data))
    } else {
      return yield put(BankTransferListActions.bankTransferListFailure(response.data.message))
    }
  } catch (err) {
    return yield put(BankTransferListActions.bankTransferListFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
