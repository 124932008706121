import React, { useEffect } from "react";
import "./styles.scss";

import WToolbar from "../../../shared/components/w-toolbar/w-toolbar";
import config from "../../../config";

export const TentangGPS = () => {
  return (
    <>
      <WToolbar fixed backButton showChat>
        Garansi Pasti Sukses
      </WToolbar>
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_0.jpg`}
        alt="..."
        width="100%"
      />
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_1.jpg`}
        alt="..."
        width="100%"
      />
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_2.jpg`}
        alt="..."
        width="100%"
      />
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_3.jpg`}
        alt="..."
        width="100%"
      />
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_4.jpg`}
        alt="..."
        width="100%"
      />
      <img
        src={`${config.imageURL}/v1606813378/gps-assets/Booklet_GPS_Landing_Page_5.jpg`}
        alt="..."
        width="100%"
      />
    </>
  );
};

export const Emas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WToolbar fixed backButton showChat>
        Garansi Pasti Sukses
      </WToolbar>
      <div>
        <div>
          <img src={`${config.imageURL}/native-assets/gps/emas.png`} alt="..." width="100%" />
        </div>
        <div className="emas">
          <span style={{ fontSize: "12px" }}>
            Mitra yang mengikuti program GPS akan mengikuti serangkaian
            tantangan menuju Mitra Sukses berupa menjual produk, konsultasi
            dengan Tim Wooniversity, Webinar, hingga tantangan khusus. Disetiap
            tantangan yang sudah dilalui, Mitra berhak mendapatkan sejumlah emas
            yang dapat dikumpulkan. Jumlah Emas yang berhasil terkumpul akan
            menjadi penilaian agar Mitra layak menjadi Mitra Sukses.
          </span>
        </div>
        <div>
          <div className="p-3" style={{ backgroundColor: "#f3f4f6" }}>
            <img src={`${config.imageURL}/native-assets/gps/jalur-emas.png`} alt="..." width="100%" />
          </div>
        </div>
      </div>
    </>
  );
};

export const CommissionGPS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WToolbar fixed backButton showChat>
        Garansi Pasti Sukses
      </WToolbar>
      <div>
        <div>
          <img src={`${config.imageURL}/native-assets/gps/komisi.png`} alt="..." width="100%" />
        </div>
        <div style={{ backgroundColor: "#f3f4f6", padding: "1rem" }}>
          <div className="gps-commission">
            <div>
              <span style={{ fontSize: "12px" }}>
                Selain kamu dapat mengambil keuntungan dari margin harga jual
                produk yang kamu tawarkan kembali kepelanggan, Kamu berhak
                mendapatkan komisi langsung disetiap pembelian Paket produk
                jualan yang ada di Woobiz. Komisi bervariasi dan dapat dicairkan
                ke rekeningmu dengan Mudah.
              </span>
            </div>
            <div className="pt-3">
              <span style={{ fontSize: "12px" }}>
                Berikut ketentuan untuk mendapatkan Komisi langsung dari Woobiz
                :
              </span>
            </div>
            <div className="pt-3">
              <ol style={{ fontSize: "12px", fontWeight: "bold" }}>
                <li>
                  Komisi hanya berlaku untuk pembelian produk secara paket
                </li>
                <li>
                  Jumlah yang kamu dapatkan dapat di check secara langsung di
                  informasi komisi beli paket yang tersedia di bawah deskripsi
                  produk
                </li>
                <li>
                  Komisi akan masuk ke Akun Woobizmu setelah status pemesanan
                  kamu sudah selesai
                </li>
                <li>
                  Jadwal pencairan Komisi akan dilakukan setiap hari jumat mulai
                  pukul 12 siang
                </li>
              </ol>
            </div>
            <div>
              <span style={{ fontSize: "12px" }}>
                Jika kamu sudah mengumpulkan komisimu dan hendak di cairkan ke
                rekening. Berikut syarat dan ketentuan pencairkan komisi :
              </span>
            </div>
            <div className="pt-3">
              <ol style={{ fontSize: "12px", fontWeight: "bold" }}>
                <li>
                  Komisi dapat dicairkan ke Rekening dengan minimum nominal Rp
                  50.000,-
                </li>
                <li>
                  Mitra akan dikenakan biaya administrasi pencairan komisi Rp
                  2.500,- / permintaan
                </li>
                <li>
                  Untuk mencairkan komisi, Mitra dapat memilih menu cairkan
                  komisi
                </li>
                <li>
                  Permintaan pencairan komisi pada hari jumat diatas jam 12
                  siang akan diproses di hari jumat berikutnya.
                </li>
                <li>
                  Komisi akan diterima di rekening mitra setiap hari jumat
                  maksimal jam 6 sore
                </li>
                <li>
                  Mitra harus melakukan Verifikasi data KTP pada menu Verifikasi
                  data diri
                </li>
                <li>
                  Mitra harus meMasukkan nomor rekening aktif atas nama mitra
                  sesuai dengan KTP yang di daftarkan
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const UndianGPS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WToolbar fixed backButton showChat>
        Garansi Pasti Sukses
      </WToolbar>
      <div>
        <div>
          <img src={`${config.imageURL}/native-assets/gps/undian.png`} alt="..." width="100%" />
        </div>
        <div style={{ backgroundColor: "#f3f4f6", padding: "1rem" }}>
          <div className="gps-commission">
            <div>
              <span style={{ fontSize: "12px" }}>
                Setiap minggunya Woobiz akan memberikan kejutan berupa hadiah
                menarik kepada Mitra Woobiz yang beruntung. Hadiah bisa berupa
                Voucer potongan harga, Voucer Gratis ongkir, hingga produk -
                produk menarik.
              </span>
            </div>
            <div className="pt-3">
              <span style={{ fontSize: "12px" }}>
                Berikut ketentuan Undian Mingguan
              </span>
            </div>
            <div className="pt-3">
              <ol style={{ fontSize: "12px", fontWeight: "bold" }}>
                <li>
                  Undian hanya dapat diikuti oleh Mitra yang mengikuti program
                  GPS
                </li>
                <li>
                  Setiap minggunya Mitra akan mendapatkan notifikasi di aplikasi
                  berupa hadiah yang akan diundi
                </li>
                <li>
                  Mitra dapat mengikuti undian dengan cara mengumpulkan sebanyak
                  banyaknya emas di minggu tersebut
                </li>
                <li>
                  Semakin banyak emas yang terkumpul maka semakin banyak
                  kesempatan mitra untuk menang
                </li>
                <li>
                  Pemenang undian mingguan akan diumumkan disetiap hari Senin
                  pukul 4 sore di Instagram Woobiz
                </li>
              </ol>
            </div>
            <div className="pt-2">
              <span style={{ fontSize: "12px" }}>
                Yuk kumpulkan emasmu sebanyak-banyaknya untuk memperbesar
                kesempatan kamu untuk memenangkan undian mingguan.
              </span>
            </div>
            <div className="p-3">
              <img src={`${config.imageURL}/native-assets/gps/gold.png`} alt="..." width="100%" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PeluangUsaha = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WToolbar fixed backButton showChat>
        Garansi Pasti Sukses
      </WToolbar>
      <div>
        <div>
          <img src={`${config.imageURL}/native-assets/gps/peluang-usaha.png`} alt="..." width="100%" />
        </div>
        <div style={{ backgroundColor: "#f3f4f6", padding: "1rem" }}>
          <div className="gps-commission">
            <div>
              <span style={{ fontSize: "12px" }}>
                Woobiz berkomitmen menjadi partner terbaik bagi Mitra yang
                memiliki niat keras untuk sukses dengan meraih pendapatan.
                Melalui program GPS Woobiz, Woobiz akan mengajak Mitra potensial
                yang berhasil melalui jalur GPS Woobiz untuk memperluas potensi
                usaha yang sudah diraih.
              </span>
            </div>
            <div className="pt-3">
              <span style={{ fontSize: "12px" }}>
                Woobiz berharap bisa menjangkau lebih banyak lagi Mitra di
                Indonesia khususnya Ibu-Ibu. Oleh karena itu Woobiz akan
                berjalan beriringan bersama Mitra terbaik yang memiliki tekad
                yang sama dengan Woobiz untuk saling memberikan dampak pada
                kesejahteraan ekonomi.
              </span>
            </div>

            <div className="pt-3">
              <div>
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  1. Ketua Perkumpulan Woobiz
                </span>
              </div>
              <span style={{ fontSize: "12px" }}>
                Ketua Perkumpulan adalah sosok Mitra sukses yang akan
                mengkoordinasi Area tertentu di sebuah wilayah untuk dapat
                memajukan potensi Mitra lainnya di area tersebut melalui
                keberadaan Woobiz dan program-program unggulannya.
              </span>
            </div>
            <div className="pt-3">
              <div>
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  2. Hub Woobiz
                </span>
              </div>
              <span style={{ fontSize: "12px" }}>
                Hub Woobiz adalah Pusat bisnis Woobiz yang menjangkau beberapa
                area di sebuah wilayah. Hub akan memfasilitasi Mitra Woobiz di
                areanya untuk mendapatkan jangkauan produk lebih cepat dan
                efisien.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
