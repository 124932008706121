import React from 'react';
import PaddingType, {
  PaddingTypeStyledHelper,
} from 'shared/types/padding.type';
import styled, { css } from 'styled-components';

export const applicationResponsive = css`
  width: 375px;
  max-width: 100%;

  /* @media only screen and (max-width: 768px) {
    width: 100%;
  } */
`;

export const ContainerGroup = styled.div`
  min-width: 100%;
  min-height: 100%;
  background: #e0e0e0e0;
`;

export const ContainerInner = styled.div`
  ${applicationResponsive}
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
`;

const ContainerBody = styled.div<
  {
    background?: string;
    flexDirection?: string;
    alignItems?: string;
    justifyContent?: string;
  } & PaddingType
>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.background ? props.background : 'transparent')};
  align-items: ${(props) => (props.alignItems)};
  justify-content: ${(props) => (props.justifyContent)}; 
  ${PaddingTypeStyledHelper}
`;

const ContainerFooter = styled.div`
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 2;
  ${applicationResponsive}
`;

const Container = (props: { children: React.ReactNode }) => (
  <ContainerGroup>
    <ContainerInner>{props.children}</ContainerInner>
  </ContainerGroup>
);

Container.defaultProps = {};

export { ContainerBody, ContainerFooter };

export default Container;
