import React from 'react';
import { Flex } from '../flex';
import Padded from '../padded';

// Image
import Logo from './logo.svg';

function PageLoader() {
  return (
    <Padded background="#fff">
      <Flex style={{ height: '100vh' }} justify="center" align="center">
        <img src={Logo} width="50%" alt="logo" />
      </Flex>
    </Padded>
  );
}

export default PageLoader;
