// @flow
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------ Types And Action Creators ------------ */

const { Types, Creators } = createActions({
  mailboxListRequest: ["limit", "page"],
  mailboxListSuccess: ["payload"],
  mailboxListFailure: ["err"],

  mailboxReadRequest: ["notification_id"],
  mailboxReadSuccess: ["payload"],
  mailboxReadFailure: ["err"],

  mailboxUnreadRequest: null,
  mailboxUnreadSuccess: ["payload"],
  mailboxUnreadFailure: ["err"],

  mailboxDeleteRequest: ["notification_id"],
  mailboxDeleteSuccess: ["payload"],
  mailboxDeleteFailure: ["err"],
});

export const MailboxTypes = Types;
export default Creators;

/* ------------- INITIAL_STATE ------------ */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  notification_id: {},
  success: false,
  err: null,
});

/* ------------- Reducer ------------ */

// Fetch Mailbox
export const mailboxListRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null });

export const mailboxListSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
  });

export const mailboxListFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err,
  });

// Read Mailbox
export const mailboxReadRequest = (state) =>
  state.merge({ fetching: true, success: false, err: false });

export const mailboxReadSuccess = (state, { notification_id }) =>
  state.merge({
    fetching: false,
    notification_id: notification_id,
    success: true,
  });

export const mailboxReadFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err,
  });

// Unread Mailbox
export const mailboxUnreadRequest = (state) =>
  state.merge({ fetching: true, success: false, err: false });

export const mailboxUnreadSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
  });

export const mailboxUnreadFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err,
  });

// Delete Mailbox
export const mailboxDeleteRequest = (state) =>
  state.merge({ fetching: true, success: false, err: false });

export const mailboxDeleteSuccess = (state, { notification_id }) =>
  state.merge({
    fetching: false,
    notification_id: notification_id,
    success: true,
  });

export const mailboxDeleteFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err,
  });

/* ---------- Hookedup Reducers To Types ----------- */
export const mailboxListReducer = createReducer(INITIAL_STATE, {
  [Types.MAILBOX_LIST_REQUEST]: mailboxListRequest,
  [Types.MAILBOX_LIST_SUCCESS]: mailboxListSuccess,
  [Types.MAILBOX_LIST_FAILURE]: mailboxListFailure,
});

export const mailboxReadReducer = createReducer(INITIAL_STATE, {
  [Types.MAILBOX_READ_REQUEST]: mailboxReadRequest,
  [Types.MAILBOX_READ_SUCCESS]: mailboxReadSuccess,
  [Types.MAILBOX_READ_FAILURE]: mailboxReadFailure,
});

export const mailboxUnreadReducer = createReducer(INITIAL_STATE, {
  [Types.MAILBOX_UNREAD_REQUEST]: mailboxUnreadRequest,
  [Types.MAILBOX_UNREAD_SUCCESS]: mailboxUnreadSuccess,
  [Types.MAILBOX_UNREAD_FAILURE]: mailboxUnreadFailure,
});

export const mailboxDeleteReducer = createReducer(INITIAL_STATE, {
  [Types.MAILBOX_DELETE_REQUEST]: mailboxDeleteRequest,
  [Types.MAILBOX_DELETE_SUCCESS]: mailboxDeleteSuccess,
  [Types.MAILBOX_DELETE_FAILURE]: mailboxDeleteFailure,
});
