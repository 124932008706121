import React, { useEffect } from 'react'
import config from '../../config'
// import { useHistory } from 'react-router-dom'

// Components
import GeneralHeaderEvent from '../../Components/navigations/GeneralHeaderEvent'
import {
  KodeSahabatHowToShareSlider,
  KodeSahabatHowToUseSlider
} from '../../Components/micro-components/KodeSahabatSlider'
import WooniversityPage from './WooniversityPages/Wooniversity'
import BlogPage from './DynamicPage'
import GratisOngkir from './GratisOngkirPage'

export const Wooniversity = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <WooniversityPage />
    </div>
  )
}

export const DynamicPage = props => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <BlogPage />
    </div>
  )
}

// export const SampleDynamic = props => {
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

//   return (
//     <div>
//       <SampleDynamicPage />
//     </div>
//   )
// }

export const KodeSahabatPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <GeneralHeaderEvent title='Kode Sahabat' />
      <div className='pt-4'>
        <img
          src={`${config.imageURL}/event/kode-sahabat/Banner_Kode_Sahabat1`}
          width='100%'
          alt='...'
        />
      </div>
      <div style={{ backgroundColor: '#BCE2DD' }}>
        <KodeSahabatHowToShareSlider />
      </div>
      <img
        src={`${config.imageURL}/event/kode-sahabat/4`}
        width='100%'
        alt='...'
      />
      <div style={{ backgroundColor: '#BCE2DD' }}>
        <KodeSahabatHowToUseSlider />
        <div className='pt-2'>
          <img
            src={`${config.imageURL}/event/kode-sahabat/Banner_Kode_Sahabat`}
            width='100%'
            alt='...'
          />
        </div>
      </div>
    </div>
  )
}

export const GratisOngkirPage = () => {
  return (
    <GratisOngkir />
  )
}

export const CommissionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='background-white'>
      <GeneralHeaderEvent title='Komisi Woobiz' />
      <div className='padding-top-l'>
        <img
          src={`${config.imageURL}/event/commission/commission`}
          width='100%'
          alt='...'
        />
        <a
          href={`https://api.whatsapp.com/send?phone=6285293311728&text=Halo%20Admin!%20Saya%20ingin%20bertanya%20mengenai%20Woobiz%0AMohon%20bantuannya%20ya%20:)`}
          target='blank'
        >
          <img
            src={`${config.imageURL}/event/commission/Footer`}
            width='100%'
            alt='...'
          />
        </a>
      </div>
    </div>
  )
}
