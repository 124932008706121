import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import PopularSearchActions, { PopularSearchTypes } from '../Reducers/PopularSearchReducer'

export function * popularSearchRequest (api, getToken) {
  let action = yield take(PopularSearchTypes.POPULAR_SEARCH_REQUEST)
  while (action !== END) {
    yield fork(popularSearchAPI, api, getToken, action)
    action = yield take(PopularSearchTypes.POPULAR_SEARCH_REQUEST)
  }
}

export function * popularSearchAPI (api, getToken) {
  const token = yield call(getToken)
  const response = yield call(api.getPopularSearch, token)
  try {
    if (response.ok) {
      return yield put(PopularSearchActions.popularSearchSuccess(response.data.data))
    } else {
      return yield put(PopularSearchActions.popularSearchFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      PopularSearchActions.popularSearchFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}
