import React from 'react'
import config from '../../config'
import Slider from 'react-slick'

export const KodeSahabatHowToShareSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'kode-sahabat-slider-share'
  }

  return (
    <div>
      <Slider {...settings}>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/3a`} width='100%' alt='...' />
        </div>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/3b`} width='100%' alt='...' />
        </div>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/3c`} width='100%' alt='...' />
        </div>
      </Slider>
    </div>
  )
}

export const KodeSahabatHowToUseSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'kode-sahabat-slider-use'
  }

  return (
    <div>
      <Slider {...settings}>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/5a`} width='100%' alt='...' />
        </div>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/5b`} width='100%' alt='...' />
        </div>
        <div>
          <img src={`${config.imageURL}/event/kode-sahabat/5c`} width='100%' alt='...' />
        </div>
      </Slider>
    </div>
  )
}
