export default {
  env: 'dev',
  // DEBUG: true,
  consoleLog: true,
  apiURL: 'https://wapi.rintiz.id/',
  imageURL: 'https://res.cloudinary.com/rintiz/image/upload/f_auto,q_auto,fl_lossy',
  browserRouterBasename: '',
  firstPathURL: window.location.host.split('.')[0], // nahar
  productURL: 'https://wapi.rintiz.id/',
  linkShare: 'http://share.woobiz.id',
  ajukanProdukURL: 'https://wapi.rintiz.id',
  sentryDsn: 'https://6040a5e6f1144fcfa37a8cf1ce4422f9@sentry.io/1830191',
  oneSignalId: '37d20a7e-e6a1-4c6b-b4d0-de62fcf70a3d',
  oneSignalGpn: '902722273961',
  customerServiceContact: {
    name: 'Woobiz',
    phone: '+6285293311728',
  },
  googleAnalytics: {
    web: 'UA-167961090-1',
    mobile: 'UA-167961090-1',
  },
};
