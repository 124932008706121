import React, { Component } from 'react';
import { withRouter } from 'react-router';
// import config from '../../../config'
import _ from 'lodash';
// import BottomSheet from '../../BottomSheet'
import { message } from 'antd';

// Redux
import { connect } from 'react-redux';
import { Spin } from 'antd';
import ProductCategoryActions from '../../../Redux/ProductCategoryRedux';
import BannerRecommendationCreator from '../../../Redux/Reducers/Banners/BannerRecommendationReducer';

import DefaultLayout from '../../../Layouts/DefaultLayout';
import './SingleCategory.scss';
// import WToolbar from '../../../shared/components/w-toolbar/w-toolbar'
import { Container, TextSmall, Divider } from '../../../Components/Styled';
import BackTop from '../../../Components/BackTop';
import { api, token as getToken } from '../../../Redux/Apis';
import { getSafely } from '../../../Utils';
import NotFound from '../../../static/images/illustrations/search-not-found.svg';
import ProductCardGrid from '../../../Components/product-card/product-card-grid/ProductCardGrid';
import { queryClient } from "App";
import toast from 'react-hot-toast';
// import filterIcon from './../../../static/images/navigations/filter-icon.svg'
// import filterVerifIcon from './../../../static/images/navigations/filter-verif-icon.svg'
// import urutkanIcon from './../../../static/images/navigations/urutkan-icon.svg'
// import urutkanVerifIcon from './../../../static/images/navigations/urutkan-verif-icon.svg'

class RelatedCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogsList: [],
      products: [],
      page: 1,
      infiniteOn: true,
      categoryId: null,
      onload: true,
      visibleSort: false,
      usingSort: false,
      sort: 1,
      checked: false,
      visibleFilter: false,
      selectedFilter: false,
      usingFilter: false,
      selectedPrice: null,
      priceMin: null,
      priceMax: null,
    };
  }

  toggleSort = () => {
    this.setState({
      visibleSort: !this.state.visibleSort,
    });
  }

  handleSort = (sort) => {
    if (sort === 1) {
      this.setState({
        sort,
        usingSort: false,
      });
    } else {
      this.setState({
        sort,
        usingSort: true,
      });
    }
    const page = 1;
    window.scrollTo(0, 0);
    this.loadData(page);
    this.toggleSort();
  }

  messageSort = (selected, note) => {
    toast.success(`Pencarian berdasarkan ${note} diterapkan`);
    this.setState({
      sort: selected,
    });
  }

  toggleFilter = () => {
    this.setState({
      visibleFilter: !this.state.visibleFilter,
    });
  }

  handlePrice = (price, min, max) => {
    this.setState({
      selectedFilter: true,
      selectedPrice: price,
      priceMin: min,
      priceMax: max,
    });
  }

  handleFilter = () => {
    this.setState({
      usingFilter: true,
    });
    toast.success('Filter diterapkan');
    const page = 1;
    window.scrollTo(0, 0);
    this.loadData(page);
    this.toggleFilter();
  }

  removeFilter = () => {
    this.setState({
      selectedFilter: true,
      selectedPrice: 0,
      priceMin: null,
      priceMax: null,
      usingFilter: false,
    });
    message.info('Filter dinonaktifkan');
    const page = 1;
    window.scrollTo(0, 0);
    this.loadData(page);
    this.toggleFilter();
  }

  componentDidMount() {
    const body = document.querySelector('body');
    if (body) {
      body.style.backgroundColor = '#fff';
    } else {
      body.style.backgroundColor = '#fff';
    }
    this.props.bannerRecommendationRequest();
    /**
     * After thinking many times how to do this fucking script
     * i'm sorry that i have to avoid this saga middleware,
     * in this specific problems :( so sad
     * localStorage.getItem('lastSearch')
    */
    let actionCallback = () => { };

    if (this.props.history.action === 'PUSH' || _.isEmpty(getSafely([[this.constructor.name]], this.props.history.location.state, null))) {
      actionCallback = () => {
        window.scrollTo(0, 0);
        this.loadData(1);
      };
    } else {
      actionCallback = () => {
        this.setState({ ...this.props.history.location.state[this.constructor.name], onload: false });
      };
    }
    this.setState({
      categoryId: this.props.category,
    }, () => {
      actionCallback();
      window.addEventListener('scroll', this.handleScroll);
    });
  }

  loadData = (page = 1) => {
    const { history } = this.props;

    /**
     * This just a handler to make sure
     * our infinite scroll doesn't fetching when data api
     * still on progress
     */
    this.setState({
      onload: true,
    });

    getToken().then((token) => {
      let ignoreSet = {};
      if (this.props.ignoreSet === true) {
        ignoreSet = { ignore_set: true };
      }

      const data = {
        pc: this.state.categoryId,
        page,
        ob: this.state.sort,
        pmax: this.state.priceMax,
        pmin: this.state.priceMin,
        ...ignoreSet,
      };

      api.getProductByCategory(data, token).then((response) => {
        if (response.ok) {
          // Product composer
          const composeProduct = () => {
            // Replace data and update query uri for remembering what how many page that we have
            this.setState({
              products: this.state.products.concat(response.data.data.list),
              page,
              infiniteOn: true,
            }, () => {
              history.replace(history.location.pathname, { ...{ [this.constructor.name]: this.state } });
            });

            // Check too if already on last page disable infinite
            if (this.state.page >= response.data.data.total_page) {
              this.setState({
                infiniteOn: false,
              }, () => {
                history.replace(history.location.pathname, { ...{ [this.constructor.name]: this.state } });
              });
            }
          };

          // Set product empty if we start with page 1
          if (page === 1) {
            this.setState({
              products: [],
            }, composeProduct);
          } else {
            composeProduct();
          }
        }
      }).finally(() => {
        if (this.state.onload === true) {
          this.setState({
            onload: false,
          });
        }
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    if (document.documentElement.clientHeight + document.documentElement.scrollTop > document.documentElement.scrollHeight - 2000 && this.state.onload === false && this.state.infiniteOn === true) {
      this.loadData(this.state.page + 1);
    }
  }

  render() {
    // const { history } = this.props
    // const categoryName = this.props.history.location.state.category.name
    const catalogList = queryClient.getQueryData('catalog-list');
    if (catalogList === undefined) return null;

    const randomRecommendation = (index) => {
      const bannerData = this.props.recommendationBannerState.data;
      const selectedBanner = bannerData[(index / 3) % bannerData.length];
      if (!_.isUndefined(selectedBanner)) {
        return (
          <a href={selectedBanner.Link}>
            <img style={{ cursor: 'pointer' }} className="w-100 img-fluid" alt={selectedBanner.Label} src={selectedBanner.Images} />
            {' '}
            <Divider />
          </a>
        );
      }
    };

    return (
      <DefaultLayout type="nav" disableHeader>
        <div className="d-flex justify-content-start align-items-center">
          <Container className="pt-3">
            <div className="d-flex flex-column">
              <TextSmall increase boldest className="font-heading">Rekomendasi Lainnya</TextSmall>
              <span className="font-size-xs font-color-tertiary mt-n1 mb-2">Cek produk pilihan dari Woobiz</span>
            </div>
          </Container>
        </div>
        <Container style={{ padding: '0px', background: 'white' }}>
          {(!this.state.infiniteOn && this.state.products.length == 0) ? (
            <div className="text-center">
              <img alt="Product Not Found" className="mt-5" src={NotFound} />
              <div className="text-center font-size-l bolder mt-3 px-3">Duh, tidak ada produk dalam kategori yang kamu cari...</div>
            </div>
          ) : ''}
          <div style={{ overflowAnchor: 'auto' }}>
            <div className="container" style={{ padding: '0px 15px' }}>
              <div className="row">
                {
                this.state.products.map((item, idx) => (
                  <>
                    {
                        idx % 4 === 0 ? (
                          <div key={idx} style={{ marginBottom: '10px' }}>
                            {randomRecommendation(idx)}
                          </div>
                        ) : null
                      }
                    <div className="col-6">
                      <ProductCardGrid catalog={catalogList} item={item} />
                    </div>
                  </>
                ))
              }
              </div>
            </div>
          </div>

          {(this.state.infiniteOn) ? <div className="text-center pt-5 pb-5"><Spin /></div> : null}
        </Container>
        <BackTop />
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  productCategoryState: state.productCategoryState,
  recommendationBannerState: state.recommendationBannerState,
});

const mapDispatchToProps = (dispatch) => ({
  productCategoryRequest: (categoryID, page, sort, pmin, pmax) => dispatch(ProductCategoryActions.productCategoryRequest(categoryID, page, sort, pmin, pmax)),
  bannerRecommendationRequest: () => dispatch(BannerRecommendationCreator.Creators.bannerRecommendationRequest()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RelatedCategory));
