import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { UpperCase } from 'legacy/Utils';
import config from 'legacy/config';
import Routes from './legacy/Routes';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60,
      staleTime: 40000,
      refetchOnWindowFocus: false,
      // suspense: true,
    },
  },
});
export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* Your app here */}
      <HelmetProvider>
        <Helmet>
          <title>
            Toko
            {' '}
            {UpperCase(config.firstPathURL)}
          </title>
        </Helmet>
        <Routes />
        <Toaster toastOptions={{ style: { textAlign: 'center' } }} />
      </HelmetProvider>
    </QueryClientProvider>
  );
}
