import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import WooniversityActions, { WooniversityTypes } from '../Redux/WooniversityRedux'
import WooniversityDetailActions, { WooniversityDetailTypes } from '../Redux/WooniversityDetailRedux'

export function * wooniversityRequest (api, getToken) {
    let action = yield take(WooniversityTypes.WOONIVERSITY_REQUEST)
    while (action !== END) {
      yield fork(wooniversityRequestAPI, api, getToken, action)
      action = yield take(WooniversityTypes.WOONIVERSITY_REQUEST)
    }
}

export function * wooniversityRequestAPI (api, getToken) {
    try {
        const token = yield call(getToken)
        const response = yield call(api.getWooniversity, token)
        if (response.ok) {
        return yield put(WooniversityActions.wooniversitySuccess(response.data.data))
        } else {
        return yield put(WooniversityActions.wooniversityFailure(response.data.message))
        }
    } catch (err) {
        return yield put(WooniversityActions.wooniversityFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
    }
}

// Wooniversity Detail

export function * wooniversityDetailRequest (api, getToken) {
    let action = yield take(WooniversityDetailTypes.WOONIVERSITY_DETAIL_REQUEST)
    while (action !== END) {
      yield fork(wooniversityDetailRequestAPI, api, getToken, action)
      action = yield take(WooniversityDetailTypes.WOONIVERSITY_DETAIL_REQUEST)
    }
}

export function * wooniversityDetailRequestAPI (api, getToken) {
    try {
        const token = yield call(getToken)
        const response = yield call(api.getDetailWooniversity, token)
        if (response.ok) {
        return yield put(WooniversityDetailActions.wooniversityDetailSuccess(response.data.data))
        } else {
        return yield put(WooniversityDetailActions.wooniversityDetailFailure(response.data.message))
        }
    } catch (err) {
        return yield put(WooniversityDetailActions.wooniversityDetailFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
    }
}