import styled from 'styled-components'

export const PricePdpSpan = styled.span`
font-size: 18px;
color: #333333;
`
export const PriceBeforePdpSpan = styled.span`
  font-style: italic;
  text-decoration: line-through;
  color: #999999;
`
export const IconSpan = styled.span`
  width: 40px;
  height: 40px;
`
export const StockAvailableSpan = styled.span`
  width: 80px;
  height: 22px;
  line-height: 1.57;
`
export const StockQuantitySpan = styled.span`
  width: 57px;
  height: 22px;
  line-height: 1.57;
`
