import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// Reducers
import { reducer as routeAuthRedux } from "./RouteAuthRedux";
import { reducer as checkPhoneRedux } from "./CheckPhoneRedux";
import { reducer as authRedux } from "./AuthRedux";
import { reducer as userRedux } from "./UserRedux";
import { reducer as userImageRedux } from "./UserImageRedux";
import { reducer as OtpRedux } from "./OtpRedux";
import { reducer as ResendOtpRedux } from "./ResendOtpRedux";
import { reducer as searchRedux } from "./SearchRedux";
import { reducer as searchFilterRedux } from "./Reducers/SearchFilterRedux";
import { reducer as productDetailRedux } from "./ProductDetailRedux";
import { reducer as stockRedux } from "./StockRedux";
import { reducer as tempCartRedux } from "./Reducers/cart/tempCartReducer";
import { reducer as cartRedux } from "./CartRedux";
import { reducer as cartWalletRedux } from "./CartWalletRedux";
import { reducer as userCheckCompleteRedux } from "./UserCheckCompleteRedux";
import { reducer as userAddressRedux } from "./UserAddressRedux";
import { reducer as masterAddressRedux } from "./MasterAddressRedux";
import { reducer as userBankAccountRedux } from "./UserBankAccountRedux";
import { reducer as bankTransferListRedux } from "./BankTransferListRedux";
import { reducer as paymentBankListTransferRedux } from "./PaymentBankListTransferRedux";
import { reducer as paymentRedux } from "./PaymentRedux";
import { reducer as changePaymentRedux } from "./ChangePaymentRedux";
// Bonus
import { reducer as userBonusRedux } from "./UserBonusRedux";
import { reducer as UserBonusHistoryRedux } from "./UserBonusHistoryRedux";
import { reducer as userBonusTermsRedux } from "./UserBonusTermsRedux";
import { reducer as userBonusLevelRedux } from "./UserBonusLevelRedux";
import { reducer as userBonusTransactionRedux } from "./UserBonusTransactionRedux";
// Commision
import { reducer as userCommisionRedux } from "./UserCommisionRedux";
import { reducer as productSpecialRedux } from "./ProductSpecialRedux";
import { reducer as productSelectionRedux } from "./ProductSelectionRedux";
import { reducer as productUnder300Redux } from "./ProductUnder300Redux";
import { reducer as productUnder200Redux } from "./ProductUnder200Redux";
import { reducer as productUnder100Redux } from "./ProductUnder100Redux";
import { reducer as bannerCollectionRedux } from "./bannerCollectionRedux";
import { reducer as productMostSharedRedux } from "./ProductMostSharedRedux";
import { reducer as productDetailCollectionMostShared } from "./ProductDetailCollectionMostSharedRedux";
import { reducer as productRecommendationRedux } from "./ProductRecommendationRedux";
import { reducer as productCommissionRedux } from "./ProductDetailCommissionRedux";
// import { reducer as productSetExplanationRedux } from './ProductSetExplanationRedux'
import { reducer as productDetailCommissionTierListRedux } from "./ProductDetailCommissionTierListRedux";
import { reducer as salesOrderRedux } from "./SalesOrderRedux";
import { reducer as validateBeforeCheckoutRedux } from "./ValidateBeforeCheckoutRedux";
import { reducer as setupRedux } from "./SetupRedux";
import { reducer as productDetailViewRedux } from "./ProductDetailViewRedux";
import { reducer as salesOrderListItemRedux } from "./SalesOrderListItem";
import { reducer as UserViewRedux } from "./UserViewRedux";
import { reducer as TriplogicPriceRedux } from "./TriplogicPriceRedux";
import { reducer as TriplogicDetailRedux } from "./TriplogicDetailRedux";
import { reducer as ProductCategoryRedux } from "./ProductCategoryRedux";
import { reducer as categoryProductRedux } from "./Reducers/Category/categoryProductReducer";
import { reducer as UserCommissionHistoryRedux } from "./UserCommissionHistoryRedux";
import { reducer as UserCommissionHistoryDebitRedux } from "./UserCommissionHistoryDebitRedux";
import { reducer as UserCommissionHistoryCreditRedux } from "./UserCommissionHistoryCreditRedux";
import { reducer as UserFriendCodeRedux } from "./UserFriendCodeRedux";
import { reducer as FavoriteRedux } from "./UserRedux/FavoriteRedux";
import { reducer as CouponRedux } from "./CouponRedux";
import { reducer as SocialShareRedux } from "./SocialShareRedux";
import { reducer as SocialShareDetailRedux } from "./SocialShareDetailRedux";
import { reducer as BlockerRedux } from "./BlockerRedux";
import { reducer as SalesInvoiceBrandRedux } from "./SalesInvoiceBrandRedux";
import { reducer as SalesCompleteInvoiceRedux } from "./SalesCompleteInvoice";
import { reducer as UserKYCRedux } from "./UserKYCRedux";
import { reducer as WooniversityRedux } from "./WooniversityRedux";
import { reducer as WooniversityDetailRedux } from "./WooniversityDetailRedux";
import { reducer as CompleteOrderRedux } from "./CompleteOrderRedux";
// blog
import { reducer as BlogRedux } from "./BlogRedux";
// free ongkir
import { reducer as FreeOngkirRedux } from "./FreeOngkirRedux";

// ajukan produk
import { reducer as AjukanProdukRedux } from "./AjukanProdukRedux/AjukanProdukRedux";

// Withdraw
import { reducer as WithdrawRedux } from "./Withdraw/WithdrawRedux";
import { reducer as productShippingCostRedux } from "./Reducers/ProductReducer";
import { reducer as bannerCatalogRedux } from "./Reducers/BannerCatalogReducer";
import { reducer as productRecommendationInfiniteRedux } from "./Reducers/ProductRecommendationInfinte";
import { reducer as productNotificationRedux } from "./Reducers/product/notificationReducer";
import { reducer as recommendationBannerRedux } from "./Reducers/Banners/BannerRecommendationReducer";
import { reducer as brandProductReducer } from "./Reducers/Category/BrandProductReducer";
import { reducer as popularSearchRedux } from "./Reducers/PopularSearchReducer";
import { reducer as allCategoryRedux } from "./Reducers/AllCategoryReducer";
import { reducer as selectedCategoryRedux } from "./Reducers/SelectedCategoryReducer";
import { reducer as displayCategoryRedux } from "./Reducers/Category/DisplayCategoryReducer";
import { reducer as brandSahabatRedux } from "./Reducers/BrandSahabatReducer";

import {
  allReducer,
  waitingReducer,
  paidReducer,
  shippedReducer,
  completedReducer,
  canceledReducer,
  canceledMitraReducer,
  processedReducer,
  deliveredReducer,
} from "./Reducers/transaction/listTransactionReducer";
import {
  mailboxListReducer,
  mailboxReadReducer,
  mailboxUnreadReducer,
  mailboxDeleteReducer,
} from "../Redux/MailboxRedux";

import { reducer as checkProductCatalogRedux } from "./Catalog/CheckProductCatalogRedux";
import { reducer as catalogRedux } from "./Catalog/CatalogRedux";
import { reducer as catalogDetailRedux } from "./Catalog/CatalogDetailRedux";
import { reducer as catalogCreateWithProductRedux } from "./Catalog/CatalogCreateWithProductRedux";

// gps
import { reducer as GPSCheckUserRedux } from "./GPS/GPSCheckUserRedux";
import { reducer as GPSProductRedux } from "./GPS/GPSProductRedux";

import { reducer as storeRedux } from "./StoreRedux";

import ConfigureStore from "./configureStore";

const initialState = {};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    routeAuth: routeAuthRedux,
    checkPhoneState: checkPhoneRedux,
    auth: authRedux, // handle login and register state
    userState: userRedux, // handle user true data,
    userImageState: userImageRedux,
    userCheckCompleteState: userCheckCompleteRedux,
    userAddressState: userAddressRedux,
    otpState: OtpRedux,
    resendOtpState: ResendOtpRedux,
    searchState: searchRedux,
    searchFilterState: searchFilterRedux,
    productDetailState: productDetailRedux,
    stockState: stockRedux,
    tempCartState: tempCartRedux,
    cartState: cartRedux,
    cartWalletState: cartWalletRedux,
    masterAddressState: masterAddressRedux,
    userBankAccountState: userBankAccountRedux,
    bankTransferListState: bankTransferListRedux,
    paymentBankTransferListState: paymentBankListTransferRedux,
    paymentState: paymentRedux,
    changePaymentState: changePaymentRedux,
    // Bonus
    userBonusState: userBonusRedux,
    userBonusHistoryState: UserBonusHistoryRedux,
    userBonusTermsState: userBonusTermsRedux,
    userBonusLevelState: userBonusLevelRedux,
    userBonusTransactionState: userBonusTransactionRedux,
    // Commision
    userCommisionState: userCommisionRedux,

    productSpecialState: productSpecialRedux,
    productSelectionState: productSelectionRedux,
    productUnder300State: productUnder300Redux,
    productUnder200State: productUnder200Redux,
    productUnder100State: productUnder100Redux,
    bannerCollectionState: bannerCollectionRedux,
    productMostSharedState: productMostSharedRedux,
    productDetailCollectionMostSharedState: productDetailCollectionMostShared,
    productRecommendationState: productRecommendationRedux,
    productCommissionState: productCommissionRedux,
    // productSetExplanationState: productSetExplanationRedux,
    productDetailCommissionTierListState: productDetailCommissionTierListRedux,
    productShippingCostState: productShippingCostRedux,
    productRecommendationInfiniteState: productRecommendationInfiniteRedux,
    productNotification: productNotificationRedux,
    recommendationBannerState: recommendationBannerRedux,
    brandProductState: brandProductReducer,

    salesState: salesOrderRedux,
    salesOrderListItemState: salesOrderListItemRedux,
    setupState: setupRedux,
    validateBeforeCheckoutState: validateBeforeCheckoutRedux,
    productDetailViewState: productDetailViewRedux,
    userViewState: UserViewRedux,
    triplogicPriceState: TriplogicPriceRedux,
    triplogicDetailState: TriplogicDetailRedux,
    productCategoryState: ProductCategoryRedux,
    productByCategoryState: categoryProductRedux,
    userCommissionHistoryState: UserCommissionHistoryRedux,
    userCommissionDebitHistoryState: UserCommissionHistoryDebitRedux,
    userCommissionCreditHistoryState: UserCommissionHistoryCreditRedux,
    appliedState: UserFriendCodeRedux,
    applyState: UserFriendCodeRedux,
    FavoriteState: FavoriteRedux,
    couponState: CouponRedux,
    socialShareState: SocialShareRedux,
    socialShareDetailState: SocialShareDetailRedux,
    blockerState: BlockerRedux,
    salesInvoiceBrandState: SalesInvoiceBrandRedux,
    salesCompleteInvoiceState: SalesCompleteInvoiceRedux,
    userKYCState: UserKYCRedux,
    wooniversityState: WooniversityRedux,
    wooniversityDetailState: WooniversityDetailRedux,
    completeOrderState: CompleteOrderRedux,

    // Blog
    blogState: BlogRedux,

    // Free Ongkir
    freeOngkirState: FreeOngkirRedux,

    // Ajukan Produk
    ajukanProdukState: AjukanProdukRedux,
    // Withdraw
    withDrawState: WithdrawRedux,
    popularSearchState: popularSearchRedux,
    bannerCatalogState: bannerCatalogRedux,
    allCategoryState: allCategoryRedux,
    selectedCategoryState: selectedCategoryRedux,
    displayCategoryState: displayCategoryRedux,
    brandSahabatState: brandSahabatRedux,

    listTransactionAllState: allReducer,
    listTransactionWaitingState: waitingReducer,
    listTransactionPaidState: paidReducer,
    listTransactionShippedState: shippedReducer,
    listTransactionCompletedState: completedReducer,
    listTransactionCanceledState: canceledReducer,
    listTransactionCanceledMitraState: canceledMitraReducer,
    listTransactionProcessedState: processedReducer,
    listTransactionDeliveredState: deliveredReducer,

    // Mailbox
    mailboxListState: mailboxListReducer,
    mailboxReadState: mailboxReadReducer,
    mailboxUnreadState: mailboxUnreadReducer,
    mailboxDeleteState: mailboxDeleteReducer,

    // Catalog
    checkProductCatalogState: checkProductCatalogRedux,
    catalogState: catalogRedux,
    catalogDetailState: catalogDetailRedux,
    catalogCreateWithProductState: catalogCreateWithProductRedux,

    // GPS
    GPSCheckUserState: GPSCheckUserRedux,
    GPSProductState: GPSProductRedux,

    storeState: storeRedux,
  });

export default ConfigureStore(rootReducer, initialState);
