const theme = {
  colors: {
    eggBlue: {
      background: '#633c97',
      contrast: '#FFFFFF',
    },
    tangaroa: {
      background: '#051E42',
      contrast: '#FFFFFF',
    },
    botticelli: {
      background: '#D8E1ED',
      contrast: '#051e42',
    },
    white: {
      background: '#FFFFFF',
      contrast: '#051E42',
    },
    black: {
      background: '#292b2c',
      contrast: '#FFFFFF',
    },
    grey: {
      background: '#747474',
      contrast: '#FFFFFF',
    },
    red: {
      background: '#d32600',
      contrast: '#FFFFFF',
    },
    green: {
      background: '#45b969',
      contrast: '#ffffff',
    },
    yellow: {
      background: '#F6BA2F',
      contrast: '#ffffff',
    },
    orange: {
      background: '#ff8b28',
      contrast: '#ffffff',
    },
    cyan: {
      background: '#DFF9F5',
      contrast: '#633c97',
    },
  },
  transition: {
    speed: '.3s',
  },
};

export type ColorTypes = keyof typeof theme.colors;

export default theme;
