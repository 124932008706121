// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
  bannerCatalogInit: null,
  bannerCatalogRequest: ['subdistrict', 'weight'],
  bannerCatalogSuccess: ['payload'],
  bannerCatalogFailure: ['err']
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false
  })

export const request = (state) =>
  state.merge({ fetching: true, success: false })

export const success = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
    err: null
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BANNER_CATALOG_INIT]: init,
  [Types.BANNER_CATALOG_REQUEST]: request,
  [Types.BANNER_CATALOG_SUCCESS]: success,
  [Types.BANNER_CATALOG_FAILURE]: failure
})
