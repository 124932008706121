import React from "react";
import Slider from "react-slick";
import "./styles.scss";
import config from "../../config";

function BrandBannerSlider({ bannerList }) {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    dots: true,
  };

  if (bannerList.length === 0) {
    return null;
  }

  const couponAction = (url) => {
    window.location.href = `${url}`;
  };

  const renderBanner = () => {
    if (bannerList.length === 1) {
      return bannerList.map((banner, idx) => (
        <div
          key={idx}
          className="brand-banner__banner pl-4 pr-4"
          onClick={() => couponAction(banner.url)}
        >
          <img
            src={`${config.imageURL}${banner.img_body}`}
            width="100%"
            alt="banner"
            className="rounded-lg"
          />
        </div>
      ));
    } else {
      return (
        <Slider {...settings}>
          {bannerList.map((banner, idx) => (
            <div
              key={idx}
              className="brand-banner__banner"
              onClick={() => couponAction(banner.url)}
            >
              <img
                src={`${config.imageURL}${banner.img_body}`}
                width="100%"
                alt="banner"
                className="rounded-lg"
              />
            </div>
          ))}
        </Slider>
      );
    }
  };

  return <div className="brand-banner">{renderBanner()}</div>;
}

export default BrandBannerSlider;
