// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  socialShareRequest: ['data'],
  socialShareSuccess: ['payload'],
  socialShareFailure: ['err']
})

export const SocialShareTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const socialShareRequest = (state) =>
  state.merge({
    fetching: true,
    success: false,
    err: null
  })

export const socialShareSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const socialShareFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SOCIAL_SHARE_REQUEST]: socialShareRequest,
  [Types.SOCIAL_SHARE_SUCCESS]: socialShareSuccess,
  [Types.SOCIAL_SHARE_FAILURE]: socialShareFailure
})
