import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ModalVideo from 'react-modal-video';
import Slider from 'react-slick';
import { connect } from 'react-redux';

import Play from '../static/images/navigations/play-button.svg';

class WooniversityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  openModal = (id) => {
    this.setState({
      modal: {
        [id]: true,
      },
    });
  };

  closeModal = (id) => {
    this.setState({
      modal: {
        [id]: false,
      },
    });
  };

  splitImage = (url) => {
    if (url.startsWith("https://www.youtube.com/embed/")) {
      return url.replace("https://www.youtube.com/embed/", "");
    } else if (url.startsWith("https://www.youtube.com/watch?v=")) {
      return url.replace("https://www.youtube.com/watch?v=", "");
    }
  };

  render() {
    const settings = {
      dots: false,
      autoplay: false,
      infinite: false,
      slidesToShow: 1,
      variableWidth: true,
      slidesToScroll: 1,
      arrows: false,
      rows: 1,
    };
    const { detail, id, history } = this.props;
    return (
      <>
        {detail.total !== 0 ? (
          <div className='container pt-4'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='font-size-s boldest'>{detail.title}</div>
              {detail.total > 1 ? (
                <span
                  className='font-size-xs font-color-primary'
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`wooniversity/${id}`)}
                >
                  Semua
                </span>
              ) : null}
            </div>
            <div className='font-size-xs font-color-tertiary mt-1 mb-1'>
              {detail.total} Video
            </div>
            <Slider {...settings}>
              {detail.videos.map((data, idx) => {
                return (
                  <div className='mr-3' key={idx}>
                    <div
                      className='rounded'
                      style={{
                        position: "relative",
                        height: "100px",
                        width: "200px",
                        backgroundColor: "#d8d8d8",
                      }}
                    >
                      <div
                        className='tumbnail-video'
                        style={{
                          backgroundImage: `url(https://img.youtube.com/vi/${this.splitImage(
                            data.VideoURL
                          )}/maxresdefault.jpg)`,
                        }}
                      />
                      <img
                        src={Play}
                        onClick={() => this.openModal(data.VideoID)}
                        height='30px'
                        width='30px'
                        style={{
                          position: "absolute",
                          left: "85px",
                          top: "35px",
                          cursor: "pointer",
                          borderRadius: "100px",
                          padding: "1px",
                          backgroundColor: "white",
                        }}
                        alt='...'
                      />
                      <span
                        className='font-size-xs boldest'
                        style={{
                          position: "absolute",
                          right: "5px",
                          bottom: "0px",
                          color: "rgba(255,255,255,.8)",
                        }}
                      >
                        {data.duration}
                      </span>
                    </div>
                    <div className='card' style={{ width: "200px" }}>
                      <span className='boldest font-size-xs pt-1'>
                        {data.Title}
                      </span>
                    </div>
                  </div>
                );
              })}
            </Slider>
            {detail.videos.map((data, idx) => (
              <ModalVideo
                key={idx}
                channel='youtube'
                isOpen={this.state.modal[data.VideoID]}
                videoId={this.splitImage(data.VideoURL)}
                onClose={() => this.closeModal(data.VideoID)}
              />
            ))}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WooniversityList)
);
