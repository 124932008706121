import React from 'react';
import BottomSheet from 'react-modal-sheet';

function Sheet({ isOpen, children, setOpen }) {
  return (
    <BottomSheet isOpen={isOpen} onClose={() => setOpen(false)}>
      <BottomSheet.Container>
        <BottomSheet.Header />
        <BottomSheet.Content>{children}</BottomSheet.Content>
      </BottomSheet.Container>
      <BottomSheet.Backdrop />
    </BottomSheet>
  );
}

export default Sheet;
