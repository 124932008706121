/* eslint-disable no-undef */
export function filterKeyEnter(handler: (event: any) => void) {
  return (e: any) => {
    if (e.keyCode === 13) {
      handler(e);
    }
  };
}

export function accessibleOnClick(handler: (event: any) => void) {
  return {
    role: 'button',
    tabIndex: 0,
    onKeyDown: filterKeyEnter(handler),
    onClick: handler,
  };
}
