// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userCommissionHistoryCreditRequest: null,
  userCommissionHistoryCreditSuccess: ['payload'],
  userCommissionHistoryCreditFailure: ['err']
})

export const UserCommissionCreditHistoryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch Credit history

export const userCommissionHistoryCreditRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userCommissionHistoryCreditSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userCommissionHistoryCreditFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_COMMISSION_HISTORY_CREDIT_REQUEST]: userCommissionHistoryCreditRequest,
  [Types.USER_COMMISSION_HISTORY_CREDIT_SUCCESS]: userCommissionHistoryCreditSuccess,
  [Types.USER_COMMISSION_HISTORY_CREDIT_FAILURE]: userCommissionHistoryCreditFailure
})
