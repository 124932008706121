import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Component
import { Row, Col } from 'antd';
import Tabbar from 'shared/components/tabbar';
import { ContainerFooter } from 'shared/components/container';
import GeneralHeader from '../Components/GeneralHeader';
// Icon
import close from '../static/images/navigations/close-template.svg';
import arrowBackwardIcon from '../static/images/navigations/arrow-backward.svg';
import arrowBackwardIconWhite from '../static/images/navigations/arrow-backward-white.svg';
import deleteIcon from '../static/images/w-delete-purple.svg';
import Home from '../static/images/w-home-2.svg';
import HomeWhite from '../static/images/w-home-white.svg';

import Dispatcher from '../Containers/Dispatcher';
// import warning from '../static/images/navigations/warning-primary.svg'

// Antd
import {
  Container, Block, TextSmall, TextMedium,
} from '../Components/Styled';
import SearchBarViewHeader from '../Components/SearchBarViewHeader';

class DefaultLayout extends Component {
  render() {
    const {
      type,
      disableHeader,
      showBottomNav,
      location,
      history,
      display,
      onDelete,
      isDelete,
    } = this.props;
    return (
      <Dispatcher>
        {display || typeof display === 'undefined' ? (
          <>
            <header>
              {disableHeader ? (
                <></>
              ) : type === 'nav' ? (
                <Container>
                  <Row
                    type="flex"
                    align="middle"
                    className="background-white pt-2 pb-2"
                  >
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                  </Row>
                </Container>
              ) : type === 'nav-dark' ? (
                <div className="container pt-3 pb-3 background-black">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIconWhite}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                  </Row>
                </div>
              ) : type === 'register' ? (
                <></>
              ) : type === 'login' ? (
                <div className="container pt-3 pb-3 background-white">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="col-10 font-size-m font-color-primary text-center font-weight-bold">
                        Masuk
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : type === 'verification' ? (
                <></>
              ) : type === 'listalamat' ? (
                <Container>
                  <Row
                    type="flex"
                    align="middle"
                    className="background-white pt-2 pb-2"
                  >
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.push('/account')}
                      />
                    </Col>
                  </Row>
                </Container>
              ) : type === 'kodesahabat' ? (
                <div className="container pt-3">
                  <Row type="flex" align="middle" className="background-white">
                    <Col span={4}>
                      <img
                        src={close}
                        width="30%"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={16}>
                      <Block textAlign="center">
                        <div>
                          <TextMedium
                            color="#633c97"
                            className="font-heading"
                            boldest
                          >
                            Kode Sahabat
                          </TextMedium>
                        </div>
                      </Block>
                    </Col>
                  </Row>
                </div>
              ) : type === 'kodesahabat2' ? (
                <div className="container pt-3">
                  <Row type="flex" align="middle" className="background-white">
                    <Col span={4}>
                      <img
                        src={close}
                        width="30%"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={16}>
                      <Block textAlign="center">
                        <div>
                          <TextMedium
                            color="#633c97"
                            className="font-heading"
                            boldest
                          >
                            Kode Sahabat
                          </TextMedium>
                        </div>
                      </Block>
                    </Col>
                  </Row>
                </div>
              ) : type === 'helpcenter' ? (
                <div className="border-bottom fixed-top" id="fixed-width">
                  <Container>
                    <Row
                      type="flex"
                      align="middle"
                      className="background-white pt-2 pb-2"
                    >
                      <Col span={4}>
                        <img
                          src={arrowBackwardIcon}
                          width="10px"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                      <Col span={16}>
                        <div className="font-size-l text-center bolder">
                          Pusat Bantuan
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : type === 'withdraw' ? (
                <div style={{ backgroundColor: '#633c97 !important' }}>
                  <Container primary>
                    <Row type="flex" align="middle" className="pt-2 pb-2">
                      <Col span={4}>
                        <img
                          src={arrowBackwardIconWhite}
                          width="10px"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                      <Col span={16}>
                        <div style={{ textAlign: 'center' }}>
                          <TextMedium style={{ color: '#fff' }}>
                            Pencairan Dana
                          </TextMedium>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : type === 'detailTransaksi' ? (
                <div className="fixed-top" id="fixed-width">
                  <Block boxShadow="0 1px 4px 0 rgba(0, 0, 0, 0.1)">
                    <Container>
                      <Block
                        backgroundColor="#FFF"
                        paddingTop="15px"
                        paddingBottom="15px"
                      >
                        <Row type="flex" align="middle">
                          <Col span={4}>
                            <img
                              src={arrowBackwardIcon}
                              width="10px"
                              alt="arrow-back-icon"
                              onClick={() => history.goBack()}
                            />
                          </Col>
                          <Col span={16}>
                            <div>
                              <Block textAlign="center">
                                <div>
                                  <TextMedium className="font-heading" boldest>
                                    Detail Transaksi
                                  </TextMedium>
                                </div>
                              </Block>
                            </div>
                          </Col>
                        </Row>
                      </Block>
                    </Container>
                  </Block>
                </div>
              ) : type === 'detailTrack' ? (
                <Block backgroundColor="#633c97" style={{ height: '10rem' }}>
                  {/* <Container> */}
                  <Block paddingTop="15px" paddingBottom="15px">
                    <Row type="flex" align="middle">
                      <Col span={4} style={{ paddingLeft: '20px' }}>
                        <img
                          src={arrowBackwardIconWhite}
                          width="30%"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                      <Col span={16}>
                        <div>
                          <Block textAlign="center">
                            <div>
                              <TextMedium
                                color="#FFF"
                                className="font-heading"
                                boldest
                              >
                                Detail Status
                              </TextMedium>
                            </div>
                          </Block>
                        </div>
                      </Col>
                      <Col span={4} style={{ paddingLeft: '20px' }}>
                        <img
                          src={HomeWhite}
                          width="25"
                          style={{ cursor: 'pointer' }}
                          alt=""
                          onClick={() => history.push('/')}
                        />
                      </Col>
                    </Row>
                  </Block>
                  {/* </Container> */}
                </Block>
              ) : type === 'userProfile' ? (
                <div className="container pt-3 pb-3 background-white">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="col-10 font-size-m font-color-primary text-center font-weight-bold">
                        Data Diri Saya
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : type === 'pictureIDCard' ? (
                <div className="container pt-3 pb-3 background-white">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="col-10 font-size-m font-color-primary text-center font-weight-bold">
                        Foto Tanda Pengenal
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : type === 'selfieIDCard' ? (
                <div className="container pt-3 pb-3 background-white">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="col-10 font-size-m font-color-primary text-center font-weight-bold">
                        Foto Diri Dengan KTP
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : type === 'previewKYC' ? (
                <div className="container pt-3 pb-3 background-white">
                  <Row type="flex" align="middle">
                    <Col span={4}>
                      <img
                        src={arrowBackwardIcon}
                        width="10"
                        alt="arrow-back-icon"
                        onClick={() => history.goBack()}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="col-12 font-size-m font-color-primary text-center font-weight-bold">
                        Pratinjau Dokumen
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : type === 'mailBox' ? (
                <div className="fixed-top" id="fixed-width">
                  <div
                    className="container pt-3 pb-3 background-white"
                    style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' }}
                  >
                    <Row type="flex" align="middle">
                      <Col span={6}>
                        <img
                          src={arrowBackwardIcon}
                          width="10"
                          alt="arrow-back-icon"
                          onClick={() => history.push('/')}
                        />
                      </Col>
                      <Col span={12}>
                        <div className="font-size-m font-heading text-center font-weight-bold">
                          Notifikasi
                        </div>
                      </Col>
                      <Col span={6}>
                        <div className="ont-size-m font-color-primary text-right font-weight-bold">
                          {isDelete ? (
                            <div
                              onClick={onDelete}
                              style={{ cursor: 'pointer' }}
                            >
                              <TextSmall color="#292b2c">Batal</TextSmall>
                            </div>
                          ) : !this.props.empty ? (
                            <div>
                              <img
                                width="16%"
                                src={deleteIcon}
                                alt="delete-icon"
                                onClick={onDelete}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : type === 'mailBoxDetail' ? (
                <div className="fixed-top" id="fixed-width">
                  <div className="container pt-3 pb-3">
                    <Row type="flex" align="middle">
                      <Col span={6}>
                        <img
                          src={arrowBackwardIconWhite}
                          width="10"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : type === 'socialshare' ? (
                <div className="container background-color-primary text-center pt-2 pb-2 pl-3 pr-3">
                  <h2 className="text-white">Ayo Segera Pesan Produk Ini!</h2>
                </div>
              ) : type === 'shopping-cart' ? (
                <></>
              ) : type === 'set-margin' ? (
                <div className="fixed-top" id="fixed-width">
                  <Container>
                    <Row type="flex" className="background-white pt-3 pb-3">
                      <Col span={2}>
                        <img
                          src={arrowBackwardIcon}
                          width="10"
                          alt="arrow-back-icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => history.goBack()}
                        />
                      </Col>
                      <Col span={16}>
                        <div>
                          <TextMedium className="font-heading">
                            Atur Margin Keuntungan
                          </TextMedium>
                        </div>
                      </Col>
                      <Col span={6} className="text-right">
                        <div className="pl-2">
                          <img
                            alt="..."
                            src={Home}
                            style={{ cursor: 'pointer' }}
                            width={23}
                            onClick={() => this.props.history.push('/')}
                          />
                          <div
                            style={{
                              fontSize: '8px',
                              position: 'relative',
                              right: '-3px',
                              bottom: '-3px',
                            }}
                          >
                            Beranda
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : type === 'checkout' ? (
                <div className="fixed-top border-bottom" id="fixed-width">
                  <Container>
                    <Row
                      type="flex"
                      align="middle"
                      className="background-white pt-3 pb-3"
                    >
                      <Col span={21} push={3}>
                        <div>
                          <TextMedium boldest className="font-heading">
                            Detail Pesanan
                          </TextMedium>
                        </div>
                      </Col>
                      <Col span={3} pull={21}>
                        <img
                          src={arrowBackwardIcon}
                          width="10"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : type === 'transaction' ? (
                <></>
              ) : type === 'all-category' ? (
                <div className="fixed-top" id="fixed-width">
                  <Container>
                    <Row
                      type="flex"
                      align="middle"
                      className="background-white pt-2 pb-2"
                    >
                      <Col span={21} push={3}>
                        <SearchBarViewHeader />
                      </Col>
                      <Col span={3} pull={21}>
                        <img
                          src={arrowBackwardIcon}
                          width="10"
                          alt="arrow-back-icon"
                          onClick={() => history.goBack()}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              ) : type === 'wooniversity' ? (
                <></>
              ) : (
                <GeneralHeader />
              )}
            </header>
            {this.props.children}
            {showBottomNav ? (
              <ContainerFooter>
                <Tabbar />
              </ContainerFooter>
            ) : null}
            <footer>{/* Reserved for Footer */}</footer>
          </>
        ) : null}
      </Dispatcher>
    );
  }
}

export default withRouter(DefaultLayout);
