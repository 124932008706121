// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  blogRequest: ['data'],
  blogSuccess: ['payload'],
  blogFailure: ['err']
})

export const BlogTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const blogRequest = (state) =>
  state.merge({
    fetching: true,
    success: false,
    err: false
  })

export const blogSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const blogFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: true
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BLOG_REQUEST]: blogRequest,
  [Types.BLOG_SUCCESS]: blogSuccess,
  [Types.BLOG_FAILURE]: blogFailure
})
