// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userAddressListRequest: null,
  userAddressListSuccess: ['payload'],
  userAddressListFailure: ['err'],
  userAddressUpdateRequest: ['data'],
  userAddressUpdateSuccess: ['payload'],
  userAddressUpdateFailure: ['err'],
  userAddressAddRequest: ['data'],
  userAddressAddSuccess: ['payload'],
  userAddressAddFailure: ['err'],
  userAddressChangeDefaultRequest: ['data'],
  userAddressChangeDefaultSuccess: ['payload'],
  userAddressChangeDefaultFailure: ['err'],
  userAddressRemoveRequest: ['data'],
  userAddressRemoveSuccess: ['payload'],
  userAddressRemoveFailure: ['err']
})

export const UserAddressTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// GET USER ADDRESS
export const userAddressListRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userAddressListSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userAddressListFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// UPDATE ADDRESS
export const userAddressUpdateRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userAddressUpdateSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userAddressUpdateFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// ADD ADDRESS
export const userAddressAddRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userAddressAddSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userAddressAddFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// DEFAULT ADDRESS
export const userAddressChangeDefaultRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userAddressChangeDefaultSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userAddressChangeDefaultFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// UPDATE ADDRESS
export const userAddressRemoveRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userAddressRemoveSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userAddressRemoveFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_ADDRESS_LIST_REQUEST]: userAddressListRequest,
  [Types.USER_ADDRESS_LIST_SUCCESS]: userAddressListSuccess,
  [Types.USER_ADDRESS_LIST_FAILURE]: userAddressListFailure,
  [Types.USER_ADDRESS_UPDATE_REQUEST]: userAddressUpdateRequest,
  [Types.USER_ADDRESS_UPDATE_SUCCESS]: userAddressUpdateSuccess,
  [Types.USER_ADDRESS_UPDATE_FAILURE]: userAddressUpdateFailure,
  [Types.USER_ADDRESS_ADD_REQUEST]: userAddressAddRequest,
  [Types.USER_ADDRESS_ADD_SUCCESS]: userAddressAddSuccess,
  [Types.USER_ADDRESS_ADD_FAILURE]: userAddressAddFailure,
  [Types.USER_ADDRESS_CHANGE_DEFAULT_REQUEST]: userAddressChangeDefaultRequest,
  [Types.USER_ADDRESS_CHANGE_DEFAULT_SUCCESS]: userAddressChangeDefaultSuccess,
  [Types.USER_ADDRESS_CHANGE_DEFAULT_FAILURE]: userAddressChangeDefaultFailure,
  [Types.USER_ADDRESS_REMOVE_REQUEST]: userAddressRemoveRequest,
  [Types.USER_ADDRESS_REMOVE_SUCCESS]: userAddressRemoveSuccess,
  [Types.USER_ADDRESS_REMOVE_FAILURE]: userAddressRemoveFailure
})
