import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware, compose } from 'redux'
import config from '../config'
import localforage from 'localforage'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../Sagas'
import newRootSaga from './Sagas'
import Immutable from 'seamless-immutable'
import ReduxPersist from './config/ReduxPersist'

// the logger master switch
// const USE_LOGGING = config.DEBUG

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

export default (rootReducer, initialState) => {
  let enhancers = []
  let middleware = []

  const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose

  // create the saga middleware
  middleware.push(routerMiddleware(history))
  middleware.push(sagaMiddleware)
  if (config.DEBUG) {
    middleware.push(logger)
  }
  // add our normal middleware to the list
  enhancers.push(applyMiddleware(...middleware))

  // a function which can create our store and auto-persist the data
  const store = createStore(persistReducer(ReduxPersist.storeConfig, rootReducer(history)), Immutable(initialState), composeEnhancers(...enhancers))

  // Run Saga
  sagaMiddleware.run(rootSaga)
  sagaMiddleware.run(newRootSaga)

  const persistor = persistStore(store)

  localforage.config({
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, localforage.WEBSQL],
    name: 'woobiz-pre'
  }).catch((err) => {
    return err
  })

  return { store, history, persistor }
}
