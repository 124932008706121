// import { put, take, call } from 'redux-saga/effects'
// import { END } from 'redux-saga'
// // Redux Stuffs

// export function* selectedCategory (api, getToken, { brand }) {
//     let action = yield take(SelectedCategoryReducer.Types.SELECTED_CATEGORY_REQUEST)
//     while (action !== END) {
//         const token = yield call(getToken)
//         const response = yield call(api.getSelectedCategory, token, )
//         if (response.ok) {
//             yield put(SelectedCategoryReducer.Creators.selectedCategorySuccess(response.data.data))
//         } else {
//             yield put(SelectedCategoryReducer.Creators.selectedCategoryFailure(response.data.message))
//         }
//         action = yield take(SelectedCategoryReducer.Types.ALL_CATEGORY_REQUEST)
//     }
// }

import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import SelectedCategoryReducer from '../Reducers/SelectedCategoryReducer'

export function * selectedCategory (api, getToken) {
  let action = yield take(SelectedCategoryReducer.Types.SELECTED_CATEGORY_REQUEST)
  while (action !== END) {
    yield fork(selectedCategoryAPI, api, getToken, action)
    action = yield take(SelectedCategoryReducer.Types.SELECTED_CATEGORY_REQUEST)
  }
}

export function * selectedCategoryAPI (api, getToken, { brand }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getSelectedCategory, token, brand)
    if (response.ok) {
      return yield put(SelectedCategoryReducer.Creators.selectedCategorySuccess(response.data))
    } else {
      return yield put(SelectedCategoryReducer.Creators.selectedCategoryFailure(response.data.message))
    }
  } catch (err) {
    return yield put(SelectedCategoryReducer.Creators.selectedCategoryFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
