/* eslint-disable no-return-await */
import localforage from 'localforage';

const tokenBroker = {
  put: async (token: string) => await localforage.setItem('auth_token', token),
  get: async () => await localforage.getItem('auth_token'),
  clear: async () => await localforage.removeItem('auth_token'),
};

export default tokenBroker;
