// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
  selectedCategoryRequest: ['brand'],
  selectedCategorySuccess: ['payload'],
  selectedCategoryFailure: ['err']
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const selectedCategoryRequest = (state) =>
  state.merge({ fetching: true, success: false })

export const selectedCategorySuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const selectedCategoryFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECTED_CATEGORY_REQUEST]: selectedCategoryRequest,
  [Types.SELECTED_CATEGORY_SUCCESS]: selectedCategorySuccess,
  [Types.SELECTED_CATEGORY_FAILURE]: selectedCategoryFailure
})
