import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

import WithdrawActions, { WithdrawTypes } from '../../Redux/Withdraw/WithdrawRedux'

export function * withdrawRequest (api, getToken) {
  let action = yield take(WithdrawTypes.WITHDRAW_INIT)
  while (action !== END) {
    yield fork(withdrawRequestAPI, api, getToken, action)
    action = yield take(WithdrawTypes.WITHDRAW_INIT)
  }
}

export function * withdrawRequestAPI (api, getToken, { data }) {
  const token = yield call(getToken)
  const response = yield call(api.requestWithdraw, data, token)
  try {
    if (response.ok) {
      return yield put(WithdrawActions.withdrawRequestSuccess(response.data))
    } else {
      return yield put(WithdrawActions.withdrawRequestFailure(response.data.message))
    }
  } catch (err) {
    return yield put(WithdrawActions.withdrawRequestFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
