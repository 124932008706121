import React from 'react';
import { CouponLoader } from '../../../../Components/loader';
import { getSafely, isEmpty } from '../../../../Utils';
import { useRequestCouponList } from '../../api/brand-page.api';
import './styles.scss';

function CouponList({ brandID }) {
  const { data, isFetching } = useRequestCouponList(brandID);
  const couponListPayload = getSafely(['list'], data, []);
  const slicedCoupon = couponListPayload.slice(0, 2);

  if (isFetching) {
    return <CouponLoader />;
  }

  return (
    <div className="brand-coupon">
      <span className="brand-coupon__title">Kupon & Misi yang tersedia</span>
      {!isEmpty(couponListPayload)
        && slicedCoupon.map((coupon, idx) => (
          <div key={idx} className="brand-coupon__list">
            <div className="d-flex flex-start align-items-center">
              <div className="pr-3">
                <img src={coupon.icon} width="25" alt="coupon" />
              </div>
              <div className="brand-coupon__name">
                <span>{coupon.name}</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CouponList;
