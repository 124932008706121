import React from 'react';
import './styles.scss';
import { getSafely } from '../../../Utils';

const Badge = (props) => {
  const paketBadge = () => {
    const { qty } = props;
    return (
      <div className="badge-container">
        <div className="badge-wrapper">
          <span className="badge-title">
            Paket (
            {qty}
            {' '}
            pcs)
          </span>
        </div>
      </div>
    );
  };

  const satuanBadge = () => (
    <div className="badge-container-satuan">
      <div className="badge-wrapper-satuan">
        <span className="badge-title-satuan">Satuan</span>
      </div>
    </div>
  );

  return props.satuanPayload === 'set' ? paketBadge() : satuanBadge();
};

const RecommendationProductBadge = (props) => {
  const satuan = getSafely(['payloadVariant', 'satuan'], props, '');

  const paketBadge = () => (
    <div className="badge-container">
      <div className="badge-wrapper">
        <span className="badge-title">
          Paket
          {' '}
          {`(${props.payloadProduct.qty_set} pcs)`}
        </span>
      </div>
    </div>
  );

  const satuanBadge = () => (
    <div className="badge-container-satuan">
      <div className="badge-wrapper-satuan">
        <span className="badge-title-satuan">Satuan</span>
      </div>
    </div>
  );
  return satuan === 'set' ? paketBadge() : satuanBadge();
};

export { Badge, RecommendationProductBadge };
