import { useHistory } from 'react-router-dom';
import { accessibleOnClick } from 'utils/accessibleClick';
import { cartTemporaryDetailQuery } from 'shared/apis/queries/cart.query';
import styled from 'styled-components';
import cartImage from '../../../../static/images/bottom-navigation/active/w-shopping-cart.svg';
import { isEmpty } from 'lodash';

const BadgeStyled = styled.div`
  position: absolute;
  top: -7px;
  right: 4px;
  background: red;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  font-size: 10px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  color: white;
`;

function BadgeCount() {
  const history = useHistory();
  const { data: cartQuery } = cartTemporaryDetailQuery();

  function count() {
    const arr: any[] = [];
    let totalBadge;

    cartQuery?.data.data.items[0].details.forEach((element: any) => {
      arr.push(element.qty_ordered); // Push them into one array first

      totalBadge = arr.reduce(
        (firstValue, restValue) => firstValue + restValue,
        0,
      );
    });
    return totalBadge;
  }

  return (
    <div {...accessibleOnClick(() => history.push('/shopping-cart'))} style={{ position: 'relative' }}>
      {!isEmpty(cartQuery?.data.data.items[0]) && <BadgeStyled>{count()}</BadgeStyled>}
      <img src={cartImage} height="22px" alt="cart" />
    </div>
  );
}

export default BadgeCount;
