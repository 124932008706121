// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  blockerCheckRequest: null,
  blockerCheckSuccess: ['payload'],
  blockerCheckFailure: ['err'],
  blockerAddRequest: ['data'],
  blockerAddSuccess: ['payload'],
  blockerAddFailure: ['err']
})

export const BlockerTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// blocker check
export const blockerCheckRequest = (state) =>
  state.merge({
    fetching: true,
    success: false,
    err: null
  })

export const blockerCheckSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const blockerCheckFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// blocker add
export const blockerAddRequest = (state) =>
  state.merge({
    fetching: true,
    success: false,
    err: null
  })

export const blockerAddSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const blockerAddFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BLOCKER_CHECK_REQUEST]: blockerCheckRequest,
  [Types.BLOCKER_CHECK_SUCCESS]: blockerCheckSuccess,
  [Types.BLOCKER_CHECK_FAILURE]: blockerCheckFailure,
  [Types.BLOCKER_ADD_REQUEST]: blockerAddRequest,
  [Types.BLOCKER_ADD_SUCCESS]: blockerAddSuccess,
  [Types.BLOCKER_ADD_FAILURE]: blockerAddFailure
})
