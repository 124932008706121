// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addSetupRequest: ['data'],
  addSetupSuccess: ['payload'],
  addSetupFailure: ['err'],
  getSetupRequest: null,
  getSetupSuccess: ['payload'],
  getSetupFailure: ['err']
})

export const SetupPreferencesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const addSetupRequest = (state) =>
  state.merge({
    fetching: true, success: false, err: null
  })

export const addSetupSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const addSetupFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

export const getSetupRequest = (state) =>
  state.merge({
    fetching: true, success: false, err: null
  })

export const getSetupSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const getSetupFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_SETUP_REQUEST]: addSetupRequest,
  [Types.ADD_SETUP_SUCCESS]: addSetupSuccess,
  [Types.ADD_SETUP_FAILURE]: addSetupFailure,
  [Types.GET_SETUP_REQUEST]: getSetupRequest,
  [Types.GET_SETUP_SUCCESS]: getSetupSuccess,
  [Types.GET_SETUP_FAILURE]: getSetupFailure
})
