import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
// Cart Redux
import CartActions from '../Redux/CartRedux'
import ProductSelectionActions from '../Redux/ProductSelectionRedux'
import ProductRecommendationActions from '../Redux/ProductRecommendationRedux'

class Dispatcher extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dispatchSelectionProducts: false,
      dispatchRecommendationProducts: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let updated = {}
    const nextProductSelectionState = nextProps.productSelectionState
    const nextProductRecommendationState = nextProps.productRecommendationState
    if (!nextProductSelectionState.fetching && _.isEmpty(nextProductSelectionState.data) && !nextProductSelectionState.success && !nextProductSelectionState.err) {
      updated = { ...updated, dispatchSelectionProducts: true }
    }

    if (!nextProductRecommendationState.fetching && _.isEmpty(nextProductRecommendationState.data) && !nextProductRecommendationState.success && !nextProductRecommendationState.err) {
      updated = { ...updated, dispatchRecommendationProducts: true }
    }

    return !_.isEmpty(updated) ? updated : null
  }

  componentDidMount () {
    const { dispatchSelectionProducts } = this.state
    const { match } = this.props

    if (match.path !== '/(|home|index|catalog)/' && match.path !== '/login' && match.path !== '/register' && dispatchSelectionProducts) {
      this.props.productSelectionRequest()
      this.props.productRecommendationRequest()
    }
    // in here, every time it fetched, we need to check if at localforage has it, if it doesn't, create cart first
    this.props.fetchCartDetail()
  }

  render () {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  productSelectionState: state.productSelectionState,
  productRecommendationState: state.productRecommendationState
})

const mapDispatchToProps = (dispatch) => ({
  fetchCartDetail: () => dispatch(CartActions.cartRequest()),
  productSelectionRequest: () => dispatch(ProductSelectionActions.productSelectionRequest()),
  productRecommendationRequest: () => dispatch(ProductRecommendationActions.productRecommendationRequest())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dispatcher))
