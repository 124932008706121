import { put, take, call, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
import transactionListReducer from '../../Reducers/transaction/listTransactionReducer'
import { api, token as getToken } from '../../Apis'

export function* transactionAllRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_ALL_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderAll, 'paid,expired,cancelled,waiting', action.page, token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionAllSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionAllFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_ALL_REQUEST)
    }
}

export function* transactionWaitingRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_WAITING_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'waiting', token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionWaitingSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionWaitingFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_WAITING_REQUEST)
    }
}

export function* transactionPaidRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_PAID_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'paid', token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionPaidSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionPaidFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_PAID_REQUEST)
    }
}

export function* transactionShippedRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_SHIPPED_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'shipped', token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionShippedSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionShippedFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_SHIPPED_REQUEST)
    }
}

export function* transactionCompletedRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_COMPLETED_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'completed', token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionCompletedSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionCompletedFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_COMPLETED_REQUEST)
    }
}

export function* transactionCanceledRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_CANCELED_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const canceledAdminResponse = yield call(api.salesOrderList, 'canceled-admin', token)
        const canceledMitraResponse = yield call(api.salesOrderList, 'canceled-mitra', token)
        const expiredResponse = yield call(api.salesOrderList, 'expired', token)

        let canceledAdmin = canceledAdminResponse.data.data.list
        let canceledMitra = canceledMitraResponse.data.data.list
        let expired = expiredResponse.data.data.list

        let joinArr = canceledAdmin.concat(canceledMitra, expired)

        if (canceledAdminResponse.ok && canceledMitraResponse.ok && expiredResponse.ok) {
            yield put(transactionListReducer.Creators.transactionCanceledSuccess(joinArr))
        } else {
            yield put(transactionListReducer.Creators.transactionCanceledFailure(canceledAdminResponse.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_CANCELED_REQUEST)
    }
}

export function* transactionCanceledMitraRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_CANCELED_MITRA_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'canceled-mitra', token)
        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionCanceledMitraSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionCanceledMitraFailure(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_CANCELED_MITRA_REQUEST)
    }
}

export function* transactionProcessedRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_PROCESSED_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const processedResponse = yield call(api.salesOrderList, 'processed', token)
        const paidResponse = yield call(api.salesOrderList, 'paid', token)
        let processed = processedResponse.data.data.list
        let paid = paidResponse.data.data.list
        let joinArr = processed.concat(paid)

        if (processedResponse.ok && paidResponse.ok) {
            yield put(transactionListReducer.Creators.transactionProcessedSuccess(joinArr))
        } else {
            yield put(transactionListReducer.Creators.transactionProcessedFailure(processedResponse.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_PROCESSED_REQUEST)
    }
}

export function* transactionDeliveredRequest(api, getToken) {
    let action = yield take(transactionListReducer.Types.TRANSACTION_DELIVERED_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.salesOrderList, 'delivered', token)

        if (response.ok) {
            yield put(transactionListReducer.Creators.transactionDeliveredSuccess(response.data.data))
        } else {
            yield put(transactionListReducer.Creators.transactionDeliveredSuccess(response.data.message))
        }
        action = yield take(transactionListReducer.Types.TRANSACTION_DELIVERED_REQUEST)
    }
}

export function* transactionListSaga() {
    yield all([
        fork(transactionAllRequest, api, getToken),
        fork(transactionWaitingRequest, api, getToken),
        fork(transactionPaidRequest, api, getToken),
        fork(transactionShippedRequest, api, getToken),
        fork(transactionCompletedRequest, api, getToken),
        fork(transactionCanceledRequest, api, getToken),
        fork(transactionCanceledMitraRequest, api, getToken),
        fork(transactionProcessedRequest, api, getToken),
        fork(transactionDeliveredRequest, api, getToken)
    ])
}