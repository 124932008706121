// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import moment from 'moment'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  recommendationProductInfiniteClear: null,
  recommendationProductInfiniteAdd: ['page', 'products'],
  recommendationProductInfiniteDone: null
})

export const RecommendationProductInfiniteTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  page: 1,
  products: [],
  infiniteOn: true,
  expired: moment().add(1, 'hour').toDate()
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

const clear = (state) =>
  state.merge({ products: [], infiniteOn: true, page: 1 })

const add = (state, { page, products }) =>
  state.merge({ products: state.products.concat(products), infiniteOn: true, page: page, expired: moment().add(1, 'hour').toDate() })

const done = (state) => state.merge({})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RECOMMENDATION_PRODUCT_INFINITE_CLEAR]: clear,
  [Types.RECOMMENDATION_PRODUCT_INFINITE_ADD]: add,
  [Types.RECOMMENDATION_PRODUCT_INFINITE_DONE]: done
})
