/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import NumberFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { Row, Col, message } from 'antd';
import {
  UpperCase,
  NumberWithCommas,
  ReplaceChar,
  getSafely,
} from 'legacy/Utils';

import checkProductCatalogActions from 'legacy/Redux/Catalog/CheckProductCatalogRedux';
import catalogActions from 'legacy/Redux/Catalog/CatalogRedux';
import emptyCatalog2 from 'legacy/static/images/w-empty.svg';
import storeActions from 'legacy/Redux/StoreRedux';

// Component
import {
  TextSmall,
  TextMedium,
  Block,
  TextLarge,
  ButtonDisable,
} from 'legacy/Components/Styled';
import { productManualMapping } from 'legacy/Containers/Product/ProductDetail/ProductDetailPage';

import { RecommendationProductBadge } from 'legacy/Components/card/badge';
import bookmarkIcon from 'legacy/static/images/w-bookmark-4.svg';
import bookmarkActive from 'legacy/static/images/w-bookmark-active.svg';
import bookmarkInactive from 'legacy/static/images/w-bookmark-inactive.svg';
import emptyCatalog from 'legacy/static/images/w-empty-collections.svg';
import Sheet from 'legacy/Components/bottomsheet/Sheet';
import NewCatalogDialog from '../../../Containers/Catalogs/NewCatalogDialog';
import { ButtonPrimary, ButtonPrimaryOutline } from '../../Styled';
import BottomSheet from '../../../Containers/BottomSheet';
import config from '../../../config';
import CatalogNotActivatedDrawer from '../catalog-not-activated-drawer';

class ProductCardGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProductCatalogState: undefined,
      imageLoaded: false,
      visibleCatalog: false,
      catalogSKU: '',
      selectedCatalog: false,
      dataCheck: [],
      priceSKU: 0,
      formatSKU: '',
      pieceSKU: 0,
      visibleAddPrice: false,
      setPrice: 0,
      calculateMargin: 0,
      alertSetPrice: '=',
      wasAddProductCatalog: false,
      visibleChooseCatalog: false,
      isWasModifyProduct: false,
      isAddProductCatalog: false,
      visibleEmptyCatalog: false,
      visibleCreateCatalog: false,
      visibleStoreNotActivated: false,
      onceCheckingCatalog: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {};

    if (
      !_.isEqual(
        nextProps.checkProductCatalogState,
        prevState.checkProductCatalogState,
      )
      && !_.isEmpty(nextProps.checkProductCatalogState)
    ) {
      updated = {
        ...updated,
        checkProductCatalogState: nextProps.checkProductCatalogState,
      };
    }

    if (
      !_.isEqual(nextProps.catalogState, prevState.catalogState)
      && !_.isEmpty(nextProps.catalogState)
    ) {
      updated = { ...updated, catalogState: nextProps.catalogState };
    }

    return updated;
  }

  componentDidMount() {
    if (_.isEmpty(this.props.storeState.data)) {
      this.props.getStoreRequest();
    }
  }

  componentDidUpdate() {
    const { dataCheck, catalogSKU, onceCheckingCatalog } = this.state;
    const { catalog } = this.props;
    if (onceCheckingCatalog) {
      if (
        !this.state.checkProductCatalogState.fetching
        && this.state.checkProductCatalogState.success
        && !this.state.catalogState.fetching
        && this.state.catalogState.success
      ) {
        this.state.catalogState.data.list.map((data, idx) => {
          let trueCheck = false;
          this.state.checkProductCatalogState.data.map((data2) => {
            if (data.id === data2.id) {
              trueCheck = true;
            }
          });

          if (trueCheck) {
            dataCheck.push({
              id: data.id,
              checkCatalog: true,
              modify: false,
            });
          } else {
            dataCheck.push({
              id: data.id,
              checkCatalog: false,
              modify: false,
            });
          }
        });
        this.setState({
          onceCheckingCatalog: false,
        });
      }
    }
    if (this.state.isAddProductCatalog) {
      this.props.checkProductCatalogRequest(catalogSKU);
      this.setState({
        isWasModifyProduct: true,
        isAddProductCatalog: false,
      });
    }
    if (this.state.isWasModifyProduct) {
      this.timerWasAddProduct = setInterval(
        () => this.setState({
          isWasModifyProduct: false,
        }),
        5000,
      );
    }
  }

  minifyText = (words) => UpperCase(
    words.length > 25
      ? `${words.substring(0, 25).toLowerCase()}...`
      : words.toLowerCase(),
  );

  toggleStoreNotActivated = () => {
    this.setState({
      visibleStoreNotActivated: !this.state.visibleStoreNotActivated,
    });
  };

  toggleEmptyCatalog = () => {
    this.setState({
      visibleAddPrice: !this.state.visibleAddPrice,
      visibleEmptyCatalog: !this.state.visibleEmptyCatalog,
    });
  };

  toggleCatalog = () => {
    // Check Store Activation
    if (_.isEmpty(this.props.storeState.data)) {
      this.setState({ visibleStoreNotActivated: true });
      return;
    }

    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
      onceCheckingCatalog: true,
    });

    this.setState({ wasAddProductCatalog: false });
  };

  closeChooseCatalog = () => {
    this.setState({
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  handleSelectCatalog = (sku, margin, format, piece) => {
    this.setState({
      catalogSKU: sku,
      selectedCatalog: true,
      dataCheck: [],
      onceCheckingCatalog: true,
      priceSKU: margin,
      formatSKU: format,
      pieceSKU: piece,
    });
    this.props.checkProductCatalogRequest(sku);
  };

  toggleAddPrice = () => {
    this.props.getCatalogRequest(1000);
    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
      visibleAddPrice: !this.state.visibleAddPrice,
      setPrice: this.state.priceSKU,
      calculateMargin: 0,
    });
  };

  handleChangePriceMargin = (e) => {
    const number = /^\d+$/;
    const value = e.split('.').join('');
    if (value.match(number) || value === '') {
      if (value < this.state.priceSKU) {
        this.setState({
          alertSetPrice: '<',
        });
      }
      if (value >= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '>',
        });
      }
      if (value >= this.state.priceSKU && value <= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '=',
        });
      }
      this.setState({
        setPrice: value,
        calculateMargin: value - this.state.priceSKU,
      });
    }
  };

  toggleChooseCatalog = () => {
    const catalog = this.props.catalogState?.data?.list || [];
    if (this.state.visibleAddPrice && catalog.length == 0) {
      this.toggleEmptyCatalog();
      return;
    }

    this.setState({
      visibleAddPrice: !this.state.visibleAddPrice,
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  onCreateCatalogSuccessHandler = () => {
    this.props.getCatalogRequest(1000);
    if (this.state.visibleEmptyCatalog) {
      this.setState({
        visibleEmptyCatalog: false,
      });
    }

    if (this.state.visibleChooseCatalog) {
      this.setState({
        visibleChooseCatalog: false,
        isAddProductCatalog: true,
        dataCheck: [],
        // onceCheckingCatalog: true
      });
    }

    this.setState({
      visibleCreateCatalog: false,
    });
  };

  toggleProductCatalog = (idx) => {
    const { dataCheck } = this.state;
    const payload = dataCheck.slice();
    payload[idx] = {
      id: payload[idx].id,
      checkCatalog: !payload[idx].checkCatalog,
      modify: true,
    };
    this.setState({
      dataCheck: payload,
      wasAddProductCatalog: true,
    });
  };

  addProductCatalog = () => {
    const {
      dataCheck, catalogSKU, priceSKU, calculateMargin,
    } = this.state;
    const payload = {
      products: [
        {
          sku: catalogSKU,
          margin: calculateMargin,
        },
      ],
    };
    dataCheck.map((data, key) => {
      if (data.modify) {
        if (data.checkCatalog) {
          this.props.createProductCatalogRequest(data.id, payload);
        } else {
          this.props.deleteProductCatalogRequest(data.id, catalogSKU);
        }
      }
    });
    this.setState({
      isWasModifyProduct: true,
      checkCatalogState: true,
    });
    this.closeChooseCatalog();
  };

  notifUpdate = () => (
    <div className="container text-center">
      <span
        style={{
          background: '#633c97',
          color: '#ffffff',
          padding: '10px',
          borderRadius: '.25rem',
        }}
      >
        Katalog produk berhasil diperbarui
        <span
          style={{
            background: '#ffffff',
            color: '#633c97',
            padding: '3px 6px',
            marginLeft: '6px',
            borderRadius: '.25rem',
          }}
          onClick={() => (window.location.href = '/store/catalogs')}
        >
          Lihat
        </span>
      </span>
    </div>
  );

  percentage = (msrp, sellingPrice) => {
    const margin = msrp - sellingPrice;
    // eslint-disable-next-line no-mixed-operators
    return parseInt((margin / msrp) * 100, 0) || 0;
    // return margin;
  };

  loadImage(source) {
    if (this.state.imageLoaded === false) {
      const img = new Image();
      img.onload = () => {
        setTimeout(() => {
          this.setState({ imageLoaded: true });
        }, 300);
      };
      img.src = `${config.imageURL}${source}`;
    }
  }

  render() {
    const { item, grid, history } = this.props;
    const catalog = this.props.catalogState?.data?.list || [];
    const variantPayload = getSafely(['variants', 0], item, '');
    const commissionPayload = getSafely(
      ['commission', 'comm_3'],
      variantPayload,
      '',
    );
    const slicedCommission = commissionPayload.toString().slice(0, -3) || 0;
    const variant = item && item.variants.length > 0 ? item.variants[0] : {};

    if (!_.isEmpty(variant)) {
      const image = variant.images[0].image_url;
      this.loadImage(image);
      let imageElement = <></>;

      const manualMap = productManualMapping(item);
      imageElement = (
        <div
          style={{
            width: '100%',
            paddingTop: '100%',
            backgroundColor: '#303030',
            backgroundImage: `url(${config.imageURL}${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '10px 10px 0 0',
          }}
        >
          {!manualMap.inStock ? (
            <div
              style={{
                backgroundColor: 'rgba(50, 50, 50, 0.4)',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                paddingTop: '50%',
                paddingBottom: '50%',
                borderRadius: '10px 10px 0 0',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <span
                  className="rounded-pill"
                  style={{
                    color: '#FFFFFF',
                    padding: '1.5rem 2rem',
                    backgroundColor: 'rgba(71,71,71, 0.9)',
                    fontSize: '14px',
                  }}
                >
                  Stok Habis
                </span>
              </div>
            </div>
          ) : null}
        </div>
      );

      // if (isEmpty(catalog)) return null;

      return (
        <div className="product-card-secondary bg-white">
          <div className="product-card-grid" />
          <div style={{ position: 'relative' }}>
            <img
              src={bookmarkIcon}
              alt="..."
              role="button"
              tabIndex="0"
              onKeyPress={null}
              className="add-to-catalog"
              onClick={this.toggleCatalog}
            />
          </div>

          <div
            className="card border-0 mb-3"
            role="button"
            tabIndex="0"
            onKeyPress={null}
            style={{
              cursor: 'pointer',
              borderRadius: '10px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => history.push(`/product/${variant.sku}`, { sku: variant.sku })}
          >
            <div style={{ position: 'relative' }}>
              {imageElement}
              {slicedCommission !== 0 && (
                <>
                  <img
                    src="/images/product/green-shape.svg"
                    className="rounded product-card-secondary__shape"
                    alt="product"
                  />
                  <div className="product-card-secondary__commission">
                    {' '}
                    <span>
                      Hingga
                      {' '}
                      <b>
                        {slicedCommission}
                        rb
                      </b>
                    </span>
                  </div>
                </>
              )}
            </div>
            <div>
              {/* <Container> */}
              <Block padding="10px 8px">
                <div>
                  <Block paddingTop="3px">
                    <TextSmall increase>
                      {(item.name)}
                    </TextSmall>
                  </Block>
                </div>
                <div>
                  <RecommendationProductBadge
                    payloadProduct={item}
                    payloadVariant={variant}
                  />
                </div>
                <div>
                  <Block>
                    <Row gutter={2} justify="start" type="flex" align="middle">
                      <Col>
                        <TextMedium boldest>Rp</TextMedium>
                      </Col>
                      <Col>
                        <TextLarge style={{ fontWeight: '700' }}>
                          {NumberWithCommas(
                            item.variants[0].prices[0].selling_price,
                          )}
                        </TextLarge>
                      </Col>
                    </Row>
                  </Block>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        fontSize: '10px',
                        color: '#9b9b9b',
                        textDecoration: 'line-through',
                        marginRight: '12px',
                      }}
                    >
                      Rp
                      {' '}
                      {NumberWithCommas(item.variants[0].prices[0].msrp)}
                    </div>
                    <div
                      style={{
                        textDecoration: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '8px',
                        background: '#d32600',
                        color: 'white',
                        borderRadius: '3px',
                        padding: '3px 4px',
                      }}
                    >
                      -
                      {this.percentage(
                        item.variants[0].prices[0].msrp,
                        item.default_selling_price,
                      )}
                      %
                    </div>
                  </div>
                </div>
              </Block>
              {/* </Container> */}
            </div>
          </div>

          <CatalogNotActivatedDrawer
            visible={this.state.visibleStoreNotActivated}
            close
            toggleVisibility={this.toggleStoreNotActivated}
          />

          <NewCatalogDialog
            onSuccess={this.onCreateCatalogSuccessHandler}
            onClosed={() => this.setState({ visibleCreateCatalog: false })}
            visible={this.state.visibleCreateCatalog}
            sku={this.state.catalogSKU}
            margin={this.state.calculateMargin}
          />

          <BottomSheet
            visible={this.state.visibleEmptyCatalog}
            toggleVisibility={this.toggleEmptyCatalog}
            close
          >
            <div className="text-center">
              <img src={emptyCatalog2} />
              <div
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '18px',
                }}
              >
                Belum Ada Katalog
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#747474',
                  marginBottom: '18px',
                }}
              >
                Belum ada katalog yang kamu buat nih, yuk buat Katalogmu
                sekarang!
              </div>
              <ButtonPrimary
                onClick={() => this.setState({ visibleCreateCatalog: true })}
              >
                Buat Katalog Baru
              </ButtonPrimary>
            </div>
          </BottomSheet>

          <Sheet
            isOpen={this.state.visibleCatalog}
            setOpen={this.toggleCatalog}
          >
            <Row type="flex" justify="end" onClick={this.toggleCatalog}>
              <Col>
                <TextSmall color="#633c97" style={{ cursor: 'pointer' }}>
                  Tutup
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">
                  Pilih Varian Produk
                </TextMedium>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <TextSmall increase color="#9b9b9b">
                  Pilih varian yang akan kamu masukkan pada katalog
                </TextSmall>
              </Col>
            </Row>
            <Row style={{ padding: '10px 0px' }}>
              <Block>
                <div>
                  <Block>
                    <Row type="flex" justify="space-between" align="middle">
                      {item.variants.map((items, idx) => (
                        <div
                          className="d-flex justify-content-between py-2 w-100"
                          onClick={() => this.handleSelectCatalog(
                            items.sku,
                            items.prices[0].selling_price,
                            items.satuan,
                            item.qty_set,
                          )}
                        >
                          <div className="pt-2">
                            <div>{items.option1}</div>
                            <div className="boldest">
                              {`Rp ${NumberWithCommas(
                                items.prices[0].selling_price,
                              )} `}
                              {items.satuan === 'set'
                                ? `/ paket (${item.qty_set}pcs)`
                                : items.satuan === 'pcs'
                                  ? '/ pc'
                                  : `${items.satuan}`}
                            </div>
                          </div>
                          <div>
                            <label className="align-self-center mr-4">
                              <input
                                type="radio"
                                name="radio-address"
                                className="custom-radio radio-primary"
                                checked={this.state.catalogSKU === items.sku}
                                onChange={() => this.handleSelectCatalog(
                                  items.sku,
                                  items.prices[0].selling_price,
                                  items.satuan,
                                  item.qty_set,
                                )}
                              />
                              <span className="checkedradio" />
                            </label>
                          </div>
                        </div>
                      ))}
                    </Row>
                  </Block>
                </div>
              </Block>
            </Row>
            {this.state.selectedCatalog ? (
              <button
                className="btn btn-block buy-now text-white bolder background-color-primary tour-next"
                onClick={this.toggleAddPrice}
              >
                Lanjutkan
              </button>
            ) : (
              <ButtonDisable
                style={{ width: '100%', height: '100%', padding: '12px 24px' }}
                disabled
              >
                Lanjutkan
              </ButtonDisable>
            )}
          </Sheet>

          <Sheet
            isOpen={this.state.visibleAddPrice}
            setOpen={this.toggleAddPrice}
          >
            <Row type="flex" justify="end" onClick={this.toggleAddPrice}>
              <Col>
                <TextSmall color="#633c97" style={{ cursor: 'pointer' }}>
                  Kembali
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">Ubah Harga</TextMedium>
              </Col>
              <Row style={{ marginTop: '10px' }}>
                <Col span={18}>
                  <TextSmall color="#9b9b9b" increase boldest>
                    Harga Mitra
                    {' '}
                    {this.state.formatSKU === 'set'
                      ? ` (paket isi ${this.state.pieceSKU}pcs)`
                      : this.state.formatSKU === 'pcs'
                        ? ' (pc)'
                        : `(${this.state.formatSKU})`}
                  </TextSmall>
                </Col>
                <Col className="text-right" span={6}>
                  <TextSmall>
                    Rp
                    {' '}
                    {NumberWithCommas(this.state.priceSKU)}
                  </TextSmall>
                </Col>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <TextSmall color="#9b9b9b" increase boldest>
                    Tentukan Harga Jualmu
                  </TextSmall>
                </Col>
                <Col className="text-right" span={12}>
                  <TextSmall>
                    Keuntungan Rp
                    {this.state.alertSetPrice === '<'
                    || this.state.alertSetPrice === '>' ? (
                      <span style={{ color: '#f02b01', paddingLeft: '.25rem' }}>
                        {NumberWithCommas(this.state.calculateMargin)}
                      </span>
                      ) : (
                        <span style={{ color: '#48a900', paddingLeft: '.25rem' }}>
                          {NumberWithCommas(this.state.calculateMargin)}
                        </span>
                      )}
                  </TextSmall>
                </Col>
              </Row>
              <Col style={{ marginTop: '10px' }}>
                <Row type="flex" align="middle">
                  <Col span={2}>
                    <div>
                      <TextSmall increase boldest>
                        Rp
                      </TextSmall>
                    </div>
                  </Col>
                  <Col span={22}>
                    <NumberFormat
                      className="form-set-margin w-100 border-top-0 border-right-0 border-left-0 no-box-shadow font-size-s"
                      thousandSeparator="."
                      // type='tel'
                      maxLength={11}
                      decimalSeparator=","
                      placeholder={this.state.setPrice}
                      value={this.state.setPrice}
                      onChange={(e) => this.handleChangePriceMargin(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              {this.state.alertSetPrice === '<' ? (
                <Col>
                  <TextSmall color="#f02b01">
                    Harga jual tidak bisa lebih rendah dari harga Woobiz
                  </TextSmall>
                </Col>
              ) : this.state.alertSetPrice === '>' ? (
                <Col>
                  <TextSmall color="#f02b01">
                    Harga jual tidak bisa lebih dari Rp.
                    {' '}
                    {NumberWithCommas(this.state.priceSKU * 3)}
                  </TextSmall>
                </Col>
              ) : null}
            </Row>
            {this.state.alertSetPrice === '=' ? (
              <button
                className="btn btn-block buy-now text-white bolder background-color-primary"
                onClick={this.toggleChooseCatalog}
              >
                Lanjutkan
              </button>
            ) : (
              <ButtonDisable
                className="tour-next"
                style={{ width: '100%', height: '100%', padding: '12px 24px' }}
                disabled
              >
                Lanjutkan
              </ButtonDisable>
            )}
          </Sheet>

          <Sheet
            isOpen={this.state.visibleChooseCatalog}
            setOpen={this.toggleChooseCatalog}
          >
            <Row type="flex" justify="end" onClick={this.toggleChooseCatalog}>
              <Col>
                <TextSmall
                  color="#633c97"
                  primary
                  style={{ cursor: 'pointer' }}
                >
                  Kembali
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">Daftar Katalog</TextMedium>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <TextSmall increase>
                  Tentukan dimana kamu akan menyimpan produk ini
                </TextSmall>
              </Col>
            </Row>
            {!_.isEmpty(catalog) ? (
              !_.isEmpty(catalog) ? (
                <>
                  <div>
                    {catalog.map((item, idx) => (
                      <Row
                        // className="tour-select-catalog"
                        style={{ marginTop: '1rem', cursor: 'pointer' }}
                        type="flex"
                        justify="space-around"
                        align="middle"
                        onClick={() => this.toggleProductCatalog(idx)}
                      >
                        <Col span={22} className="text-left">
                          <TextMedium bold>{item.name}</TextMedium>
                        </Col>
                        <Col className="gutter-row text-right" span={2}>
                          {!_.isEmpty(this.state.dataCheck) ? (
                            this.state.dataCheck[idx]?.checkCatalog ? (
                              <img
                                src={bookmarkActive}
                                width="20px"
                                alt="..."
                              />
                            ) : (
                              <img
                                src={bookmarkInactive}
                                width="20px"
                                alt="..."
                              />
                            )
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    <div className="row no-gutters">
                      <div className="col-6 pr-1">
                        <ButtonPrimary
                          onClick={this.addProductCatalog}
                          disabled={!this.state.wasAddProductCatalog}
                        >
                          Masukkan
                        </ButtonPrimary>
                      </div>
                      <div className="col-6 pl-1">
                        <ButtonPrimaryOutline
                          onClick={() => this.setState({ visibleCreateCatalog: true })}
                        >
                          Katalog Baru
                        </ButtonPrimaryOutline>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div style={{ padding: '0px 24px', marginTop: '24px' }}>
                  <Col className="text-center">
                    <img src={emptyCatalog} width="91" />
                  </Col>
                  <Col className="text-center" style={{ marginBottom: '24px' }}>
                    <TextMedium boldest>Katalog anda masih kosong!</TextMedium>
                  </Col>
                  <button
                    className="btn btn-block buy-now text-white bolder background-color-primary"
                    onClick={() => (window.location.href = '/store')}
                  >
                    Buat Katalog Baru
                  </button>
                </div>
              )
            ) : (
              <div style={{ padding: '0px 24px', marginTop: '24px' }}>
                <button
                  className="btn btn-block buy-now text-white bolder background-color-primary"
                  onClick={() => (window.location.href = '/store')}
                >
                  Buat Katalog Baru
                </button>
              </div>
            )}
          </Sheet>
          <div
            style={{
              position: 'fixed',
              bottom: '150px',
              width: '100%',
              zIndex: '2',
              left: '0',
              right: '0',
            }}
            id="fixed-width"
          >
            {this.state.isWasModifyProduct ? this.notifUpdate() : null}
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  checkProductCatalogState: state.checkProductCatalogState,
  catalogState: state.catalogState,
  storeState: state.storeState,
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogRequest: (data) => dispatch(catalogActions.getCatalogRequest(data)),
  checkProductCatalogRequest: (data) => dispatch(checkProductCatalogActions.checkProductCatalogRequest(data)),
  createProductCatalogRequest: (id, data) => dispatch(catalogActions.createProductCatalogRequest(id, data)),
  deleteProductCatalogRequest: (id, data) => dispatch(catalogActions.deleteProductCatalogRequest(id, data)),
  getStoreRequest: () => dispatch(storeActions.getStoreRequest()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCardGrid),
);
