import { put, take, fork, cancel, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

// Redux Stuffs
import MailboxActions, { MailboxTypes } from '../Redux/MailboxRedux'

// MailboxList
export function* mailboxListRequest (api, getToken) {
  let lastTask
  while(true) {
    const action = yield take(MailboxTypes.MAILBOX_LIST_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(mailboxListRequestAPI, api, getToken, action)
  }
}

export function* mailboxListRequestAPI (api, getToken, { limit, page }) {
  let data = {
    limit: limit,
    page: page
  }
  try {
    const token = yield call(getToken)
    const response = yield call(api.getMailboxList, token, data)
    if(response.ok) {
      return yield put(MailboxActions.mailboxListSuccess(response.data.data))
    } else {
      return yield put(MailboxActions.mailboxListFailure('Terjadi kesalahan'))
    }
  } catch (err) {
    return yield put(MailboxActions.mailboxListFailure('Terjadi kesalahan'))
  }
}

// MailboxRead
export function * mailboxReadRequest (api, getToken) {
  let action = yield take(MailboxTypes.MAILBOX_READ_REQUEST)
  while (action !== END) {
    yield fork(mailboxReadRequestAPI, api, getToken, action)
    action = yield take(MailboxTypes.MAILBOX_READ_REQUEST)
  }
}

export function* mailboxReadRequestAPI (api, getToken, { notification_id }) {
  try {
    if(notification_id !== undefined) {
      const token = yield call(getToken)
      const response = yield call(api.mailboxRead, notification_id , token)
      if(response.ok) {
        yield put(MailboxActions.mailboxReadSuccess(response))
        yield call(api.getMailboxList, token)
      } else {
        return yield put(MailboxActions.mailboxReadFailure('Terjadi kesalahan'))
      }
    }
  } catch (err) {
    return yield put(MailboxActions.mailboxReadFailure('Terjadi kesalahan'))
  }
}

// MailboxUnread
export function * mailboxUnreadRequest (api, getToken) {
  let action = yield take(MailboxTypes.MAILBOX_UNREAD_REQUEST)
  while (action !== END) {
    yield fork(mailboxUnreadRequestAPI, api, getToken, action)
    action = yield take(MailboxTypes.MAILBOX_UNREAD_REQUEST)
  }
}

export function * mailboxUnreadRequestAPI(api, getToken) {
  try {
      const token = yield call(getToken)
      const response = yield call(api.mailboxUnread, token)
      if (response.ok) {
          return yield put(MailboxActions.mailboxUnreadSuccess(response.data.data))
      } else {
          return yield put(MailboxActions.mailboxUnreadFailure(response.data.message))
      }
  } catch (err) {
      return yield put(MailboxActions.mailboxUnreadFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// MailboxDelete
export function * mailboxDeleteRequest (api, getToken) {
  let action = yield take(MailboxTypes.MAILBOX_DELETE_REQUEST)
  while (action !== END) {
    yield fork(mailboxDeleteRequestAPI, api, getToken, action)
    action = yield take(MailboxTypes.MAILBOX_DELETE_REQUEST)
  }
}

export function* mailboxDeleteRequestAPI (api, getToken, { notification_id }) {
  try {
      const token = yield call(getToken)
      const response = yield call(api.mailboxDelete, notification_id , token)
      if(response.ok) {
        return yield put(MailboxActions.mailboxDeleteSuccess(response))
      } else {
        return yield put(MailboxActions.mailboxDeleteFailure('Respon OK tapi error'))
      
    }
  } catch (err) {
    console.log(err)
    return yield put(MailboxActions.mailboxDeleteFailure('Terjadi kesalahan'))
  }
}