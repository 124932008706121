// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCatalogRequest: ['data'],
  getCatalogSuccess: ['payload'],
  getCatalogFailure: ['err'],
  createCatalogRequest: ['data'],
  createCatalogSuccess: ['payload'],
  createCatalogFailure: ['err'],
  updateCatalogRequest: ['id','data'],
  updateCatalogSuccess: ['payload'],
  updateCatalogFailure: ['err'],
  deleteCatalogRequest: ['data'],
  deleteCatalogSuccess: ['payload'],
  deleteCatalogFailure: ['err'],
  createProductCatalogRequest: ['id','data'],
  createProductCatalogSuccess: ['payload'],
  createProductCatalogFailure: ['err'],
  deleteProductCatalogRequest: ['id','data'],
  deleteProductCatalogSuccess: ['payload'],
  deleteProductCatalogFailure: ['err'],
})

export const CatalogTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// GET CATALOG
export const getCatalogRequest = (state) =>
state.merge({ fetching: true, success: false, err: null })

export const getCatalogSuccess = (state, { payload }) =>
state.merge({
  fetching: false,
  data: payload,
  success: true
})

export const getCatalogFailure = (state, { err }) =>
state.merge({
  fetching: false,
  success: false,
  err
})

// CREATE CATALOG
export const createCatalogRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const createCatalogSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const createCatalogFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

  // UPDATE CATALOG
  export const updateCatalogRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })
  
  export const updateCatalogSuccess = (state, { payload }) =>
    state.merge({
      fetching: false,
      data: payload,
      success: true
    })
  
  export const updateCatalogFailure = (state, { err }) =>
    state.merge({
      fetching: false,
      success: false,
      err
    })

  // DELETE CATALOG
  export const deleteCatalogRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })
  
  export const deleteCatalogSuccess = (state, { payload }) =>
    state.merge({
      fetching: false,
      data: payload,
      success: true
    })
  
  export const deleteCatalogFailure = (state, { err }) =>
    state.merge({
      fetching: false,
      success: false,
      err
    })

  // CREATE PRODUCT CATALOG
  export const createProductCatalogRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })
  
  export const createProductCatalogSuccess = (state, { payload }) =>
    state.merge({
      fetching: false,
      data: payload,
      success: true
    })
  
  export const createProductCatalogFailure = (state, { err }) =>
    state.merge({
      fetching: false,
      success: false,
      err
    })

    // DELETE PRODUCT CATALOG
    export const deleteProductCatalogRequest = (state) =>
      state.merge({ fetching: true, success: false, err: null })
    
    export const deleteProductCatalogSuccess = (state, { payload }) =>
      state.merge({
        fetching: false,
        data: payload,
        success: true
      })
    
    export const deleteProductCatalogFailure = (state, { err }) =>
      state.merge({
        fetching: false,
        success: false,
        err
      })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CATALOG_REQUEST]: getCatalogRequest,
  [Types.GET_CATALOG_SUCCESS]: getCatalogSuccess,
  [Types.GET_CATALOG_FAILURE]: getCatalogFailure,
  [Types.CREATE_CATALOG_REQUEST]: createCatalogRequest,
  [Types.CREATE_CATALOG_SUCCESS]: createCatalogSuccess,
  [Types.CREATE_CATALOG_FAILURE]: createCatalogFailure,
  [Types.UPDATE_CATALOG_REQUEST]: updateCatalogRequest,
  [Types.UPDATE_CATALOG_SUCCESS]: updateCatalogSuccess,
  [Types.UPDATE_CATALOG_FAILURE]: updateCatalogFailure,
  [Types.DELETE_CATALOG_REQUEST]: deleteCatalogRequest,
  [Types.DELETE_CATALOG_SUCCESS]: deleteCatalogSuccess,
  [Types.DELETE_CATALOG_FAILURE]: deleteCatalogFailure,
  [Types.CREATE_PRODUCT_CATALOG_REQUEST]: createProductCatalogRequest,
  [Types.CREATE_PRODUCT_CATALOG_SUCCESS]: createProductCatalogSuccess,
  [Types.CREATE_PRODUCT_CATALOG_FAILURE]: createProductCatalogFailure,
  [Types.DELETE_PRODUCT_CATALOG_REQUEST]: deleteProductCatalogRequest,
  [Types.DELETE_PRODUCT_CATALOG_SUCCESS]: deleteProductCatalogSuccess,
  [Types.DELETE_PRODUCT_CATALOG_FAILURE]: deleteProductCatalogFailure,
})
