import React from "react";
import { useRequestBrandBanner } from "../../api/brand-page.api";
import BrandBannerSlider from "../../../../Components/banner/BrandBannerSlider";
import { isEmpty } from "../../../../Utils";
import { BannerBrandLoader } from "../../../../Components/loader";

function BrandBanner({ brandID }) {
  const { data, isFetching } = useRequestBrandBanner(brandID);

  if (isFetching) {
    return <BannerBrandLoader />
  }

  return (
    !isEmpty(data) &&
    <div className='pt-3'>
      <BrandBannerSlider bannerList={data} />
    </div>
  );
}

export default BrandBanner;
