import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

// Redux Stuffs
import StockActions, { StockTypes } from '../Redux/StockRedux'

export function * stockRequest (api, getToken) {
  let action = yield take(StockTypes.STOCK_REQUEST)
  while (action !== END) {
    yield fork(stockRequestAPI, api, getToken, action)
    action = yield take(StockTypes.STOCK_REQUEST)
  }
}

export function * stockRequestAPI (api, getToken, { sku, qty }) {
  try {
    if (sku !== '') {
      const token = yield call(getToken)
      const response = yield call(api.getProductStock, sku, qty, token)
      if (response.ok) {
        return yield put(StockActions.stockSuccess(response.data.data))
      } else {
        return yield put(StockActions.stockFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(StockActions.stockFailure('Terjadi kendala saat melakukan pencarian'))
  }
}
