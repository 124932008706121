import React, { Component } from 'react'
import _ from 'lodash'

// Img
import shareLinkImage from './../../../static/images/share/share-link.svg'
import fbImage from './../../../static/images/share/fb.svg'
import igImage from './../../../static/images/share/ig.svg'
import tawarkanImage from './../../../static/images/share/tawarkan-barang.svg'
import whatsappImage from './../../../static/images/share/whatsapp.svg'

// import shareLink from './../../../static/images/share/bagikan-link-gambar-01.svg'
import downloadImage from './../../../static/images/share/bagikan-link-gambar-02.svg'

// Styled
import { RoundedShareButton, RoundedShareWhatsappButton } from './../../../Components/Styled'

import { Modal, Progress, message, Spin } from 'antd';

// Redux
import { connect } from 'react-redux'

import SocialShareActions from './../../../Redux/SocialShareRedux'
import config from './../../../config'
import { NumberWithCommas, getSafely } from './../../../Utils'
import NumberFormat from 'react-currency-format'
import BottomSheet from '../../../Containers/BottomSheet'
import './social-share.scss'
import slugify from 'slugify'

import { Plugins, FilesystemDirectory } from '@capacitor/core';

const { Filesystem } = Plugins;

class SocialShareComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isMarginSet: false,
            isPack: false,
            price: getSafely(['product', 'single', 'price'], this.props, 0),
            base64Galleries: [],
            downloadGallery: {
                isDownloading: false,
                downloaded: 0
            },
            shareLink: {
                dialogOpen: false
            },
            form: {
                name: this.props.product.name,
                price: getSafely(['product', 'single', 'price'], this.props, 0),
                message: `Yuk, dapatkan ${this.props.product.name} sekarang di saya!`,
            },
            formValidity: true,
            socialShareState: {},
            isNative: window.Capacitor.isNative,
            nativeShare: {
                instagram: true,
                facebook: true,
            }
        }
    }

    componentDidMount() {
        // id better make sure everythings good
        if (!this.props.product.single.active) {
            this.setState({
                isPack: true,
                price: this.props.product.pack.price,
                form: {
                    ...this.state.form,
                    price: this.props.product.pack.price
                }
            })
        }
    }

    toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }


    nativeShareImages = (callback) => {
        this.setState({
            downloadGallery: {
                isDownloading: true,
                downloaded: 0
            }
        })

        let images = []
        if (this.state.base64Galleries.length < this.sharedImages().length) {
            this.sharedImages().forEach((o) => {
                this.toDataUrl(o.url, (base64) => {
                    images.push(base64)

                    this.setState({
                        downloadGallery: {
                            downloaded: this.state.downloadGallery.downloaded + 1
                        }
                    })

                    if (this.state.downloadGallery.downloaded == this.sharedImages().length) {
                        this.setState({
                            downloadGallery: {
                                isDownloading: false
                            }
                        })

                        message.success("Semua gambar telah di download")
                        this.setState({ base64Galleries: images })
                        callback(images)
                        console.log("masih diatas", images)
                    }
                })
            })
        } else {
            console.log("masuk dibawah", this.state.base64Galleries)
            callback(this.state.base64Galleries)
            this.setState({
                downloadGallery: {
                    isDownloading: false,
                }
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let updated = {}

        if (!_.isEqual(nextProps.socialShareState, prevState.socialShareState) && !_.isEmpty(nextProps.socialShareState)) {
            updated = {
                ...updated,
                ...{ socialShareState: nextProps.socialShareState }
            }
        }

        if (!_.isEqual(nextProps.userState, prevState.userState) && !_.isEmpty(nextProps.userState)) {
            updated = {
                ...updated,
                ...{ userState: nextProps.userState }
            }
        }

        return updated
    }

    sharedImages = () => {
        const getFileName = (url) => {
            var filename = url.substring(url.lastIndexOf('/') + 1);
            return filename
        }

        let images = []
        let propsImages = _.filter(this.props.product.images, (o) => { return o.type === 1 })
        propsImages.map((url, key) => {
            if (key == 0 && propsImages.length > 1) {
                return url;
            }

            images.push({
                url: config.imageURL + url.image_url, name: getFileName(url.image_url)
            })
        })

        return images
    }

    componentDidUpdate() {
        const formValidity = (this._marginDialogValidate('name') == false && this._marginDialogValidate('price') == false && this._marginDialogValidate('message') == false)
        if (this.state.formValidity != formValidity) {
            this.setState({ formValidity: formValidity })
        }
    }

    downloadImage = () => {
        // Filename functions
        const forceDownload = (url, fileName) => {
            const self = this;

            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.download = fileName;
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
                self.setState({
                    downloadGallery: {
                        downloaded: self.state.downloadGallery.downloaded + 1
                    }
                })

                if (self.state.downloadGallery.downloaded == (self.props.product.images.length - 1)) {
                    self.setState({
                        downloadGallery: {
                            isDownloading: false
                        }
                    })

                    message.success("Semua gambar telah di download")
                }
            }
            xhr.send();
        }

        const nativeForceDownload = (urls) => {
            this.toDataUrl(urls.url, (base64) => {
                Filesystem.writeFile({
                    path: urls.name,
                    data: base64,
                    directory: FilesystemDirectory.Documents,
                    // encoding: FilesystemEncoding.UTF8
                }).then((response) => {
                    console.log(response)
                    this.setState({
                        downloadGallery: {
                            downloaded: this.state.downloadGallery.downloaded + 1
                        }
                    })

                    if (this.state.downloadGallery.downloaded == this.sharedImages().length) {
                        this.setState({
                            downloadGallery: {
                                isDownloading: false
                            }
                        })

                        message.success("Semua gambar telah di download")
                    }
                }).catch(() => {
                    this.setState({
                        downloadGallery: {
                            downloaded: this.state.downloadGallery.downloaded + 1
                        }
                    })

                    if (this.state.downloadGallery.downloaded == this.sharedImages().length) {
                        this.setState({
                            downloadGallery: {
                                isDownloading: false
                            }
                        })

                        message.success("Gambar telah didownload")
                    }
                })
            })
        }


        this.setState({ downloadGallery: { isDownloading: true, downloaded: 0 } })
        if (this.state.isNative) {
            this.sharedImages().map((image, key) => {
                try {
                    nativeForceDownload(image);   
                } catch (error) {
                    this.setState({
                        downloadGallery: {
                            isDownloading: false
                        }
                    });

                    message.success("Terjadi kesalahan, mohon terima permintaan akses ke memori penyimpanan.");
                }
            })
        } else {
            this.sharedImages().map((image, key) => {
                forceDownload(image.url, image.name)
            })
        }
    }

    render() {

        const images = (this.props.product.images) ? this.props.product.images.length : []
        const modal = (<Modal
            centered
            bodyStyle={{ textAlign: 'center', padding: 15 }}
            width={320}
            cancelText='Tutup'
            zIndex={2000}
            closable={false}
            visible={this.state.downloadGallery.isDownloading}
            onCancel={() => this.setState({ downloadGallery: { isDownloading: false } })}
            onOk={() => this.setState({ downloadGallery: { isDownloading: false } })}
        >
            <Progress percent={parseInt((this.state.downloadGallery.downloaded / (images - 1)) * 100)} status="active" />
            Sedang mendownload gambar
        </Modal>)

        // Still not cleaning code
        // if (!this.state.isMarginSet) {

        if (!this.state.isNative) {
            return (<div className='mt-4 mb-4 d-flex justify-content-between align-items-center web-layout'>
                <div className="button-outline" onClick={(e) => { e.stopPropagation(); this.setState({ shareLink: { dialogOpen: true } }) }}>
                    <img alt="Tawarkan Barang" src={tawarkanImage} width="22" className="mr-2" /> Tawarkan Barang
                </div>
                <div className="button-outline" onClick={(e) => { e.stopPropagation(); this.downloadImage(); }}>
                    <img alt="Download Gambar" src={downloadImage} className="mr-2" width="16" /> Download Gambar
                </div>

                {modal}
                {this._render_set_margin_dialog()}
            </div >)
        }

        return (<>
            <div className='mt-4 mb-4 d-flex justify-content-between align-items-center native-layout'>
                {/* {this.__shareButtonLink()} */}
                <RoundedShareButton onClick={(e) => { e.stopPropagation(); this.setState({ shareLink: { dialogOpen: true } }) }}>
                    <img src={tawarkanImage} height='40px' alt='Share Link' />
                </RoundedShareButton>
                <RoundedShareButton onClick={(e) => { e.stopPropagation(); this.downloadImage(); }}>
                    <img style={{ padding: "10px" }} src={downloadImage} height='40px' alt='Share Link' />
                </RoundedShareButton>
                {this.__shareButtonInstagram()}
                {this.__shareButtonFacebook()}
                {this.__shareButtonWhatsapp()}
            </div>
            {this._render_set_margin_dialog()}
            {modal}
        </>
        )
    }
    __shareButtonLink() {
        const share = () => {
            this.nativeShareImages((images) => {
                window.plugins.socialsharing.share('',
                    null,
                    images,
                    () => { console.log("shared") },
                    () => { console.log("not shared, and error acured") })
            })
        }

        return (<RoundedShareButton onClick={(e) => { e.stopPropagation(); share() }}>
            <img src={shareLinkImage} height='40px' alt='Share Link' />
        </RoundedShareButton>)
    }

    __shareButtonFacebook() {
        const share = () => {
            this.nativeShareImages((images) => {
                window.plugins.socialsharing.shareViaFacebook('',
                    images,
                    null,
                    () => { console.log("shared") },
                    () => { console.log("not shared, and error acured") })
            })
        }

        if (this.state.nativeShare.facebook) {
            // Good for prefetch
            return (<RoundedShareButton onClick={(e) => { e.stopPropagation(); share() }}>
                <img src={fbImage} height='40px' alt='Facebook' />
            </RoundedShareButton>)
        }
        return "";
    }

    __shareButtonInstagram() {

        const share = () => {
            this.nativeShareImages((images) => {
                window.plugins.socialsharing.shareViaInstagram(
                    'Share Via Instagram',
                    images[0],
                    () => { console.log("shared") },
                    (errormsg) => { console.log(errormsg) })
            })
        }

        if (this.state.nativeShare.instagram) {
            return (<RoundedShareButton onClick={(e) => { e.stopPropagation(); share() }}>
                <img src={igImage} height='40px' alt='Instagram' />
            </RoundedShareButton>)
        }

        return ""
    }

    __shareButtonWhatsapp() {
        const share = () => {
            this.nativeShareImages((images) => {
                console.log(images)
                window.plugins.socialsharing.shareViaWhatsApp('',
                    images,
                    null,
                    () => { console.log("shared") },
                    () => { console.log("not shared, and error acured") })
            })
        }

        if(this.props.compact) {
            return (<div class='social-whatsapp-share' onClick={(e) => { e.stopPropagation(); share() }}>
                <img src={whatsappImage} height='40px' alt='Whatsapp' />
            </div>)
        }

        return (<RoundedShareWhatsappButton onClick={(e) => { e.stopPropagation(); share() }}>
            <img src={whatsappImage} className=' ml-n2' height='40px' alt='Whatsapp' /> Bagikan
        </RoundedShareWhatsappButton >)
    }

    switchUnit() {
        this.setState({
            isPack: !this.state.isPack
        }, () => {
            const price = (this.state.isPack) ? this.props.product.pack.price : this.props.product.single.price

            this.setState({
                price: price
            })

            this.setState((state) => ({
                form: {
                    ...state.form,
                    price: price
                }
            }))
        })
    }

    marginDialogUpdate = (callback) => {
        this.setState({
            isLoading: true
        })

        const product = _.find(this.props.product.variants, (o) => {
            if (this.state.isPack) return o.satuan === "set"
            return o.satuan === this.props.product.single.unit
        })

        let customPrice = _.toString(this.state.form.price).split('.').join('')
        customPrice = parseInt(customPrice)

        this.props.socialShareRequest(
            {
                "sku": product.sku,
                "name": this.state.form.name,
                "price": customPrice,
                "description": this.state.form.message
            }
        )

        // We should wait and do some watcher
        const interval = setInterval(() => {
            if (this.state.isLoading === true && this.state.socialShareState.fetching === false && this.state.socialShareState.success === true) {
                clearInterval(interval)
                this.setState({ isLoading: false })

                // Generate Link
                const message = {
                    title: this.state.form.name,
                    text: `${this.state.form.message} hanya dengan harga Rp.${NumberWithCommas(customPrice)}, silahkan cek link berikut`,
                    url: `${config.linkShare}/${slugify(this.state.userState.data.name)}/${this.state.socialShareState.data.slug}`
                }
                callback(message)
            }
        }, 500);
    }
    marginDialogBrowserShare = () => {
        this.marginDialogUpdate(async (shareData) => {
            try {
                window.navigator.share(shareData)
            } catch (error) {
                const el = document.createElement('textarea');
                el.value = `${shareData.text} ${shareData.url}`;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                message.info("Url tawarkan barang telah di salin")
            }

        })
    }
    marginDialogShareWhatsapp = () => {
        this.marginDialogUpdate((shareData) => {
            const text = encodeURI(`${shareData.text} ${shareData.url}`)
            window.open(`https://api.whatsapp.com/send?text=${text}`, '_blank')
        })
    }


    _marginDialogValidate(key) {
        let message = ""
        switch (key) {
            case 'name':
                if (this.state.form[key].length == 0) {
                    message = "Nama tidak boleh kosong"
                }
                // Margin check
                break;
            case 'price':
                if (this.state.form[key] == 0) {
                    message = "Harga tidak boleh kosong"
                }
                else if (this.margin() < 0) {
                    message = "Keuntungan tidak boleh kurang dari 0"
                }
                else if (this.margin() > this.state.price) {
                    message = `Harga penawaran barang tidak boleh lebih dari Rp. ${NumberWithCommas(this.state.price * 2)}`
                }
                break;
            case 'message':
                if (this.state.form[key].length == 0) {
                    message = "Pesan tidak boleh kosong"
                }
                break;
            default:
                break;
        }

        if (message.length > 0) {
            return (<div className="error-bag">{message}</div>)
        }
        return false
    }

    margin() {
        let customPrice = _.toString(this.state.form.price).split('.').join('')
        customPrice = parseInt(customPrice)
        const margin = _.isNaN(customPrice) ? 0 : customPrice - this.state.price
        return margin
    }

    _render_set_margin_dialog() {
        const toggleVisibility = () => { this.setState({ shareLink: { dialogOpen: !this.state.shareLink.dialogOpen } }) }
        const updateFormInput = (key, value) => {

            // lets validate
            if (key === "message") {
                if (value.length > 300) return;
            }

            if (key === "price") {
                if (_.isEmpty(value)) value = 0
                value = _.toInteger(_.toString(value).split('.').join(''))
            }

            let form = this.state.form;
            form[key] = value;
            this.setState({ form });
        }

        return (<BottomSheet
            close
            visible={this.state.shareLink.dialogOpen}
            toggleVisibility={toggleVisibility}
            customHeight={
                window.screen.availHeight - window.screen.availHeight * 0.2
            }
            onClick={(e) => { e.stopPropagation(); }}
        >
            <Spin spinning={this.state.isLoading}>
                <div className='social-share-set-margin'>
                    <div className="title">Tawarkan Barang</div>
                    <div className="subtitle">Kamu bisa ubah Nama Barang dan atur Harga sesuai keuntungan yang ingin Kamu peroleh.</div>

                    <div className="price-detail">
                        {(this.props.product.single.active) ? (<div className={"d-flex justify-content-between mb-1 " + (!this.state.isPack ? "active" : "")}>
                            <div className="">Harga Satuan (1 {this.props.product.single.unit})</div>
                            <div className="">Rp {NumberWithCommas(this.props.product.single.price)}</div>
                        </div>) : null}

                        {(this.props.product.pack.active) ? (<div className={"d-flex justify-content-between mb-1 " + (this.state.isPack ? "active" : "")}>
                            <div className="">Harga Set ({NumberWithCommas(this.props.product.pack.qty)} {this.props.product.single.unit})</div>
                            <div className="">Rp. {NumberWithCommas(this.props.product.pack.price)}</div>
                        </div>) : null}
                    </div>

                    <div className="data-input">
                        <div className="data-input-group">
                            <div className="label boldest">Nama Barang</div>
                            <div className="d-flex border-bottom  align-items-center">
                                <input type='text' className='dig-input' value={this.state.form.name} onChange={(e) => updateFormInput('name', e.target.value)} />
                                <span className='fa fa-edit' />
                            </div>
                            {this._marginDialogValidate('name')}
                        </div>
                        <div className="data-input-group">
                            <div className="d-flex  justify-content-between align-items-center">
                                <div className="label boldest">Tentukan Harga ({(this.state.isPack) ? "Set" : "Satuan"})</div>
                                <div className="label margin">Keuntungan : Rp {NumberWithCommas(this.margin())}</div>
                            </div>
                            <div className="d-flex border-bottom align-items-center">
                                <NumberFormat type="tel" thousandSeparator={'.'} decimalSeparator={','} className='dig-input' value={this.state.form.price} onChange={(e) => updateFormInput('price', e.target.value)} />
                                <span className='fa fa-edit' />
                            </div>
                            {this._marginDialogValidate('price')}
                        </div>

                        {(this.props.product.pack.active && this.props.product.single.active) ? (<div className="switch-unit" onClick={(e) => { e.stopPropagation(); this.switchUnit() }}>
                            Ingin menawarkan produk dalam {(this.state.isPack) ? "Satuan" : "Set"}? Klik <span className="bolder color-primary">disini</span>
                        </div>) : null}

                        <div className="data-input-group">
                            <div className="label boldest">Pesan</div>
                            <textarea className="dig-textarea" rows="3" value={this.state.form.message} onChange={(e) => updateFormInput('message', e.target.value)}></textarea>
                            <div className="d-flex  justify-content-between align-items-center">
                                {this._marginDialogValidate('message')}
                                <div className="text-right flex-grow-1 dig-message-count">{this.state.form.message.length}/300</div>
                            </div>
                        </div>
                    </div>
                    <div className="action-footer d-flex justify-content-between">

                        <button onClick={(e) => { e.stopPropagation(); this.marginDialogBrowserShare() }} disabled={!this.state.formValidity} className="custom-btn btn-link"><img src={shareLinkImage} className='ml-n2 mr-n1' height='40px' alt='Share Link' /> Lainnya</button>
                        <button onClick={(e) => { e.stopPropagation(); this.marginDialogShareWhatsapp() }} disabled={!this.state.formValidity} className="custom-btn btn-whatsapp"><img src={whatsappImage} className='ml-n2 mr-n1' height='40px' alt='Share Whatsapp' /> Bagikan</button>
                    </div>
                </div>
            </Spin>
        </BottomSheet>)
    }
}

const mapStateToProps = state => ({
    socialShareState: state.socialShareState,
    userState: state.userState
    //   productCommissionState: state.productCommissionState,
    //   productDetailState: state.productDetailState
})

const mapDispatchToProps = dispatch => ({
    socialShareRequest: (data) => dispatch(SocialShareActions.socialShareRequest(data))
    //   productCommissionRequest: (sku, qty) => dispatch(ProductDetailCommissionActions.productCommissionRequest(sku, qty)),
    //   fetchProductDetail: sku => dispatch(ProductDetailActions.productDetailRequest(sku))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SocialShareComponent)