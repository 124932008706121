import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'
import _ from 'lodash'
import localforage from 'localforage'

// Catalog
import checkProductCatalogActions, { CheckProductCatalogTypes } from '../Redux/Catalog/CheckProductCatalogRedux'
import catalogActions, { CatalogTypes } from '../Redux/Catalog/CatalogRedux'
import CatalogCreateWithProductActions , { CatalogCreateWithProductTypes } from '../Redux/Catalog/CatalogCreateWithProductRedux'

export function* checkProductCatalogRequest(api, getToken) {
  let action = yield take(CheckProductCatalogTypes.CHECK_PRODUCT_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(checkProductCatalogRequestAPI, api, getToken, action)
    action = yield take(CheckProductCatalogTypes.CHECK_PRODUCT_CATALOG_REQUEST)
  }
}

export function * checkProductCatalogRequestAPI (api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.checkProductCatalog, token, data)
    if (response.ok) {
        return yield put(checkProductCatalogActions.checkProductCatalogSuccess(response.data.data))
    } else {
        return yield put(checkProductCatalogActions.checkProductCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(checkProductCatalogActions.checkProductCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* getCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.GET_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(getCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.GET_CATALOG_REQUEST)
  }
}

export function * getCatalogRequestAPI (api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getCatalog, token, data)
    if (response.ok) {
        return yield put(catalogActions.getCatalogSuccess(response.data.data))
    } else {
        return yield put(catalogActions.getCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.getCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* createCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.CREATE_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(createCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.CREATE_CATALOG_REQUEST)
  }
}

export function * createCatalogRequestAPI (api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.createCatalog, token, data)
    if (response.ok) {
      const response2 = yield call(api.getCatalog, token, 1)
      return yield put(catalogActions.createCatalogSuccess(response.data.data, response2.data.data))
    } else {
      return yield put(catalogActions.createCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.createCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* createCatalogWithProductRequest(api, getToken) {
  let action = yield take(CatalogCreateWithProductTypes.CATALOG_CREATE_WITH_PRODUCT_REQUEST)
  while (action !== END) {
    yield fork(createCatalogWithProductRequestAPI, api, getToken, action)
    action = yield take(CatalogCreateWithProductTypes.CATALOG_CREATE_WITH_PRODUCT_REQUEST)
  }
}

export function * createCatalogWithProductRequestAPI (api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.createCatalog, token, data)
    if (response.ok) {
      return yield put(CatalogCreateWithProductActions.catalogCreateWithProductSuccess(response.data.data))
    } else {
      return yield put(CatalogCreateWithProductActions.catalogCreateWithProductFailure(response.data.data))
    }
  } catch (err) {
    return yield put(CatalogCreateWithProductActions.catalogCreateWithProductFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* updateCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.UPDATE_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(updateCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.UPDATE_CATALOG_REQUEST)
  }
}

export function * updateCatalogRequestAPI (api, getToken, {id, data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.updateCatalog, token, id, data)
    if (response.ok) {
      const response = yield call(api.getCatalog, token, 1)
      return yield put(catalogActions.createProductCatalogSuccess(response.data.data))
    } else {
      return yield put(catalogActions.createProductCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.createProductCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* deleteCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.DELETE_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(deleteCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.DELETE_CATALOG_REQUEST)
  }
}

export function * deleteCatalogRequestAPI (api, getToken, {data}) {
  try {
    console.log('test')
    const token = yield call(getToken)
    const response = yield call(api.deleteCatalog, token, data)
    if (response.ok) {
      const response = yield call(api.getCatalog, token, 1)
      return yield put(catalogActions.createProductCatalogSuccess(response.data.data))
    } else {
      return yield put(catalogActions.createProductCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.createProductCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* createProductCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.CREATE_PRODUCT_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(createProductCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.CREATE_PRODUCT_CATALOG_REQUEST)
  }
}

export function * createProductCatalogRequestAPI (api, getToken, {id, data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.createProductCatalog, token, id, data)
    if (response.ok) {
      const response = yield call(api.getCatalog, token, 1)
      return yield put(catalogActions.createProductCatalogSuccess(response.data.data))
    } else {
      return yield put(catalogActions.createProductCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.createProductCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* deleteProductCatalogRequest(api, getToken) {
  let action = yield take(CatalogTypes.DELETE_PRODUCT_CATALOG_REQUEST)
  while (action !== END) {
    yield fork(deleteProductCatalogRequestAPI, api, getToken, action)
    action = yield take(CatalogTypes.DELETE_PRODUCT_CATALOG_REQUEST)
  }
}

export function * deleteProductCatalogRequestAPI (api, getToken, {id, data}) {
  try {
    const token = yield call(getToken)
    const payload = {
      "products" : [
        data
      ]
    }
    const response = yield call(api.deleteProductCatalog, token, id, payload)
    if (response.ok) {
      const response = yield call(api.getCatalog, token, 1)
      return yield put(catalogActions.deleteProductCatalogSuccess(response.data.data))
    } else {
      return yield put(catalogActions.deleteProductCatalogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(catalogActions.deleteProductCatalogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}