import React from "react";

export const BrandLoader = (props) => (
  <></>
);

export const ProductLoader = (props) => (
  <></>
);

export const ProductListLoader = (props) => (
  <></>
);

export const CouponLoader = (props) => (
  <></>
);

export const BannerBrandLoader = (props) => (
  <></>
)

export const SahabatBrandLoader = (props) => (
  <></>
)