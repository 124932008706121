import { put, take, call, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
import notificationReducer from '../../Reducers/product/notificationReducer'
import { api, token as getToken } from '../../Apis'

export function* productNotificationRequest(api, getToken) {
    let action = yield take(notificationReducer.Types.PRODUCT_NOTIFICATION_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.getPdpNotification, token, {})
        if (response.ok) {
            yield put(notificationReducer.Creators.productNotificationSuccess(response.data.data))
        } else {
            yield put(notificationReducer.Creators.productNotificationFailure(response.data.message))
        }
        action = yield take(notificationReducer.Types.PRODUCT_NOTIFICATION_REQUEST)
    }
}

export function* productNotificationSaga() {
    yield all([
        fork(productNotificationRequest, api, getToken),
    ])
}