// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addUserFavoriteRequest: ['data'],
  addUserFavoriteSuccess: ['payload'],
  addUserFavoriteFailure: ['err'],
  getUserFavoriteRequest: null,
  getUserFavoriteSuccess: ['payload'],
  getUserFavoriteFailure: ['err']
})

export const UserFavoriteTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const addUserFavoriteRequest = (state) =>
  state.merge({
    fetching: true, success: false, err: null
  })

export const addUserFavoriteSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const addUserFavoriteFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

export const getUserFavoriteRequest = (state) =>
  state.merge({
    fetching: true, success: false, err: null
  })

export const getUserFavoriteSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const getUserFavoriteFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_USER_FAVORITE_REQUEST]: addUserFavoriteRequest,
  [Types.ADD_USER_FAVORITE_SUCCESS]: addUserFavoriteSuccess,
  [Types.ADD_USER_FAVORITE_FAILURE]: addUserFavoriteFailure,
  [Types.GET_USER_FAVORITE_REQUEST]: getUserFavoriteRequest,
  [Types.GET_USER_FAVORITE_SUCCESS]: getUserFavoriteSuccess,
  [Types.GET_USER_FAVORITE_FAILURE]: getUserFavoriteFailure
})
