// @flow
import { createReducer, createActions } from 'reduxsauce'
import { INITIAL_STATE } from '../Banners/BannerRecommendationReducer'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
    productNotificationRequest: null, // get cart detail - fetch from localforage
    productNotificationSuccess: ['payload'],
    productNotificationFailure: ['err'],
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */
export const productNotificationRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const productNotificationSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const productNotificationFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.PRODUCT_NOTIFICATION_REQUEST]: productNotificationRequest,
    [Types.PRODUCT_NOTIFICATION_SUCCESS]: productNotificationSuccess,
    [Types.PRODUCT_NOTIFICATION_FAILURE]: productNotificationFailure,
})
