import React, { ReactNode, useState } from 'react';
import theme, { ColorTypes } from 'shared/theme';
import MarginType, { marginTypePropsHelper } from 'shared/types/margin.type';
import PaddingType, { paddingTypePropsHelper } from 'shared/types/padding.type';

import { ThemeProvider } from 'styled-components';
import StyledButton from './button.styled';

export type ButtonProps = {
  children: ReactNode;

  className?: string;

  /**
   * Lebar tombol akan mengikuti lebar container.
   */
  block?: boolean;

  /**
   * Tombol tidak akan bisa di klik
   */
  disabled?: boolean;

  /**
   * Menampilkan loading pada tombol
   */
  loading?: boolean;

  color?: ColorTypes;

  textColor?: ColorTypes;

  fontSize?: string;

  /**
   * Ukuran tombol
   */
  size?: 'default' | 'medium' | 'small';

  /**
   * Variant
   */
  variant?: 'default' | 'ghost' | 'clear' | 'outline'

  isError?: boolean | string;
  /**
   * Fungsi Click, kamu bisa menaruh fungsi biasa kesini
   * atau bisa juga dengan return Promise, jika kamu melampirkan
   * return promise kesini maka secara otomatis tombol akan mengaktifkan
   * state loading sampai promise terpenuhi.
   */
  onClick?: (props?: any) => void | Promise<any>;

  type?: 'button' | 'submit';
} & MarginType & PaddingType

const Button = (props: ButtonProps) => {
  const [loading, setLoading] = useState(false);

  const onClickHandler = () => {
    if (!props.onClick) {
      return;
    }

    const onClick = props.onClick();

    if (onClick instanceof Promise) {
      setLoading(true);
      onClick.finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        {...props as any}
        className={props.className}
        data-is-block={props.block}
        data-is-loading={loading || props.loading}
        data-size={props.size}
        data-is-error={props.isError}
        data-variant={props.variant}
        data-color={props.color}
        disabled={loading || props.disabled}
        onClick={onClickHandler}
        fontSize={props.fontSize}
        {...marginTypePropsHelper(props)}
        {...paddingTypePropsHelper(props)}
      />
    </ThemeProvider>
  );
};

Button.defaultProps = {
  block: false,
  disabled: false,
  loading: false,
  fontSize: '14px',
  size: 'default',
  variant: 'default',
  color: 'purple',
  type: 'button',
};

export default Button;
