// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  otpInit: null,
  verifyOtpRequest: ['phoneNumber', 'agentId', 'otp', 'subdomain'],
  verifyOtpSuccess: ['payload'],
  verifyOtpFailure: ['err']
})

export const VerifyOtpTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false,
    err: null
  })

// VERIFY OTP
export const verifyOtpRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const verifyOtpSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const verifyOtpFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.OTP_INIT]: init,
  [Types.VERIFY_OTP_REQUEST]: verifyOtpRequest,
  [Types.VERIFY_OTP_SUCCESS]: verifyOtpSuccess,
  [Types.VERIFY_OTP_FAILURE]: verifyOtpFailure
})
