import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'

import SetupActions, { SetupPreferencesTypes } from '../Redux/SetupRedux'

const getUserState = state => state.userState

// Store Setup Preference to Database
export function * addSetupUserRequest (api, getToken) {
  let action = yield take(SetupPreferencesTypes.ADD_SETUP_REQUEST)
  while (action !== END) {
    yield fork(addSetupUserRequestAPI, api, getToken, action)
    action = yield take(SetupPreferencesTypes.ADD_SETUP_REQUEST)
  }
}

export function * addSetupUserRequestAPI (api, getToken, { data }) {
  const token = yield call(getToken)
  const response = yield call(api.addSetupUser, data, token)
  try {
    if (response.ok) {
      return yield put(SetupActions.addSetupSuccess(response.data.data.setup))
    } else {
      return yield put(SetupActions.addSetupFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      SetupActions.addSetupFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// Get setup user data

export function * getSetupUserRequest (api, getToken) {
  let action = yield take(SetupPreferencesTypes.GET_SETUP_REQUEST)
  while (action !== END) {
    yield fork(getSetupUserRequestAPI, api, getToken, action)
    action = yield take(SetupPreferencesTypes.GET_SETUP_REQUEST)
  }
}

export function * getSetupUserRequestAPI (api, getToken) {
  const token = yield call(getToken)
  const userState = yield select(getUserState)
  const agentId = userState.data.agent_id
  const response = yield call(api.getSetupUser, agentId, token)
  try {
    if (response.ok) {
      return yield put(SetupActions.getSetupSuccess(response.data.data.setup))
    } else {
      return yield put(SetupActions.getSetupFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      SetupActions.getSetupFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}
