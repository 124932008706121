import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'
import _ from 'lodash'
import localforage from 'localforage'

// Cart
import CartActions, { CartTypes } from '../Redux/CartRedux'
import CartWalletActions, { CartWalletTypes } from '../Redux/CartWalletRedux'
import CompleteOrderActions, { CompleteOrderTypes } from '../Redux/CompleteOrderRedux'

// Get User State
const getUserState = state => state.userState

export function* cartDetailRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.CART_REQUEST)
  while (action !== END) {
    yield fork(cartRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.CART_REQUEST)
  }
}

export function* cartUpdateRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.UPDATE_CART_REQUEST)
  while (action !== END) {
    yield fork(updateCartRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.UPDATE_CART_REQUEST)
  }
}

export function* cartCreateRequest(api, getToken) {
  let action = yield take(CartTypes.CREATE_CART_REQUEST)
  while (action !== END) {
    yield fork(createCartRequestAPI, api, getToken, action)
    action = yield take(CartTypes.CREATE_CART_REQUEST)
  }
}

export function* removeCartItemRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.REMOVE_CART_ITEM_REQUEST)
  while (action !== END) {
    yield fork(removeCartItemRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.REMOVE_CART_ITEM_REQUEST)
  }
}

export function * cartRequestAPI (api, getToken, getCartId) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.getCartDetail, cartId, token)
    if (!_.isEmpty(token)) {
      if (!_.isEmpty(cartId)) {
        if (_.isEmpty(response.data.data)) {
          return yield fork(createCartRequestAPI, api, getToken, getCartId)
        }
        if (response.data.data.status !== 'pending') {
          return yield fork(createCartRequestAPI, api, getToken, getCartId)
        }
        if (response.ok) {
          yield localforage.setItem('cart_id', response.data.data.cart_id)
          return yield put(CartActions.cartSuccess(response.data.data))
        } else {
          return yield put(CartActions.cartFailure(response.data.message))
        }
      } else {
        // we need to post create cart
        return yield fork(createCartRequestAPI, api, getToken, getCartId)
      }
    }
  } catch (err) {
    return yield put(CartActions.cartFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* cartRequestPaymentAPI(api, getToken, getCartId) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    if (!_.isEmpty(token)) {
      if (!_.isEmpty(cartId)) {
        const response = yield call(api.getCartDetail, cartId, token)
        if (response.ok) {
          yield localforage.setItem('cart_id', response.data.data.cart_id)
          return yield put(CartActions.cartSuccess(response.data.data))
        } else {
          return yield fork(createCartRequestAPI, api, getToken, getCartId)
          // return yield put(CartActions.cartFailure(response.data.message))
        }
      } else {
        // we need to post create cart
        return yield fork(createCartRequestAPI, api, getToken, getCartId)
      }
    }
  } catch (err) {
    return yield put(
      CartActions.cartFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

export function* updateCartRequestAPI(api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    if (!_.isEmpty(cartId) && !_.isEmpty(token)) {
      const payload = {
        cart_id: cartId,
        ...data
      }
      const response = yield call(api.updateCart, payload, token)
      if (response.ok) {
        yield localforage.setItem('cart_id', response.data.data.cart_id)
        yield fork(cartRequestAPI, api, getToken, getCartId)

        // update shipping options list
        // yield fork(refreshShippingOptionRequestAPI, api, getToken, getCartId)
        
        // yield put(CartActions.updateCartSuccess(response.data.data))
      } else {
        return yield put(CartActions.updateCartFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(
      CartActions.updateCartFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

export function* createCartRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id
    const data = {
      agent: {
        agent_id: agentId
      }
    }

    const response = yield call(api.createCart, data, token)
    if (response.ok) {
      yield localforage.setItem('cart_id', response.data.data.cart_id)
      if (response.data.data.status === 'processing') {
        yield localforage.removeItem('cart_id')
      }
      yield fork(cartRequestAPI, api, getToken, getCartId)

      // yield put(CartActions.createCartSuccess(response.data.data))
    } else {
      return yield put(CartActions.createCartFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      CartActions.createCartFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

export function* removeCartItemRequestAPI(api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    if (!_.isEmpty(cartId) && !_.isEmpty(token)) {
      const payload = {
        cart_id: cartId,
        ...data
      }
      const response = yield call(api.removeCartItem, payload, token)
      if (response.ok) {
        yield localforage.setItem('cart_id', response.data.data.cart_id)
        yield fork(cartRequestAPI, api, getToken, getCartId)
        // yield put(CartActions.updateCartSuccess(response.data.data))
      } else {
        return yield put(
          CartActions.removeCartItemFailure(response.data.message)
        )
      }
    }
  } catch (err) {
    return yield put(
      CartActions.removeCartItemFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// Apply Friend Code

export function* applyFriendCodeRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.APPLY_FRIEND_CODE_REQUEST)
  while (action !== END) {
    yield fork(applyFriendCodeRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.APPLY_FRIEND_CODE_REQUEST)
  }
}

export function* applyFriendCodeRequestAPI(
  api,
  getToken,
  getCartId,
  { friendCode }
) {
  try {
    const token = yield call(getToken)
    const cartId = yield call(getCartId)
    const response = yield call(api.applyCoupon, token, cartId, friendCode)
    if (response.ok) {
      yield localforage.setItem('cart_id', response.data.data.cart_id)
      yield fork(cartRequestAPI, api, getToken, getCartId)
    } else {
      return yield put(CartActions.applyFriendCodeFailure(response.data.error))
    }
  } catch (err) {
    return yield put(
      CartActions.applyFriendCodeFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// Remove Friend Code

export function* removeFriendCodeRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.REMOVE_FRIEND_CODE_REQUEST)
  while (action !== END) {
    yield fork(removeFriendCodeRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.REMOVE_FRIEND_CODE_REQUEST)
  }
}

export function* removeFriendCodeRequestAPI(
  api,
  getToken,
  getCartId,
  { friendCode }
) {
  try {
    const token = yield call(getToken)
    const cartId = yield call(getCartId)
    const response = yield call(api.removeCoupon, token, cartId, friendCode)
    if (response.ok) {
      yield localforage.setItem('cart_id', response.data.data.cart_id)
      yield fork(cartRequestAPI, api, getToken, getCartId)
    } else {
      return yield put(CartActions.removeFriendCodeFailure(response.data.error))
    }
  } catch (err) {
    return yield put(
      CartActions.removeFriendCodeFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// Remove All Coupon

export function* removeAllCouponRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.REMOVE_ALL_COUPON_REQUEST)
  while (action !== END) {
    yield fork(removeAllCouponRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.REMOVE_ALL_COUPON_REQUEST)
  }
}

export function* removeAllCouponRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const cartId = yield call(getCartId)
    const response = yield call(api.removeAllCoupon, token, cartId)
    if (response.ok) {
      yield localforage.setItem('cart_id', response.data.data.cart_id)
      yield fork(cartRequestAPI, api, getToken, getCartId)
    } else {
      return yield put(CartActions.removeAllCouponFailure(response.data.error))
    }
  } catch (err) {
    return yield put(
      CartActions.removeAllCouponFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// UPDATE SHIPPING VENDOR
export function* updateExpeditionRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.UPDATE_EXPEDITION_REQUEST)
  while (action !== END) {
    yield fork(updateExpeditionRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.UPDATE_EXPEDITION_REQUEST)
  }
}

export function* updateExpeditionRequestAPI(
  api,
  getToken,
  getCartId,
  { brandownerId, deliveryType }
) {
  try {
    const token = yield call(getToken)
    const cartId = yield call(getCartId)
    const response = yield call(
      api.updateExpedition,
      token,
      cartId,
      brandownerId,
      deliveryType
    )
    if (response.ok) {
      // return yield put(CartActions.updateExpeditionFailure(response.data.error))
      yield fork(cartRequestAPI, api, getToken, getCartId)
    } else {
      return yield put(CartActions.updateExpeditionFailure(response.data.error))
    }
  } catch (err) {
    return yield put(
      CartActions.updateExpeditionFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

export function* refreshShippingOptionRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.REFRESH_SHIPPING_OPTION_REQUEST)
  while (action !== END) {
    yield fork(refreshShippingOptionRequestAPI, api, getToken, getCartId)
    action = yield take(CartTypes.REFRESH_SHIPPING_OPTION_REQUEST)
  }
}

export function* refreshShippingOptionRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const cartId = yield call(getCartId)
    const response = yield call(api.refreshShippingOption, token, cartId)
    if (response.ok) {
      yield fork(cartRequestAPI, api, getToken, getCartId)
      // check if theres no default shipping options set it for each brandowner

      var itemsLength = response.data.data.items.length
      for (var i = 0; i < itemsLength; i++) {
        var item = response.data.data.items[i]
        if (_.isEmpty(item.shipping.delivery_type)) {
          if (item.shipping.delivery_options.length > 0) {
            var deliveryType = item.shipping.delivery_options[0].name
            yield fork(updateExpeditionRequestAPI, api, getToken, getCartId, {
              brandownerId: item.brandowner_id,
              deliveryType
            })
          }
        }
      }

      return yield put(
        CartActions.refreshShippingOptionSuccess(response.data.data)
      )
    } else {
      return yield put(
        CartActions.refreshShippingOptionFailure(response.data.error)
      )
    }
  } catch (err) {
    return yield put(
      CartActions.refreshShippingOptionFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}


export function* updatePaymentRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.UPDATE_PAYMENT_REQUEST)
  while (action !== END) {
    yield fork(updatePaymentRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.UPDATE_PAYMENT_REQUEST)
  }
}

export function* updatePaymentRequestAPI(api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    if (!_.isEmpty(cartId) && !_.isEmpty(token)) {
      const payload = {
        cart_id: cartId,
        ...data
      }
      const response = yield call(api.updatePayment, payload, token)
      if (response.ok) {
        yield localforage.setItem('cart_id', response.data.data.cart_id)
        yield fork(cartRequestAPI, api, getToken, getCartId)

        // update shipping options list
        // yield fork(refreshShippingOptionRequestAPI, api, getToken, getCartId)

        // yield put(CartActions.updateCartSuccess(response.data.data))
      } else {
        return yield put(CartActions.updatePaymentFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(
      CartActions.updatePaymentFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

export function* setMarginRequest(api, getToken, getCartId) {
  let action = yield take(CartTypes.SET_MARGIN_REQUEST)
  while (action !== END) {
    yield fork(setMarginRequestAPI, api, getToken, getCartId, action)
    action = yield take(CartTypes.SET_MARGIN_REQUEST)
  }
}

export function* setMarginRequestAPI(api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    if (!_.isEmpty(cartId) && !_.isEmpty(token)) {
      const payload = {
        cart_id: cartId,
        ...data
      }
      const response = yield call(api.setMargin, payload, token)
      if (response.ok) {
        yield localforage.setItem('cart_id', response.data.data.cart_id)
        yield fork(cartRequestAPI, api, getToken, getCartId)
      } else {
        return yield put(CartActions.setMarginFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(
      CartActions.setMarginFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}

// Complete Order

export function* completeOrderRequest(api, getToken, getCartId) {
  let action = yield take(CompleteOrderTypes.COMPLETE_ORDER_REQUEST)
  while (action !== END) {
    yield fork(completeOrderRequestAPI, api, getToken, action)
    action = yield take(CompleteOrderTypes.COMPLETE_ORDER_REQUEST)
  }
}

export function* completeOrderRequestAPI(api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.completeOrder, data, token)
    if (response.ok) {
      return yield put(CompleteOrderActions.success(response.data.data))
    } else {
      return yield put(CompleteOrderActions.success(response.data.message))
    }
  } catch (err) {
    return yield put(CompleteOrderActions.failure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Wallet

export function* cartWalletRequest(api, getToken) {
  let action = yield take(CartWalletTypes.CART_WALLET_REQUEST)
  while (action !== END) {
    yield fork(cartWalletRequestAPI, api, getToken, action)
    action = yield take(CartWalletTypes.CART_WALLET_REQUEST)
  }
}

export function * cartWalletRequestAPI (api, getToken, {data}) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getCartDetail, data, token)
    if (response.ok) {
      return yield put(CartWalletActions.cartWalletSuccess(response.data.data))
    } else {
      return yield put(CartWalletActions.cartWalletFailure(response.data.error.message))
    }
  } catch (err) {
    return yield put(CartWalletActions.cartWalletFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}