import { put, take, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import SearchFilterAction, { SearchFilterTypes } from '../Reducers/SearchFilterRedux'

export function * searchFilterClear () {
  let action = yield take(SearchFilterTypes.SEARCH_FILTER_CLEAR)
  while (action !== END) {
    yield put(SearchFilterAction.searchFilterClear())
    action = yield take(SearchFilterTypes.SEARCH_FILTER_CLEAR)
  }
}

export function * searchFilterAdd () {
  let action = yield take(SearchFilterTypes.SEARCH_FILTER_ADD)
  while (action !== END) {
    const { sort, usingSort, selectedPrice, minPrice, maxPrice, category, usingFilter } = action
    yield put(SearchFilterAction.searchFilterAdd(sort, usingSort, selectedPrice, minPrice, maxPrice, category, usingFilter));
    action = yield take(SearchFilterTypes.SEARCH_FILTER_ADD)
  }
}

export function * searchFilterDone () {
  let action = yield take(SearchFilterTypes.SEARCH_FILTER_DONE)
  while (action !== END) {
    yield put(SearchFilterAction.searchFilterDone())
    action = yield take(SearchFilterTypes.SEARCH_FILTER_DONE)
  }
}

export function * searchFilterSaga () {
  yield all([
    fork(searchFilterClear),
    fork(searchFilterAdd)
  ])
}
