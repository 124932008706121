import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Logo from '../static/images/logo/woobiz_search.svg'
import { Block } from './Styled'
import { Row, Col } from 'antd'
import { Text } from 'shared/components/typhography'

class SearchBarViewHeader extends Component {
  render () {
    return (
      <div onClick={() => this.props.history.push('/search')}>
        <Block
          borderRadius='5px' padding='5px' backgroundColor='#f2f2f2'>
          <Row type='flex' justify='start' align='middle'>
            <Col style={{ textAlign: 'left' }}>
              <i className='fa fa-search fa-md' style={{ color: '#cccccc', paddingLeft: '10px' }} aria-hidden='true' />
              <Text>Cari barang...</Text>
            </Col>
          </Row>
        </Block>
      </div>
    )
  }
}

export default withRouter(SearchBarViewHeader)
