// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  validateDataInit: null,
  // validateDataRequest: ['collectedUserProfileData', 'collectedBankData', 'collectedAddressData'],
  validateDataRequest: ['collectedUserProfileData', 'collectedAddressData'],
  validateDataSuccess: null,
  validateDataFailure: ['err']
})

export const ValidateBeforeCheckoutTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
  state.merge({ fetching: false, success: false, err: null })

export const request = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const success = (state) =>
  state.merge({
    fetching: false,
    success: true,
    err: null
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.VALIDATE_DATA_INIT]: init,
  [Types.VALIDATE_DATA_REQUEST]: request,
  [Types.VALIDATE_DATA_SUCCESS]: success,
  [Types.VALIDATE_DATA_FAILURE]: failure
})
