import { put, take, fork, call, cancel } from 'redux-saga/effects'
import localforage from 'localforage'

// Check Phone
import CheckPhoneActions, { CheckPhoneTypes } from '../Redux/CheckPhoneRedux' 
// Login & Register & Verify OTP
import AuthActions, { AuthTypes } from '../Redux/AuthRedux'
// import RouteAuthActions from '../Redux/RouteAuthRedux'
import VerifyOtpActions, { VerifyOtpTypes } from '../Redux/OtpRedux'
// Resend OTP
import ResendOtpActions, { ResendOtpTypes } from '../Redux/ResendOtpRedux'

// User Redux
import UserActions from '../Redux/UserRedux'

export function * checkPhoneRequest (api) {
  let lastTask
  while (true) {
    const action = yield take(CheckPhoneTypes.CHECK_PHONE_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(checkPhoneRequestAPI, api, action)
  }
}

export function * checkPhoneRequestAPI (api, { phoneNumber }) {
  try {
    if (phoneNumber !== '') {
      const response = yield call(api.checkPhone, phoneNumber)
      if (response.ok) {
        return yield put(CheckPhoneActions.checkPhoneSuccess(response.data.data))
      } else {
        if (response.status === 400) {
          // not yet registered
          return yield put(CheckPhoneActions.checkPhoneFailure('PHONE_NOT_REGISTERED'))
        } else {
          return yield put(CheckPhoneActions.checkPhoneFailure(response.data.message))
        }
      }
    }
  } catch (err) {
    return yield put(CheckPhoneActions.checkPhoneFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * loginRequest (api) {
  let lastTask
  while (true) {
    const action = yield take(AuthTypes.LOGIN_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(loginRequestAPI, api, action)
  }
}

export function * registerRequest (api) {
  let lastTask
  while (true) {
    const action = yield take(AuthTypes.REGISTER_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(registerRequestAPI, api, action)
  }
}

export function * verifyOtpRequest (api) {
  let lastTask
  while (true) {
    const action = yield take(VerifyOtpTypes.VERIFY_OTP_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(verifyOtpAPI, api, action)
  }
}

export function * loginRequestAPI (api, { phoneNumber }) {
  try {
    if (phoneNumber !== '') {
      const response = yield call(api.login, phoneNumber)
      if (response.ok) {
        return yield put(AuthActions.loginSuccess(response.data.data))
      } else {
        if (response.status === 400) {
          // not yet registered
          return yield put(AuthActions.loginFailure('PHONE_NOT_REGISTERED'))
        } else {
          return yield put(AuthActions.loginFailure(response.data.message))
        }
      }
    }
  } catch (err) {
    return yield put(AuthActions.loginFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * registerRequestAPI (api, { data }) {
  try {
    // if (phoneNumber !== '') {
    const response = yield call(api.register, data)
    if (response.ok) {
      return yield put(AuthActions.registerSuccess(response.data.data))
    } else {
      return yield put(AuthActions.registerFailure(response.data.message))
    }
    // }
  } catch (err) {
    return yield put(AuthActions.registerFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * verifyOtpAPI (api, { phoneNumber, agentId, otp, subdomain }) { // using redux persist ? soon
  try {
    if (phoneNumber !== '' && agentId !== '' && otp !== '') {
      const response = yield call(api.verifyOtp, phoneNumber, agentId, otp, subdomain)
      if (response.ok) {
        yield localforage.setItem('auth_token', response.data.data.auth_token)
        yield localforage.setItem('cart_id', response.data.data.cart_id || '')
        // mutate response data for user redux

        yield put(UserActions.userDataSuccess(response.data.data))
        // fork authenticateRoute saga - why? GuardComponent has limitation only mounted one time, but all of the routes, are using this protected route so auth checking wii be not re-dispatch
        // since auth check dispatched from componentDidMount()

        // Btw, there are 2 solutions by far:
        // 1st solution: import auth route worker saga and fork it
        // yield fork(authenticateRoute)
        // 2nd solution: put to route auth redux request to begin the same flow as auth check in GuardComponent. Extra effect => loading sequence :) and i love this
        // yield put(RouteAuthActions.routeAuthRequest())
        // Currently, this best solution so far, handle manual redirection at componentDidUpdate() in otp component. See when we dispatch again RouteAuthRequest
        return yield put(VerifyOtpActions.verifyOtpSuccess(response.data.data))
      } else {
        return yield put(VerifyOtpActions.verifyOtpFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(VerifyOtpActions.verifyOtpFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * resendOtpRequest (api) {
  let lastTask
  while (true) {
    const action = yield take(ResendOtpTypes.RESEND_OTP_REQUEST)
    if (lastTask) {
      yield cancel(lastTask)
    }
    yield fork(resendOtpRequestAPI, api, action)
  }
}

export function * resendOtpRequestAPI (api, { phoneNumber }) {
  try {
    const response = yield call(api.login, phoneNumber)
    if (response.ok) {
      return yield put(ResendOtpActions.resendOtpSuccess(response.data.data))
    } else {
      return yield put(ResendOtpActions.resendOtpFailure(response.data.message))
    }
  } catch (err) {
    return yield put(AuthActions.resendOtpFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
