// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  appliedFriendCodeRequest: null,
  appliedFriendCodeSuccess: ['payload'],
  appliedFriendCodeFailure: ['payload'],
  friendCodeRequest: ['data'],
  friendCodeSuccess: ['payload'],
  friendCodeFailure: ['err']
})

export const FriendCodeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// APPLIED FRIEND CODE
export const appliedFriendCodeRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const appliedFriendCodeSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const appliedFriendCodeFailure = (state, { payload }) =>
  state.merge({
    fetching: false,
    success: false,
    data: payload,
    err: true
  })

// POST FRIEND CODE
export const friendCodeRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const friendCodeSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const friendCodeFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.APPLIED_FRIEND_CODE_REQUEST]: appliedFriendCodeRequest,
  [Types.APPLIED_FRIEND_CODE_SUCCESS]: appliedFriendCodeSuccess,
  [Types.APPLIED_FRIEND_CODE_FAILURE]: appliedFriendCodeFailure,
  [Types.FRIEND_CODE_REQUEST]: friendCodeRequest,
  [Types.FRIEND_CODE_SUCCESS]: friendCodeSuccess,
  [Types.FRIEND_CODE_FAILURE]: friendCodeFailure
})
