import styled from 'styled-components'

export const Container = styled.div`
  background-color:  ${props => props.primary ? '#633c97' : '#fff'};
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
`

export const Divider24px = styled.div`
  height: 24px;
  background-color: #ffefef;
`

export const BottomsheetCue = styled.div`
  width: 47px;
  height: 6px;
  border-radius: 3.5px;
  background-color: #d8d8d8;
`

export const WhiteContainer = styled.div`
  background-color: #ffffff;
`

export const IconLabelContainer = styled.div`
  height: 17px;
  font-size: 12px;
  line-height: 1.42;
  color: #333333;
`

export const FixedBottom = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 375px;
  margin: 0 auto;
  z-index: 1030;
`
