import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import BrandSahabatActions, { BrandSahabatTypes } from '../Reducers/BrandSahabatReducer'

export function * brandSahabatRequest (api, getToken) {
  let action = yield take(BrandSahabatTypes.BRAND_SAHABAT_REQUEST)
  while (action !== END) {
    yield fork(brandSahabatRequestAPI, api, getToken, action)
    action = yield take(BrandSahabatTypes.BRAND_SAHABAT_REQUEST)
  }
}

export function * brandSahabatRequestAPI (api, getToken, { page, post }) {
  const token = yield call(getToken)
  const data = {
    page: page,
    post_per_page: post
  }
  const response = yield call(api.getSahabatBrand, data, token)
  try {
    if (response.ok) {
      return yield put(BrandSahabatActions.brandSahabatSuccess(response.data.data))
    } else {
      return yield put(BrandSahabatActions.brandSahabatFailure(response.data.message))
    }
  } catch (err) {
    return yield put(
      BrandSahabatActions.brandSahabatFailure(
        'Terjadi kesalahan, silahkan ulangi beberapa saat lagi'
      )
    )
  }
}
