import FlexType, { flexTypeStyledHelper } from 'shared/types/flex.type';
import MarginType, { marginTypePropsHelper } from 'shared/types/margin.type';
import styled from 'styled-components';

const PaddedStyled = styled.div<{
  bottom?: string;
  top?: string;
  left?: string;
  right?: string;
  background?: string;
  shadow?: boolean;
  height?: string;
  borderBottomRadius?: string;
  borderRadius?: string;
} & FlexType & MarginType>`
  padding: ${(props) => `${props.top || '20px'} ${props.right || '20px'} ${
    props.bottom || '20px'
  } ${props.left || '20px'}`};
  height: ${(props) => props.height || 'auto'};
  background-color: ${(props) => (props.background && props.background)};
  box-shadow: ${(props) => (props.shadow && '0px -1px 4px rgb(0 0 0 / 10%)')};
  border-top-left-radius: ${(props) => (props.borderRadius && props.borderRadius)};
  border-top-right-radius: ${(props) => (props.borderRadius && props.borderRadius)};
  border-bottom-right-radius: ${(props) => (props.borderBottomRadius && props.borderBottomRadius)};
  border-bottom-left-radius: ${(props) => (props.borderBottomRadius && props.borderBottomRadius)};
  ${flexTypeStyledHelper}
  ${marginTypePropsHelper}
`;

export { PaddedStyled };
