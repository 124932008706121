import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

// antd
import { Spin } from 'antd'
import { Block } from '../../Components/Styled'
import WToolbar from '../../shared/components/w-toolbar/w-toolbar'

// Redux
import { connect } from 'react-redux'
import BlogActions from '../../Redux/BlogRedux'
import Illustration from '../../static/images/illustrations/Tunggu-Sebentar.png'

class DynamicPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogState: undefined,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {}
    if (!_.isEqual(nextProps.blogState, prevState.blogState) && !_.isEmpty(nextProps.blogState)) {
      updated = { ...updated, blogState: nextProps.blogState }
    }
    if (!_.isEqual(nextProps.wooniversityState, prevState.wooniversityState) && !_.isEmpty(nextProps.wooniversityState)) {
      updated = { ...updated, wooniversityState: nextProps.wooniversityState }
    }
    if (!_.isEmpty(updated)) {
      return updated
    }

    return null
  }

  componentDidMount() {
    const { slug } = this.props.match.params
    const body = document.querySelector('body')
    if (body) {
      body.style.backgroundColor = '#fff'
    } else {
      body.style.backgroundColor = '#fff'
    }
    this.props.blogRequest(slug)
  }

  render() {
    const { blogState } = this.state
    const blogPayload = !blogState.fetching && blogState.success && !_.isEmpty(blogState.data) ? blogState.data : {}
    const decodeHTML = function (html) {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    };
    
    return (
        <>
            { !blogState.fetching && blogState.success && !_.isEmpty(blogPayload) ? (
                <div>
                    <WToolbar backButton showChat fixed>{blogPayload.title}</WToolbar>
                    <div dangerouslySetInnerHTML={{ __html: decodeHTML(blogPayload.content) }} />
                </div>
            ) : !blogState.fetching && !blogState.success && blogState.err ? ( 
                <div className='background-white'>
                    <div className='text-center pt-2'>
                        <div className='boldest font-size-l pt-3'>Yahhhh...</div>
                        <div className='font-size-s font-color-tertiary pt-2'>Halaman yang kamu akses tidak ada :(</div>
                    </div>
                    <div className='container'>
                        <div className='row justify-content-md-center'>
                        <div className='col'>
                            <img src={Illustration} alt='empty-cart' width='100%' className='pr-3 pl-4' />
                        </div>
                        </div>
                    </div>
                    <div className='container justify-content-center pb-5'>
                        <div className='border rounded'>
                            <div className='container pt-2'>
                                <span className='font-size-m boldest font-color-primary'>Tahukah Kamu?</span>
                                <p className='font-size-xs font-color-primary bolder'>Di Jakarta, per orang membutuhkan rata-rata Rp 3.951.036 untuk memenuhi kebutuhan pokok loh!</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : 
                <div>
                    <Block textAlign='center' paddingTop='30%'>
                        <Spin />
                    </Block>
                </div>
            }
        </>
    )
  }
}

const mapStateToProps = (state) => ({
  blogState: state.blogState,
})

const mapDispatchToProps = (dispatch) => ({
  blogRequest: data => dispatch(BlogActions.blogRequest(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DynamicPage))
