export default (number: string | number, decimal: number = 0) => {
  if (!number) {
    return '0';
  }

  let snumber: string;
  if (typeof number === 'number') {
    snumber = number.toFixed(decimal).toString();
  } else {
    snumber = number;
  }

  return snumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};
