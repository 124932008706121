import React, { Component } from 'react'
import { BackTop as AntBackTop, Icon } from 'antd'

class BackTop extends Component {
  render() {
    return (<AntBackTop {...this.props} style={{bottom: '30px'}}><div className='ant-back-top-inner'><Icon type="up" className="mr-1" /> Kembali ke atas</div></AntBackTop>)
  }
}

export default BackTop
