import { put, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import AllCategoryReducer from '../Reducers/AllCategoryReducer'

export function * allCategoryRequest (api, getToken) {
  let action = yield take(AllCategoryReducer.Types.ALL_CATEGORY_REQUEST)
  while (action !== END) {
    const token = yield call(getToken)
    const response = yield call(api.getAllCategory, token, {})
    if (response.ok) {
      yield put(AllCategoryReducer.Creators.allCategorySuccess(response.data.data))
    } else {
      yield put(AllCategoryReducer.Creators.allCategoryFailure(response.data.message))
    }
    action = yield take(AllCategoryReducer.Types.ALL_CATEGORY_REQUEST)
  }
}
