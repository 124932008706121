import { none } from 'ramda';
import React from 'react'
import Joyride, { Props } from 'react-joyride';

const defaultOptions = {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#f04',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    width: 'auto !important',
    height: 'auto !important',
    zIndex: 100,
};

const tooltipTitleOptions = {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    margin: '0 0 10px 0',
    textAlign: 'left'
}

const tooltipContentOption = {
    fontSize: 14,
    textAlign: "left",
    padding: 0
}

const buttonNext = {
    display: "none"
}

const buttonSkip = {
    display: "block",
    width: "100%",
    color: "#633c97",
    fontWeight: "bold"
}

const Tour = (props) => {
    return <Joyride styles={{ options: defaultOptions, tooltipTitle: tooltipTitleOptions, tooltipContent: tooltipContentOption, buttonNext, buttonBack: buttonNext, buttonSkip }} {...props} />
}
export default Tour;