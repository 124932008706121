/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { Component, useState } from 'react';
import _ from 'lodash';

// export class InputSpinner extends Component {
//   state = {
//     value: 1
//   }

//   static getDerivedStateFromProps(nextProps, prevState) {
//     let updated = {}

//     if (
//       !_.isEqual(nextProps.value, prevState.value) &&
//       !_.isEmpty(nextProps.value)
//     ) {
//       updated = { ...updated, value: nextProps.value }
//     }

//     return updated
//   }

//   get max() {
//     return this.props.max || 9999
//   }

//   handleChange(event) {
//     if (this.disabled) {
//       return;
//     }

//     if (event.target.value !== this.state.value) {
//       event.target.value = event.target.value.match(/\d+/g)

//       if (event.target.value === '') {
//         event.target.value = 1
//       }

//       if (parseInt(event.target.value) < 1) {
//         this.setState({
//           value: 1
//         })
//         this.props.onChange(this.state.value)
//       } else if (parseInt(event.target.value) > this.max) {
//         this.setState({
//           value: this.max
//         })
//         this.props.onChange(this.state.value)
//       } else {
//         this.setState({
//           value: parseInt(event.target.value)
//         })
//         this.props.onChange(parseInt(event.target.value))
//       }
//     }
//   }

//   increase() {
//     if (this.disabled) {
//       return;
//     }

//     if (this.props.value >= this.max) {
//       this.setState({
//         value: this.max
//       })
//       this.props.onChange(this.state.value)
//     } else {
//       this.props.onChange(this.props.value + 1)
//       this.setState({
//         value: this.props.value + 1
//       })
//     }
//   }

//   decrease() {
//     if (this.disabled) {
//       return;
//     }

//     if (this.props.value > 1) {
//       this.props.onChange(this.props.value - 1)
//       this.setState({
//         value: this.props.value - 1
//       })
//     }
//   }

//   get incButtonClass() {
//     if (this.props.value >= this.max || this.disabled) {
//       return 'disabled'
//     }

//     return ''
//   }

//   get decButtonClass() {
//     if (this.props.value === 1 || this.disabled) {
//       return 'disabled'
//     }
//     return ''
//   }

//   get disabled() {
//     return this.props.disabled || false;
//   }

//   render() {
//     const { value, qtySet } = this.props

//     return (<>
//       <div className='text-muted mr-2 mb-2'>Tersedia</div>
//       <div className='d-flex number-input-spinner align-self-center'>
//         <div className='d-flex'>
//           <div className={'nis-operator ' + this.decButtonClass} onClick={() => this.decrease()}>
//             -
//           </div>
//           <input
//             disabled={this.disabled}
//             className='nis-input'
//             value={value}
//             onChange={(value) => { this.handleChange(value) }}
//             placeholder='Qty'
//           />
//           <div className={'nis-operator ' + this.incButtonClass} onClick={() => this.increase()}>
//             +
//           </div>
//         </div>
//         <div className='text-muted ml-2 small' style={{ lineHeight: '28px' }}>{this.props.helper === 'Set' ? `Paket (${qtySet} pcs)` : this.props.helper}</div>
//       </div></>
//     )
//   }
// }

// export class InputRadioLabel extends Component {
//   state = {
//     items: [],
//     selected: 0
//   }

//   // static getDerivedStateFromProps(nextProps, prevState) {
//   //   let updated = {}
//   //   if (
//   //     !_.isEqual(nextProps.items, prevState.items) &&
//   //     !_.isEmpty(nextProps.items)
//   //   ) {
//   //     // nextProps.isPack
//   //     const items = (nextProps.items || []).filter(item => {
//   //       const filteredUnit = (item.satuan || []).filter(satuan => {
//   //         return satuan === (nextProps.isPack ? 'set' : 'pcs');
//   //       });

//   //       return filteredUnit.length > 0;
//   //     });

//   //     updated = { ...updated, items: items }
//   //   }

//   //   return updated;
//   // }

//   componentDidMount() {
//     this.select(0)
//   }

// select(index) {
//   this.setState({
//     selected: index
//   })
//   if (_.isFunction(this.props.onChange) && this.state.items.length > 0 ) {
//     this.props.onChange(this.state.items[index].value)
//   }
// }

//   render() {
//     // if(this.state.items.length === 0){
//     //   return null;
//     // }

//     return (
// <div className='radio-label-component'>
//   <div className='option-label mb-2'>Pilih {this.props.name}</div>
//   <div className='option-list mb-2'>
//     {
//       this.props.items.map((item, index) => (
//         <div key={index} onClick={() => { this.select(index) }} className={"option-item " + (index === this.state.selected ? "selected" : "")} > {item.name}</div>
//       ))

//       // <div className='option-item selected'>Hijau</div>
//     }
//   </div>
// </div >
//     )
//   }
// }

export function InputRadioLabel({ items, onChange, name }) {
  const [productIndex, setProductIndex] = useState(null);

  const selectItem = (index, value) => {
    setProductIndex(index);

    if (_.isFunction(onChange) && items.length > 0) {
      onChange(value);
    }
  };

  return (
    <div className="radio-label-component">
      <div className="option-label mb-2">
        Pilih
        {' '}
        {name}
      </div>
      <div className="option-list mb-2">
        {
          items.map((item, index) => (
            <div
              key={index}
              onClick={() => selectItem(index, item.value)}
              className={`option-item ${
                index === productIndex ? 'selected' : ''
              }`}
            >
              {' '}
              {item.name}
            </div>
          ))

          // <div className='option-item selected'>Hijau</div>
        }
      </div>
    </div>
  );
}
