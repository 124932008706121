import { fork, all } from 'redux-saga/effects'
import localforage from 'localforage'
// sagas
import { routeAuthRequest } from '../Sagas/RouteAuthSaga'
import {
  loginRequest,
  registerRequest,
  verifyOtpRequest,
  resendOtpRequest,
  checkPhoneRequest
} from '../Sagas/AuthSaga'
import {
  userDataRequest,
  userUpdateImageRequest,
  checkCompletenessRequest,
  userAddressListRequest,
  userUpdateAddressRequest,
  userUpdateProfileRequest,
  userAddAddressRequest,
  userRemoveAddressRequest,
  userBankAccountList,
  addBankAccountRequest,
  updateBankAccountRequest,
  userBonusRequest,
  userBonusHistoryRequest,
  userBonusTermsRequest,
  userBonusLevelRequest,
  userBonusTransactionRequest,
  userCommisionRequest,
  userAddressChangeDefaultRequest,
  validateBeforeCheckoutRequest,
  userDataViewRequest,
  userCommissionHistoryRequest,
  userCommissionHistoryDebitRequest,
  userCommissionHistoryCreditRequest,
  appliedFriendCodeRequest,
  friendCodeRequest,
  userFavoriteRequest,
  userKYCRequest,
  userKYCAddRequest
} from '../Sagas/UserSaga'
import { searchRequest } from '../Sagas/SearchSaga'
import {
  productDetailRequest,
  productSpecialRequest,
  productSelectionRequest,
  productUnder300Request,
  productUnder200Request,
  productUnder100Request,
  productMostSharedRequest,
  productDetailCollectionMostSharedRequest,
  bannerCollectionRequest,
  productRecommendationRequest,
  productCommissionRequest,
  // productSetExplanationRequest,
  productCommissionTierListRequest,
  productDetailViewRequest,
  productCategoryRequest
} from './ProductSaga'
import { stockRequest } from '../Sagas/StockSaga'
import {
  cartDetailRequest,
  cartUpdateRequest,
  cartCreateRequest,
  removeCartItemRequest,
  applyFriendCodeRequest,
  removeFriendCodeRequest,
  removeAllCouponRequest,
  updateExpeditionRequest,
  refreshShippingOptionRequest,
  updatePaymentRequest,
  setMarginRequest,
  completeOrderRequest,
  cartWalletRequest
} from '../Sagas/CartSaga'
import {
  provinceRequest,
  cityRequest,
  districtRequest,
  bankTransferListRequest
} from './MiscSaga'
import { 
  paymentListRequest,
  submitPaymentRequest,
  submitPaymentWalletRequest,
  changePaymentRequest
} from './PaymentSaga'
import {
  salesOrderRequest,
  salesOrderListItemRequest,
  salesInvoiceBrandRequest,
  salesCompleteInvoiceRequest
} from './SalesSaga'
import { addSetupUserRequest, getSetupUserRequest } from './SetupSaga'
import { triplogicPriceRequest, triplogicDetailRequest } from './TriplogicSaga'
import { socialShareRequest, socialShareDetailRequest } from './SocialShareSaga'
import { blockerCheckRequest, blockerAddRequest } from './BlockerSaga'
import { addProductRequest } from './AjukanProdukSagas/AjukanProdukSagas'
import { withdrawRequest } from './WithdrawSagas/WithdrawSaga'
import {
  wooniversityRequest,
  wooniversityDetailRequest
} from './WooniversitySaga'
import { blogRequest } from './BlogSaga'
import { freeOngkirRequest } from './FreeOngkirSaga'
import {
  checkProductCatalogRequest,
  getCatalogRequest,
  createCatalogRequest,
  createCatalogWithProductRequest,
  updateCatalogRequest,
  deleteCatalogRequest,
  createProductCatalogRequest,
  deleteProductCatalogRequest
} from './CatalogSaga'
import {
  GPSCheckUserRequest,
  GPSProductRequest
} from './GPSSaga'

import {
  storeRequest
} from './StoreSaga'

import { calculateShippingCostRequest } from './../Redux/Sagas/ProductSaga'
import { bannerRecommendationRequest } from './../Redux/Sagas/Banners/BannerRecommendationSaga'
import { popularSearchRequest } from '../Redux/Sagas/PopularSearchSaga'
import { productRecommendationInfiniteSaga } from '../Redux/Sagas/ProductRecommendationInfinteSaga'
import { searchFilterSaga } from '../Redux/Sagas/SearchFilterSaga'
import { bannerCatalogRequest } from '../Redux/Sagas/BannerCatalogSaga'
import { brandProductRequest } from '../Redux/Sagas/Category/BrandProductSaga'
import { allCategoryRequest } from '../Redux/Sagas/AllCategorySaga'
import { selectedCategory } from '../Redux/Sagas/SelectedCategorySaga'
import { displayCategoryRequest } from '../Redux/Sagas/Category/DisplayCategorySaga'
import { brandSahabatRequest } from '../Redux/Sagas/BrandSahabatSaga'
import { mailboxListRequest, mailboxReadRequest, mailboxUnreadRequest, mailboxDeleteRequest } from '../Sagas/MailboxSaga'

// api
import API from '../Services/Api'

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create()
const getToken = () => localforage.getItem('auth_token')
const getCartId = () => localforage.getItem('cart_id')

function* paymentSaga() {
  yield all([
    fork(paymentListRequest, api, getToken, getCartId),
    fork(submitPaymentRequest, api, getToken, getCartId),
    fork(submitPaymentWalletRequest, api, getToken, getCartId),
    fork(changePaymentRequest, api, getToken)
  ])
}

function* miscSaga() {
  yield all([
    fork(provinceRequest, api, getToken, getCartId),
    fork(cityRequest, api, getToken, getCartId),
    fork(districtRequest, api, getToken, getCartId),
    fork(bankTransferListRequest, api, getToken, getCartId)
  ])
}

function* cartSaga() {
  yield all([
    fork(cartDetailRequest, api, getToken, getCartId),
    fork(cartUpdateRequest, api, getToken, getCartId),
    fork(cartCreateRequest, api, getToken, getCartId),
    fork(removeCartItemRequest, api, getToken, getCartId),
    fork(applyFriendCodeRequest, api, getToken, getCartId),
    fork(removeFriendCodeRequest, api, getToken, getCartId),
    fork(removeAllCouponRequest, api, getToken, getCartId),
    fork(updateExpeditionRequest, api, getToken, getCartId),
    fork(refreshShippingOptionRequest, api, getToken, getCartId),
    fork(updatePaymentRequest, api, getToken, getCartId),
    fork(setMarginRequest, api, getToken, getCartId),
    fork(completeOrderRequest, api, getToken, getCartId),
    fork(cartWalletRequest, api, getToken),
  ])
}

function* productSaga() {
  yield all([
    fork(searchRequest, api, getToken),
    fork(productDetailRequest, api, getToken),
    fork(productDetailViewRequest, api),
    fork(productSpecialRequest, api, getToken),
    fork(productSelectionRequest, api, getToken),
    fork(productUnder300Request, api, getToken),
    fork(productUnder200Request, api, getToken),
    fork(productUnder100Request, api, getToken),
    fork(productMostSharedRequest, api, getToken),
    fork(productDetailCollectionMostSharedRequest, api, getToken),
    fork(bannerCollectionRequest, api, getToken),
    fork(productRecommendationRequest, api, getToken),
    fork(productCommissionRequest, api, getToken),
    fork(productCommissionTierListRequest, api, getToken),
    // fork(productSetExplanationRequest, api, getToken),
    fork(productCategoryRequest, api, getToken),
    fork(addProductRequest, api, getToken),
    fork(popularSearchRequest, api, getToken),
    fork(allCategoryRequest, api, getToken),
    fork(displayCategoryRequest, api, getToken),
    fork(selectedCategory, api, getToken),
    fork(brandProductRequest, api, getToken)
  ])
}

function* stockSaga() {
  yield all([fork(stockRequest, api, getToken)])
}

function* userSaga() {
  yield all([
    fork(userDataRequest, api, getToken, getCartId),
    fork(userUpdateImageRequest, api, getToken, getCartId),
    fork(checkCompletenessRequest, api, getToken, getCartId),
    fork(userAddressListRequest, api, getToken, getCartId),
    fork(userUpdateAddressRequest, api, getToken, getCartId),
    fork(userAddAddressRequest, api, getToken, getCartId),
    fork(userRemoveAddressRequest, api, getToken, getCartId),
    fork(userUpdateProfileRequest, api, getToken, getCartId),
    fork(userBankAccountList, api, getToken, getCartId),
    fork(addBankAccountRequest, api, getToken, getCartId),
    fork(updateBankAccountRequest, api, getToken, getCartId),
    fork(userBonusRequest, api, getToken, getCartId),
    fork(userBonusHistoryRequest, api, getToken, getCartId),
    fork(userBonusTransactionRequest, api, getToken, getCartId),
    fork(userBonusTermsRequest, api, getToken, getCartId),
    fork(userBonusLevelRequest, api, getToken, getCartId),
    fork(userCommisionRequest, api, getToken, getCartId),
    fork(userAddressChangeDefaultRequest, api, getToken, getCartId),
    fork(validateBeforeCheckoutRequest, api, getToken, getCartId),
    fork(userDataViewRequest, api),
    fork(userCommissionHistoryRequest, api, getToken),
    fork(userCommissionHistoryDebitRequest, api, getToken),
    fork(userCommissionHistoryCreditRequest, api, getToken),
    fork(appliedFriendCodeRequest, api, getToken),
    fork(friendCodeRequest, api, getToken),
    fork(userFavoriteRequest, api, getToken),
    fork(userKYCRequest, api, getToken),
    fork(userKYCAddRequest, api, getToken)
  ])
}

function* salesSaga() {
  yield all([
    fork(salesOrderRequest, api, getToken),
    fork(salesOrderListItemRequest, api, getToken),
    fork(salesInvoiceBrandRequest, api, getToken),
    fork(salesCompleteInvoiceRequest, api, getToken)
  ])
}

function* authSaga() {
  yield all([
    fork(checkPhoneRequest, api),
    fork(loginRequest, api),
    fork(registerRequest, api),
    fork(verifyOtpRequest, api),
    fork(resendOtpRequest, api)
  ])
}

function* routeAuthSaga() {
  yield all([fork(routeAuthRequest, api)])
}

function* setupSaga() {
  yield all([
    fork(addSetupUserRequest, api, getToken),
    fork(getSetupUserRequest, api, getToken)
  ])
}

function* triplogicSaga() {
  yield all([
    fork(triplogicPriceRequest, api, getToken),
    fork(triplogicDetailRequest, api, getToken)
  ])
}

function* socialShareSaga() {
  yield all([
    yield fork(socialShareRequest, api, getToken),
    yield fork(socialShareDetailRequest, api, getToken)
  ])
}

function* blockerSaga() {
  yield all([
    yield fork(blockerCheckRequest, api, getToken),
    yield fork(blockerAddRequest, api, getToken)
  ])
}

function* withdrawSaga() {
  yield all([
    yield fork(withdrawRequest, api, getToken)
  ])
}

function* wooniversitySaga() {
  yield all([
    yield fork(wooniversityRequest, api, getToken),
    yield fork(wooniversityDetailRequest, api, getToken)
  ])
}

function * blogSaga() {
  yield all([
    yield fork(blogRequest, api, getToken)
  ])
}

function * freeOngkirSaga() {
  yield all([
    yield fork(freeOngkirRequest, api, getToken)
  ])
}

function * catalogSaga() {
  yield all([
    yield fork(checkProductCatalogRequest, api, getToken),
    yield fork(getCatalogRequest, api, getToken),
    yield fork(createCatalogRequest, api, getToken),
    yield fork(createCatalogWithProductRequest, api, getToken),
    yield fork(updateCatalogRequest, api, getToken),
    yield fork(deleteCatalogRequest, api, getToken),
    yield fork(createProductCatalogRequest, api, getToken),
    yield fork(deleteProductCatalogRequest, api, getToken)
  ])
}

function * GPSSaga () {
  yield all([
    yield fork(GPSCheckUserRequest, api),
    yield fork(GPSProductRequest, api)
  ])
}

function * storeSaga() {
  yield all([
    yield fork(storeRequest, api, getToken)
  ])
}

function* shippingCost() {
  yield all[(
    yield fork(calculateShippingCostRequest, api, getToken)
  )]
}

function * bannerRecommendationSaga () {
  yield all[(
    yield fork(bannerRecommendationRequest, api, getToken)
  )]
}

function * bannerCatalogSaga () {
  yield all[(
    yield fork(bannerCatalogRequest, api, getToken)
  )]
}

function * brandSahabat () {
  yield all[(
    yield fork(brandSahabatRequest, api, getToken)
  )]
}

function * mailboxSaga () {
  yield all[(
    yield fork(mailboxListRequest, api, getToken)
  )]
}

function * mailboxReadSaga () {
  yield all[(
    yield fork(mailboxReadRequest, api, getToken)
  )]
}

function * mailboxUnreadSaga () {
  yield all[(
    yield fork(mailboxUnreadRequest, api, getToken)
  )]
}

function * mailboxDeleteSaga () {
  yield all[(
    yield fork(mailboxDeleteRequest, api, getToken)
  )]
}

export default function* root() {
  yield all([
    fork(routeAuthSaga),
    fork(authSaga),
    fork(userSaga),
    fork(productSaga),
    fork(productRecommendationInfiniteSaga),
    fork(searchFilterSaga),
    fork(stockSaga),
    fork(cartSaga),
    fork(miscSaga),
    fork(paymentSaga),
    fork(salesSaga),
    fork(setupSaga),
    fork(triplogicSaga),
    fork(socialShareSaga),
    fork(blockerSaga),
    fork(withdrawSaga),
    fork(wooniversitySaga),
    fork(blogSaga),
    fork(freeOngkirSaga),
    fork(catalogSaga),
    fork(GPSSaga),
    fork(storeSaga),
    fork(shippingCost),
    fork(bannerCatalogSaga),
    fork(bannerRecommendationSaga),
    fork(brandSahabat),
    fork(mailboxSaga),
    fork(mailboxReadSaga),
    fork(mailboxUnreadSaga),
    fork(mailboxDeleteSaga)
  ])
}
