import React from "react";
import "./styles.scss";

import { useRequestBrandBestSelling } from "../../api/brand-page.api";
import { ProductCardMain } from "../../../../Components/product-card";
import ScrollWrapper from "../../../../Layouts/scroll-wrapper/ScrollWrapper";
import { isEmpty, getSafely } from "../../../../Utils";
import { ProductLoader } from "../../../../Components/loader";
import { Divider, TextMedium } from "../../../../Components/Styled";

function BestSellingProduct({ brandID }) {
  const { data, isFetching } = useRequestBrandBestSelling(brandID);
  const productList = getSafely(["list"], data, []);

  const toProductDetail = (sku) => {
    window.location.href = `/product/${sku}`;
  };

  if (isFetching) return <ProductLoader />;

  return (
    !isEmpty(productList) && (
      <div className="best-selling">
        <div className="pt-3">
          <Divider />
        </div>
        <div className="best-selling__title-wrapper">
          {/* <div className='d-flex justify-content-between align-items-center'>
            <span className='font-heading'>Produk Terlaris</span>
            <small>Lihat Semua</small>
          </div> */}
          <TextMedium className="font-heading">Produk Terlaris</TextMedium>
        </div>
        <ScrollWrapper>
          {(productList || []).map((productDetail, idx) => (
            <div key={idx}>
              <ProductCardMain
                action={() =>
                  toProductDetail(
                    getSafely(["variants", 0, "sku"], productDetail, [])
                  )
                }
                productDetail={productDetail}
              />
            </div>
          ))}
        </ScrollWrapper>
      </div>
    )
  );
}

export default BestSellingProduct;
