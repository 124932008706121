import React from 'react';
import './styles.scss';
import config from '../../../../config';

// api
import { useRequestBrandDetail } from '../../api/brand-page.api';

import { BrandLoader } from '../../../../Components/loader';
import {
  TextSmall,
  TextMedium,
  Container,
} from '../../../../Components/Styled';
import CouponList from '../CouponList/CouponList';
import { isEmpty } from '../../../../Utils';

function BrandCard({ brandID }) {
  const { data, isFetching } = useRequestBrandDetail(brandID);
  if (isFetching) {
    return <BrandLoader />;
  }

  return (
    <div className="brand-detail">
      <div>
        {isEmpty(data.image) || data.image === '/' ? (
          <img
            className="brand-detail__banner"
            src={`${config.imageURL}/v1598876964/banner-dev/b_1598876962_1.png`}
            height="153"
            width="100%"
            alt="banner"
          />
        ) : (
          <img
            className="brand-detail__banner"
            src={`${config.imageURL}${data.image}`}
            height="153"
            width="100%"
            alt="banner"
          />
        )}
      </div>
      <Container>
        <div className="brand-detail__card-wrapper">
          <div className="card">
            <div className="d-flex flex-start">
              <div className="pr-3">
                <div className="logo">
                  {isEmpty(data.image) || data.image === '/' ? (
                    <img
                      src={`${config.imageURL}/v1586166234/banner-dev/b_1586166233_1.png`}
                      width="40"
                      alt="..."
                    />
                  ) : (
                    <img
                      src={`${config.imageURL}${data.image}`}
                      width="40"
                      alt="..."
                    />
                  )}
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="brand-detail__name">
                  <TextMedium boldest>{data.name}</TextMedium>
                </div>
                {!isEmpty(data.province_name) && (
                  <div className="brand-detail__location">
                    <TextSmall color="#9b9b9b">
                      Lokasi,
                      {' '}
                      {data.province_name}
                    </TextSmall>
                  </div>
                )}
              </div>
            </div>
            <CouponList brandID={brandID} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BrandCard;
