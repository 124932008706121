// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  productCommissionTierListRequest: ['sku'],
  productCommissionTierListSuccess: ['payload'],
  productCommissionTierListFailure: ['err']
})

export const ProductDetailCommissionTierListTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch
export const request = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const success = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_COMMISSION_TIER_LIST_REQUEST]: request,
  [Types.PRODUCT_COMMISSION_TIER_LIST_SUCCESS]: success,
  [Types.PRODUCT_COMMISSION_TIER_LIST_FAILURE]: failure
})
