import { put, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import BannerCatalogReducer from '../Reducers/BannerCatalogReducer'

export function * bannerCatalogRequest (api, getToken) {
  let action = yield take(BannerCatalogReducer.Types.BANNER_CATALOG_REQUEST)
  while (action !== END) {
    const token = yield call(getToken)
    const response = yield call(api.getBannerCatalog, {}, token)
    if (response.ok) {
      yield put(BannerCatalogReducer.Creators.bannerCatalogSuccess(response.data.data))
    } else {
      yield put(BannerCatalogReducer.Creators.bannerCatalogFailure(response.data.message))
    }
    action = yield take(BannerCatalogReducer.Types.BANNER_CATALOG_REQUEST)
  }
}
