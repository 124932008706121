import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'
import moment from 'moment'
// import _ from 'lodash'
// import localforage from 'localforage'

// Login & Register & Verify OTP
import UserActions, { UserTypes } from '../Redux/UserRedux'
import UserImageActions, { UserImageTypes } from '../Redux/UserImageRedux'
import UserCheckCompleteActions, { UserCheckCompleteTypes } from '../Redux/UserCheckCompleteRedux'
import UserAddressActions, { UserAddressTypes } from '../Redux/UserAddressRedux'
import UserBankAccountActions, { UserBankAccountTypes } from '../Redux/UserBankAccountRedux'
import UserBonusActions, { UserBonusTypes } from '../Redux/UserBonusRedux'
import UserBonusHistoryActions, { UserBonusHistoryTypes } from '../Redux/UserBonusHistoryRedux'
import UserBonusTermsActions, { UserBonusTermsTypes } from '../Redux/UserBonusTermsRedux'
import UserBonusLevelActions, { UserBonusLevelTypes } from '../Redux/UserBonusLevelRedux'
import UserBonusTransactionActions, { UserBonusTransactionTypes } from '../Redux/UserBonusTransactionRedux'
import UserCommisionActions, { UserCommisionTypes } from '../Redux/UserCommisionRedux'
import ValidateBeforeCheckoutActions, { ValidateBeforeCheckoutTypes } from '../Redux/ValidateBeforeCheckoutRedux'
import UserViewActions, { UserViewTypes } from '../Redux/UserViewRedux'
import UserCommissionHistoryActions, { UserCommissionHistoryTypes } from '../Redux/UserCommissionHistoryRedux'
import UserCommissionDebitHistoryActions, { UserCommissionDebitHistoryTypes } from '../Redux/UserCommissionHistoryDebitRedux'
import UserCommissionCreditHistoryActions, { UserCommissionCreditHistoryTypes } from '../Redux/UserCommissionHistoryCreditRedux'
import FriendCodeActions, { FriendCodeTypes } from '../Redux/UserFriendCodeRedux'
import UserFavoriteActions, { UserFavoriteTypes } from '../Redux/UserRedux/FavoriteRedux'
import UserKYCActions, { UserKYCTypes } from '../Redux/UserKYCRedux'
import localforage from 'localforage'

// Get User State
const getUserState = state => state.userState

// User Edit Profile Saga
export function* userDataRequest(api, getToken, getCartId) {
  let action = yield take(UserTypes.USER_DATA_REQUEST)
  while (action !== END) {
    yield fork(userDataRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserTypes.USER_DATA_REQUEST)
  }
}

export function* userDataRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id
    const data = { agent_id: agentId }
    const response = yield call(api.getUserProfile, data, token)
    if (response.ok) {
      return yield put(UserActions.userDataSuccess(response.data.data))
    } else {
      yield fork(checkTokenOrConnection, api, getToken)
      // Mean api cannot access so we need to check the current situation about api validity
      return yield put(UserActions.userDataFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserActions.userDataFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* checkTokenOrConnection(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.tokenValidity, token)

    if (response.ok) {
      if (response.data.verify.status === false) {
        yield localforage.clear()
        window.location.reload(true)
      }
    } else {
      // means api connections is deadddddd!!!!
      // window.location.href = "/no-connections"
    }
  } catch (err) {

  }
}

// Get user view data

export function* userDataViewRequest(api) {
  let action = yield take(UserViewTypes.USER_DATA_VIEW_REQUEST)
  while (action !== END) {
    yield fork(userDataViewRequestAPI, api, action)
    action = yield take(UserViewTypes.USER_DATA_VIEW_REQUEST)
  }
}

export function* userDataViewRequestAPI(api, { referer }) {
  try {
    const agentId = parseInt(referer)
    // const data = { agent_id: agentId }
    const response = yield call(api.getUserProfileView, agentId)
    if (response.ok) {
      return yield put(UserViewActions.userDataViewSuccess(response.data.data))
    } else {
      return yield put(UserViewActions.userDataViewFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserViewActions.userDataViewFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Check Completeness
export function* checkCompletenessRequest(api, getToken, getCartId) {
  let action = yield take(UserCheckCompleteTypes.CHECK_COMPLETENESS_REQUEST)
  while (action !== END) {
    yield fork(checkCompletenessRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserCheckCompleteTypes.CHECK_COMPLETENESS_REQUEST)
  }
}

export function* checkCompletenessRequestAPI(api, getToken, getCartId, { phoneNumber }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.userCheckCompleteness, phoneNumber, token)
    if (response.ok) {
      return yield put(UserCheckCompleteActions.checkCompletenessSuccess(response.data.data))
    } else {
      return yield put(UserCheckCompleteActions.checkCompletenessFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserCheckCompleteActions.checkCompletenessFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// User Get Address List Saga
export function* userAddressListRequest(api, getToken, getCartId) {
  let action = yield take(UserAddressTypes.USER_ADDRESS_LIST_REQUEST)
  while (action !== END) {
    yield fork(userAddressListRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserAddressTypes.USER_ADDRESS_LIST_REQUEST)
  }
}

export function* userAddressListRequestAPI(api, getToken, getCartId) {
  try {
    // const cartId = yield call(getCartId)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    const token = yield call(getToken)
    const response = yield call(api.getUserAddressList, agentId, token)
    if (response.ok) {
      return yield put(UserAddressActions.userAddressListSuccess(response.data.data))
    } else {
      return yield put(UserAddressActions.userAddressListFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserAddressActions.userAddressListFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// User Update Address
export function* userUpdateAddressRequest(api, getToken, getCartId) {
  let action = yield take(UserAddressTypes.USER_ADDRESS_UPDATE_REQUEST)
  while (action !== END) {
    yield fork(userUpdateAddressRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserAddressTypes.USER_ADDRESS_UPDATE_REQUEST)
  }
}

export function* userUpdateAddressRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    data = { ...data, agent_id: agentId }
    const response = yield call(api.updateUserAddress, data, token)
    if (response.ok) {
      yield fork(userAddressListRequestAPI, api, getToken, getCartId)
      // return yield put(UserAddressActions.userAddressUpdateSuccess(response.data.data))
    } else {
      return yield put(UserAddressActions.userAddressUpdateFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserAddressActions.userAddressUpdateFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// User Add Address
export function* userAddAddressRequest(api, getToken, getCartId) {
  let action = yield take(UserAddressTypes.USER_ADDRESS_ADD_REQUEST)
  while (action !== END) {
    yield fork(userAddAddressRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserAddressTypes.USER_ADDRESS_ADD_REQUEST)
  }
}

export function* userAddAddressRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    data = { ...data, agent_id: agentId }
    const response = yield call(api.addUserAddress, data, token)
    if (response.ok) {
      // yield put(UserAddressActions.userAddressAddSuccess(response.data.data))
      yield fork(userAddressListRequestAPI, api, getToken, getCartId)
    } else {
      return yield put(UserAddressActions.userAddressAddFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserAddressActions.userAddressAddFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Change is_default address
export function* userAddressChangeDefaultRequest(api, getToken, getCartId) {
  let action = yield take(UserAddressTypes.USER_ADDRESS_CHANGE_DEFAULT_REQUEST)
  while (action !== END) {
    yield fork(userAddressChangeDefaultRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserAddressTypes.USER_ADDRESS_CHANGE_DEFAULT_REQUEST)
  }
}

export function* userAddressChangeDefaultRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const response = yield call(api.changeAddressIsDefault, data, token)
    if (response.ok) {
      yield fork(userAddressListRequestAPI, api, getToken, getCartId)
      // return yield put(UserAddressActions.userAddressAddSuccess(response.data.data))
    } else {
      return yield put(UserAddressActions.userAddressChangeDefaultFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserAddressActions.userAddressChangeDefaultFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
// User Remove Address
export function* userRemoveAddressRequest(api, getToken, getCartId) {
  let action = yield take(UserAddressTypes.USER_ADDRESS_REMOVE_REQUEST)
  while (action !== END) {
    yield fork(userRemoveAddressRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserAddressTypes.USER_ADDRESS_REMOVE_REQUEST)
  }
}

export function* userRemoveAddressRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    data = { ...data, agent_id: agentId }
    const response = yield call(api.removeUserAddress, data, token)
    if (response.ok) {
      yield fork(userAddressListRequestAPI, api, getToken, getCartId)
      // return yield put(UserAddressActions.userAddressUpdateSuccess(response.data.data))
    } else {
      return yield put(UserAddressActions.userAddressRemoveFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserAddressActions.userAddressRemoveFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// User Update Profile
export function* userUpdateProfileRequest(api, getToken, getCartId) {
  let action = yield take(UserTypes.USER_UPDATE_PROFILE_REQUEST)
  while (action !== END) {
    yield fork(userUpdateProfileRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserTypes.USER_UPDATE_PROFILE_REQUEST)
  }
}

export function* userUpdateProfileRequestAPI(api, getToken, getCartId, { data }) {
  try {
    const token = yield call(getToken)
    data = { ...data }
    const response = yield call(api.updateUserProfile, data, token)
    if (response.ok) {
      yield fork(userDataRequestAPI, api, getToken, getCartId)
      // return yield put(UserActions.userUpdateProfileSuccess({ ...prevUserStateData, ...data }))
    } else {
      return yield put(UserActions.userUpdateProfileFailure(response.data.error.message))
    }
  } catch (err) {
    return yield put(UserActions.userUpdateProfileFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// update image
export function* userUpdateImageRequest(api, getToken) {
  let action = yield take(UserImageTypes.USER_UPDATE_IMAGE_PROFILE_REQUEST)
  while (action !== END) {
    yield fork(userUpdateImageRequestAPI, api, getToken, action)
    action = yield take(UserImageTypes.USER_UPDATE_IMAGE_PROFILE_REQUEST)
  }
}

export function* userUpdateImageRequestAPI(api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    console.log(data)
    const response = yield call(api.updateImageProfile, data, token)
    if (response.ok) {
      return yield put(UserImageActions.userUpdateImageProfileSuccess(response.data.data))
    } else {
      return yield put(UserImageActions.userUpdateImageProfileFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserImageActions.userUpdateImageProfileFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userBankAccountList(api, getToken, getCartId) {
  let action = yield take(UserBankAccountTypes.BANK_ACCOUNT_LIST_REQUEST)
  while (action !== END) {
    yield fork(userBankAccountListAPI, api, getToken, getCartId, action)
    action = yield take(UserBankAccountTypes.BANK_ACCOUNT_LIST_REQUEST)
  }
}

export function* userBankAccountListAPI(api, getToken, getCartId) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id
    const response = yield call(api.getUserBankAccountList, agentId, token)
    if (response.ok) {
      yield put(UserBankAccountActions.bankAccountListSuccess(response.data.data))
    } else {
      return yield put(UserBankAccountActions.bankAccountListFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBankAccountActions.bankAccountListFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* addBankAccountRequest(api, getToken, getCartId) {
  let action = yield take(UserBankAccountTypes.ADD_BANK_ACCOUNT_REQUEST)
  while (action !== END) {
    yield fork(addBankAccountRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBankAccountTypes.ADD_BANK_ACCOUNT_REQUEST)
  }
}

export function* addBankAccountRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    data = { ...data, agent_id: userState.data.agent_id, account_name: userState.data.name || '' }
    const response = yield call(api.addBankAccount, data, token)
    if (response.ok) {
      yield fork(userBankAccountListAPI, api, getToken, getCartId)
      yield put(UserBankAccountActions.addBankAccountSuccess(response.data.data))
    } else {
      return yield put(UserBankAccountActions.addBankAccountFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBankAccountActions.addBankAccountFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* updateBankAccountRequest(api, getToken, getCartId) {
  let action = yield take(UserBankAccountTypes.UPDATE_BANK_ACCOUNT_REQUEST)
  while (action !== END) {
    yield fork(updateBankAccountRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBankAccountTypes.UPDATE_BANK_ACCOUNT_REQUEST)
  }
}

export function* updateBankAccountRequestAPI(api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    data = { ...data, agent_id: userState.data.agent_id, account_name: userState.data.name }
    const response = yield call(api.updateBankAccount, data, token)
    if (response.ok) {
      yield put(UserBankAccountActions.updateBankAccountSuccess(response.data.data))
    } else {
      return yield put(UserBankAccountActions.updateBankAccountFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBankAccountActions.updateBankAccountFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Bonus

export function* userBonusRequest(api, getToken, getCartId) {
  let action = yield take(UserBonusTypes.USER_BONUS_REQUEST)
  while (action !== END) {
    yield fork(userBonusRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBonusTypes.USER_BONUS_REQUEST)
  }
}

export function* userBonusRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {
      date: moment().format('YYYY-MM-DD')
    }
    const response = yield call(api.getUserBonus, data, token)
    if (response.ok) {
      return yield put(UserBonusActions.userBonusSuccess(response.data.data))
    } else {
      return yield put(UserBonusActions.userBonusFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBonusActions.userBonusFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userBonusHistoryRequest(api, getToken, getCartId) {
  let action = yield take(UserBonusHistoryTypes.USER_BONUS_HISTORY_REQUEST)
  while (action !== END) {
    yield fork(userBonusHistoryRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBonusHistoryTypes.USER_BONUS_HISTORY_REQUEST)
  }
}

export function* userBonusHistoryRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {
      limit: 6
    }
    const response = yield call(api.getBonusHistory, data, token)
    if (response.ok) {
      return yield put(UserBonusHistoryActions.userBonusHistorySuccess(response.data.data))
    } else {
      return yield put(UserBonusHistoryActions.userBonusHistoryFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBonusHistoryActions.userBonusHistoryFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userBonusTermsRequest(api, getToken, getCartId) {
  let action = yield take(UserBonusTermsTypes.USER_BONUS_TERMS_REQUEST)
  while (action !== END) {
    yield fork(userBonusTermsRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBonusTermsTypes.USER_BONUS_TERMS_REQUEST)
  }
}

export function* userBonusTermsRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {
    }
    const response = yield call(api.getBonusTerms, data, token)
    if (response.ok) {
      return yield put(UserBonusTermsActions.userBonusTermsSuccess(response.data.data))
    } else {
      return yield put(UserBonusTermsActions.userBonusTermsFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBonusTermsActions.userBonusTermsFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userBonusLevelRequest(api, getToken, getCartId) {
  let action = yield take(UserBonusLevelTypes.USER_BONUS_LEVEL_REQUEST)
  while (action !== END) {
    yield fork(userBonusLevelRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBonusLevelTypes.USER_BONUS_LEVEL_REQUEST)
  }
}

export function* userBonusLevelRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {
    }
    const response = yield call(api.getBonusLevel, data, token)
    if (response.ok) {
      return yield put(UserBonusLevelActions.userBonusLevelSuccess(response.data.data))
    } else {
      return yield put(UserBonusLevelActions.userBonusLevelFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBonusLevelActions.userBonusLevelFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userBonusTransactionRequest(api, getToken, getCartId) {
  let action = yield take(UserBonusTransactionTypes.USER_BONUS_TRANSACTION_REQUEST)
  while (action !== END) {
    yield fork(userBonusTransactionRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserBonusTransactionTypes.USER_BONUS_TRANSACTION_REQUEST)
  }
}

export function* userBonusTransactionRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {
      week_date: moment().format('YYYY-MM-DD')
    }
    const response = yield call(api.getBonusTransaction, data, token)
    if (response.ok) {
      return yield put(UserBonusTransactionActions.userBonusTransactionSuccess(response.data.data))
    } else {
      return yield put(UserBonusTransactionActions.userBonusTransactionFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserBonusTransactionActions.userBonusTransactionFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Commission

export function* userCommisionRequest(api, getToken, getCartId) {
  let action = yield take(UserCommisionTypes.USER_COMMISION_REQUEST)
  while (action !== END) {
    yield fork(userCommisionRequestAPI, api, getToken, getCartId, action)
    action = yield take(UserCommisionTypes.USER_COMMISION_REQUEST)
  }
}

export function* userCommisionRequestAPI(api, getToken, getCartId) {
  try {
    const token = yield call(getToken)
    const data = {}
    const response = yield call(api.getUserCommision, data, token)
    if (response.ok) {
      return yield put(UserCommisionActions.userCommisionSuccess(response.data.data))
    } else {
      return yield put(UserCommisionActions.userCommisionFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserCommisionActions.userCommisionFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userCommissionHistoryRequest(api, getToken) {
  let action = yield take(UserCommissionHistoryTypes.USER_COMMISSION_HISTORY_REQUEST)
  while (action !== END) {
    yield fork(userCommissionHistoryRequestAPI, api, getToken, action)
    action = yield take(UserCommissionHistoryTypes.USER_COMMISSION_HISTORY_REQUEST)
  }
}

export function* userCommissionHistoryRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getUserCommissionHistory, token)
    if (response.ok) {
      return yield put(UserCommissionHistoryActions.userCommissionHistorySuccess(response.data.data))
    } else {
      return yield put(UserCommissionHistoryActions.userCommissionHistoryFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserCommissionHistoryActions.userCommissionHistoryFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Fetch data debit commission

export function* userCommissionHistoryDebitRequest(api, getToken) {
  let action = yield take(UserCommissionDebitHistoryTypes.USER_COMMISSION_HISTORY_DEBIT_REQUEST)
  while (action !== END) {
    yield fork(userCommissionHistoryDebitRequestAPI, api, getToken, action)
    action = yield take(UserCommissionDebitHistoryTypes.USER_COMMISSION_HISTORY_DEBIT_REQUEST)
  }
}

export function* userCommissionHistoryDebitRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getUserCommissionDebitHistory, token)
    if (response.ok) {
      return yield put(UserCommissionDebitHistoryActions.userCommissionHistoryDebitSuccess(response.data.data))
    } else {
      return yield put(UserCommissionDebitHistoryActions.userCommissionHistoryDebitFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserCommissionDebitHistoryActions.userCommissionHistoryDebitFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userCommissionHistoryCreditRequest(api, getToken) {
  let action = yield take(UserCommissionCreditHistoryTypes.USER_COMMISSION_HISTORY_CREDIT_REQUEST)
  while (action !== END) {
    yield fork(userCommissionHistoryCreditRequestAPI, api, getToken, action)
    action = yield take(UserCommissionCreditHistoryTypes.USER_COMMISSION_HISTORY_CREDIT_REQUEST)
  }
}

export function* userCommissionHistoryCreditRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getUserCommissionCreditHistory, token)
    if (response.ok) {
      return yield put(UserCommissionCreditHistoryActions.userCommissionHistoryCreditSuccess(response.data.data))
    } else {
      return yield put(UserCommissionCreditHistoryActions.userCommissionHistoryCreditFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserCommissionCreditHistoryActions.userCommissionHistoryCreditFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// validate before checkout
// User Update Profile
export function* validateBeforeCheckoutRequest(api, getToken, getCartId) {
  let action = yield take(ValidateBeforeCheckoutTypes.VALIDATE_DATA_REQUEST)
  while (action !== END) {
    yield fork(validateBeforeCheckoutRequestAPI, api, getToken, getCartId, action)
    action = yield take(ValidateBeforeCheckoutTypes.VALIDATE_DATA_REQUEST)
  }
}

export function* validateBeforeCheckoutRequestAPI(api, getToken, getCartId, { collectedUserProfileData, collectedAddressData }) {
  try {
    const token = yield call(getToken)
    const userState = yield select(getUserState)
    const agentId = userState.data.agent_id

    // Update User Profile
    const userProfileData = { ...collectedUserProfileData, agent_id: agentId }
    const userProfileResponse = yield call(api.updateUserProfile, userProfileData, token)

    // Add Bank Data
    // let bankData
    // let bankResponse = {
    //   ok: true
    // }
    // if (!_.isEmpty(collectedBankData)) {
    //   bankData = { ...collectedBankData, agent_id: agentId, account_name: collectedUserProfileData.name || '' }
    //   bankResponse = yield call(api.addBankAccount, bankData, token)
    // }

    // Add Address
    const addressData = { ...collectedAddressData, agent_id: agentId }
    const addressResponse = yield call(api.addUserAddress, addressData, token)

    // if (userProfileResponse.ok && bankResponse.ok && addressResponse.ok) {
    if (userProfileResponse.ok && addressResponse.ok) {
      // we need to return success adding
      return yield put(ValidateBeforeCheckoutActions.validateDataSuccess())
    } else {
      return yield put(ValidateBeforeCheckoutActions.validateDataFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
    }
  } catch (err) {
    return yield put(ValidateBeforeCheckoutActions.validateDataFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Cek Friend Code was active

export function* appliedFriendCodeRequest(api, getToken) {
  let action = yield take(FriendCodeTypes.APPLIED_FRIEND_CODE_REQUEST)
  while (action !== END) {
    yield fork(appliedFriendCodeRequestAPI, api, getToken, action)
    action = yield take(FriendCodeTypes.APPLIED_FRIEND_CODE_REQUEST)
  }
}

export function* appliedFriendCodeRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.appliedFriendCode, token)
    if (response.ok) {
      return yield put(FriendCodeActions.appliedFriendCodeSuccess(response.data))
    } else {
      return yield put(FriendCodeActions.appliedFriendCodeFailure(response.data))
    }
  } catch (err) {
    return yield put(FriendCodeActions.appliedFriendCodeFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// Actived Friend Code

export function* friendCodeRequest(api, getToken) {
  let action = yield take(FriendCodeTypes.FRIEND_CODE_REQUEST)
  while (action !== END) {
    yield fork(friendCodeRequestAPI, api, getToken, action)
    action = yield take(FriendCodeTypes.FRIEND_CODE_REQUEST)
  }
}

export function* friendCodeRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.activeFriendCode, token)
    if (response.ok) {
      return yield put(FriendCodeActions.friendCodeSuccess(response.data.data))
    } else {
      return yield put(FriendCodeActions.friendCodeFailure(response.data.data))
    }
  } catch (err) {
    return yield put(FriendCodeActions.friendCodeFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

// User Favorites

export function* userFavoriteRequest(api, getToken) {
  let action = yield take(UserFavoriteTypes.GET_USER_FAVORITE_REQUEST)
  while (action !== END) {
    yield fork(getUserFavoriteRequestAPI, api, getToken, action)
    action = yield take(UserFavoriteTypes.GET_USER_FAVORITE_REQUEST)
  }
}

export function* getUserFavoriteRequestAPI(api, getToken) {
  const token = yield call(getToken)
  const response = yield call(api.getUserFavorite, token)
  try {
    if (response.ok) {
      return yield put(UserFavoriteActions.getUserFavoriteSuccess(response.data))
    } else {
      return yield put(UserFavoriteActions.getUserFavoriteFailure(response.message))
    }
  } catch (err) {
    return yield put(
      UserFavoriteActions.getUserFavoriteFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi')
    )
  }
}

// User KYC

export function* userKYCRequest(api, getToken) {
  let action = yield take(UserKYCTypes.USER_KYC_REQUEST)
  while (action !== END) {
    yield fork(userKYCRequestAPI, api, getToken, action)
    action = yield take(UserKYCTypes.USER_KYC_REQUEST)
  }
}

export function* userKYCRequestAPI(api, getToken) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getKYC, token)
    if (response.ok) {
      return yield put(UserKYCActions.userKYCSuccess(response.data.data))
    } else {
      return yield put(UserKYCActions.userKYCFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserKYCActions.userKYCFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function* userKYCAddRequest(api, getToken) {
  let action = yield take(UserKYCTypes.USER_KYC_ADD_REQUEST)
  while (action !== END) {
    yield fork(userKYCAddRequestAPI, api, getToken, action)
    action = yield take(UserKYCTypes.USER_KYC_ADD_REQUEST)
  }
}

export function* userKYCAddRequestAPI(api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.addKYC, data, token)
    if (response.ok) {
      return yield put(UserKYCActions.userKYCAddSuccess(response.data.data))
    } else {
      return yield put(UserKYCActions.userKYCAddFailure(response.data.message))
    }
  } catch (err) {
    return yield put(UserKYCActions.userKYCAddFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
