// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  freeOngkirRequest: null,
  freeOngkirSuccess: ['payload'],
  freeOngkirFailure: ['err']
})

export const FreeOngkirTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const freeOngkirRequest = (state) =>
  state.merge({
    fetching: true,
    success: false,
    err: false
  })

export const freeOngkirSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const freeOngkirFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: true
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FREE_ONGKIR_REQUEST]: freeOngkirRequest,
  [Types.FREE_ONGKIR_SUCCESS]: freeOngkirSuccess,
  [Types.FREE_ONGKIR_FAILURE]: freeOngkirFailure
})
