import { put, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import RecommendationBannerReducer from './../../Reducers/Banners/BannerRecommendationReducer'

export function * bannerRecommendationRequest (api, getToken) {
  let action = yield take(RecommendationBannerReducer.Types.BANNER_RECOMMENDATION_REQUEST)
  while (action !== END) {
    const token = yield call(getToken)
    const response = yield call(api.getPromoBannerOnScroll, token, {})
    if (response.ok) {
      yield put(RecommendationBannerReducer.Creators.bannerRecommendationSuccess(response.data.data))
    } else {
      yield put(RecommendationBannerReducer.Creators.bannerRecommendationFailure(response.data.message))
    }
    action = yield take(RecommendationBannerReducer.Types.BANNER_RECOMMENDATION_REQUEST)
  }
}
