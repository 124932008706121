import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

import AjukanProdukAction, { AjukanProdukTypes } from '../../Redux/AjukanProdukRedux/AjukanProdukRedux'

export function * addProductRequest (api, getToken) {
  let action = yield take(AjukanProdukTypes.ADD_PRODUCT_INIT)
  while (action !== END) {
    yield fork(addProductRequestAPI, api, getToken, action)
    action = yield take(AjukanProdukTypes.ADD_PRODUCT_INIT)
  }
}

export function * addProductRequestAPI (api, getToken, { data }) {
  const token = yield call(getToken)
  const response = yield call(api.ajukanProduk, data, token)
  try {
    if (response.ok) {
      return yield put(AjukanProdukAction.addProductSuccess(response.data))
    } else {
      return yield put(AjukanProdukAction.addProductFailure(response.data.message))
    }
  } catch (err) {
    return yield put(AjukanProdukAction.addProductFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi')
    )
  }
}
