// @flow
import { createReducer, createActions } from 'reduxsauce'
import { INITIAL_STATE } from '../Banners/BannerRecommendationReducer'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
    transactionAllRequest: ['page'],
    transactionAllSuccess: ['payload'],
    transactionAllFailure: ['err'],

    transactionWaitingRequest: null,
    transactionWaitingSuccess: ['payload'],
    transactionWaitingFailure: ['err'],

    transactionPaidRequest: null,
    transactionPaidSuccess: ['payload'],
    transactionPaidFailure: ['err'],

    transactionProcessedRequest: null,
    transactionProcessedSuccess: ['payload'],
    transactionProcessedFailure: ['err'],


    transactionShippedRequest: null,
    transactionShippedSuccess: ['payload'],
    transactionShippedFailure: ['err'],

    transactionCompletedRequest: null,
    transactionCompletedSuccess: ['payload'],
    transactionCompletedFailure: ['err'],

    transactionCanceledRequest: null,
    transactionCanceledSuccess: ['payload'],
    transactionCanceledFailure: ['err'],

    transactionCanceledMitraRequest: null,
    transactionCanceledMitraSuccess: ['payload'],
    transactionCanceledMitraFailure: ['err'],

    transactionDeliveredRequest: null,
    transactionDeliveredSuccess: ['payload'],
    transactionDeliveredFailure: ['err']
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */
export const transactionAllRequest = (state) =>
state.merge({ fetching: true, success: false, err: null })

export const transactionAllSuccess = (state, { payload }) =>
state.merge({
    fetching: false,
    data: payload,
    success: true
})

export const transactionAllFailure = (state, { err }) =>
state.merge({
    fetching: false,
    success: false,
    err
})

export const transactionWaitingRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionWaitingSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionWaitingFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

export const transactionProcessedRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionProcessedSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionProcessedFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })



export const transactionPaidRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionPaidSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionPaidFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


export const transactionShippedRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionShippedSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionShippedFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

export const transactionCanceledRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionCanceledSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionCanceledFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


export const transactionCanceledMitraRequest = (state) =>
state.merge({ fetching: true, success: false, err: null })

export const transactionCanceledMitraSuccess = (state, { payload }) =>
state.merge({
    fetching: false,
    data: payload,
    success: true
})

export const transactionCanceledMitraFailure = (state, { err }) =>
state.merge({
    fetching: false,
    success: false,
    err
})

export const transactionCompletedRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionCompletedSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionCompletedFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

export const transactionDeliveredRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const transactionDeliveredSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const transactionDeliveredFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


/* ------------- Hookup Reducers To Types ------------- */
export const allReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_ALL_REQUEST]: transactionAllRequest,
    [Types.TRANSACTION_ALL_SUCCESS]: transactionAllSuccess,
    [Types.TRANSACTION_ALL_FAILURE]: transactionAllFailure,
})

export const waitingReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_WAITING_REQUEST]: transactionWaitingRequest,
    [Types.TRANSACTION_WAITING_SUCCESS]: transactionWaitingSuccess,
    [Types.TRANSACTION_WAITING_FAILURE]: transactionWaitingFailure,
})

export const paidReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_PAID_REQUEST]: transactionPaidRequest,
    [Types.TRANSACTION_PAID_SUCCESS]: transactionPaidSuccess,
    [Types.TRANSACTION_PAID_FAILURE]: transactionPaidFailure,
})

export const shippedReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_SHIPPED_REQUEST]: transactionShippedRequest,
    [Types.TRANSACTION_SHIPPED_SUCCESS]: transactionShippedSuccess,
    [Types.TRANSACTION_SHIPPED_FAILURE]: transactionShippedFailure,
})


export const completedReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_COMPLETED_REQUEST]: transactionCompletedRequest,
    [Types.TRANSACTION_COMPLETED_SUCCESS]: transactionCompletedSuccess,
    [Types.TRANSACTION_COMPLETED_FAILURE]: transactionCompletedFailure,
})

export const canceledReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_CANCELED_REQUEST]: transactionCanceledRequest,
    [Types.TRANSACTION_CANCELED_SUCCESS]: transactionCanceledSuccess,
    [Types.TRANSACTION_CANCELED_FAILURE]: transactionCanceledFailure,
})

export const canceledMitraReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_CANCELED_MITRA_REQUEST]: transactionCanceledMitraRequest,
    [Types.TRANSACTION_CANCELED_MITRA_SUCCESS]: transactionCanceledMitraSuccess,
    [Types.TRANSACTION_CANCELED_MITRA_FAILURE]: transactionCanceledMitraFailure,
})

export const processedReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_PROCESSED_REQUEST]: transactionProcessedRequest,
    [Types.TRANSACTION_PROCESSED_SUCCESS]: transactionProcessedSuccess,
    [Types.TRANSACTION_PROCESSED_FAILURE]: transactionProcessedFailure,
})

export const deliveredReducer = createReducer(INITIAL_STATE, {
    [Types.TRANSACTION_DELIVERED_REQUEST]: transactionDeliveredRequest,
    [Types.TRANSACTION_DELIVERED_SUCCESS]: transactionDeliveredSuccess,
    [Types.TRANSACTION_DELIVERED_FAILURE]: transactionDeliveredFailure,
})