// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  init: null,
  submitPaymentRequest: ['data'],
  submitPaymentSuccess: ['payload'],
  submitPaymentFailure: ['err'],
  submitPaymentWalletRequest: ['data'],
  submitPaymentWalletSuccess: ['payload'],
  submitPaymentWalletFailure: ['err']
})

export const PaymentTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false,
    err: null
  })

export const submitPaymentRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const submitPaymentSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
})

export const submitPaymentFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

  export const submitPaymentWalletRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })
  
  export const submitPaymentWalletSuccess = (state, { payload }) =>
    state.merge({
      fetching: false,
      data: payload,
      success: true
  })
  
  export const submitPaymentWalletFailure = (state, { err }) =>
    state.merge({
      fetching: false,
      success: false,
      err
    })


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT]: init,
  [Types.SUBMIT_PAYMENT_REQUEST]: submitPaymentRequest,
  [Types.SUBMIT_PAYMENT_SUCCESS]: submitPaymentSuccess,
  [Types.SUBMIT_PAYMENT_FAILURE]: submitPaymentFailure,
  [Types.SUBMIT_PAYMENT_WALLET_REQUEST]: submitPaymentWalletRequest,
  [Types.SUBMIT_PAYMENT_WALLET_SUCCESS]: submitPaymentWalletSuccess,
  [Types.SUBMIT_PAYMENT_WALLET_FAILURE]: submitPaymentWalletFailure
})
