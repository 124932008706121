import React, { Component } from 'react'

// loading
import tailSpinLoading from '../static/svg-loaders/w-loading.gif'

import { Plugins } from '@capacitor/core';
const { App, SplashScreen, StatusBar, Toast } = Plugins;

export default class Loading extends Component {
  componentDidMount() {
    const body = document.querySelector('body')
    if (body) {
      body.style.backgroundColor = '#633c97'
    } else {
      body.style.backgroundColor = '#f3f4f6'
    }

    if (window.Capacitor.isNative) {
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
      window['deeplinkOn'] = false;

      StatusBar.setBackgroundColor({ color: "#633c97" });
      window['doubleTapExit'] = 0;
      if (window['hardwareBackListener'] !== undefined) {
        window['hardwareBackListener'].remove();
      }
      window['hardwareBackListener'] = App.addListener("backButton", function (data) {
        if (window.location.pathname === "/" || window.location.pathname === "/boarding") {
          if (window['doubleTapExit'] < 1) {
            window['doubleTapExit']++;
            Toast.show({ text: "Tekan lagi untuk keluar Aplikasi" });
            setTimeout(() => {
              window['doubleTapExit'] = 0;
            }, 2000);
          } else {
            App.exitApp();
          }
        } else {
          window.history.back();
        }
      });
    }
  }

  componentWillUnmount() {
    const body = document.querySelector('body')
    if (body) {
      body.style.backgroundColor = '#f3f4f6'
    } else {
      body.style.backgroundColor = '#633c97'
    }
  }

  render() {
    return (
      <div className='container h-100 p-0 m-0'>
        <div className='d-flex justify-content-center align-items-center flex-column text-center'>
          <img src={tailSpinLoading} width='30%' className='background-color-primary' style={{ marginTop: '75%' }} alt='loading-icon' />
          <span className='font-size-l bold mt-2 text-white'>Memuat...</span>
        </div>
      </div>
    )
  }
}
