import { put, take, call, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
import TempCartReducer from '../../Reducers/cart/tempCartReducer'
import { api, token as getToken } from '../../Apis'

export function* tempCartRequest(api, getToken) {
    let action = yield take(TempCartReducer.Types.TEMP_CART_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.getTempCartDetail, token, {})
        if (response.ok) {
            yield put(TempCartReducer.Creators.tempCartSuccess(response.data.data))
        } else {
            yield put(TempCartReducer.Creators.tempCartFailure(response.data.message))
        }
        action = yield take(TempCartReducer.Types.TEMP_CART_REQUEST)
    }
}

export function* updateTempCartItemRequest(api, getToken) {
    let action = yield take(TempCartReducer.Types.UPDATE_TEMP_CART_ITEM_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.updateTempCartItem, token, { ...action.data })
        if (response.ok) {
            yield put(TempCartReducer.Creators.tempCartRequest())
        } else {
            yield put(TempCartReducer.Creators.updateTempCartItemFailure(response.data.error.message))
        }
        action = yield take(TempCartReducer.Types.UPDATE_TEMP_CART_ITEM_REQUEST)
    }
}

export function* removeTempCartItemRequest(api, getToken) {
    let action = yield take(TempCartReducer.Types.REMOVE_TEMP_CART_ITEM_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.removeTempCartItem, token, { ...action.data })
        if (response.ok) {
            yield put(TempCartReducer.Creators.removeTempCartItemSuccess(response.data.data))
            yield put(TempCartReducer.Creators.tempCartRequest())
        } else {
            yield put(TempCartReducer.Creators.removeTempCartItemFailure(response.data.message))
        }
        action = yield take(TempCartReducer.Types.REMOVE_TEMP_CART_ITEM_REQUEST)
    }
}

export function* tempCartSaga() {
    yield all([
        fork(tempCartRequest, api, getToken),
        fork(updateTempCartItemRequest, api, getToken),
        fork(removeTempCartItemRequest, api, getToken),
    ])
}