// @flow
import { createReducer, createActions } from 'reduxsauce'
import { INITIAL_STATE } from '../Banners/BannerRecommendationReducer'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
    tempCartRequest: null, // get cart detail - fetch from localforage
    tempCartSuccess: ['payload'],
    tempCartFailure: ['err'],
    updateTempCartItemRequest: ['data'],
    updateTempCartItemSuccess: ['payload'],
    updateTempCartItemFailure: ['err'],
    removeTempCartItemRequest: ['data', 'clear'],
    removeTempCartItemSuccess: ['payload'],
    removeTempCartItemFailure: ['err'],
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */
export const tempCartRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const tempCartSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const tempCartFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


export const updateTempCartItemRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const updateTempCartItemSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const updateTempCartItemFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

export const removeTempCartItemRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const removeTempCartItemSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        success: true
    })

export const removeTempCartItemFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

export const updateTempExpeditionRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const updateTempExpeditionSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const updateTempExpeditionFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.TEMP_CART_REQUEST]: tempCartRequest,
    [Types.TEMP_CART_SUCCESS]: tempCartSuccess,
    [Types.TEMP_CART_FAILURE]: tempCartFailure,
    [Types.UPDATE_TEMP_CART_ITEM_REQUEST]: updateTempCartItemRequest,
    [Types.UPDATE_TEMP_CART_ITEM_SUCCESS]: updateTempCartItemSuccess,
    [Types.UPDATE_TEMP_CART_ITEM_FAILURE]: updateTempCartItemFailure,
    [Types.REMOVE_TEMP_CART_ITEM_REQUEST]: removeTempCartItemRequest,
    [Types.REMOVE_TEMP_CART_ITEM_SUCCESS]: removeTempCartItemSuccess,
    [Types.REMOVE_TEMP_CART_ITEM_FAILURE]: removeTempCartItemFailure,
})
