import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// import _ from 'lodash'

// Social Share
import SocialShareActions, { SocialShareTypes } from '../Redux/SocialShareRedux'
import SocialShareDetailActions, { SocialShareDetailTypes } from '../Redux/SocialShareDetailRedux'

export function * socialShareRequest (api, getToken) {
  let action = yield take(SocialShareTypes.SOCIAL_SHARE_REQUEST)
  while (action !== END) {
    yield fork(socialShareRequestAPI, api, getToken, action)
    action = yield take(SocialShareTypes.SOCIAL_SHARE_REQUEST)
  }
}

export function * socialShareRequestAPI (api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.socialShare, data, token)
    if (response.ok) {
      return yield put(SocialShareActions.socialShareSuccess(response.data.data))
    } else {
      return yield put(SocialShareActions.socialShareFailure(response.data.data))
    }
  } catch (err) {
    return yield put(SocialShareActions.socialShareFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * socialShareDetailRequest (api) {
  let action = yield take(SocialShareDetailTypes.SOCIAL_SHARE_DETAIL_REQUEST)
  while (action !== END) {
    yield fork(socialShareDetailRequestAPI, api, action)
    action = yield take(SocialShareDetailTypes.SOCIAL_SHARE_DETAIL_REQUEST)
  }
}

export function * socialShareDetailRequestAPI (api, { loc }) {
  try {
    if (loc !== '') {
      const response = yield call(api.getSocialShare, loc)
      if (response.ok) {
        return yield put(SocialShareDetailActions.socialShareDetailSuccess(response.data.data))
      } else {
        return yield put(SocialShareDetailActions.socialShareDetailFailure(response.data.data))
      }
    }
  } catch (err) {
    return yield put(SocialShareDetailActions.socialShareDetailFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}
