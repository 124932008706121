import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

// Redux Stuffs
import TriplogicPriceActions, { TriplogicPriceTypes } from '../Redux/TriplogicPriceRedux'
import TriplogicDetailActions, { TriplogicDetailTypes } from '../Redux/TriplogicDetailRedux'

export function * triplogicPriceRequest (api, getToken) {
  let action = yield take(TriplogicPriceTypes.TRIPLOGIC_PRICE_REQUEST)
  while (action !== END) {
    yield fork(triplogicPriceRequestAPI, api, getToken, action)
    action = yield take(TriplogicPriceTypes.TRIPLOGIC_PRICE_REQUEST)
  }
}

export function * triplogicPriceRequestAPI (api, getToken, { ori, dest, weight }) {
  try {
    if (ori !== '' && dest !== '') {
      const token = yield call(getToken)
      const response = yield call(api.getTriplogicPrice, ori, dest, weight, token)
      if (response.ok) {
        return yield put(TriplogicPriceActions.triplogicPriceSuccess(response.data.data))
      } else {
        return yield put(TriplogicPriceActions.triplogicPriceFailure(response.data.message))
      }
    }
  } catch (err) {
    return yield put(TriplogicPriceActions.triplogicPriceFailure('Gagal mendapatkan harga Triplogic'))
  }
}

export function * triplogicDetailRequest (api, getToken) {
  let action = yield take(TriplogicDetailTypes.TRIPLOGIC_DETAIL_REQUEST)
  while (action !== END) {
    yield fork(triplogicDetailRequestAPI, api, getToken, action)
    action = yield take(TriplogicDetailTypes.TRIPLOGIC_DETAIL_REQUEST)
  }
}

export function * triplogicDetailRequestAPI (api, getToken, { trackingId }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.getTriplogicDetail, trackingId, token)
    if (response.ok) {
      return yield put(TriplogicDetailActions.triplogicDetailSuccess(response.data.data))
    } else {
      return yield put(TriplogicDetailActions.triplogicDetailFailure(response.data.message))
    }
  } catch (err) {
    return yield put(TriplogicDetailActions.triplogicDetailFailure('Gagal mendapatkan detil Triplogic'))
  }
}
