import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
// import _ from 'lodash'

// Social Share
import BlogActions, { BlogTypes } from '../Redux/BlogRedux'

export function * blogRequest (api, getToken) {
  let action = yield take(BlogTypes.BLOG_REQUEST)
  while (action !== END) {
    yield fork(blogRequestAPI, api, getToken, action)
    action = yield take(BlogTypes.BLOG_REQUEST)
  }
}

export function * blogRequestAPI (api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.dynamicPage, data, token)
    if (response.ok) {
      return yield put(BlogActions.blogSuccess(response.data.data))
    } else {
      return yield put(BlogActions.blogFailure(response.data.data))
    }
  } catch (err) {
    return yield put(BlogActions.blogFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}