import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// Icon
import arrowBackwardIcon from '../../static/images/navigations/arrow-backward.svg'

// Antd & styled
import { Container, Block, TextLarge } from '../Styled'
import { Col, Row } from 'antd'

class GeneralHeaderEvent extends Component {
  render () {
    const { history } = this.props
    return (
      <div>
        <Container>
          <div className='fixed-top' id='fixed-width'>
            <Row
              type='flex'
              align='middle'
              className='container background-white pt-2 pb-2'
            >
              <Col span={4}>
                <img
                  src={arrowBackwardIcon}
                  width='10'
                  alt='arrow-back-icon'
                  onClick={() => history.goBack()}
                />
              </Col>
              <Col span={16}>
                <div>
                  <Block textAlign='center'>
                    <TextLarge className='font-heading' boldest>
                      {this.props.title}
                    </TextLarge>
                  </Block>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

export default withRouter(GeneralHeaderEvent)
