import React, { useState, useEffect } from "react";
import "./styles.scss";
import filterOptions from "./filter.json";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import BottomSheet from "../../../BottomSheet";
import sortOptions from "./sort.json";
import { isEmpty } from "../../../../Utils";

// Img
import filterIcon from "../../../../static/images/navigations/filter-icon.svg";
import filterCheckedIcon from "../../../../static/images/navigations/filter-verif-icon.svg";
import sortIcon from "../../../../static/images/navigations/urutkan-icon.svg";
import sortCheckedIcon from "../../../../static/images/navigations/urutkan-verif-icon.svg";
import gridIcon from "../../../../static/images/navigations/grid.svg";
import listIcon from "../../../../static/images/navigations/list.svg";

function Filter({
  setPriceMax,
  setPriceMin,
  doFilter,
  setFilter,
  filter,
  sort,
  setSort,
  setOb,
  doSort,
  changeLayout,
  layout,
  resetFilter,
  resetSort,
}) {
  const history = useHistory();
  const queryParam = queryString.parse(history.location.search);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);

  const [isActiveFilter, setActiveFilter] = useState(false);
  const [isActiveSort, setActiveSort] = useState(true);

  const toggleFilter = () => setFilter(!filter); // From props
  const toggleSort = () => setSort(!sort); // From props

  useEffect(() => {
    setActiveFilter(queryParam.filter);
    setActiveSort(queryParam.sort);
  }, []);

  const handleSelect = (priceMax, priceMin) => {
    setPriceMin(priceMin);
    setPriceMax(priceMax);
    setSelectedFilter(priceMax);
  };

  const handleSortSelect = (ob, id) => {
    setSelectedSort(id);
    setOb(ob);
  };

  const activateSort = () => {
    doSort();
    setActiveSort(true);
  };

  const activateFilter = () => {
    doFilter();
    setActiveFilter(true);
  };

  const renderFilterSheet = () => (
    <BottomSheet visible={filter} toggleVisibility={setFilter}>
      <div className="reset-button">
        <span onClick={resetFilter}>Reset</span>
      </div>
      <div className="d-flex align-items-center">
        {filterOptions.map((filter) => (
          <div key={filter.id} className="filter__options">
            <input
              onChange={() => handleSelect(filter.pmax, filter.pmin)}
              type="radio"
              defaultChecked={queryParam.pmin === filter.pmin.toString()}
              id={filter.id}
              name="filter"
              value={filter}
            />
            <label htmlFor={filter.id}>{filter.name}</label>
          </div>
        ))}
      </div>
      <div className="pt-3">
        <button
          disabled={isEmpty(selectedFilter)}
          className="btn background-color-primary text-white bolder w-100"
          onClick={() => activateFilter()}
        >
          TERAPKAN FILTER
        </button>
      </div>
    </BottomSheet>
  );

  const renderSortSheet = () => (
    <BottomSheet visible={sort} toggleVisibility={setSort}>
      <div className="reset-button">
        <span onClick={resetSort}>Reset</span>
      </div>
      {sortOptions.map((val) => (
        <div
          key={val.id}
          className="d-flex filter__sort-options align-items-center border-bottom"
        >
          <div className="pr-4">
            <div>{val.name}</div>
          </div>
          <div className="ml-auto">
            <label>
              <input
                type="radio"
                defaultChecked={queryParam.ob === val.ob.toString()}
                name="select"
                onChange={() => handleSortSelect(val.ob, val.id)}
                value={val.ob}
              />
              <span />
            </label>
          </div>
        </div>
      ))}
      <div className="pt-3">
        <button
          disabled={isEmpty(selectedSort)}
          className="btn background-color-primary text-white bolder w-100"
          onClick={activateSort}
        >
          TERAPKAN FILTER
        </button>
      </div>
    </BottomSheet>
  );

  return (
    <div
      className="fixed-bottom filter d-flex align-items-center"
      id="fixed-width"
    >
      <div
        className="col p-3 d-flex justify-content-center align-items-center border-right"
        onClick={toggleSort}
        style={{ cursor: "pointer" }}
      >
        <img
          src={`${isActiveSort ? sortCheckedIcon : sortIcon}`}
          className="pr-1"
          width="26"
          alt="..."
        />
        Urutkan
      </div>
      <div
        className="col d-flex justify-content-center align-items-center p-3 border-right"
        onClick={toggleFilter}
        style={{ cursor: "pointer" }}
      >
        <img
          src={`${isActiveFilter ? filterCheckedIcon : filterIcon}`}
          className="pr-1"
          width="26"
          alt="..."
        />
        Filter
      </div>
      <div
        className="col d-flex justify-content-center align-items-center p-3"
        onClick={changeLayout}
        style={{ cursor: "pointer" }}
      >
        <img
          src={`${layout ? gridIcon : listIcon}`}
          className="pr-1"
          width="26"
          alt="..."
        />
        {`${layout ? "List" : "Grid"}`}
      </div>
      {renderFilterSheet()}
      {renderSortSheet()}
    </div>
  );
}

export default Filter;
