// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userDataRequest: null,
  userDataSuccess: ['payload'],
  userDataFailure: ['err'],
  userUpdateProfileRequest: ['data'],
  userUpdateProfileSuccess: ['payload'],
  userUpdateProfileFailure: ['err']
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const userDataRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userDataSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userDataFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// User Profile Update
export const userUpdateProfileRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userUpdateProfileSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userUpdateProfileFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_DATA_REQUEST]: userDataRequest,
  [Types.USER_DATA_SUCCESS]: userDataSuccess,
  [Types.USER_DATA_FAILURE]: userDataFailure,
  [Types.USER_UPDATE_PROFILE_REQUEST]: userUpdateProfileRequest,
  [Types.USER_UPDATE_PROFILE_SUCCESS]: userUpdateProfileSuccess,
  [Types.USER_UPDATE_PROFILE_FAILURE]: userUpdateProfileFailure
})
