import { put, take, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
// Redux Stuffs
import RecommendationProductInfiniteAction, { RecommendationProductInfiniteTypes } from '../Reducers/ProductRecommendationInfinte'

export function * recommendationProductInfiniteClear () {
  let action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_CLEAR)
  while (action !== END) {
    yield put(RecommendationProductInfiniteAction.recommendationProductInfiniteClear())
    action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_CLEAR)
  }
}

export function * recommendationProductInfiniteAdd () {
  let action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_ADD)
  while (action !== END) {
    // yield put(RecommendationProductInfiniteAction.recommendationProductInfiniteAdd(page, products));
    action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_ADD)
  }
}

export function * recommendationProductInfiniteDone () {
  let action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_DONE)
  while (action !== END) {
    yield put(RecommendationProductInfiniteAction.recommendationProductInfiniteDone())
    action = yield take(RecommendationProductInfiniteTypes.RECOMMENDATION_PRODUCT_INFINITE_DONE)
  }
}

export function * productRecommendationInfiniteSaga () {
  yield all([
    fork(recommendationProductInfiniteClear),
    fork(recommendationProductInfiniteAdd)
  ])
}
