import { ColorTypes } from 'shared/theme';
import { MarginTypeStyledHelper } from 'shared/types/margin.type';
import { PaddingTypeStyledHelper } from 'shared/types/padding.type';
import styled from 'styled-components';

const StyledButton = styled.button<{ textColor: ColorTypes, fontSize:string }>`
    /* Basic Button Layout */
    color: ${(props) => (props.textColor ? props.theme.colors[props.textColor ?? '']?.background : props.theme.colors[props.color ?? '']?.contrast)};
    border: none;
    border-radius: 5px;
    background: ${(props) => props.theme.colors[props.color ?? '']?.background};
    font-family: Montserrat;
    font-size: ${(props) => props.fontSize};
    font-weight: 600;
    padding: 10px 20px;
    transition: ease all ${(props) => props.theme.transition.speed};
    border: 1px solid ${(props) => props.theme.colors[props.color ?? '']?.background};
    cursor: pointer;
    user-select: none;
    ${MarginTypeStyledHelper}
    ${PaddingTypeStyledHelper}

    &:active {
        opacity:.8;
    }
    
    &[data-is-block="true"] {
        display: block;
        width: 100%;
    }

    /* Size Medium */
    &[data-size="medium"] {
        padding: 6px 20px;
        font-weight: 500;
        font-family: Nunito;
    }

    /* Size Small */
    &[data-size="small"] {
        padding: 6px 10px;
        font-family: Nunito;
        font-size: 10px;
        font-weight: normal;
    }

    /* Variant Ghost */
    &[data-variant="ghost"] {
        background: transparent;
        color: ${(props) => props.theme.colors[props.color ?? ''].background};
        border-color: transparent;

        &[disabled], &:disabled, &[data-is-loading="true"] {
            background: transparent;
            border-color: #d0d0d0;
            color: #9b9b9b;
        }
    }

    /* Variant Ghost */
    &[data-variant="outline"] {
        background: transparent;
        color: ${(props) => props.theme.colors[props.color ?? ''].background};
        border-color: ${(props) => props.theme.colors[props.color ?? ''].background};

        &:active {
        opacity:.8;
    }
    }

    &[data-is-error="true"] {
        background: transparent;
        color: ${(props) => props.theme.colors[props.color ?? ''].background};
        border-color: red;
    }

    /* Variant Clear */
    &[data-variant="clear"] {
        border-color: transparent;
        background: transparent;
        color: ${(props) => props.theme.colors[props.color ?? ''].background};

        &[disabled], &:disabled, &[data-is-loading="true"] {
            background: transparent;
            border-color: transparent;
            color: #9b9b9b;
        }
    }

    &[disabled], &:disabled, &[data-is-loading="true"] {
        background: #d0d0d0;
        border-color: #d0d0d0;
        color: #9b9b9b;
    }
`;

export default StyledButton;
