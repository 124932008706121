// @flow
import { createReducer, createActions } from 'reduxsauce'
import { INITIAL_STATE } from '../Banners/BannerRecommendationReducer'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
    categoryProductRequest: ['categoryId', 'page'], // get cart detail - fetch from localforage
    categoryProductSuccess: ['payload'],
    categoryProductFailure: ['err'],
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */
export const categoryProductRequest = (state) =>
    state.merge({ fetching: true, success: false, err: null })

export const categoryProductSuccess = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true
    })

export const categoryProductFailure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CATEGORY_PRODUCT_REQUEST]: categoryProductRequest,
    [Types.CATEGORY_PRODUCT_SUCCESS]: categoryProductSuccess,
    [Types.CATEGORY_PRODUCT_FAILURE]: categoryProductFailure,
})
