import { useQuery } from 'react-query';
import Immutable from 'seamless-immutable';
import { api, token as getToken } from '../../../Redux/Apis';

export const fetchBrandProduct = async (filterData) => {
  const token = await getToken();

  try {
    const response = await api.getProductByCategory(filterData, token);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const useRequestBrandBanner = (brandID) => {
  const { data, isFetching } = useQuery(
    ['brand-banner', { brandID }],
    async () => {
      try {
        const token = await getToken();
        const response = await api.getBannerBrand(brandID, token);
        return Immutable(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: true,
    },
  );
  return { data, isFetching };
};

export const useRequestBrandBestSelling = (brandID) => {
  const { data, isFetching } = useQuery(
    ['brand-best-selling', { brandID }],
    async () => {
      try {
        const token = await getToken();
        const response = await api.getBrandBestSeller(brandID, token);
        return Immutable(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: true,
    },
  );
  return { data, isFetching };
};

export const useRequestCouponList = (brandID) => {
  const { data, isFetching, isLoading } = useQuery(
    ['brand-coupon', { brandID }],
    async () => {
      try {
        const token = await getToken();
        const response = await api.getCouponListBrand(brandID, token);
        return Immutable(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: true,
    },
  );
  return { data, isFetching, isLoading };
};

export const useRequestBrandDetail = (brandID) => {
  const { data, isFetching, status } = useQuery(
    ['brand-detail', { brandID }],
    async () => {
      try {
        const token = await getToken();
        const response = await api.getBrandDetail(brandID, token);
        return Immutable(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: true,
    },
  );
  return { data, isFetching, status };
};
