// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  bankAccountListRequest: null,
  bankAccountListSuccess: ['payload'],
  bankAccountListFailure: ['err'],
  addBankAccountRequest: ['data'],
  addBankAccountSuccess: ['payload'],
  addBankAccountFailure: ['err'],
  updateBankAccountRequest: ['data'],
  updateBankAccountSuccess: ['payload'],
  updateBankAccountFailure: ['err']
})

export const UserBankAccountTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: null,
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const bankAccountListRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const bankAccountListSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload || [],
    success: true
  })

export const bankAccountListFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

export const addBankAccountRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const addBankAccountSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const addBankAccountFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

export const updateBankAccountRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const updateBankAccountSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const updateBankAccountFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BANK_ACCOUNT_LIST_REQUEST]: bankAccountListRequest,
  [Types.BANK_ACCOUNT_LIST_SUCCESS]: bankAccountListSuccess,
  [Types.BANK_ACCOUNT_LIST_FAILURE]: bankAccountListFailure,
  [Types.ADD_BANK_ACCOUNT_REQUEST]: addBankAccountRequest,
  [Types.ADD_BANK_ACCOUNT_SUCCESS]: addBankAccountSuccess,
  [Types.ADD_BANK_ACCOUNT_FAILURE]: addBankAccountFailure,
  [Types.UPDATE_BANK_ACCOUNT_REQUEST]: updateBankAccountRequest,
  [Types.UPDATE_BANK_ACCOUNT_SUCCESS]: updateBankAccountSuccess,
  [Types.UPDATE_BANK_ACCOUNT_FAILURE]: updateBankAccountFailure
})
