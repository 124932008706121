import React from "react";
import "./styles.scss";
import Masonry from "react-masonry-css";
import { Waypoint } from "react-waypoint";
import { Spin } from "antd";
import ProductCardGrid from "../../../../Components/product-card/product-card-grid/ProductCardGrid";
import ProductCardList from "../../../../Components/product-card/product-card-list/ProductCardList";
import { queryClient } from "App";
import {
  TextMedium,
  DividerLight,
  Container,
  TextSmall,
  Divider,
} from "../../../../Components/Styled";
import { isEmpty } from "../../../../Utils";

// Img
import EmptyProductImage from "../../../../static/images/illustrations/w-empty-product.svg";
import { ProductListLoader } from "../../../../Components/loader";

function ProductListBrand({
  products,
  layout,
  isFetching,
  fetchMoreData,
  hasMore,
  totalPage,
}) {
  const catalogList = queryClient.getQueryData("catalog-list");

  if (catalogList === undefined) return null;

  function ListLayout() {
    return products.map((product, idx) => (
      <div key={idx}>
        <div className="pt-3 pb-3">
          <ProductCardList
            catalog={catalogList}
            item={product}
          />
        </div>
        <DividerLight />
      </div>
    ));
  }

  function GridLayout() {
    const catalogList = queryClient.getQueryData('catalog-list');
    return (
      <Masonry
        breakpointCols={2}
        className="search-mansonry pt-3"
        columnClassName="my-masonry-grid_column"
      >
        {React.Children.toArray(products.map((product) => (
          <ProductCardGrid catalog={catalogList} grid={14} item={product} />
        )))}
      </Masonry>
    );
  }

  const renderLayout = () => {
    switch (layout) {
      case true:
        return <GridLayout products={products} />;
      default:
        return <ListLayout products={products} />;
    }
  };

  function EmptyProduct() {
    return (
      <div className="empty-product">
        <div>
          <img src={EmptyProductImage} alt="..." width="120" />
          <div className="text-center">
            <div className="pt-2">
              <TextMedium className="font-heading">
                Barang Tidak tersedia
              </TextMedium>
            </div>
            <div>
              <TextSmall increase color="#9b9b9b">
                Yah, sepertinya barangnya belum tersedia
              </TextSmall>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isFetching) return <ProductListLoader />;

  return !isEmpty(products) ? (
    <div className="product-list-brand">
      <Divider />
      <Container>
        <div className="pt-3">
          <TextMedium className="font-heading">
            Rekomendasi untuk Kamu
          </TextMedium>
        </div>
      </Container>
      {renderLayout()}
      {hasMore && totalPage.total_page !== 1 && (
        <Waypoint key={products.length} onEnter={fetchMoreData}>
          <div className="text-center w-100 mt-4 mb-4">
            <Spin />
          </div>
        </Waypoint>
      )}
    </div>
  ) : (
    EmptyProduct()
  );
}

export default ProductListBrand;
