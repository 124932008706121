// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
    displayCategoryInit: null,
    displayCategoryRequest: ['isNative'],
    displayCategorySuccess: ['payload'],
    displayCategoryFailure: ['err']
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetching: false,
    data: {},
    success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
    state.merge({
        fetching: false,
        data: {},
        success: false
    })

export const request = (state) =>
    state.merge({ fetching: true, success: false })

export const success = (state, { payload }) =>
    state.merge({
        fetching: false,
        data: payload,
        success: true,
        err: null
    })

export const failure = (state, { err }) =>
    state.merge({
        fetching: false,
        success: false,
        err
    })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.DISPLAY_CATEGORY_INIT]: init,
    [Types.DISPLAY_CATEGORY_REQUEST]: request,
    [Types.DISPLAY_CATEGORY_SUCCESS]: success,
    [Types.DISPLAY_CATEGORY_FAILURE]: failure
})
