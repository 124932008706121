// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCatalogDetailRequest: ['data'],
  getCatalogDetailSuccess: ['payload'],
  getCatalogDetailFailure: ['err']
})

export const CatalogDetailTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// GET CATALOG DETAIL
export const getCatalogDetailRequest = (state) =>
state.merge({ fetching: true, success: false, err: null })

export const getCatalogDetailSuccess = (state, { payload }) =>
state.merge({
  fetching: false,
  data: payload,
  success: true
})

export const getCatalogDetailFailure = (state, { err }) =>
state.merge({
  fetching: false,
  success: false,
  err
})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CATALOG_DETAIL_REQUEST]: getCatalogDetailRequest,
  [Types.GET_CATALOG_DETAIL_SUCCESS]: getCatalogDetailSuccess,
  [Types.GET_CATALOG_DETAIL_FAILURE]: getCatalogDetailFailure
})
