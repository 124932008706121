/* eslint-disable implicit-arrow-linebreak */
import apisauce from 'apisauce';
import config from '../config';

// a library to wrap and simplify api calls

const { apiURL } = config;
const { socialURL } = config;
const favoriteURL = config.favoriteAPI;

// our "constructor"
const create = (baseURL = apiURL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const apiWrapper = apisauce.create({
    // base URL is read from the "constructor"
    baseURL: apiURL,
    // 10 second timeout...
    timeout: 10000,
  });

  const socialAPI = apisauce.create({
    baseURL: socialURL,
    timeout: 10000,
  });

  const favoriteAPI = apisauce.create({
    baseURL: favoriteURL,
    timeout: 10000,
  });

  const multipartFormWithToken = (Authorization) => ({
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${Authorization}`,
    },
  });

  const jsonHeader = () => ({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const headerWithToken = (Authorization) =>
    // JWT
    ({
      headers: {
        'Content-Type': 'application/json',
        // 'Cache-Control': 'public, max-age=604800',
        Authorization: `Bearer ${Authorization}`,
      },
    })
  ;

  // Wrap api's addMonitor to allow the calling code to attach
  // additional monitors in the future.  But only in __DEV__ and only
  // if we've attached Reactotron to console (it isn't during unit tests).
  if (config.DEBUG) {
    const naviMonitor = (response) => console.log('API DEBUG! response =', response);
    apiWrapper.addMonitor(naviMonitor);
  }

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const otpAttempt = (data, subdomain) => {
    const phone = data.replace(/(^08|^8|^628|^\+628)/g, '+628');
    return apiWrapper.get(`/auth/otp_attempt_daily/${phone}?subdomain=${subdomain}`, {}, jsonHeader());
  };

  // Auth
  const checkPhone = (data, subdomain) => apiWrapper.get(`/front/agent/detail/${data}?subdomain=${subdomain}`, {}, jsonHeader());

  const checkReferral = (referralCode) => apiWrapper.post(`/front/agent/referral/check/${referralCode}`, {}, jsonHeader());

  const register = (data) =>
    // it'll return auth_token, store in localforage
    apiWrapper.post('/auth/register', data, jsonHeader());
  const login = (phoneNumber) =>
    // it'll return auth_token, store in localforage
    apiWrapper.post('/auth/login', phoneNumber, jsonHeader());
  const verifyOtp = (phoneNumber, agentId, otp, subdomain) =>
    // it'll return auth_token, store in localforage
    apiWrapper.post('/auth/otp', {
      phone_number: phoneNumber,
      agent_id: agentId,
      otp,
      subdomain,
    }, jsonHeader());

  // User
  const userCheckCompleteness = (phoneNumber, token) => apiWrapper.post('/agent/check_profile', {
    phone_number: phoneNumber,
  }, headerWithToken(token));

  const androidVersion = (token) => apiWrapper.get('/mobile/android/version', {}, headerWithToken(token));

  const tokenValidity = (token) => apiWrapper.get('/v2/auth/check-auth-token', {}, headerWithToken(token));

  const getUserAddressList = (agentId, token) => apiWrapper.post('/agent/address_list', {
    agentId,
  }, headerWithToken(token));
  const updateUserAddress = (data, token) => apiWrapper.put('/agent/update_address', data, headerWithToken(token));
  const changeAddressIsDefault = (data, token) => apiWrapper.put('/agent/is_default_address', data, headerWithToken(token));
  const addUserAddress = (data, token) => apiWrapper.post('/agent/add_address', data, headerWithToken(token));
  const removeUserAddress = (data, token) => apiWrapper.put('/agent/remove_address', data, headerWithToken(token));

  const getUserProfile = (data, token) => apiWrapper.post('/agent/detail_profile', data, headerWithToken(token));

  const getUserProfileView = (agentId) => apiWrapper.get(`front/agent/detail_profile/${agentId}`, {}, jsonHeader());

  const updateImageProfile = (data, token) => apiWrapper.post('/agent/update_profile_picture', data, multipartFormWithToken(token));

  const updateUserProfile = (data, token) => apiWrapper.put('/agent/update_profile', data, headerWithToken(token));

  const getUserBankAccountList = (agentId, token) => apiWrapper.post('/agent/bank_account_list', {
    agent_id: agentId,
  }, headerWithToken(token));

  const addBankAccount = (data, token) => apiWrapper.post('/agent/add_account', data, headerWithToken(token));

  const updateBankAccount = (data, token) => apiWrapper.put('/agent/update_account', data, headerWithToken(token));

  // Bonus

  const getUserBonus = (data, token) => apiWrapper.get('/bonus/detail', data, headerWithToken(token));

  const getBonusHistory = (data, token) => apiWrapper.get('/bonus/history', data, headerWithToken(token));

  const getBonusLevel = (data, token) => apiWrapper.get('/bonus/level', data, headerWithToken(token));

  const getBonusTerms = (data, token) => apiWrapper.get('/bonus/terms', data, headerWithToken(token));

  const getBonusTransaction = (data, token) => apiWrapper.get('/bonus/transaction/history', data, headerWithToken(token));

  // Commision

  const getUserCommision = (data, token) => apiWrapper.get('/agent/get_commission', data, headerWithToken(token));

  const getUserCommissionHistory = (token) => apiWrapper.get('/agent/get_commission_history', {}, headerWithToken(token));

  const getUserCommissionDebitHistory = (token) => apiWrapper.get('/agent/get_commission_debit_history', {}, headerWithToken(token));

  const getUserCommissionCreditHistory = (token) => apiWrapper.get('/agent/get_commission_credit_history', {}, headerWithToken(token));

  const activeFriendCode = (token) => apiWrapper.post('/agent/activate_friendcode', {}, headerWithToken(token));

  const appliedFriendCode = (token) => apiWrapper.get('/agent/friendcode_list', {}, headerWithToken(token));

  // Withdraw

  const requestWithdraw = (amountChange, token) => apiWrapper.post('/withdraw', amountChange, headerWithToken(token));

  // KYC
  const getKYC = (token) => apiWrapper.get('/agent/kyc/check', {}, headerWithToken(token));

  const addKYC = (data, token) => apiWrapper.post('/agent/kyc/', data, multipartFormWithToken(token));

  // User favorite
  const getUserFavorite = (token) => favoriteAPI.get('/list', {}, headerWithToken(token));

  const getGratisOngkir = (token) => apiWrapper.get('/info/free-ongkir', {}, headerWithToken(token));

  // Product
  const searchProduct = (data, token) => apiWrapper.get('/search/product', data, headerWithToken(token));

  const getAllCategory = (token) => apiWrapper.get('/brand/category', {}, headerWithToken(token));

  const getSelectedCategory = (token, data) => apiWrapper.get(`/search/product?limit=100&page=1&q=${data}`, {}, headerWithToken(token));

  const getProductBrand = (brandId, page, token) => apiWrapper.get(`/search/product/filters?brand=${brandId}&page=${page || 1}&limit=4`, {}, headerWithToken(token));

  // const getProductByCategory = (categoryId, page, token) => {
  //   return apiWrapper.get(`/search/product/filters?pc=${categoryId}&page=${page || 1}&limit=4`, {}, headerWithToken(token))
  // }
  const getProductByCategory = (data, token) => apiWrapper.get('/search/product/filters', data, headerWithToken(token));

  const getDisplayCategory = (isNative, token) => apiWrapper.get('/category/?include_in_menu=1&limit=8&page=1&orderby=order_no&order=asc&is_native=true', {}, headerWithToken(token));

  const getProductFilterPrice = (data, token) => apiWrapper.get('/search/product/filters', data, headerWithToken(token));

  const getProductMostShared = (token) => apiWrapper.get('/product/home/most-shared', {}, headerWithToken(token));

  const getPopularSearch = (token) => apiWrapper.get('/search/popular_tags', {}, headerWithToken(token));

  const getProductDetail = (sku, token) => apiWrapper.get(`/v2/product/detail/${sku}`, {}, headerWithToken(token));

  const getProductDetailView = (sku) => apiWrapper.get(`/product/detail/view/${sku}`, {}, jsonHeader());

  const getProductStock = (sku, qty, token) => apiWrapper.get(`/product/stock/${sku}`, {}, headerWithToken(token));

  const getProductSpecial = (data, token) => apiWrapper.get('/v2/product/selection', data, headerWithToken(token));

  const getProductSelection = (token, qty = null) => apiWrapper.get('/product/home/selection', { qty }, headerWithToken(token));

  const getSearchSuggestion = (token, keyword, limit) => apiWrapper.get(`/search/product/autocomplete?q=${keyword}&limit=${limit}`, {}, headerWithToken(token));

  const getProductRecommendation = (data, token) => apiWrapper.get('/v2/product/recommendation', data, headerWithToken(token));

  const getBannerCatalog = (data, token) => apiWrapper.get('/banner/suggest?page=1&limit=15&qty=20', data, headerWithToken(token));

  const getProductDetailCommission = (data, token) => apiWrapper.post('/product/commission', data, headerWithToken(token));

  const getProductDetailCommissionTierList = (data, token) => apiWrapper.post('/product/commission/list', data, headerWithToken(token));

  const getProductSetExplanation = (sku, token) => apiWrapper.get(`/product/qty_set/${sku}`, {}, headerWithToken(token));

  const getProductCategory = (categoryId, page, token) =>
    // return apiWrapper.get(`/search/product/filters?page=${page}&ob=3&pc=${categoryId}`, {}, headerWithToken(token))
    apiWrapper.get(`/product/list?categoryId=${categoryId}&page=${page}&sort=newArrival`, {}, headerWithToken(token))
    // return apiWrapper.get(`/v2/product/list?categoryId=${categoryId}&page=${page}&sort=newArrival`, {}, headerWithToken(token))
  ;

  const getPromoBannerOnScroll = (token) => apiWrapper.get('/product/promo/onscroll', {}, headerWithToken(token));

  const getSahabatBrand = (data, token) => apiWrapper.get('/brand/list', data, headerWithToken(token));

  const ajukanProduk = (data, token) => apiWrapper.post('/product-request', data, multipartFormWithToken(token));

  const getTempCartDetail = (token) => apiWrapper.get('v2/cart/temporary/detail', {}, headerWithToken(token));

  const updateTempCartItem = (token, data) => apiWrapper.put('v2/cart/temporary/update', { ...data }, headerWithToken(token));

  const removeTempCartItem = (token, data) => apiWrapper.put('v2/cart/temporary/remove', { ...data }, headerWithToken(token));

  // const addFavorite = () => {
  //   return apiWrapper.put('')
  // }

  // Shopping Cart
  const getCartDetail = (cartId, token) => apiWrapper.get(`v2/cart/${cartId}`, {}, headerWithToken(token));

  const updateCart = (data, token) => apiWrapper.put('v2/cart/update', { ...data }, headerWithToken(token));

  const removeCartItem = (payload, token) => apiWrapper.put('/v2/cart/remove_item', payload, headerWithToken(token));

  const createCart = (payload, token) => apiWrapper.post('v2/cart/create', payload, headerWithToken(token));

  const applyCoupon = (token, cartId, friendCode) => apiWrapper.put('v2/cart/apply_coupon', { cart_id: cartId, code: friendCode }, headerWithToken(token));

  const removeCoupon = (token, cartId, friendCode) => apiWrapper.put('v2/cart/remove_coupon', { cart_id: cartId, code: friendCode }, headerWithToken(token));

  const removeAllCoupon = (token, cartId) => apiWrapper.put('v2/cart/remove_all_coupon', { cart_id: cartId }, headerWithToken(token));

  const getPdpNotification = (token, data) => apiWrapper.get('/partial-config/detail/NOTIF_PDP', data, headerWithToken(token));

  const getHomepageBanner = (token, data) => apiWrapper.get('/partial-config/detail/KODE_SAHABAT', data, headerWithToken(token));

  // const updateExpedition = (token, cartId, brandownerId, type) => {
  //   return apiWrapper.put('/cart/update_shipping', { cart_id: cartId, brandowner_id: brandownerId, delivery_type: type }, headerWithToken(token))
  // }

  const updateExpedition = (token, cart_id, brandownerId, type) => apiWrapper.put('v2/cart/update_shipping', { cart_id, brandowner_id: brandownerId, delivery_type: type }, headerWithToken(token));

  const refreshShippingOption = (token, cartId) => apiWrapper.get(`v2/cart/${cartId}?reload_shipping=1`, {}, headerWithToken(token));

  const calculateShippingCost = (token, subdistrict, weight) => apiWrapper.post('v2/cart/shipping_rates', { subdistrict, weight }, headerWithToken(token));

  // Misc
  const getProvinces = (token) => apiWrapper.get('/misc/provinces', {}, headerWithToken(token));
  const getCities = (provinceId, token) => apiWrapper.get(`/misc/cities/${provinceId}`, {}, headerWithToken(token));
  const getDistricts = (cityId, token) => apiWrapper.get(`/misc/districts/${cityId}`, {}, headerWithToken(token));
  const getBankTransferList = (token) => apiWrapper.get('/misc/bank_transfer_list', {}, headerWithToken(token));

  // Payment
  const getPaymentList = (token) => apiWrapper.get('v2/payment/list', {}, headerWithToken(token));

  const getPaymentListWithPayGroup = (group, token) => apiWrapper.get(`v2/payment/list?payment_group=${group}`, {}, headerWithToken(token));

  const submitPayment = (data, token) => apiWrapper.put('v2/payment', { ...data }, headerWithToken(token));

  const changePayment = (data, token) => apiWrapper.post('/agent/orders/change_payment', { ...data }, headerWithToken(token));

  const updatePayment = (data, token) => apiWrapper.put('/v2/cart/update_payment', { ...data }, headerWithToken(token));

  const setMargin = (data, token) => apiWrapper.put('/v2/cart/update_margin', { ...data }, headerWithToken(token));

  const submitPaymentWallet = (data, token) => apiWrapper.post('/v2/payment/create-payment', { ...data }, headerWithToken(token));

  const salesOrderAll = (data, page, token) => apiWrapper.get(`/agent/all_orders_status?status=${data}&page=${page}`, {}, headerWithToken(token));

  // Sales Order
  // const salesOrderList = (dartoken) => {
  //   return apiWrapper.get(`/agent/orders`, {}, headerWithToken(token))
  // }

  const salesOrderList = (data, token) => apiWrapper.get(`/agent/order_by_status?status=${data}`, {}, headerWithToken(token));

  const salesOrderCount = (data, token) => apiWrapper.get(`/agent/total_order_by_status?status=${data}`, {}, headerWithToken(token));

  const getSalesOrderItemList = (orderNo, token) => apiWrapper.get(`/agent/orders/list?sales_order_no=${orderNo}`, {}, headerWithToken(token));

  const getSalesOrderFull = (orderNo, token) => apiWrapper.get(`/agent/orders/full/${orderNo}`, {}, headerWithToken(token));

  const getInvoiceBrand = (noInvoice, token) => apiWrapper.get(`/agent/invoice/detail/${noInvoice}`, {}, headerWithToken(token));

  const completeOrder = (noInvoice, token) => apiWrapper.post('/agent/invoice/complete', { sales_invoice_no: noInvoice }, headerWithToken(token));

  const getMailboxList = (token, data) => apiWrapper.get(`/notifications/list?limit=${data.limit}&page=${data.page}`, {}, headerWithToken(token));

  const mailboxRead = (notification_id, token) => apiWrapper.post('/notifications/read', { notification_id }, headerWithToken(token));

  const mailboxUnread = (token) => apiWrapper.get('/notifications/unread', {}, headerWithToken(token));

  const mailboxDelete = (notification_id, token) => apiWrapper.post('/notifications/delete', { notification_id }, headerWithToken(token));

  // Triplogic
  const getTriplogicPrice = (ori, dest, weight, token) => apiWrapper.get(`/triplogic/price?ori=${ori}&dest=${dest}&weight=${weight}`,
    {}, headerWithToken(token));

  const getTriplogicDetail = (trackingId, token) => apiWrapper.get(`/triplogic/tracking/${trackingId}`,
    {}, headerWithToken(token));

  // Social Share
  const socialShare = (data, token) => apiWrapper.post('/social_share', data, headerWithToken(token));
  const getSocialShare = (slug) => socialAPI.get(`/detail/${slug}`, {}, jsonHeader());

  // Blocker
  const blockerCheck = (token) => apiWrapper.get('/agent/check_blocker_page', {}, headerWithToken(token));
  const blockerAdd = (data, token) => apiWrapper.post('/agent/add_blocker_page', data, headerWithToken(token));

  // Wooniversity
  const getWooniversity = (token) => apiWrapper.get('/wooniversity', {}, headerWithToken(token));

  const getDetailWooniversity = (token) => apiWrapper.get('/partial-config/detail/WOONIVERSITY_DESC', {}, headerWithToken(token));

  // Collection
  const getCollection = (token) => apiWrapper.get('/collection/vertical', {}, headerWithToken(token));

  const getMostSharedCollection = (token) => apiWrapper.get('/collection/share/list', {}, headerWithToken(token));

  const getDetailCollection = (data, token) => apiWrapper.get(`/collection/detail/${data}`, {}, headerWithToken(token));

  const bannerShowCollection = (token) => apiWrapper.get('/partial-config/detail/IMG_COLLECTION_SHOW_ALL', {}, headerWithToken(token));

  const dynamicPage = (slug, token) => apiWrapper.get(`/dynamic-page/detail/${slug}`, {}, headerWithToken(token));

  // catalog pdp

  const checkProductCatalog = (token, data) => apiWrapper.get(`/catalog/check/${data}`, {}, headerWithToken(token));

  const getCatalog = (token, data) => apiWrapper.get(`/catalog/list?limit=${data}`, {}, headerWithToken(token));

  const createCatalog = (token, data) => apiWrapper.post('/catalog/create', data, headerWithToken(token));

  const updateCatalog = (token, id, data) => apiWrapper.post(`/catalog/update/${id}`, data, headerWithToken(token));

  const deleteCatalog = (token, data) => apiWrapper.post(`/catalog/delete/${data}`, {}, headerWithToken(token));

  const createProductCatalog = (token, id, data) => apiWrapper.post(`/catalog/product/${id}/update`, data, headerWithToken(token));

  const deleteProductCatalog = (token, id, data) => apiWrapper.post(`/catalog/product/${id}/remove`, data, headerWithToken(token));

  // store
  const detailStore = (token) => apiWrapper.get('/store/detail', {}, headerWithToken(token));

  /**
   * Solving case all store removed in dev
   */
  const masterApi = apisauce.create({
    baseURL: 'https://wapi.woobiz.id/',
    timeout: 10000,
  });
  const detailStoreMaster = (token) => masterApi.get('/store/detail', {}, headerWithToken(token));

  const detailCatalog = (token, id) => apiWrapper.get(`/catalog/detail/${id}`, {}, headerWithToken(token));

  const catalogproductlist = (token, id, data) => apiWrapper.get(`/catalog/product/${id}/list`, data, headerWithToken(token));

  const catalogproductupdate = (token, id, products) => apiWrapper.post(`/catalog/product/${id}/update`, { products }, headerWithToken(token));

  const catalogproductdelete = (token, id, products) => apiWrapper.post(`/catalog/product/${id}/remove`, products, headerWithToken(token));

  // const CatalogProductListCommand = (id: number, body: PaginatedRequest = {}) => ApiCommand<PaginatedRequest, PaginatedResponse<ProductResponse>>(`/catalog/product/${id}/list`, 'get', body, true);

  // const CatalogProductUpdateCommand = (id: number, products: { sku: string; margin: number; }[]) => ApiCommand<{}, {}>(`/catalog/product/${id}/update`, 'post', { products }, true);

  // const CatalogProductDeleteCommand = (id: number, products: string[]) => ApiCommand<{}, {}>(`/catalog/product/${id}/remove`, 'post', { products }, true);

  const checkDomain = (token, name) => apiWrapper.get(`/store/check-available-domain/${name}`, {}, headerWithToken(token));

  const activateStore = (token, name, domain) => apiWrapper.post('/store/activation', { name, domain }, headerWithToken(token));

  const customerList = (token, take, orderBy) => apiWrapper.get('/catalog/order/list', { order_by: orderBy, limit: take }, headerWithToken(token));
  const customerDetail = (token, phone) => apiWrapper.get('/catalog/order/detail', { phone }, headerWithToken(token));
  const templateList = (token) => apiWrapper.get('/store/themes', {}, headerWithToken(token));

  const updateStore = (token, data) => apiWrapper.post('/store/update', data, headerWithToken(token));

  // Brand
  const getBannerBrand = (brandId, token) => apiWrapper.get(`banner/brand/${brandId}`, {}, headerWithToken(token));

  const getBrandDetail = (brandId, token) => apiWrapper.get(`/brand/detail/${brandId}`, {}, headerWithToken(token));

  const getBrandBestSeller = (brandId, token) => apiWrapper.get(`search/product/brand/bestseller?brand=${brandId}`, {}, headerWithToken(token));

  const getCouponListBrand = (brandID, token) => apiWrapper.get(`coupon/list?brand=${brandID}`, {}, headerWithToken(token));

  // GPS

  const getCheckUserGPS = (phone) => apiWrapper.get(`/front/agent/check-user-gps/${phone}`, {}, jsonHeader());

  const getProductGPS = (data) => apiWrapper.get('/public/search/product', data, jsonHeader());

  const getAnnoucement = () => apiWrapper.get('/public/mobile/info-homepage', {}, jsonHeader());

  const getDataGPS = () => apiWrapper.get('/public/static/gps', {}, jsonHeader());
  //   const CustomerListCommand = (body: PaginatedRequest & { order_by?: 1 | 2 | 3 | number } = {}) => ApiCommand<PaginatedRequest, PaginatedResponse<CustomerResponse>>('/catalog/order/list', 'get', body, true);
  // const CustomerDetailCommand = (phoneNumber: string) => ApiCommand<{ phone: string }, CustomerResponse>('/catalog/order/detail', 'get', { phone: phoneNumber }, true);

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    otpAttempt,
    checkPhone,
    checkReferral,
    register,
    login,
    verifyOtp,
    getGratisOngkir,
    searchProduct,
    getProductFilterPrice,
    getProductMostShared,
    getPopularSearch,
    getProductDetail,
    getProductStock,
    getCartDetail,
    // updateCartItem,
    // updateCart,
    // createCart,
    userCheckCompleteness,
    getUserAddressList,
    androidVersion,
    tokenValidity,
    updateUserAddress,
    addUserAddress,
    removeUserAddress,
    updateImageProfile,
    updateUserProfile,
    getProvinces,
    getCities,
    getDistricts,
    getUserBankAccountList,
    getBankTransferList,
    addBankAccount,
    updateBankAccount,
    getPaymentList,
    getPaymentListWithPayGroup,
    submitPayment,
    changePayment,
    getUserProfile,
    getUserBonus,
    getBonusHistory,
    getBonusLevel,
    getBonusTerms,
    getBonusTransaction,
    getUserCommision,
    getProductSpecial,
    getProductSelection,
    getSearchSuggestion,
    getProductRecommendation,
    getBannerCatalog,
    getProductDetailCommission,
    getProductDetailView,
    getProductCategory,
    getPromoBannerOnScroll,
    removeCartItem,
    createCart,
    changeAddressIsDefault,
    getProductSetExplanation,
    getProductDetailCommissionTierList,
    salesOrderAll,
    salesOrderList,
    getSalesOrderItemList,
    getSalesOrderFull,
    getInvoiceBrand,
    completeOrder,
    getUserProfileView,
    getTriplogicPrice,
    getTriplogicDetail,
    getUserCommissionHistory,
    getUserCommissionDebitHistory,
    getUserCommissionCreditHistory,
    activeFriendCode,
    appliedFriendCode,
    applyCoupon,
    removeCoupon,
    removeAllCoupon,
    getPdpNotification,
    getHomepageBanner,
    updateExpedition,
    refreshShippingOption,
    calculateShippingCost,
    socialShare,
    getSocialShare,
    blockerCheck,
    blockerAdd,
    getUserFavorite,
    ajukanProduk,
    getKYC,
    addKYC,
    requestWithdraw,
    getAllCategory,
    getSelectedCategory,
    getProductBrand,
    getDisplayCategory,
    getWooniversity,
    getDetailWooniversity,
    getCollection,
    getMostSharedCollection,
    getDetailCollection,
    bannerShowCollection,
    getProductByCategory,
    updateCart,
    salesOrderCount,
    // temporary cart endpoint
    getTempCartDetail,
    updateTempCartItem,
    removeTempCartItem,

    updatePayment,
    setMargin,
    submitPaymentWallet,
    getSahabatBrand,

    // Inbox
    getMailboxList,
    mailboxRead,
    mailboxUnread,
    mailboxDelete,
    dynamicPage,

    // Catalog
    checkProductCatalog,
    getCatalog,
    createCatalog,
    updateCatalog,
    deleteCatalog,
    createProductCatalog,
    deleteProductCatalog,
    detailStore,
    checkDomain,
    activateStore,
    customerList,
    customerDetail,
    templateList,
    updateStore,
    detailCatalog,
    catalogproductlist,
    catalogproductupdate,
    catalogproductdelete,
    // Brand Page API
    getBrandDetail,
    getBannerBrand,
    getBrandBestSeller,
    getCouponListBrand,
    getCheckUserGPS,
    getProductGPS,
    detailStoreMaster,
    // Announcement
    getAnnoucement,
    getDataGPS,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
