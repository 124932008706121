import immutablePersistenceTransform, { reducedStateHydrate } from './ImmutablePersistenceTransform'
import localforage from 'localforage'

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '4',
  storeConfig: {
    key: 'woobiz',
    storage: localforage,
    // blacklist: [], // reducer keys that you do NOT want stored to persistence here
    whitelist: ['userState'], // Optionally, just specify the keys you DO want stored to
    // persistence. An empty array means 'don't store any reducers' -> infinitered/ignite#409
    transforms: [immutablePersistenceTransform],
    // redux-persist ^5 need implement manual reconciler to make immutable state work
    stateReconciler: reducedStateHydrate
  }
}

export default REDUX_PERSIST
