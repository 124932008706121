// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  provinceRequest: null,
  cityRequest: ['provinceId'],
  districtRequest: ['cityId'],
  provinceSuccess: ['payload'],
  citySuccess: ['payload'],
  districtSuccess: ['payload'],
  provinceFailure: ['err'],
  cityFailure: ['err'],
  districtFailure: ['err']
})

export const MasterAddressTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  provinces: [],
  cities: [],
  districts: [],
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const provinceRequest = (state) =>
  state.merge({ fetching: true, cities: [], districts: [], success: false, err: null })
export const cityRequest = (state) =>
  state.merge({ fetching: true, districts: [], success: false, err: null })
export const districtRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const provinceSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    provinces: payload,
    success: true
  })
export const citySuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    cities: payload,
    success: true
  })
export const districtSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    districts: payload,
    success: true
  })

export const provinceFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

export const cityFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })
export const districtFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROVINCE_REQUEST]: provinceRequest,
  [Types.CITY_REQUEST]: cityRequest,
  [Types.DISTRICT_REQUEST]: districtRequest,
  [Types.PROVINCE_SUCCESS]: provinceSuccess,
  [Types.CITY_SUCCESS]: citySuccess,
  [Types.DISTRICT_SUCCESS]: districtSuccess,
  [Types.PROVINCE_FAILURE]: provinceFailure,
  [Types.CITY_FAILURE]: cityFailure,
  [Types.DISTRICT_FAILURE]: districtFailure
})
