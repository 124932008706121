// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  init: null,
  checkPhoneRequest: ['phoneNumber'],
  checkPhoneSuccess: ['payload'],
  checkPhoneFailure: ['err'],
})

export const CheckPhoneTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false,
    err: null
  })

// CHECK PHONE
export const checkPhoneRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const checkPhoneSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const checkPhoneFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT]: init,
  [Types.CHECK_PHONE_REQUEST]: checkPhoneRequest,
  [Types.CHECK_PHONE_SUCCESS]: checkPhoneSuccess,
  [Types.CHECK_PHONE_FAILURE]: checkPhoneFailure,
})
