import styled from 'styled-components'

export const TextExtraSmall = styled.span`
  font-size: 10px;
  color: ${props => props.color};
  font-weight: ${props => props.boldest ? '700' : '500'};
`

export const TextSmall = styled.span`
  font-size: ${props => props.increase ? '14px' : '12px'};
  color: ${props => props.color};
  font-weight: ${props => props.boldest ? '700' : '600'};
`

export const TextMedium = styled.span`
  font-size: 16px;
  color: ${props => props.color};
  font-weight: ${props => props.boldest ? '700' : '600'};
`

export const TextLarge = styled.span`
  font-size: 18px;
  font-weight: ${props => props.boldest ? '700' : '400'};
  color: ${props => props.color};
`

export const TextExtraLarge = styled.span`
  font-size: 26px;
  font-weight: ${props => props.boldest ? '700' : '400'};
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: ${props => props.boldest ? 'bold' : '400'};
`

export const MitraName = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: #633c97;
  margin: 0;
`
export const HeaderTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`
