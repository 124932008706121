import { fork, all } from 'redux-saga/effects'
import { tempCartSaga } from './cart/tempCartSaga'
import { productNotificationSaga } from './product/notificationSaga'
import { productByCategorySaga } from './Category/categoryProductSaga';
import { transactionListSaga } from './transaction/listTransactionSaga';

export default function* root() {
    yield all([
        fork(productByCategorySaga),
        fork(tempCartSaga),
        fork(productNotificationSaga),
        fork(productNotificationSaga),
        fork(transactionListSaga)
    ]);
}
