// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userKYCRequest: null,
  userKYCSuccess: ['payload'],
  userKYCFailure: ['err'],
  userKYCAddRequest: ['data'],
  userKYCAddSuccess: ['payload'],
  userKYCAddFailure: ['err']
})

export const UserKYCTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch KYC
export const userKYCRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userKYCSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userKYCFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// KYC Add
export const userKYCAddRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userKYCAddSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userKYCAddFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_KYC_REQUEST]: userKYCRequest,
  [Types.USER_KYC_SUCCESS]: userKYCSuccess,
  [Types.USER_KYC_FAILURE]: userKYCFailure,
  [Types.USER_KYC_ADD_REQUEST]: userKYCAddRequest,
  [Types.USER_KYC_ADD_SUCCESS]: userKYCAddSuccess,
  [Types.USER_KYC_ADD_FAILURE]: userKYCAddFailure
})
