import React, { useState } from "react";
import "./styles.scss";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
// Api
import { useQuery } from "react-query";

import WToolbar from "../../shared/components/w-toolbar/w-toolbar";
import BrandCard from "./components/BrandCard/BrandCard";
import ProductListBrand from "./components/ProductListBrand/ProductListBrand";
import Filter from "./components/Filters/Filter";
import { isEmpty } from "../../Utils";
import BestSellingProduct from "./components/BestSellingProduct/BestSellingProduct";
import BrandBanner from "./components/BrandBanner/BrandBanner";
import { fetchBrandProduct } from "./api/brand-page.api";
import { useRequestCatalogListRQ } from "../../React-Query/catalog-api/catalog.api";

function BrandPage() {
  const history = useHistory();
  const queryParam = queryString.parse(history.location.search);

  const [filter, setFilter] = useState(false);
  const [sort, setSort] = useState(false);
  const [layout, setLayout] = useState(false); // Default set to list

  const [products, setProducts] = useState([]);
  const [priceMax, setPriceMax] = useState(null);
  const [priceMin, setPriceMin] = useState(null);

  const [page, setPage] = useState(1);
  const [ob, setOb] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const URLParams = new URLSearchParams(window.location.search);

  const filterData = {
    brand: queryParam.id,
    ob: !isEmpty(queryParam.ob) ? queryParam.ob : ob,
    pmax: !isEmpty(queryParam.pmax) ? queryParam.pmax : priceMax,
    pmin: !isEmpty(queryParam.pmin) ? queryParam.pmin : priceMin,
  };

  const { isFetching, data } = useQuery(
    ["product-brand", { filterData }],
    () =>
      fetchBrandProduct(filterData).then((res) => {
        setProducts(res.data.data.list);
        return res.data.data;
      }),
    { refetchOnWindowFocus: false }
  );
  useRequestCatalogListRQ();

  // This one is for infinite scroll
  const fetchMoreData = () => {
    // if total page only 1, we dont need fetch another pagination

    if (!hasMore || data.total_page === 1) return;

    const newpage = page + 1;
    const filterParam = {
      brand: queryParam.id,
      page: newpage,
      ob: !isEmpty(queryParam.ob) ? queryParam.ob : ob,
      pmax: !isEmpty(priceMax) ? priceMax : queryParam.pmax,
      pmin: !isEmpty(priceMin) ? priceMin : queryParam.pmin,
    };
    setPage(newpage);
    fetchBrandProduct(filterParam).then((res) => {
      if (newpage >= data.total_page) {
        setHasMore(false);
        setPage(1);
      }
      setProducts([...products, ...res.data.data.list]);
    });
  };

  const doFilter = () => {
    setProducts([]);
    setPage(1);

    const dataFilter = {
      brand: queryParam.id,
      ob: !isEmpty(queryParam.ob) ? queryParam.ob : ob,
      pmax: priceMax,
      pmin: priceMin,
    };
    // Set new or modify existing parameter value.
    URLParams.set("filter", filter);
    URLParams.set("pmax", priceMax);
    URLParams.set("pmin", priceMin);

    // Replace current querystring with the new one.
    window.history.replaceState(null, null, `?${URLParams}`);

    fetchBrandProduct(dataFilter)
      .then((res) => {
        setProducts(res.data.data.list);
        setHasMore(true);
        setFilter(!filter);
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 180)
      );
  };

  const doSort = () => {
    setProducts([]); // Set arry to empty first, to prevent duplicate data
    /**
     * We need to set page to 1 after infinite scroll finish,
     * so the page start from 1
     * */
    setPage(1);
    // Set new or modify existing parameter value.
    URLParams.set("sort", sort);
    URLParams.set("ob", ob);

    // Replace current querystring with the new one.
    window.history.replaceState(null, null, `?${URLParams}`);

    const dataSort = {
      brand: queryParam.id,
      ob,
      pmax: !isEmpty(queryParam.pmax) ? queryParam.pmax : priceMax,
      pmin: !isEmpty(queryParam.pmin) ? queryParam.pmin : priceMin,
    };

    fetchBrandProduct(dataSort)
      .then((res) => {
        setProducts(res.data.data.list); // After array is empty, we put the newest data
        setHasMore(true); // And then set HasMore to true for infinite scroll
        setSort(!sort); // Toggle bottom sheet
      })
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 180)
      );
  };

  const resetFilter = () => {
    URLParams.delete("filter");
    URLParams.delete("pmax");
    URLParams.delete("pmin");

    window.history.replaceState(null, null, `?${URLParams}`);
    window.location.reload();
  };

  const resetSort = () => {
    URLParams.delete("sort");
    URLParams.delete("ob");

    window.history.replaceState(null, null, `?${URLParams}`);
    window.location.reload();
  };

  const changeLayout = () => {
    setLayout(!layout);
  };

  return (
    <div className="background-white brand-page">
      <WToolbar showSearch fixed backButton showChat showHome />
      <BrandCard brandID={queryParam.id} />
      <BrandBanner brandID={queryParam.id} />
      <BestSellingProduct brandID={queryParam.id} />
      <ProductListBrand
        layout={layout}
        hasMore={hasMore}
        totalPage={data}
        isFetching={isFetching}
        products={products}
        fetchMoreData={fetchMoreData}
      />
      <Filter
        layout={layout}
        products={products}
        changeLayout={changeLayout}
        doFilter={doFilter}
        doSort={doSort}
        setFilter={setFilter}
        filter={filter}
        setSort={setSort}
        sort={sort}
        setOb={setOb}
        setPriceMax={setPriceMax}
        setPriceMin={setPriceMin}
        resetFilter={resetFilter}
        resetSort={resetSort}
      />
    </div>
  );
}

export default BrandPage;
