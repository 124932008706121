import { ColorTypes } from 'shared/theme';
import styled from 'styled-components';

const StyledText = styled.div<{
  color?: ColorTypes;
  customColor?: string;
  width?: string;
  size?: number;
  decoration?: string;
  weight?: string;
  block?: boolean;
  overflowWrap?: string;
  align?: string;
  marginBottom?: string;
  capitalize?: boolean;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  cursor?: string;
  fontStyle? :string;
}>`
  color: ${(props) => (props.customColor ? `${props.customColor}` : props.theme.colors[props.color ?? '']?.background)};
  font-size: ${(props) => (props.size ? `${props.size}px` : '12px')};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  text-decoration: ${(props) => (props.decoration ? props.decoration : null)};
  font-family: 'Nunito', sans-serif;
  text-align: ${(props) => (props.align ? props.align : null)};
  margin-bottom: ${(props) => (props.marginBottom && props.marginBottom)};
  margin-top: ${(props) => (props.marginTop && props.marginTop)};
  margin-left: ${(props) => (props.marginLeft && props.marginLeft)};
  margin-right: ${(props) => (props.marginRight && props.marginRight)};
  font-style: ${(props) => (props.fontStyle && props.fontStyle)};
  cursor: ${(props) => (props.cursor && props.cursor)};
  width: ${(props) => (props.width && props.width)};
  text-transform: ${(props) => (props.capitalize && 'capitalize')};
  overflow-wrap: ${(props) => (props.overflowWrap && props.overflowWrap)};
`;

export { StyledText };
