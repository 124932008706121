import config from 'legacy/config';
import { isArray, isEmpty } from 'lodash';

export default (
  url: string,
  queryParams: any = {},
  customDomain?: string,
): string => {
  let qp = Object.keys(queryParams)
    .map((key) => {
      if (isArray(queryParams[key])) {
        return (queryParams[key] as Array<string>).map((val) => `${key}[]=${val}`).join('&');
      }

      return `${key}=${queryParams[key]}`;
    })
    .join('&');

  if (!isEmpty(qp)) {
    qp = `?${qp}`;
  }

  if (customDomain) {
    return `${customDomain}${url}${qp}`;
  }
  return `${config.apiURL}${url}${qp}`;
};
