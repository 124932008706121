/* eslint-disable react/button-has-type */
// React
import React, { Component, createRef } from 'react';

// Router
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import Slider from 'react-slick';
import dot from 'dot-object';
import {
  Rate, Icon, Badge, Spin, Modal, Row, Col, Input,
} from 'antd';
import NumberFormat from 'react-currency-format';
import { Heading, Text } from 'shared/components/typhography';
import Padded from 'shared/components/padded';
import ProductDetailActions from '../../../Redux/ProductDetailRedux';
import ProductDetailCommissionTierListActions from '../../../Redux/ProductDetailCommissionTierListRedux';
import ProductNotificationAction from '../../../Redux/Reducers/product/notificationReducer';
import UserBonusActions from '../../../Redux/UserBonusRedux';
import UserBonusLevelActions from '../../../Redux/UserBonusLevelRedux';
import checkProductCatalogActions from '../../../Redux/Catalog/CheckProductCatalogRedux';
import catalogCreateWithProductActions from '../../../Redux/Catalog/CatalogCreateWithProductRedux';
import catalogActions from '../../../Redux/Catalog/CatalogRedux';
import storeActions from '../../../Redux/StoreRedux';

// Utils

// Antd

// Component
import DefaultLayout from '../../../Layouts/DefaultLayout';
import {
  getUrlParameter,
  NumberWithCommas,
  detectFileType,
  getSafely,
  UpperCase,
} from '../../../Utils';
import {
  Container,
  TextSmall,
  TextMedium,
  Block,
  ButtonPrimaryShadow,
  ButtonDisable,
  ButtonPrimary,
  ButtonPrimaryOutline,
} from '../../../Components/Styled';
import config from '../../../config';
import emptyCatalog2 from '../../../static/images/w-empty.svg';
import checkImage from '../../../static/images/logo/check-ok.png';
import checkWrongImage from '../../../static/images/logo/check-wrong.png';
import cartImage from '../../../static/images/bottom-navigation/active/w-shopping-cart.svg';
import Play from '../../../static/images/navigations/play-button.svg';
import More from '../../../static/images/More-profile.svg';
import ProductNotFoundImage from '../../../static/images/illustrations/search-not-found.svg';
import bookmarkActive from '../../../static/images/w-bookmark-active.svg';
import bookmarkInactive from '../../../static/images/w-bookmark-inactive.svg';
import bookmark from '../../../static/images/w-bookmark-title.svg';
import emptyCatalog from '../../../static/images/w-empty-collections.svg';
import BottomSheet from '../../BottomSheet';
import BackTop from '../../../Components/BackTop';
import '../../../shared/components/react-image-lightbox/style.css';

// Stylesheet
import './ProductDetailPage.scss';
import WToolbar from '../../../shared/components/w-toolbar/w-toolbar';
import SocialShare from '../../../shared/components/social-share/social-share';
import Lightbox from '../../../shared/components/react-image-lightbox/src/index';
// import SingleCategory from '../../Category/SingleCategory/SingleCategory'
import RelatedCategory from '../../Category/SingleCategory/RelatedCategory';
// import SearchBarViewHeader from '../../../Components/SearchBarViewHeader'

import TourCatalog from '../../../shared/components/tour-catalog';
import NewCatalogDialog from '../../Catalogs/NewCatalogDialog';
import CatalogNotActivatedDrawer from '../../../Components/product-card/catalog-not-activated-drawer';
import { Flex } from 'shared/components/flex';
import toast from 'react-hot-toast';
import Button from 'shared/components/forms/button';
import { OrderDrawerVariant } from './_components/OrderDrawerVariant';
import { OrderDrawer } from './_components/OrderDrawer';
import { cartTemporaryDetailQuery } from 'shared/apis/queries/cart.query';
import { queryClient } from 'App';
import Footer from './_components/BadgeCount';
import BadgeCount from './_components/BadgeCount';

class ProductDetailPage extends Component {
  /**
   * Component Constructor
   */
  constructor(props) {
    super(props);

    this.state = {
      userBonusState: undefined,
      userBonusLevelState: undefined,
      catalogState: undefined,
      catalogCreateWithProductState: undefined,
      storeState: undefined,
      checkCatalogState: true,
      productDetailState: {},
      visibleDescription: false,
      visibleCommission: false,
      visibleCatalog: false,
      checkProductCatalogState: undefined,
      isAddProductCatalog: false,
      isWasModifyProduct: false,
      isWasRemoveCatalog: false,
      isWasAddCatalog: false,
      isAddCatalog: false,
      isAddCatalogWithProduct: false,
      visibleAddPrice: false,
      checkingCatalog: false,
      onceCheckingCatalog: false,
      visibleChooseCatalog: false,
      modalAddCatalog: false,
      modalRemoveProduct: false,
      catalogSKU: '',
      drawerVariant: false,
      drawerNonVariant: false,
      selectedCatalog: false,
      nameCatalog: '',
      descriptionCatalog: '',
      modalBonus: false,
      priceSKU: 0,
      formatSKU: '',
      pieceSKU: 0,
      setPrice: 0,
      calculateMargin: 0,
      alertSetPrice: '=',
      wasAddProductCatalog: false,
      visibleStoreNotActivated: false,
      sliders: {
        mainSliderSwipe: true,
        mainSlider: null,
        navSlider: null,
      },
      galleryLightbox: {
        visible: false,
        index: 0,
      },
      product: {
        exist: false,
        images: [],
      },
      catalog: {
        choose: false,
        sku: '',
      },
      cartItem: 0,

      /**
       * Bottom Drawer visible state
       */
      showDrawers: {
        singleUnitOrder: false,
        setUnitOrder: false,
        findLocation: false,
        changeProductBrand: false,
      },
      drawerData: {
        changeProductBrand: {
          oldBrandName: null,
          newBrandName: null,
          callback: () => {},
        },
      },
      dataCheck: [],
      /**
       * Show back top button when scroll reach
       */
      scrollTop: 400,
      visibleCreateCatalog: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  /**
   * Toggle bottom drawer state
   */
  setDrawerState = (key, value) => {
    const state = { ...this.state };
    key = `showDrawers.${key}`;
    const oldValue = dot.pick(key, state);
    if (!_.isEqual(oldValue, value)) {
      const finalState = dot.str(key, value, {});
      this.setState(finalState);
    }
  };

  /**
   * getDerivedStateFromProps
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {};

    // used for mapping product detail state, just to make sure product is available and easy to render
    const mappingProductState = (props) => {
      let product = {};

      // to avoid error when initiate fetch product
      if (_.isEmpty(props.data)) {
        return (product = {});
      }

      if (props.data.total === 0) {
        product = { ...product, exist: false };
        return product;
      }

      product = { ...product, exist: true };

      // extract product images
      const selectedProduct = props.data;

      const parsedProduct = productManualMapping(selectedProduct);
      if (parsedProduct != null) {
        product = {
          ...product,
          ...parsedProduct,
        };
      }

      return product;
    };

    if (
      !_.isEqual(nextProps.productDetailState, prevState.productDetailState)
      && !_.isEmpty(nextProps.productDetailState)
    ) {
      updated = {
        ...updated,
        productDetailState: nextProps.productDetailState,
        product: {
          ...mappingProductState(nextProps.productDetailState),
        },
      };
    }

    // commission list
    if (
      !_.isEqual(
        nextProps.productDetailCommissionTierListState,
        prevState.productDetailCommissionTierListState,
      )
      && !_.isEmpty(nextProps.productDetailCommissionTierListState)
    ) {
      let commissionSummary = '';
      const last = nextProps.productDetailCommissionTierListState.data.length - 1;
      if (last > 0) {
        const minCommission = nextProps.productDetailCommissionTierListState?.data[0]?.commission;
        const maxCommission = nextProps.productDetailCommissionTierListState?.data[last]
          ?.commission;
        commissionSummary = `Rp ${NumberWithCommas(
          minCommission,
        )} - Rp ${NumberWithCommas(maxCommission)} / Set`;
      }

      return {
        productDetailCommissionTierListState:
          nextProps.productDetailCommissionTierListState,
        commissionSummary,
      };
    }

    if (
      !_.isEqual(nextProps.cartState, prevState.cartState)
      && !_.isEmpty(nextProps.cartState)
    ) {
      let cartItem = _.isEmpty(nextProps.cartState.data)
        ? 0
        : nextProps.cartState.data.items.length;

      if (!_.isEmpty(nextProps.cartState.data)) {
        cartItem = _.flatten(
          nextProps.cartState.data.items.map((o) => o.details),
        ).length;
      }

      updated = {
        ...updated,
        ...{ cartState: nextProps.cartState, cartItem },
      };
    }

    if (
      !_.isEqual(
        nextProps.productNotification,
        prevState.productNotification,
      )
      && !_.isEmpty(nextProps.productNotification)
    ) {
      updated = {
        ...updated,
        ...{ productNotification: nextProps.productNotification },
      };
    }

    if (
      !_.isEqual(nextProps.userBonusState, prevState.userBonusState)
      && !_.isEmpty(nextProps.userBonusState)
    ) {
      updated = { ...updated, userBonusState: nextProps.userBonusState };
    }

    if (
      !_.isEqual(
        nextProps.userBonusLevelState,
        prevState.userBonusLevelState,
      )
      && !_.isEmpty(nextProps.userBonusLevelState)
    ) {
      updated = {
        ...updated,
        userBonusLevelState: nextProps.userBonusLevelState,
      };
    }

    if (
      !_.isEqual(
        nextProps.checkProductCatalogState,
        prevState.checkProductCatalogState,
      )
      && !_.isEmpty(nextProps.checkProductCatalogState)
    ) {
      updated = {
        ...updated,
        checkProductCatalogState: nextProps.checkProductCatalogState,
      };
    }

    if (
      !_.isEqual(nextProps.catalogState, prevState.catalogState)
      && !_.isEmpty(nextProps.catalogState)
    ) {
      updated = { ...updated, catalogState: nextProps.catalogState };
    }

    if (
      !_.isEqual(
        nextProps.catalogCreateWithProductState,
        prevState.catalogCreateWithProductState,
      )
      && !_.isEmpty(nextProps.catalogCreateWithProductState)
    ) {
      updated = {
        ...updated,
        catalogCreateWithProductState: nextProps.catalogCreateWithProductState,
      };
    }

    if (
      !_.isEqual(nextProps.storeState, prevState.storeState)
      && !_.isEmpty(nextProps.storeState)
    ) {
      updated = { ...updated, storeState: nextProps.storeState };
    }

    return updated;
  }

  componentDidMount() {
    const { location } = this.props;
    const { sku } = this.props.match.params;
    if (!_.isUndefined(location)) {
      if (location.pathname === '/product-detail') {
        // Redirect 301 from product-detail into
        // product/:product-sku make product easy to read

        const sku = getUrlParameter('sku');
        this.props.history.replace(`/product/${sku}`);
      } else {
        this.props.fetchProductDetail(sku);
        this.props.userBonusRequest();
        this.props.userBonusLevelRequest();
        this.props.productCommissionTierListRequest(sku);
        this.props.getCatalogRequest(1000);
        this.props.getStoreRequest();
      }
    }
    this.props.productNotificationRequest();
    window.addEventListener('scroll', this.handleScroll);
    if (this.props.history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let trueCheck = true;
    const oldSku = prevProps.match.params.sku;
    const { sku } = this.props.match.params;
    const { catalogSKU, dataCheck } = this.state;
    clearInterval(this.timerWasAddProduct);
    // // detect if there is product sku changes within the same page
    if (!_.isEmpty(oldSku) && !_.isEmpty(sku)) {
      //   //if sku changed we should refetch the product and restore scroll to top
      if (!_.isEqual(oldSku, sku)) {
        if (this.props.history.action === 'PUSH') {
          this.props.fetchProductDetail(sku);
          window.scrollTo(0, 0);
        } else {
          this.props.fetchProductDetail(sku);
        }
      }
    }

    if (
      !_.isEqual(
        getSafely(['product', 'pack', 'sku'], prevState, null),
        getSafely(['product', 'pack', 'sku'], this.state, null),
      )
    ) {
      this.props.productCommissionTierListRequest(
        getSafely(['product', 'pack', 'sku'], this.state, null),
      );
    }
    if (this.state.isAddProductCatalog) {
      this.props.checkProductCatalogRequest(catalogSKU);
      this.setState({
        isWasModifyProduct: true,
        isAddProductCatalog: false,
      });
    }
    if (this.state.isWasModifyProduct) {
      this.timerWasAddProduct = setInterval(
        () => this.setState({
          isWasModifyProduct: false,
        }),
        5000,
      );
    }
    if (this.state.isWasAddCatalog) {
      this.timerWasAddCatalog = setInterval(
        () => this.setState({
          isWasAddCatalog: false,
        }),
        5000,
      );
    }
    if (this.state.isAddCatalog) {
      this.setState({
        isAddCatalog: false,
      });
      this.props.getCatalogRequest(1000);
    }
    if (
      this.state.catalogCreateWithProductState !== undefined
      && this.state.isAddCatalogWithProduct
    ) {
      if (
        !this.state.catalogCreateWithProductState.fetching
        && this.state.catalogCreateWithProductState.success
      ) {
        const payload = {
          products: [
            {
              sku: catalogSKU,
              margin: this.state.priceSKU,
            },
          ],
        };
        this.props.createProductCatalogRequest(
          this.state.catalogCreateWithProductState.data.id,
          payload,
        );
        this.setState({
          isWasModifyProduct: true,
          isAddProductCatalog: true,
          isAddCatalogWithProduct: false,
          checkingCatalog: true,
          checkCatalogState: true,
        });
      }
    }
    if (this.state.checkingCatalog) {
      this.props.checkProductCatalogRequest(catalogSKU);
      this.setState({
        checkingCatalog: false,
      });
    }
    if (this.state.onceCheckingCatalog) {
      if (
        !this.state.checkProductCatalogState.fetching
        && this.state.checkProductCatalogState.success
      ) {
        this.state.catalogState.data.list.map((data, idx) => {
          trueCheck = false;
          this.state.checkProductCatalogState.data.map((data2) => {
            if (data.id === data2.id) {
              trueCheck = true;
            }
          });
          if (trueCheck) {
            dataCheck.push({
              id: data.id,
              checkCatalog: true,
              modify: false,
            });
          } else {
            dataCheck.push({
              id: data.id,
              checkCatalog: false,
              modify: false,
            });
          }
        });
        this.setState({
          onceCheckingCatalog: false,
        });
      }
    }
    if (this.state.checkCatalogState && !_.isEmpty(this.state.catalogState)) {
      if (
        !this.state.catalogState.fetching
        && this.state.catalogState.success
      ) {
        this.props.getCatalogRequest(this.state.catalogState.data.total_data);
        this.setState({
          checkCatalogState: false,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.timerWasAddProduct);
    // So, i think
  }

  /**
   * Handling All Scroll Event on this page
   */
  handleScroll() {
    try {
      const componentheight = document
        .querySelector('.product-action-footer')
        .getBoundingClientRect();
      let bottomScroll = document.body.scrollTop || document.documentElement.scrollTop;
      bottomScroll = bottomScroll + document.body.offsetHeight - componentheight.height;
      const limitPosition = document.querySelector('.cart-footer-limit').getBoundingClientRect()
        .top + window.scrollY;
      const backTop = limitPosition - window.scrollY + 500;

      if (this.state.scrollTop !== backTop) {
        this.setState({
          scrollTop: backTop,
        });
      }

      if (limitPosition > bottomScroll) {
        document
          .querySelector('.product-action-footer')
          .classList.add('sticky');
      } else {
        document
          .querySelector('.product-action-footer')
          .classList.remove('sticky');
      }
    } catch (error) {}
  }

  /**
   * Rendering Product Information
   *
   * This component will render when product fetched
   */
  toggleDescription = () => {
    this.setState({
      visibleDescription: !this.state.visibleDescription,
    });
  };

  toggleCommission = () => {
    this.setState({
      visibleCommission: !this.state.visibleCommission,
    });
  };

  toggleStoreNotActivated = () => {
    this.setState({
      visibleStoreNotActivated: !this.state.visibleStoreNotActivated,
    });
  };

  toggleCatalog = () => {
    if (_.isEmpty(this.props.storeState.data)) {
      this.setState({ visibleStoreNotActivated: true });
      return;
    }

    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
      dataCheck: [],
      onceCheckingCatalog: true,
    });
  };

  toggleRemoveProduct = () => {
    this.setState({
      modalRemoveProduct: !this.state.modalRemoveProduct,
    });
  };

  toggleChooseCatalog = () => {
    if (
      this.state.visibleAddPrice
      && (this.state.catalogState?.data?.list || []).length == 0
    ) {
      this.toggleEmptyCatalog();
      return;
    }

    this.setState({
      visibleAddPrice: !this.state.visibleAddPrice,
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  toggleAddPrice = () => {
    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
      visibleAddPrice: !this.state.visibleAddPrice,
      setPrice: this.state.priceSKU,
      calculateMargin: 0,
    });
  };

  closeChooseCatalog = () => {
    this.setState({
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  toggleModalAddCatalog = () => {
    this.setState({
      visibleEmptyCatalog: false,
      visibleChooseCatalog: false,
      wasAddProductCatalog: false,
      modalAddCatalog: !this.state.modalAddCatalog,
    });
  };

  addCatalog = () => {
    const { nameCatalog, descriptionCatalog } = this.state;
    if (_.isEmpty(nameCatalog)) {
      toast.error('Nama katalog harus diisi!', { id: 'add-catalog-error' });
    } else {
      const payload = {
        name: nameCatalog,
        description: descriptionCatalog,
      };
      this.setState({
        modalAddCatalog: false,
        isAddCatalog: true,
        isAddCatalogWithProduct: true,
        onceCheckingCatalog: true,
        // isWasAddCatalog: true,
      });
      this.props.createCatalogRequest(payload);
    }
  };

  toggleProductCatalog = (idx) => {
    const { dataCheck } = this.state;
    const payload = dataCheck.slice();
    payload[idx] = {
      id: payload[idx]?.id,
      checkCatalog: !payload[idx]?.checkCatalog,
      modify: true,
    };
    this.setState({
      dataCheck: payload,
      wasAddProductCatalog: true,
    });
  };

  addProductCatalog = () => {
    const {
      dataCheck, catalogSKU, priceSKU, calculateMargin,
    } = this.state;
    const payload = {
      products: [
        {
          sku: catalogSKU,
          margin: calculateMargin,
        },
      ],
    };
    dataCheck.map((data, key) => {
      if (data.modify) {
        if (data.checkCatalog) {
          this.props.createProductCatalogRequest(data.id, payload);
        } else {
          this.props.deleteProductCatalogRequest(data.id, catalogSKU);
        }
      }
    });
    this.setState({
      isWasModifyProduct: true,
      checkCatalogState: true,
    });
    this.closeChooseCatalog();
  };

  handleSelectCatalog = (sku, margin, format, piece) => {
    this.setState({
      catalogSKU: sku,
      selectedCatalog: true,
      dataCheck: [],
      onceCheckingCatalog: true,
      priceSKU: margin,
      formatSKU: format,
      pieceSKU: piece,
    });
    this.props.checkProductCatalogRequest(sku);
  };

  handleChangePriceMargin = (e) => {
    const number = /^\d+$/;
    const value = e.split('.').join('');
    if (value.match(number) || value === '') {
      if (value < this.state.priceSKU) {
        this.setState({
          alertSetPrice: '<',
        });
      }
      if (value >= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '>',
        });
      }
      if (value >= this.state.priceSKU && value <= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '=',
        });
      }
      this.setState({
        setPrice: value,
        calculateMargin: value - this.state.priceSKU,
      });
    }
  };

  notifUpdate = () => (
    <div className="container text-center">
      <span
        style={{
          background: '#633c97',
          color: '#ffffff',
          padding: '10px',
          borderRadius: '.25rem',
        }}
      >
        Katalog produk berhasil diperbarui
        <span
          style={{
            background: '#ffffff',
            color: '#633c97',
            padding: '3px 6px',
            marginLeft: '6px',
            borderRadius: '.25rem',
          }}
          onClick={() => this.props.history.push('/store/catalogs')}
        >
          Lihat
        </span>
      </span>
    </div>
  );

  notifAdded = () => (
    <div className="container text-center" style={{ marginTop: '25px' }}>
      <span
        style={{
          background: '#633c97',
          color: '#ffffff',
          padding: '10px',
          borderRadius: '.25rem',
        }}
      >
        Katalog berhasil ditambahkan
      </span>
    </div>
  );

  badgeCount() {
    const badgeCount = [];
    let totalBadge;

    const itemsPayload = getSafely(
      ['data', 'items', 0, 'details'],
      this.props.cartState,
      [],
    );

    itemsPayload.forEach((element) => {
      badgeCount.push(element.qty_ordered); // Push them into one array first

      totalBadge = badgeCount.reduce(
        (firstValue, restValue) => firstValue + restValue,
        0,
      );
    });
    return totalBadge;
  }

  onCreateCatalogSuccessHandler = () => {
    this.props.getCatalogRequest(1000);
    if (this.state.visibleEmptyCatalog) {
      this.setState({
        visibleEmptyCatalog: false,
      });
    }

    if (this.state.visibleChooseCatalog) {
      this.setState({
        visibleChooseCatalog: false,
        isAddProductCatalog: true,
        dataCheck: [],
        onceCheckingCatalog: true,
      });
    }

    this.setState({
      visibleCreateCatalog: false,
    });
  };

  toggleEmptyCatalog = () => {
    this.setState({
      visibleAddPrice: !this.state.visibleAddPrice,
      visibleEmptyCatalog: !this.state.visibleEmptyCatalog,
    });
  };

  _renderProduct = () => {
    const commissionData = this.state.productDetailCommissionTierListState;
    const shareDescription = () => {
      try {
        window.navigator.share({
          text: this.state.product?.description
            .replace(/<br\s*[\/]?>/gi, '\n')
            .replace(/<\/?[^>]+(>|$)/g, ''),
        });
      } catch (error) {
        const el = document.createElement('textarea');
        el.value = this.state.product?.description
          .replace(/<br\s*[\/]?>/gi, '\n')
          .replace(/<\/?[^>]+(>|$)/g, '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.setState({
          visibleDescription: false,
        });

        toast.success('Deskripsi telah disalin', {
          id: 'toast-description',
        });
      }
    };

    return (
      <>
        <div
          style={{
            position: 'fixed',
            bottom: '75px',
            width: '100%',
            zIndex: '2',
          }}
          id="fixed-width"
        >
          {this.state.isWasModifyProduct ? this.notifUpdate() : null}
          {this.state.isWasAddCatalog ? this.notifAdded() : null}
        </div>
        {this._renderProduct__notice()}
        {this._renderProduct__slider()}
        {this._renderProduct__information()}
        {/* <div className='card-divider' /> */}
        {/* {this._renderProduct__review()} */}
        {/* {this._renderProduct__shipping()} */}
        <div className="card-divider" />
        {this._renderProduct__unit()}
        <div className="card-divider" />
        {this._renderProduct__service()}
        <div className="card-divider" />
        {this._renderProduct__description()}
        <div className="card-divider" />
        {this._renderProduct__bonus()}
        <div className="card-divider" />
        {this._renderProduct__actionfooter()}
        <div className="card-divider" />
        {this._renderProduct__recommendation()}
        {/* <BackTop visibilityHeight={this.state.scrollTop} /> */}

        {/* Drawer */}
        {this._renderDrawerVariant()}
        {this._renderDrawerNonVariant()}

        {this._renderProduct__bs_changeProductBrand()}

        {this._renderProduct__lightbox()}
        <TourCatalog run />

        <CatalogNotActivatedDrawer
          visible={this.state.visibleStoreNotActivated}
          close
          toggleVisibility={this.toggleStoreNotActivated}
        />
        <NewCatalogDialog
          onSuccess={this.onCreateCatalogSuccessHandler}
          onClosed={() => this.setState({ visibleCreateCatalog: false })}
          visible={this.state.visibleCreateCatalog}
          sku={this.state.catalogSKU}
          margin={this.state.calculateMargin}
        />
        <BottomSheet
          visible={this.state.visibleDescription}
          toggleVisibility={this.toggleDescription}
        >
          <Flex justify="space-between" align="center">
            <Heading size={16} weight={600}>
              Deskripsi Produk
            </Heading>
            <Button
              size="small"
              variant="outline"
              color="eggBlue"
              onClick={() => {
                shareDescription();
              }}
            >
              Salin Teks
            </Button>
          </Flex>
          <Text marginTop="10px" block>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.product?.description,
              }}
            />
          </Text>
          {/* <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Deskripsi Produk</h5>
            <div
              className="button-copy-desc"
              onClick={() => {
                shareDescription();
              }}
            >
              Salin Teks
            </div>
          </div>
          <div
            className="my-3"
            dangerouslySetInnerHTML={{
              __html: this.state.product?.description,
            }}
          /> */}
          <Button color="eggBlue" block onClick={this.toggleDescription}>
            Tutup
          </Button>
        </BottomSheet>
        <BottomSheet
          visible={this.state.visibleCommission}
          toggleVisibility={this.toggleCommission}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Komisi Beli Paket</h5>
          </div>
          <div className="my-3">
            {!_.isEmpty(commissionData.data)
              ? commissionData.data.map((commission, key) => (
                <div className="row" key={key}>
                  <div className="col commission-title">
                    {commission.commission_type}
                  </div>
                  <div className="col commission-desc">
                    Rp
                    {' '}
                    {NumberWithCommas(commission.commission)}
                    {' '}
                    / Paket
                  </div>
                </div>
              ))
              : null}
          </div>
          {/* <div className='mt-3' dangerouslySetInnerHTML={{ __html: this.state.product?.description }}></div> */}
          <button
            className="btn btn-block buy-now text-white bolder background-color-primary"
            onClick={() => this.toggleCommission()}
          >
            TUTUP
          </button>
        </BottomSheet>
        <BottomSheet
          visible={this.state.visibleCatalog}
          toggleVisibility={this.toggleCatalog}
        >
          <Row type="flex" justify="end" onClick={this.toggleCatalog}>
            <Col>
              <TextSmall color="#633c97">Tutup</TextSmall>
            </Col>
          </Row>
          <Row
            style={{
              padding: '10px 0px',
              borderBottom: '1px solid rgba(204, 204, 204, .3)',
            }}
          >
            <Col>
              <TextMedium className="montserrat">
                Pilih Varian Produk
              </TextMedium>
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <TextSmall increase color="#9b9b9b">
                Pilih varian yang akan kamu masukkan pada katalog
              </TextSmall>
            </Col>
          </Row>
          <Row style={{ padding: '10px 0px' }}>
            <Block>
              <div>
                <Block>
                  <Row type="flex" justify="space-between" align="middle">
                    {/* item.prices[0].selling_price */}
                    {this.state.productDetailState.data.variants.map(
                      (item, idx) => (
                        <div
                          className="tour-variant d-flex justify-content-between py-2 w-100"
                          onClick={() => this.handleSelectCatalog(
                            item.sku,
                            item.prices[0].selling_price,
                            item.satuan,
                            this.state.productDetailState.data.qty_set,
                          )}
                        >
                          <div className="pt-2">
                            <div>{item.option1}</div>
                            <div className="boldest">
                              {`Rp ${NumberWithCommas(
                                item.prices[0].selling_price,
                              )} `}
                              {item.satuan === 'set'
                                ? `/ paket (${this.state.productDetailState.data.qty_set}pcs)`
                                : item.satuan === 'pcs'
                                  ? '/ pc'
                                  : `${item.satuan}`}
                            </div>
                          </div>
                          <div>
                            <label className="align-self-center mr-4">
                              <input
                                type="radio"
                                name="radio-address"
                                className="custom-radio radio-primary"
                                checked={this.state.catalogSKU === item.sku}
                                onChange={() => this.handleSelectCatalog(
                                  item.sku,
                                  item.prices[0].selling_price,
                                  item.satuan,
                                  this.state.productDetailState.data.qty_set,
                                )}
                              />
                              <span className="checkedradio" />
                            </label>
                          </div>
                        </div>
                      ),
                    )}
                  </Row>
                </Block>
              </div>
            </Block>
          </Row>
          {this.state.selectedCatalog ? (
            <button
              className="btn btn-block buy-now text-white bolder background-color-primary tour-next"
              onClick={this.toggleAddPrice}
            >
              Lanjutkan
            </button>
          ) : (
            <ButtonDisable
              style={{ width: '100%', height: '100%', padding: '12px 24px' }}
              disabled
            >
              Lanjutkan
            </ButtonDisable>
          )}
        </BottomSheet>
        <BottomSheet
          visible={this.state.visibleAddPrice}
          toggleVisibility={this.toggleAddPrice}
        >
          <Row type="flex" justify="end" onClick={this.toggleAddPrice}>
            <Col>
              <TextSmall color="#633c97">Kembali</TextSmall>
            </Col>
          </Row>
          <Row
            style={{
              padding: '10px 0px',
              borderBottom: '1px solid rgba(204, 204, 204, .3)',
            }}
          >
            <Col>
              <TextMedium className="montserrat">Ubah Harga</TextMedium>
            </Col>
            <Row style={{ marginTop: '10px' }}>
              <Col span={18}>
                <TextSmall increase boldest color="#9b9b9b">
                  Harga Mitra
                  {' '}
                  {this.state.formatSKU === 'set'
                    ? ` (paket isi ${this.state.pieceSKU}pcs)`
                    : this.state.formatSKU === 'pcs'
                      ? ' (pc)'
                      : `(${this.state.formatSKU})`}
                </TextSmall>
              </Col>
              <Col className="text-right" span={6}>
                <TextSmall>
                  Rp
                  {NumberWithCommas(this.state.priceSKU)}
                </TextSmall>
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <Col span={12}>
                <TextSmall increase boldest color="#9b9b9b">
                  Tentukan Harga Jualmu
                </TextSmall>
              </Col>
              <Col className="text-right" span={12}>
                <TextSmall>
                  Keuntungan Rp
                  {this.state.alertSetPrice === '<'
                  || this.state.alertSetPrice === '>' ? (
                    <span style={{ color: '#f02b01', paddingLeft: '.25rem' }}>
                      {NumberWithCommas(this.state.calculateMargin)}
                    </span>
                    ) : (
                      <span style={{ color: '#48a900', paddingLeft: '.25rem' }}>
                        {NumberWithCommas(this.state.calculateMargin)}
                      </span>
                    )}
                </TextSmall>
              </Col>
            </Row>
            <Col style={{ marginTop: '10px' }}>
              <Row type="flex" align="middle">
                <Col span={2}>
                  <div>
                    <TextSmall increase boldest>
                      Rp
                    </TextSmall>
                  </div>
                </Col>
                <Col span={22}>
                  <NumberFormat
                    className="form-set-margin w-100 border-top-0 border-right-0 border-left-0 no-box-shadow font-size-s"
                    thousandSeparator="."
                    type="tel"
                    // type='tel'
                    maxLength={11}
                    decimalSeparator=","
                    placeholder={this.state.setPrice}
                    value={this.state.setPrice}
                    onChange={(e) => this.handleChangePriceMargin(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            {this.state.alertSetPrice === '<' ? (
              <Col>
                <TextSmall color="#f02b01">
                  Harga jual tidak bisa lebih rendah dari harga Woobiz
                </TextSmall>
              </Col>
            ) : this.state.alertSetPrice === '>' ? (
              <Col>
                <TextSmall color="#f02b01">
                  Harga jual tidak bisa lebih dari Rp.
                  {NumberWithCommas(this.state.priceSKU * 3)}
                </TextSmall>
              </Col>
            ) : null}
          </Row>
          {this.state.alertSetPrice === '=' ? (
            <button
              className="btn btn-block buy-now text-white bolder background-color-primary tour-save-price"
              onClick={this.toggleChooseCatalog}
            >
              Lanjutkan
            </button>
          ) : (
            <ButtonDisable
              className="tour-next"
              style={{ width: '100%', height: '100%', padding: '12px 24px' }}
              disabled
            >
              Lanjutkan
            </ButtonDisable>
          )}
        </BottomSheet>
        <BottomSheet
          visible={this.state.visibleEmptyCatalog}
          toggleVisibility={this.toggleEmptyCatalog}
          close
        >
          <div className="text-center">
            <img src={emptyCatalog2} />
            <div
              style={{
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontWeight: '600',
                marginTop: '18px',
              }}
            >
              Belum Ada Katalog
            </div>
            <div
              style={{
                fontSize: '12px',
                color: '#747474',
                marginBottom: '18px',
              }}
            >
              Belum ada katalog yang kamu buat nih, yuk buat Katalogmu sekarang!
            </div>
            <ButtonPrimary
              onClick={() => this.setState({ visibleCreateCatalog: true })}
            >
              Buat Katalog Baru
            </ButtonPrimary>
          </div>
        </BottomSheet>
        <BottomSheet
          visible={this.state.visibleChooseCatalog}
          toggleVisibility={this.toggleChooseCatalog}
        >
          <Row type="flex" justify="end" onClick={this.toggleChooseCatalog}>
            <Col>
              <TextSmall color="#633c97">Kembali</TextSmall>
            </Col>
          </Row>
          <Row
            style={{
              padding: '10px 0px',
              borderBottom: '1px solid rgba(204, 204, 204, .3)',
            }}
          >
            <Col>
              <TextMedium className="montserrat">Daftar Katalog</TextMedium>
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <TextSmall increase color="#9b9b9b">
                Tentukan dimana kamu akan menyimpan produk ini
              </TextSmall>
            </Col>
          </Row>
          {!this.state.storeState.fetching && this.state.storeState.success ? (
            !this.state.catalogState.fetching
            && this.state.catalogState.success
            && !_.isEmpty(this.state.catalogState.data.list) ? (
              <>
                <div>
                  {this.state.catalogState.data.list.map((item, idx) => (
                    <div
                      className="d-flex justify-content-between align-items-center pt-3 tour-select-catalog"
                      onClick={() => this.toggleProductCatalog(idx)}
                    >
                      <div>
                        <TextMedium bold>{item.name}</TextMedium>
                      </div>
                      <div>
                        {!_.isEmpty(this.state.dataCheck)
                        && this.state?.dataCheck[idx]?.checkCatalog ? (
                          <img src={bookmarkActive} width="20px" alt="..." />
                          ) : (
                            <img src={bookmarkInactive} width="20px" alt="..." />
                          )}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ marginTop: '24px', display: 'flex' }}>
                  <ButtonPrimary
                    onClick={this.addProductCatalog}
                    disabled={!this.state.wasAddProductCatalog}
                    className="save-catalog"
                  >
                    Masukkan
                  </ButtonPrimary>
                  <ButtonPrimaryOutline
                    className="ml-3"
                    onClick={() => this.setState({ visibleCreateCatalog: true })}
                  >
                    Katalog Baru
                  </ButtonPrimaryOutline>
                </div>
              </>
              ) : (
                <div style={{ padding: '0px 24px', marginTop: '24px' }}>
                  <Col className="text-center">
                    <img src={emptyCatalog} width="91" />
                  </Col>
                  <Col className="text-center" style={{ marginBottom: '24px' }}>
                    <TextMedium boldest>Katalog anda masih kosong!</TextMedium>
                  </Col>
                  <button
                    className="btn btn-block buy-now text-white bolder background-color-primary"
                    onClick={() => this.setState({ visibleCreateCatalog: true })}
                  >
                    Buat Katalog Baru
                  </button>
                </div>
              )
          ) : (
            <div style={{ padding: '0px 24px', marginTop: '24px' }}>
              <button
                className="btn btn-block buy-now text-white bolder background-color-primary"
                onClick={() => this.setState({ visibleCreateCatalog: true })}
              >
                Buat Katalog Baru
              </button>
            </div>
          )}
        </BottomSheet>
        <Modal
          closable={false}
          centered
          bodyStyle={{ textAlign: 'center', lineHeight: '15px' }}
          zIndex={2000}
          footer={null}
          width={317}
          visible={this.state.modalAddCatalog}
        >
          <TextMedium>Buat Katalog</TextMedium>
          <div className="d-flex flex-column pt-4 text-left">
            <span className="boldest font-size-xs font-color-tertiary">
              Nama Katalog
            </span>
            <Input
              type="text"
              style={{ background: 'transparent', color: '#7c7c7c' }}
              className="font-size-s border-top-0 border-left-0 border-right-0 border-bottom boldest my-3"
              placeholder="Nama Katalog"
              onChange={(e) => {
                this.setState({ nameCatalog: e.target.value });
              }}
              value={this.state.nameCatalog || ''}
            />
          </div>
          <Row gutter={[12, 12]}>
            <Col className="gutter-row" span={12}>
              <Button
                className="btn-block font-color-primary bolder background-white"
                style={{ height: '36px' }}
                onClick={this.toggleModalAddCatalog}
              >
                BATAL
              </Button>
            </Col>
            <Col className="gutter-row" span={12}>
              <Button>TAMBAH</Button>
            </Col>
          </Row>
        </Modal>
        {!this.state.checkProductCatalogState.fetching
        && this.state.checkProductCatalogState.success
        && !_.isEmpty(this.state.checkProductCatalogState.data) ? (
          <Modal
            closable={false}
            centered
            bodyStyle={{ textAlign: 'center', lineHeight: '15px' }}
            zIndex={2000}
            footer={null}
            width={317}
            visible={this.state.modalRemoveProduct}
          >
            <TextMedium>Hapus Produk Katalog</TextMedium>
            <div className="text-center my-3" style={{ color: '#9b9b9b' }}>
              <span>
                Apakah anda yakin ingin menghapus produk ini dari katalog
                {this.state.checkProductCatalogState.data[0].name}
                {' '}
                ?
              </span>
            </div>
            <Row gutter={[12, 12]}>
              <Col className="gutter-row" span={12}>
                <Button
                  className="btn-block font-color-primary bolder background-white"
                  style={{ height: '36px' }}
                  onClick={this.toggleRemoveProduct}
                >
                  BATAL
                </Button>
              </Col>
              <Col className="gutter-row" span={12}>
                <Button
                  className="btn-block text-white bolder background-color-primary"
                  style={{ height: '36px' }}
                  onClick={() => this.removeProductCatalog(
                    this.state.checkProductCatalogState.data[0].id,
                  )}
                >
                  HAPUS
                </Button>
              </Col>
            </Row>
          </Modal>
          ) : null}
      </>
    );
  };

  /**
   * This page will render when product not found
   */
  _renderProductNotFound() {
    return (
      <>
        <div className="product-card text-center">
          <Container>
            <img
              src={ProductNotFoundImage}
              alt="product not found"
              width="75%"
              className="pr-5 pl-5 pt-5 mb-3"
            />
            <h5 className="mb-0 pb-0">Duh, Produk Tidak ditemukan</h5>
            <p className="small text-muted mb-0">
              Mungkin anda tertarik dengan produk rekomendasi lainnya,
              <br />
              {' '}
              silahkan scroll kebawah...
            </p>
            <Icon type="arrow-down" />
          </Container>
        </div>
        {this._renderProduct__recommendation()}
        ;
        <BackTop />
      </>
    );
  }

  /**
   * Render Product Notice information about anything included promo or selling target
   */
  _renderProduct__notice() {
    const bonusPayload = !this.state.userBonusState?.fetching
      && this.state.userBonusState?.success
      && !_.isEmpty(this.state?.userBonusState?.data)
      ? this.state?.userBonusState?.data
      : {};
    const bonusLevelPayload = !this.state?.userBonusLevelState?.fetching
      && this.state?.userBonusLevelState?.success
      && !_.isEmpty(this.state?.userBonusLevelState?.data)
      ? this.state?.userBonusLevelState?.data
      : {};
    if (!_.isEmpty(bonusLevelPayload)) {
      return (
        <div style={styles.productNotice} className="row no-gutters pt-2 pb-2">
          {!_.isEmpty(bonusPayload) && !_.isEmpty(bonusLevelPayload) ? (
            <>
              <div className="col pl-4">
                {_.isEmpty(bonusPayload.bonus) ? (
                  <span>
                    Ayo jualan
                    {' '}
                    <b>
                      Rp
                      {NumberWithCommas(
                        bonusLevelPayload[0].min_order
                          - bonusPayload.income_qualified,
                      )}
                    </b>
                    {' '}
                    lagi untuk dapat
                    {' '}
                    {bonusLevelPayload[0].percentage}
                    % bonus
                    Mingguan-mu!
                  </span>
                ) : !_.isEmpty(bonusPayload.bonus)
                  && bonusLevelPayload.length !== bonusPayload.bonus.level ? (
                    <span>
                      Ayo jualan
                      <b>
                        Rp
                        {NumberWithCommas(
                          bonusLevelPayload[bonusPayload.bonus.level].min_order
                          - bonusPayload.income_qualified,
                        )}
                      </b>
                      {' '}
                      lagi untuk dapat
                      {' '}
                      {bonusLevelPayload[bonusPayload.bonus.level].percentage}
                      %
                      bonus Mingguan-mu!
                    </span>
                  ) : (
                    <span>Anda sudah mencapai bonus maksimal mingguan</span>
                  )}
              </div>
              <div className="col-4 text-right pl-2 pr-4">
                <Button
                  onClick={() => {
                    this.props.history.push('/history-commission');
                  }}
                >
                  Lihat
                </Button>
              </div>
            </>
          ) : (
            <div className="col pl-4 pr-4">Harap tunggu...</div>
          )}
        </div>
      );
    }
  }

  /**
   * Render Slider
   */
  _renderProduct__slider = () => {
    const { images } = this.state.product;
    const { inStock } = this.state.product;

    const mainSetting = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dotsClass: 'slider-pdp',
      lazyLoad: false,
      swipe: false,
    };

    const navSetting = {
      infinite: true,
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      lazyLoad: false,
    };

    if (
      !_.isEqual(this.state.sliders.mainSlider, this.slider1)
      || !_.isEqual(this.state.sliders.navSlider, this.slider2)
    ) {
      // Setup Slider Navigation
      this.setState({
        sliders: {
          ...this.state.sliders,
          ...{
            mainSlider: this.slider1,
            navSlider: this.slider2,
          },
        },
      });
    }

    const renderByType = (image) => {
      if (image.type === 1) {
        return (
          <img
            alt=""
            width="100%"
            style={{ cursor: 'zoom-in' }}
            src={`${config.imageURL}${image.image_url}`}
          />
        );
      }
      if (image.type === 2) {
        return (
          <div className="video-holder">
            <img
              src={Play}
              alt="..."
              className="play-button"
              style={{
                borderRadius: '100px',
                padding: '1px',
                backgroundColor: 'white',
              }}
            />
            {' '}
            <video
              preload="metadata"
              controls={false}
              src={`https://res.cloudinary.com/rintiz/video/upload${image.image_url}`}
            />
          </div>
        );
      }

      return '';
    };

    const renderImages = (images) => {
      if (!_.isEmpty(images)) {
        const imagesArray = images.map((image, idx) => (
          <div
            className="main-slider-item"
            key={idx}
            onClick={() => {
              this.setState({ galleryLightbox: { visible: true, index: idx } });
            }}
          >
            {renderByType(image)}
            {!inStock ? (
              <div
                style={{
                  backgroundColor: 'rgba(50, 50, 50, 0.4)',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  paddingTop: '50%',
                  paddingBottom: '50%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <span
                    className="rounded-pill"
                    style={{
                      color: '#FFFFFF',
                      padding: '1.5rem 3rem',
                      backgroundColor: 'rgba(71,71,71, 0.9)',
                      fontSize: '16px',
                    }}
                  >
                    Stok Habis
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ));
        return imagesArray;
      }
      return null;
    };

    const renderImagesNav = (images) => {
      if (!_.isEmpty(images)) {
        const imagesArray = images.map((image, idx) => (
          <div key={idx}>
            <div className="d-block nav-slider-item rounded">
              {renderByType(image)}
              {/* <img src={`${config.imageURL}${image.image_url}`} alt='...' /> */}
            </div>
          </div>
        ));
        return imagesArray;
      }
      return null;
    };

    return (
      <div className="product-slider">
        <div className="main-slider">
          <Slider
            swipe={this.state.sliders.mainSliderSwipe}
            {...mainSetting}
            arrows={false}
            asNavFor={this.state.sliders.navSlider}
            ref={(slider) => (this.slider1 = slider)}
            swipeToSlide
          >
            {renderImages(images)}
          </Slider>
        </div>
        <Container style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Row>
            <Col span={18}>
              <div className="nav-slider">
                <Slider
                  {...navSetting}
                  arrows={false}
                  asNavFor={this.state.sliders.mainSlider}
                  ref={(slider) => (this.slider2 = slider)}
                  focusOnSelect
                >
                  {renderImagesNav(images)}
                </Slider>
              </div>
            </Col>
            <Col span={6}>
              <div>
                <img
                  className="tour-add-bookmark"
                  alt="..."
                  width="100%"
                  src={bookmark}
                  style={{ height: '28px', cursor: 'pointer' }}
                  onClick={this.toggleCatalog}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  /**
   * Rendering Product information and Commission
   */
  _renderProduct__information() {
    const { product, productDetailCommissionTierListState } = this.state;

    const last = !productDetailCommissionTierListState.fetching
      && productDetailCommissionTierListState.success
      && !_.isEmpty(productDetailCommissionTierListState.data)
      ? productDetailCommissionTierListState.data.length - 1
      : {};
    return (
      <Container>
        {/* <div
          onClick={() => {
            this.props.history.push(
              `/brand/${product.name}?id=${product.brand_id}`,
            );
          }}
          className="brand-name"
        >
          {product.brandName}
        </div> */}
        {/* <div className="product-name">{product.name}</div> */}
        <Heading weight="600" size={16}>
          {product.name}
        </Heading>
        {product?.pack?.active ? (
          <div className="mt-n2 product-commission">
            Tersedia Paket
            {' '}
            {this._renderProduct__unit_pcsPerSet()}
            {' '}
            | Komisi Beli
            Paket Rp
            {productDetailCommissionTierListState.fetching === true
            && productDetailCommissionTierListState.success === false ? (
              <Spin size="small" />
              ) : (
                NumberWithCommas(
                  productDetailCommissionTierListState?.data[last]?.commission,
                )
              )}
          </div>
        ) : (
          ''
        )}
        {product?.single?.active ? (
          <div className="product-price">
            Rp
            {NumberWithCommas(
              product?.single?.active
                ? product?.single?.price
                : product?.pack?.price,
            )}
            {' '}
            /
            {UpperCase(product.single.unit)}
          </div>
        ) : (
          <div className="product-price">
            Rp
            {NumberWithCommas(
              product?.single?.active
                ? product?.single?.price
                : product?.pack?.price,
            )}
            {' '}
            / Paket
          </div>
        )}
        <div className="mt-2 product-commission" style={{ color: '#9b9b9b' }}>
          Harga Pasaran Rp
          {' '}
          {NumberWithCommas(
            product?.single?.active
              ? product?.single?.msrp
              : product?.pack?.msrp,
          )}
        </div>
        <SocialShare product={product} />
        <div className="clearfix pb-1" />
      </Container>
    );
  }

  /**
   * Rendering Product Skeleton Load
   *
   * This component will render when product fetching / product first load.
   */
  _renderProductSkeleton() {
    return (
      <>
        <div className="product-card">
          <div style={{ height: '2000px' }} />
        </div>
        {this._renderProduct__recommendation()}
      </>
    );
  }

  /**
   * Render product review
   */
  _renderProduct__review() {
    const toggleExpand = () => {
      const collapsed = this.refProductDescription.current.classList.contains('collapsed');
      if (collapsed) {
        this.refProductDescription.current.classList.remove('collapsed');
      } else {
        this.refProductDescription.current.classList.add('collapsed');
      }
    };

    return (
      <div className="product-card space-top">
        <Container>
          <div className="product-card-title">Penilaian</div>
          <div
            className="product-review expandable collapsed"
            ref={this.refProductDescription}
          >
            <div className="row">
              <div className="col">
                <div className="reviewer">Ulasan Mitra</div>
                <Rate style={{ fontSize: 12, disabled: true }} value={4} />
                <div className="reviewer-desc">20+ Ulasan</div>
              </div>
              <div className="col">
                <div className="reviewer">Ulasan Woobiz</div>
                <Rate
                  className="review-indicator fire"
                  character={<Icon type="fire" theme="filled" />}
                  style={{ fontSize: 12, disabled: true }}
                />
                <div className="reviewer-desc">Sangat Baik</div>
              </div>
            </div>
            <div
              className="expandable-content zero"
              dangerouslySetInnerHTML={{
                __html: this.state.product?.description,
              }}
            />

            <button
              onClick={() => {
                toggleExpand();
              }}
              className="toggle-button toggle-expand"
            >
              Selengkapnya
            </button>
            <button
              onClick={() => {
                toggleExpand();
              }}
              className="toggle-button toggle-collapse"
            >
              Sembunyikan
            </button>
          </div>
        </Container>
      </div>
    );
  }

  /**
   * Render shipping cost information
   */
  _renderProduct__shipping() {
    return (
      <div className="product-card space-top">
        <Container>
          <div className="product-card-title">Info Pengiriman</div>
          <div
            className="d-flex location-search-input"
            onClick={() => {
              this.props.history.push('/product/shipping');
            }}
          >
            <span className="fa fa-map-marker" />
            <div className="lsi__holder">Tentukan Lokasi</div>
            <span className="fa fa-search" />
          </div>
        </Container>
      </div>
    );
  }

  _renderProduct__unit_pcsPerSet() {
    const { productDetailState } = this.state;
    if (
      productDetailState.fetching === true
      && productDetailState.success === false
    ) {
      return <Spin size="small" />;
    }

    return productDetailState.data.qty_set !== 1
      ? `(${`${productDetailState.data.qty_set} ${this.state.product?.single.unit}`})`
      : null;
  }

  /**
   * Render product stock
   */
  _renderProduct__unit() {
    const { product } = this.state;
    return (
      <div className="product-card product-stock-information">
        <Container>
          <div className="product-card-title">Info Produk</div>
          <div>
            <div className="row">
              <div className="col title">Stok Tersedia</div>
              <div className="col desc">
                {(product?.stock || '').replace('Set', 'Paket')}
                {/* {(stockState.fetching === true && stockState.success === false) ? (<Spin size='small' />) : stockState.data.stock_available + ' Pcs'} */}
              </div>
            </div>
            {product?.pack?.active ? (
              <>
                <div className="row">
                  <div className="col title">Berat 1 Paket</div>
                  <div className="col desc">
                    {product?.shipping?.weight_set}
                    {' '}
                    kg
                  </div>
                </div>
                <div className="row">
                  <div className="col title">1 Paket</div>
                  <div className="col desc">
                    {this._renderProduct__unit_pcsPerSet()}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </Container>
      </div>
    );
  }

  /**
   * Render services that this product have
   */
  _renderProduct__service() {
    return (
      <div className="product-card">
        <Container>
          <div className="product-card-title">Layanan Produk</div>
          <div className="row">
            <div
              className={`col bolder ${
                this.state.product?.shipping?.is_free_ongkir
                  ? 'feature-enabled'
                  : 'feature-disabled'
              }`}
            >
              <img
                src={
                  this.state.product?.shipping?.is_free_ongkir
                    ? checkImage
                    : checkWrongImage
                }
                height="18px"
                className="mr-2"
                alt="Product Service"
              />
              {' '}
              Gratis Ongkir
            </div>
            <div
              className={`col bolder ${
                this.state.product?.shipping?.is_cod
                  ? 'feature-enabled'
                  : 'feature-disabled'
              }`}
            >
              <img
                src={
                  this.state.product?.shipping?.is_cod
                    ? checkImage
                    : checkWrongImage
                }
                height="18px"
                className="mr-2"
                alt="Product Service"
              />
              {' '}
              Bisa COD
            </div>
          </div>
          {/* <div className="row mt-3">
            <div
              className={`col bolder ${
                this.state.product?.variants[0]?.has_weekly_bonus
                  ? 'feature-enabled'
                  : 'feature-disabled'
              }`}
            >
              <img
                src={
                  this.state.product?.variants[0]?.has_weekly_bonus
                    ? checkImage
                    : checkWrongImage
                }
                height="18px"
                className="mr-2"
                alt="Product Service"
              />
              {' '}
              Bonus Mingguan
              <img
                src={More}
                height="18px"
                className="ml-2"
                alt="..."
                onClick={() => this.setState({ modalBonus: !this.state.modalBonus })}
                style={{ cursor: 'pointer' }}
              />
              <Modal
                closable={false}
                centered
                bodyStyle={{ textAlign: 'center', lineHeight: '15px' }}
                zIndex={2000}
                footer={null}
                width={317}
                visible={this.state.modalBonus}
              >
                <Block>
                  <div>
                    <TextSmall
                      color="#000"
                      increase
                      boldest
                      className="font-heading"
                    >
                      Bonus Mingguan
                    </TextSmall>
                  </div>
                  <Block paddingTop="8px">
                    <TextSmall>
                      Bonus mingguan adalah bonus yang akan Kamu dapatkan dari
                      total pemesanan Kamu disetiap minggunya. Info lebih
                      lengkap lihat
                      {' '}
                      <span
                        className="font-color-primary bolder"
                        onClick={() => this.props.history.push('/history-commission')}
                      >
                        disini
                      </span>
                    </TextSmall>
                  </Block>
                  <Block paddingTop="16px">
                    <Row gutter={8} justify="space-between">
                      <Col span={24}>
                        <ButtonPrimaryShadow
                          onClick={() => this.setState({
                            modalBonus: !this.state.modalBonus,
                          })}
                        >
                          <div>
                            <TextSmall
                              primary
                              increase
                              className="font-heading"
                              style={{ color: '#fff' }}
                            >
                              MENGERTI
                            </TextSmall>
                          </div>
                        </ButtonPrimaryShadow>
                      </Col>
                    </Row>
                  </Block>
                </Block>
              </Modal>
            </div>
          </div> */}
        </Container>
      </div>
    );
  }

  /**
   * Render product description
   */
  refProductDescription = createRef();

  _renderProduct__description() {
    // console.log("element", this.refProductDescription);
    // if (!_.isNull(this.refProductDescription.current)) {
    //   const onExpand = !this.refProductDescription.current.classList.contains("collapsed");
    //   if (this.refProductDescription.current.clientHeight > 120 && !onExpand) {
    //     console.log("more need to expanded");
    //   }
    // }

    // const toggleExpand = () => {
    //   const collapsed = this.refProductDescription.current.classList.contains('collapsed')
    //   if (collapsed) {
    //     this.refProductDescription.current.classList.remove('collapsed')
    //   } else {
    //     this.refProductDescription.current.classList.add('collapsed')
    //   }
    // }

    const shareDescription = () => {
      try {
        window.navigator.share({
          text: this.state.product?.description
            .replace(/<br\s*[\/]?>/gi, '\n')
            .replace(/<\/?[^>]+(>|$)/g, ''),
        });
      } catch (error) {
        const el = document.createElement('textarea');
        el.value = this.state.product?.description
          .replace(/<br\s*[\/]?>/gi, '\n')
          .replace(/<\/?[^>]+(>|$)/g, '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.success('Keterangan produk berhasil disalin', {
          id: 'copy-description',
        });
      }
    };

    return (
      <Padded bottom="3rem">
        <Flex justify="space-between" align="center">
          <Text size={14} color="grey">
            Deskripsi Produk
          </Text>
          <Button
            size="small"
            variant="outline"
            color="eggBlue"
            onClick={() => {
              shareDescription();
            }}
          >
            Salin Teks
          </Button>
        </Flex>
        <Text marginTop="10px" block>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.product?.description,
            }}
          />
        </Text>
        <Text
          onClick={this.toggleDescription}
          cursor="pointer"
          color="eggBlue"
          block
          align="center"
          marginTop="10px"
          marginBottom="10px"
        >
          Selengkapnya
        </Text>
      </Padded>
      // <div className='product-card'>
      //   <Container>
      //     <div className='product-card-title'>
      //       Deskripsi Produk
      //       <div
      //         className='product-card-action'
      //         onClick={() => {
      //           shareDescription();
      //         }}
      //       >
      //       @  Salin Teks
      //       </div>
      //     </div>
      //     <Text>Selengkapnya</Text>
      //     <div className='product-description padding-bottom-xl expandable collapsed'>
      //       <div
      //         className='expandable-content'
      //         dangerouslySetInnerHTML={{
      //           __html: this.state.product?.description,
      //         }}
      //       />

    //       <button
    //         onClick={() => this.toggleDescription()}
    //         className='toggle-button toggle-expand'
    //       >
    //         Selengkapnya
    //       </button>
    //     </div>
    //   </Container>
    // </div>
    );
  }

  /**
   * Render how much bonus you get when sell this product
   */
  refProductBonus = createRef();

  _renderProduct__bonus() {
    if (!this.state.product?.pack?.active) {
      return '';
    }

    // const toggleExpand = () => {
    //   const collapsed = this.refProductBonus.current.classList.contains('collapsed')
    //   if (collapsed) {
    //     this.refProductBonus.current.classList.remove('collapsed')
    //   } else {
    //     this.refProductBonus.current.classList.add('collapsed')
    //   }
    // }

    const commissionData = this.state.productDetailCommissionTierListState;
    if (commissionData.fetching === true && commissionData.success === false) {
      return '';
    }

    const commissionMin = _.first(commissionData.data)?.commission;
    const commissionMax = _.last(commissionData.data)?.commission;

    // this.state.productDetailCommissionTierListState.success &&

    return (
      <div className="product-card product-stock-information">
        <Container>
          <div className="product-card-title">Komisi Beli Paket</div>
          <div
            className="product-bonus expandable collapsed"
            ref={this.refProductBonus}
          >
            Rp
            {' '}
            {NumberWithCommas(commissionMin)}
            {' '}
            - Rp
            {' '}
            {NumberWithCommas(commissionMax)}
            {' '}
            / Paket
            <div className="expandable-content zero">
              <div className="pt-4">
                {commissionData.data.map((commission, key) => (
                  <div className="row" key={key}>
                    <div className="col title">
                      {commission.commission_type}
                    </div>
                    <div className="col desc">
                      Rp
                      {' '}
                      {NumberWithCommas(commission.commission)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={() => this.toggleCommission()}
              className="toggle-button toggle-expand"
            >
              Selengkapnya
            </button>
            {/* <button onClick={() => { toggleExpand() }} className='toggle-button toggle-collapse'>Sembunyikan</button> */}
          </div>
        </Container>
      </div>
    );
  }

  /**
   * Render action footer
   */
  _renderProduct__actionfooter() {
    const { product } = this.state;
    const isHaveVariant = !isEmpty(product.variantOptions);
    console.log(isHaveVariant);

    const handleOpenDrawer = () => {
      if (isHaveVariant) {
        this.setState({
          drawerVariant: true,
        });
      } else {
        this.setState({
          drawerNonVariant: true,
        });
      }
    };

    const actionButtons = (
      <Button onClick={handleOpenDrawer} color="eggBlue" block>
        Pesan
      </Button>
    );

    return (
      <>
        <div className="cart-footer-limit" />
        <div className="product-action-footer product-card sticky">
          <Container>
            <div className="d-flex justify-content-start align-items-center">
              <div className="w-25 pr-2">
                <div
                  className="button-action-cart"
                  onClick={() => {
                    history.push('/shopping-cart');
                  }}
                >
                  {/* <Badge count={this.badgeCount()} overflowCount={9}>
                    <img src={cartImage} height="22px" alt="cart" />
                  </Badge> */}
                  <BadgeCount />
                </div>
              </div>
              {actionButtons}
            </div>
          </Container>
        </div>
      </>
    );
  }

  /**
   * Render Infinite scroll product recommendation
   */
  _renderProduct__recommendation() {
    const { productDetailState } = this.state;
    const category0Payload = getSafely(
      ['data', 'categories', 0],
      productDetailState,
      [],
    );
    const category1Payload = getSafely(
      ['data', 'categories', 1],
      productDetailState,
      [],
    );
    const categoryLengthPayload = getSafely(
      ['data', 'categories'],
      productDetailState,
      [],
    );
    const categoryIndex0 = getSafely(['category_id'], category0Payload, []);
    const categoryIndex1 = getSafely(['category_id'], category1Payload, []);
    const ignoreSet = getSafely(
      ['product', 'pack', 'active'],
      this.state,
      false,
    );
    return (
      <div>
        <RelatedCategory
          ignoreSet={!ignoreSet}
          category={`${
            categoryLengthPayload.length > 1 ? categoryIndex1 : categoryIndex0
          }`}
        />
      </div>
    );
  }

  /**
   * Render Bottom Sheet Single Unit Order
   */
  _renderDrawerVariant() {
    const { product, drawerVariant } = this.state;

    return (
      <BottomSheet
        close
        visible={drawerVariant}
        toggleVisibility={() => {
          this.setState({ drawerVariant: false });
        }}
      >
        <OrderDrawerVariant
          product={product}
          toggleVisibility={() => {
            this.setState({ drawerVariant: false });
          }}
        />
      </BottomSheet>
    );
  }

  _renderDrawerNonVariant() {
    const { product, drawerNonVariant } = this.state;

    return (
      <BottomSheet
        close
        visible={drawerNonVariant}
        toggleVisibility={() => {
          this.setState({ drawerNonVariant: false });
        }}
      >
        <OrderDrawer
          product={product}
          toggleVisibility={() => {
            this.setState({ drawerNonVariant: false });
          }}
        />
      </BottomSheet>
    );
  }

  /**
   * Render Bottom Sheet Change product Brand
   */
  _renderProduct__bs_changeProductBrand() {
    // const { cartState } = this.state
    return (
      <BottomSheet
        close
        visible={this.state.showDrawers.changeProductBrand}
        toggleVisibility={() => {
          this.setDrawerState('changeProductBrand', false);
        }}
      >
        <div className="change-product-brand-drawer">
          <h3 className="title">Ingin mengganti pesanan?</h3>
          <p className="message">
            Kamu sudah memiliki Produk dari Brand
            {' '}
            <strong>
              {this.state.drawerData.changeProductBrand.oldBrandName}
            </strong>
            {' '}
            dikeranjang. Apakah ingin mengganti isi keranjangmu dengan Produk
            dari Brand
            {' '}
            <strong>
              {this.state.drawerData.changeProductBrand.newBrandName}
            </strong>
            ?
          </p>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-custom-clear btn-block"
                onClick={() => {
                  this.setDrawerState('changeProductBrand', false);
                }}
              >
                Batal
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-custom-primary btn-block"
                onClick={() => {
                  this.state.drawerData.changeProductBrand.callback();
                  this.setDrawerState('changeProductBrand', false);
                }}
              >
                Lanjut
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    );
  }

  _renderProduct__lightbox() {
    const { images } = this.state.product;
    const lightboxContentCustom = (image) => {
      if (image.type === 2) {
        return (
          <video
            className="slider-video"
            preload="metadata"
            src={`https://res.cloudinary.com/rintiz/video/upload${image.image_url}`}
            controls
          />
        );
      }

      return null;
    };

    const lightboxContentImage = (image) => {
      if (image.type === 1) {
        return `${config.imageURL}${image.image_url}`;
      }
      return null;
    };

    return (
      <>
        {this.state.galleryLightbox.visible && (
          <Lightbox
            mainSrc={lightboxContentImage(
              images[this.state.galleryLightbox.index],
            )}
            mainCustomContent={lightboxContentCustom(
              images[this.state.galleryLightbox.index],
            )}
            nextSrc={
              images[(this.state.galleryLightbox.index + 1) % images.length]
            }
            prevSrc={
              images[
                (this.state.galleryLightbox.index + images.length - 1)
                  % images.length
              ]
            }
            onCloseRequest={() => {
              this.setState({ galleryLightbox: false });
            }}
            onMovePrevRequest={() => {
              const selectedIndex = (this.state.galleryLightbox.index + images.length - 1)
                % images.length;

              this.setState({
                galleryLightbox: {
                  visible: true,
                  index: selectedIndex,
                },
              });

              this.slider1.slickGoTo(selectedIndex);
            }}
            onMoveNextRequest={() => {
              const selectedIndex = (this.state.galleryLightbox.index + 1) % images.length;
              this.setState({
                galleryLightbox: {
                  visible: true,
                  index: selectedIndex,
                },
              });

              this.slider1.slickGoTo(selectedIndex);
            }}
          />
        )}
      </>
    );
  }

  render_condition() {
    const { productDetailState, product, catalogState } = this.state;

    let render = this._renderProductSkeleton();
    // if (productDetailState.fetching === false && productDetailState.success === true && product.exist === true) {
    if (this.state.product.name && product.exist === true) {
      render = this._renderProduct();
    } else if (
      productDetailState.fetching === false
      && productDetailState.success === true
      && product.exist === false
    ) {
      render = this._renderProductNotFound();
    } else if (
      productDetailState.fetching === false
      && productDetailState.success === false
    ) {
      render = this._renderProductNotFound();
    }

    return render;
  }

  /**
   * Render Display
   */
  render() {
    // console.log(this.state.calculateMargin)
    return (
      <div>
        <WToolbar fixed backButton showSearch showHome showChat />
        <div className="product-detail-page">{this.render_condition()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartState: state.tempCartState,
  productDetailState: state.productDetailState,
  // stockState: state.stockState,
  productDetailCommissionTierListState:
    state.productDetailCommissionTierListState,
  productNotification: state.productNotification,
  userBonusState: state.userBonusState,
  userBonusLevelState: state.userBonusLevelState,
  checkProductCatalogState: state.checkProductCatalogState,
  catalogCreateWithProductState: state.catalogCreateWithProductState,
  catalogState: state.catalogState,
  storeState: state.storeState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductDetail: (sku) => dispatch(ProductDetailActions.productDetailRequest(sku)),
  // checkStock: (sku, qty) => dispatch(StockActions.stockRequest(sku, qty)),
  productCommissionTierListRequest: (sku) => dispatch(
    ProductDetailCommissionTierListActions.productCommissionTierListRequest(
      sku,
    ),
  ),
  productNotificationRequest: () => dispatch(ProductNotificationAction.Creators.productNotificationRequest()),
  userBonusRequest: () => dispatch(UserBonusActions.userBonusRequest()),
  userBonusLevelRequest: () => dispatch(UserBonusLevelActions.userBonusLevelRequest()),
  checkProductCatalogRequest: (data) => dispatch(checkProductCatalogActions.checkProductCatalogRequest(data)),
  getCatalogRequest: (data) => dispatch(catalogActions.getCatalogRequest(data)),
  createCatalogRequest: (data) => dispatch(
    catalogCreateWithProductActions.catalogCreateWithProductRequest(data),
  ),
  createProductCatalogRequest: (id, data) => dispatch(catalogActions.createProductCatalogRequest(id, data)),
  deleteProductCatalogRequest: (id, data) => dispatch(catalogActions.deleteProductCatalogRequest(id, data)),
  getStoreRequest: () => dispatch(storeActions.getStoreRequest()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage),
);

const styles = {
  productNotice: {
    backgroundColor: '#ebecff',
    fontSize: '12px',
  },
};

export const productManualMapping = (selectedProduct) => {
  const GlobalVariants = _.filter(
    selectedProduct.variants,
    (o) => o.status === 10,
  );

  if (!_.isEmpty(GlobalVariants)) {
    const single = _.find(GlobalVariants, (o) => o.is_set !== 1);
    const singleActive = !_.isEmpty(single);

    const pack = _.find(GlobalVariants, (o) => o.is_set === 1);
    const packActive = !_.isEmpty(pack);

    /**
     * Do not delete this section. someday we might need it
     *
     * let images = _.flatten(GlobalVariants.map((o) => { return o.images })) || []
     * images = _.uniqBy(images, (o) => { return o.image_url })
     */

    let images = GlobalVariants[0]?.images || [];
    images = images.map((image) => ({
      ...image,
      type: detectFileType(image.image_url),
    }));

    return {
      images,
      sku: GlobalVariants[0]?.sku,
      brandName: selectedProduct.brandowner.name,
      name: selectedProduct.name,
      description: selectedProduct.description,
      stock: `${GlobalVariants[0]?.stock} ${_.capitalize(
        GlobalVariants[0]?.satuan,
      )}`,
      inStock: selectedProduct.is_in_stock,
      single: {
        sku: singleActive ? single.sku : null,
        active: singleActive,
        price: singleActive ? single.prices[0].selling_price : 0,
        msrp: singleActive ? single.prices[0].msrp : 0,
        stock: singleActive ? single.stock : 0,
        unit: singleActive ? single.satuan : 'pcs',
        commission: singleActive ? single.commission : {},
      },
      pack: {
        sku: packActive ? pack.sku : null,
        active: packActive,
        price: packActive ? pack.prices[0].selling_price : 0,
        stock: packActive ? pack.stock : 0,
        msrp: packActive ? pack.prices[0].msrp : 0,
        qty: selectedProduct.qty_set,
        commission: packActive ? pack.commission : {},
      },
      shipping: selectedProduct.shipping_information,
      variantOptions: selectedProduct.variant_options,
      variants: GlobalVariants,
      brand_id: selectedProduct.brandowner.brandowner_id,
      brand_name: selectedProduct.brandowner.name,
    };
  }

  return {};
};
