import styled from 'styled-components'

export const AddToCartBtn = styled.button`
  width: 208px;
  background-color: #007bff;
  color: white;
`
export const IncrDecrFormBtn = styled.button`
  width: 40px;
  height: 40px;
  border-top-left-radius: ${props => props.left ? '2px' : '0px'};
  border-bottom-left-radius: ${props => props.left ? '2px' : '0px'};
  border-top-right-radius: ${props => props.right ? '2px' : '0px'};
  border-bottom-right-radius: ${props => props.right ? '2px' : '0px'};
  border: solid 1px #d1e2ef;
  background-color: #ECFAFD;
  color: #633c97;
  z-index: 3;
`

export const Button = styled.button`
  background-color: #633c97;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  border: none;
  cursor: pointer;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`

export const ButtonLight = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: #f3f4f6;
  border: none;
  font-weight: 600;
  color: #633c97;
  cursor: pointer;
  transition: ease all .2s;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`
export const ButtonPurple = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: #ECFAFD;
  border: none;
  color: #fff;
  cursor: pointer;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`
export const ButtonPrimary = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: #633c97;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: ease all .2s;
  font-family: Montserrat;
  font-weight: 600;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`

export const ButtonPrimaryOutline = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  background-color: white;
  border:1px solid #633c97;
  color: #633c97;
  cursor: pointer;
  transition: ease all .2s;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`

export const ButtonWhite = styled.button`
  width: 100%;
  height: 33px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  color: #633c97;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`

export const ButtonDisable = styled.button`
  width: 100%;
  height: 37px;
  border-radius: 4px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
`

export const RoundedShareButton = styled.div`
width: 32px;
height: 32px;
box-shadow: 0px 0px 7px rgba(0,0,0, 0.1), 0px 2px 3px rgba(0,0,0,0.06);
border-radius: 100px;
background: white;
text-align: center;
display: flex;
display: flex;
align-items: center;
justify-content: center;
cursor:pointer;
& + & {
  margin-left:12px;
}
`

export const RoundedShareWhatsappButton = styled.div`
width: auto;
height: 32px;
box-shadow: 0px 0px 7px rgba(0,0,0, 0.1), 0px 2px 3px rgba(0,0,0,0.06);
border-radius: 100px;
background: white;
text-align: center;
display: flex;
display: flex;
align-items: center;
justify-content: center;
flex:1;
margin-left:12px;
background:#7ad06d;
color:white;
cursor:pointer;
`

export const ButtonPrimaryShadow = styled.button`
  width: 100%;
  height: 37px;
  border-radius: 4px;
  background-color: #633c97;
  border: none;
  text-align: center;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &[disabled]{
    background: #d0d0d0 !important;
    opacity: 1;
    border-color: transparent !important;
  }
`

export const ButtonWhiteShadow = styled.button`
  width: 100%;
  color: #633c97;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 37px;
  border-radius: 4px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`
export const ButtonBorder = styled.button`
  width: auto;
  color: #633c97;
  height: 25px;
  border-radius: 2px;
  border: 1px solid #633c97;
  background-color: transparent;
  cursor: pointer;
`
export const ButtonOrange = styled.button`
  width: auto;
  color: #FFF;
  height: 32px;
  border-radius: 5px;
  background-color: #FF8b28;
  cursor: pointer;
  border: none;
`

export const CouponButton = styled.button`
  height: 37px;
  width: 90px;
  border-radius: 5px;
  background-color: #348f51;
  color: #FFF;
  border: none;
`

export const CatalogButton = styled.button`
  width: 55px;
  height: 24px;
  border-radius: 5px;
  /* background-color: ${(props) => props.theme.color}; */
  background-color: red;
  color: #fff;
  font-size: 0.75rem;
  border: unset;
  cursor: pointer;
`;