/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import config from 'legacy/config';
import { Flex } from 'shared/components/flex';
import { Text } from 'shared/components/typhography';
import numberFormat from 'utils/number-format';
import Button from 'shared/components/forms/button';
import { updateCartTemporaryRequest } from 'shared/apis/command/cart.command';
import { cartTemporaryDetailQuery } from 'shared/apis/queries/cart.query';
import toast from 'react-hot-toast';

export function OrderDrawer({ product, toggleVisibility }) {
  const cartQuery = cartTemporaryDetailQuery();
  const cartPayload = cartQuery.data?.data.data;
  const [variant, setVariant] = React.useState();
  const productImage = product.variants[0].images[0].image_url;

  useEffect(() => {
    setVariant(product.variants[0]);
  }, []);

  const handleExistProduct = () => {
    addToCart(addToCartExistProduct());
  };

  const addToCartExistProduct = () => {
    let qty = 1;
    // If product already in cart
    if (!isEmpty(cartPayload.items || [])) {
      (cartPayload.items[0].details || []).find((item) => {
        if (item.sku === variant.sku) {
          qty = item.qty_ordered + 1;
          return qty;
        }
      });
    }
    return qty;
  };

  const addToCart = (qty_ordered) => {
    updateCartTemporaryRequest({
      items: [
        {
          sku: variant.sku,
          qty_ordered,
        },
      ],
    })
      .then(() => {
        toggleVisibility();
        cartQuery.refetch();
        toast.success('Berhasil menambahkan ke keranjang');
      })
      .catch((err) => {
        toast.error('Gagal menambahkan ke keranjang');
        toggleVisibility();
      });
  };

  return (
    <>
      <Flex justify="start">
        <img
          src={`${config.imageURL}${productImage}`}
          width="50"
          height="50"
          style={{ objectFit: 'cover', borderRadius: '5px' }}
          alt={product.name}
        />
        <Flex direction="column" marginLeft="1rem">
          <Text size={14} weight="600">
            {product.name}
          </Text>
          <Text marginTop="1rem" size={12}>
            Harga
          </Text>
          <Text size={16} weight="700">
            Rp
            {' '}
            {numberFormat(variant?.prices[0].selling_price || 0)}
          </Text>
        </Flex>
      </Flex>
      <Button
        disabled={variant?.stock === 0}
        marginTop="2rem"
        onClick={handleExistProduct}
        color="eggBlue"
        block
      >
        Lanjut
      </Button>
    </>
  );
}
