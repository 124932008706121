// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import moment from 'moment'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  searchFilterClear: null,
  searchFilterAdd: ['sort', 'usingSort', 'selectedPrice','minPrice','maxPrice','category', 'usingFilter'],
  searchFilterDone: null
})

export const SearchFilterTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  sort: 1,
  selectedPrice: 0,
  minPrice: null,
  maxPrice: null,
  category: null,
  usingFilter: false,
  expired: moment().add(1, 'hour').toDate()
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

const clear = (state) =>
  state.merge({ sort: 1, usingSort: false, selectedPrice: 0, minPrice: null, maxPrice: null, category: null, usingFilter: false })

const add = (state, { sort, usingSort, selectedPrice, minPrice, maxPrice, category, usingFilter }) =>
  state.merge({
    sort: sort,
    usingSort: usingSort,
    selectedPrice: selectedPrice,
    minPrice: minPrice,
    maxPrice: maxPrice,
    category: category,
    usingFilter: usingFilter,
    expired: moment().add(1, 'hour').toDate()
  })

const done = (state) => state.merge({})

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SEARCH_FILTER_CLEAR]: clear,
  [Types.SEARCH_FILTER_ADD]: add,
  [Types.SEARCH_FILTER_DONE]: done
})
