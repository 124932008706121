import { put, take, fork, call, select } from 'redux-saga/effects'
import { END } from 'redux-saga'
import _ from 'lodash'

import GPSCheckUserActions, { GPSCheckUserTypes } from '../Redux/GPS/GPSCheckUserRedux'
import GPSProductActions, { GPSProductTypes } from '../Redux/GPS/GPSProductRedux'

export function * GPSCheckUserRequest(api) {
    let action = yield take(GPSCheckUserTypes.GPS_CHECK_USER_REQUEST)
    while (action !== END) {
      yield fork(GPSCheckUserRequestAPI, api, action)
      action = yield take(GPSCheckUserTypes.GPS_CHECK_USER_REQUEST)
    }
}

export function * GPSCheckUserRequestAPI (api, {phone}) {
  try {
    const response = yield call(api.getCheckUserGPS, phone)
    if (response.ok) {
        return yield put(GPSCheckUserActions.gpsCheckUserSuccess(response.data.data))
    } else {
        return yield put(GPSCheckUserActions.gpsCheckUserFailure(response.data.data))
    }
  } catch (err) {
    return yield put(GPSCheckUserActions.gpsCheckUserFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * GPSProductRequest(api) {
  let action = yield take(GPSProductTypes.GPS_PRODUCT_REQUEST)
  while (action !== END) {
    yield fork(GPSProductRequestAPI, api, action)
    action = yield take(GPSProductTypes.GPS_PRODUCT_REQUEST)
  }
}

export function * GPSProductRequestAPI (api, {is_gps, gps_type, limit, page}) {
  try {
    const data = {
      is_gps,
      gps_type,
      limit,
      page
    }
    const response = yield call(api.getProductGPS, data)
    if (response.ok) {
        return yield put(GPSProductActions.gpsProductSuccess(response.data.data))
    } else {
        return yield put(GPSProductActions.gpsProductFailure(response.data.data))
    }
  } catch (err) {
    return yield put(GPSProductActions.gpsProductFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}