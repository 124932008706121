// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userBonusTermsRequest: null,
  userBonusTermsSuccess: ['payload'],
  userBonusTermsFailure: ['err']
})

export const UserBonusTermsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch User Data
export const userBonusTermsRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userBonusTermsSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userBonusTermsFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_BONUS_TERMS_REQUEST]: userBonusTermsRequest,
  [Types.USER_BONUS_TERMS_SUCCESS]: userBonusTermsSuccess,
  [Types.USER_BONUS_TERMS_FAILURE]: userBonusTermsFailure
})
