import React from "react";
import "./styles.scss";
import { minifyText } from "../../../Utils/minifyText";
import { getSafely, isEmpty, NumberWithCommas } from "../../../Utils";
import GPSIcon from "../../../static/images/garansipastisukses/gps-icon.png";
import config from "../../../config";

function ProductCardMain({ action, productDetail }) {
  const variantPayload = getSafely(["variants", 0], productDetail, []);
  const productImage = getSafely(["images", 0], variantPayload, []);
  const pricePayload = getSafely(["prices", 0], variantPayload, "");
  const commissionPayload = getSafely(
    ["commission", "comm_3"],
    variantPayload,
    ""
  );
  const slicedCommission = commissionPayload.toString().slice(0, -3) || 0;
  const percentage = (msrp, sellingPrice) => {
    const margin = msrp - sellingPrice;
    // eslint-disable-next-line no-mixed-operators
    return parseInt((margin / msrp * 100), 0) || 0;
    // return margin;
  };

  return (
    <div className="product-card-main" onClick={action}>
      <div style={{ position: "relative" }}>
        <img
          src={`${config.imageURL}${productImage.image_url}`}
          className="rounded-top"
          width="120"
          height="120"
          style={{ objectFit: "contain" }}
          alt="product"
        />

        {slicedCommission !== 0 && (
          <>
            <img
              src="/images/product/green-shape.svg"
              className="rounded product-card-main__shape"
              alt="product"
            />
            <div className="product-card-main__commission">
              Hingga <b>{slicedCommission}rb</b>
            </div>
          </>
        )}
        {variantPayload.is_gps !== 0 && (
          <>
            <img
              src={GPSIcon}
              className="rounded product-card-main__gps"
              alt="product"
              width="20"
            />
          </>
        )}
      </div>
      <div className="pl-2 pr-2">
        <div className="product-card-main__name">
          {minifyText(productDetail.name)}
        </div>
        <div className="product-card-main__badge">
          {variantPayload.satuan === "set" ? (
            <span>Paket (isi {productDetail.qty_set}pcs)</span>
          ) : (
            <span>Satuan</span>
          )}
        </div>
        <div className="product-card-main__price">
          <div className="d-flex">
            <span className="currency">Rp</span>
            <span className="price">
              {NumberWithCommas(pricePayload.selling_price)}
            </span>
          </div>
        </div>
        {!isEmpty(pricePayload.msrp) &&
          pricePayload.discount_percentage !== 0 && (
            <span className="product-card-main__badge-discount">
              <del>
                <small>Rp {NumberWithCommas(pricePayload.msrp)}</small>
              </del>
              <span>{percentage(pricePayload.msrp, pricePayload.selling_price)}%</span>
            </span>
          )}
      </div>
    </div>
  );
}

export default ProductCardMain;
