// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  productCategoryRequest: ['categoryID', 'page', 'filters'],
  productCategorySuccess: ['payload'],
  productCategoryFailure: ['err']
})

export const ProductCategoryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const productCategoryRequest = (state) =>
  state.merge({ fetching: true, success: false })

export const productCategorySuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
    err: null
  })

export const productCategoryFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_CATEGORY_REQUEST]: productCategoryRequest,
  [Types.PRODUCT_CATEGORY_SUCCESS]: productCategorySuccess,
  [Types.PRODUCT_CATEGORY_FAILURE]: productCategoryFailure
})
