// /* eslint-disable import/no-duplicates */
// /* eslint-disable radix */
// import React, { useState } from 'react';
// import './styles.scss';
// import { Container } from '../../Styled';
// import { getSafely, isEmpty, NumberWithCommas } from '../../../Utils';
// import config from '../../../config';
// import { minifyText } from '../../../Utils/minifyText';

// import SelectVariant from '../../../../shared/components/add-to-catalog/select-variant';
// import AddToCatalog from '../../../../shared/components/add-to-catalog/add-to-catalog';
// import ChangeProductPrice from '../../../../shared/components/add-to-catalog/change-product-price';

// // API
// import { UseCheckProductCatalog } from '../../../React-Query/catalog-api/catalog.api';

// // IMG
// import bookmarkIcon from '../../../static/images/w-bookmark-4.svg';
// // eslint-disable-next-line import/no-cycle

// function ProductCardSecondary({ product, action }) {
//   const [isOpenProductList, setOpenProductList] = useState(false);
//   const [isOpenChangeProductPrice, setOpenChangeProductPrice] = useState(false);
//   const [isOpenAddtoCatalog, setOpenAddToCatalog] = useState(false);

//   const [isActiveButton, setIsActiveButton] = useState(true);
//   const [price, setPrice] = useState(0);

//   const [catalogSKU, setCatalogSKU] = useState('');
//   const [selectedCatalog, setSelectedCatalog] = useState(false);
//   const [productCheck, setProductCheck] = useState([]);
//   const [clickSKU, setClickSKU] = useState(false);
//   const [skuPrice, setSKUPrice] = useState(0);
//   const [formatSKU, setFormatSKU] = useState('');
//   const [pieceSKU, setPieceSKU] = useState(0);

//   const variantPayload = getSafely(['variants', 0], product, '');
//   const pricePayload = getSafely(['prices', 0], variantPayload, '');
//   const productImage = getSafely(['images', 0], variantPayload, []);

//   const commissionPayload = getSafely(
//     ['commission', 'comm_3'],
//     variantPayload,
//     '',
//   );
//   const slicedCommission = commissionPayload.toString().slice(0, -3) || 0;

//   const checkProductCatalog = (variant) => {
//     UseCheckProductCatalog(variant.sku).then((res) => {
//       if (res.status === 200) {
//         setIsActiveButton(false);
//         setProductCheck(res.data.data);
//       }
//     });
//   };

//   const handleChangePrice = (e) => {
//     const { value } = e.target;
//     const priceFinal = value.split('.').join('') || 0;
//     setPrice(parseInt(priceFinal));
//   };

//   return (
//     <>
//       <Container>
//         <div className="product-card-secondary">
//           <div className="d-flex flex-start">
//             <div style={{ position: 'relative', height: '104px' }}>
//               <img
//                 src={`${config.imageURL}${productImage.image_url}`}
//                 className="rounded-lg border"
//                 width="104"
//                 height="104"
//                 style={{ objectFit: 'cover' }}
//                 alt="product"
//               />
//               <img
//                 src={bookmarkIcon}
//                 onClick={() => setOpenProductList(true)}
//                 className="product-card-secondary__catalog-icon"
//                 alt="product"
//               />
//               {slicedCommission !== 0 && (
//                 <>
//                   <img
//                     src="/images/product/green-shape.svg"
//                     className="rounded product-card-secondary__shape"
//                     alt="product"
//                   />
//                   <div className="product-card-secondary__commission">
//                     {' '}
//                     <span>
//                       Hingga
//                       {' '}
//                       <b>
//                         {slicedCommission}
//                         rb
//                       </b>
//                     </span>
//                   </div>
//                 </>
//               )}
//             </div>
//             <div className="d-flex flex-column pl-3">
//               <div className="product-card-secondary__name">
//                 {minifyText(product.name)}
//               </div>
//               <div className="product-card-secondary__badge">
//                 {variantPayload.satuan === 'set' ? (
//                   <span>
//                     Paket (isi
//                     {product.qty_set}
//                     pcs)
//                   </span>
//                 ) : (
//                   <span>Satuan</span>
//                 )}
//               </div>
//               {/* <div>
//               <TextSmall color="#9b9b9b">Harga Jual Rp 300.000</TextSmall>
//             </div> */}
//               <div className="product-card-secondary__price">
//                 <div className="d-flex">
//                   <span className="currency">Rp</span>
//                   <span className="price">
//                     {NumberWithCommas(pricePayload.selling_price)}
//                   </span>
//                 </div>
//               </div>
//               {!isEmpty(pricePayload.original_price)
//                 && pricePayload.discount_percentage !== 0 && (
//                   <div className="product-card-secondary__badge-discount">
//                     <del>
//                       <small>
//                         Rp
//                         {' '}
//                         {NumberWithCommas(pricePayload.original_price)}
//                       </small>
//                     </del>
//                     <span>
//                       {pricePayload.discount_percentage}
//                       %
//                     </span>
//                   </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </Container>

//       {/* Add to catalog pop up */}
//       <SelectVariant
//         setProductCheck={setProductCheck}
//         setCatalogSKU={setCatalogSKU}
//         setClickSKU={setClickSKU}
//         setFormatSKU={setFormatSKU}
//         setSKUPrice={setSKUPrice}
//         setPrice={setPrice}
//         setSelectedCatalog={setSelectedCatalog}
//         setPieceSKU={setPieceSKU}
//         checkProductCatalog={checkProductCatalog}
//         isActiveButton={isActiveButton}
//         setOpenProductList={setOpenProductList}
//         setOpenChangeProductPrice={setOpenChangeProductPrice}
//         productDetail={product}
//         isOpen={isOpenProductList}
//         variantList={product.variants}
//       />
//       <ChangeProductPrice
//         skuPrice={skuPrice}
//         price={price}
//         handleChangePrice={handleChangePrice}
//         setOpenProductList={setOpenProductList}
//         setOpenChangeProductPrice={setOpenChangeProductPrice}
//         setOpenAddToCatalog={setOpenAddToCatalog}
//         isOpen={isOpenChangeProductPrice}
//       />
//       <AddToCatalog
//         productCheck={productCheck}
//         isOpen={isOpenAddtoCatalog}
//         setOpenAddToCatalog={setOpenAddToCatalog}
//       />
//       {/* end add to catalog pop up */}
//     </>
//   );
// }

// export default ProductCardSecondary;

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import NumberFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import config from '../../../config';
import {
  UpperCase, NumberWithCommas, ReplaceChar, getSafely, isEmpty,
} from 'legacy/Utils';

import checkProductCatalogActions from 'legacy/Redux/Catalog/CheckProductCatalogRedux';
import catalogActions from 'legacy/Redux/Catalog/CatalogRedux';

// Component
import {
  TextSmall,
  TextMedium,
  Block,
  TextLarge,
  ButtonDisable,
  Container,
} from 'legacy/Components/Styled';
import { productManualMapping } from 'legacy/Containers/Product/ProductDetail/ProductDetailPage';

import { RecommendationProductBadge } from 'legacy/Components/card/badge';
import bookmarkIcon from 'legacy/static/images/w-bookmark-4.svg';
import bookmarkActive from 'legacy/static/images/w-bookmark-active.svg';
import bookmarkInactive from 'legacy/static/images/w-bookmark-inactive.svg';
import emptyCatalog from 'legacy/static/images/w-empty-collections.svg';
import Sheet from 'legacy/Components/bottomsheet/Sheet';

class ProductCardGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProductCatalogState: undefined,
      imageLoaded: false,
      visibleCatalog: false,
      catalogSKU: '',
      selectedCatalog: false,
      dataCheck: [],
      clickSKU: false,
      priceSKU: 0,
      formatSKU: '',
      pieceSKU: 0,
      visibleAddPrice: false,
      setPrice: 0,
      calculateMargin: 0,
      alertSetPrice: '=',
      wasAddProductCatalog: false,
      visibleChooseCatalog: false,
      isWasModifyProduct: false,
      isAddProductCatalog: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {};

    if (
      !_.isEqual(
        nextProps.checkProductCatalogState,
        prevState.checkProductCatalogState,
      )
      && !_.isEmpty(nextProps.checkProductCatalogState)
    ) {
      updated = {
        ...updated,
        checkProductCatalogState: nextProps.checkProductCatalogState,
      };
    }

    return updated;
  }

  componentDidUpdate() {
    const { clickSKU, dataCheck, catalogSKU } = this.state;
    const { catalog } = this.props;
    if (clickSKU) {
      if (
        !this.state.checkProductCatalogState.fetching
        && this.state.checkProductCatalogState.success
      ) {
        catalog.map((data, idx) => {
          let trueCheck = false;
          this.state.checkProductCatalogState.data.map((data2) => {
            if (data.id === data2.id) {
              trueCheck = true;
            }
          });

          if (trueCheck) {
            dataCheck.push({
              id: data.id,
              checkCatalog: true,
              modify: false,
            });
          } else {
            dataCheck.push({
              id: data.id,
              checkCatalog: false,
              modify: false,
            });
          }
        });
        this.setState({
          clickSKU: false,
        });
      }
    }
    if (this.state.isAddProductCatalog) {
      this.props.checkProductCatalogRequest(catalogSKU);
      this.setState({
        isWasModifyProduct: true,
        isAddProductCatalog: false,
      });
    }
    if (this.state.isWasModifyProduct) {
      this.timerWasAddProduct = setInterval(
        () => this.setState({
          isWasModifyProduct: false,
        }),
        5000,
      );
    }
  }

  minifyText = (words) => UpperCase(
    words.length > 25
      ? `${words.substring(0, 25).toLowerCase()}...`
      : words.toLowerCase(),
  );

  toggleCatalog = () => {
    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
    });
  };

  closeChooseCatalog = () => {
    this.setState({
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  handleSelectCatalog = (sku, margin, format, piece) => {
    this.setState({
      catalogSKU: sku,
      selectedCatalog: true,
      dataCheck: [],
      clickSKU: true,
      priceSKU: margin,
      formatSKU: format,
      pieceSKU: piece,
    });
    this.props.checkProductCatalogRequest(sku);
  };

  toggleAddPrice = () => {
    this.setState({
      visibleCatalog: !this.state.visibleCatalog,
      visibleAddPrice: !this.state.visibleAddPrice,
      setPrice: this.state.priceSKU,
      calculateMargin: 0,
    });
  };

  handleChangePriceMargin = (e) => {
    const number = /^\d+$/;
    const value = e.split('.').join('');
    if (value.match(number) || value === '') {
      if (value < this.state.priceSKU) {
        this.setState({
          alertSetPrice: '<',
        });
      }
      if (value >= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '>',
        });
      }
      if (value >= this.state.priceSKU && value <= this.state.priceSKU * 3) {
        this.setState({
          alertSetPrice: '=',
        });
      }
      this.setState({
        setPrice: value,
        calculateMargin: value - this.state.priceSKU,
      });
    }
  };

  toggleChooseCatalog = () => {
    this.setState({
      visibleAddPrice: !this.state.visibleAddPrice,
      visibleChooseCatalog: !this.state.visibleChooseCatalog,
    });
  };

  toggleProductCatalog = (idx) => {
    const { dataCheck } = this.state;
    const payload = dataCheck.slice();
    payload[idx] = {
      id: payload[idx].id,
      checkCatalog: !payload[idx].checkCatalog,
      modify: true,
    };
    this.setState({
      dataCheck: payload,
      wasAddProductCatalog: true,
    });
  };

  addProductCatalog = () => {
    const {
      dataCheck, catalogSKU, priceSKU, calculateMargin,
    } = this.state;
    const payload = {
      products: [
        {
          sku: catalogSKU,
          margin: calculateMargin,
        },
      ],
    };
    dataCheck.map((data, key) => {
      if (data.modify) {
        if (data.checkCatalog) {
          this.props.createProductCatalogRequest(data.id, payload);
        } else {
          this.props.deleteProductCatalogRequest(data.id, catalogSKU);
        }
      }
    });
    this.setState({
      isWasModifyProduct: true,
      checkCatalogState: true,
    });
    this.closeChooseCatalog();
  };

  notifUpdate = () => (
    <div className="container text-center">
      <span
        style={{
          background: '#633c97',
          color: '#ffffff',
          padding: '10px',
          borderRadius: '.25rem',
        }}
      >
        Katalog produk berhasil diperbarui
        <span
          style={{
            background: '#ffffff',
            color: '#633c97',
            padding: '3px 6px',
            marginLeft: '6px',
            borderRadius: '.25rem',
          }}
          onClick={() => (window.location.href = '/store/catalogs')}
        >
          Lihat
        </span>
      </span>
    </div>
  );

  percentage = (msrp, sellingPrice) => {
    const margin = msrp - sellingPrice;
    // eslint-disable-next-line no-mixed-operators
    return parseInt((margin / msrp) * 100, 0) || 0;
    // return margin;
  };

  loadImage(source) {
    if (this.state.imageLoaded === false) {
      const img = new Image();
      img.onload = () => {
        setTimeout(() => {
          this.setState({ imageLoaded: true });
        }, 300);
      };
      img.src = `${config.imageURL}${source}`;
    }
  }

  render() {
    const {
      item, grid, history, catalog,
    } = this.props;

    const variantPayload = getSafely(['variants', 0], item, '');
    const pricePayload = getSafely(['prices', 0], variantPayload, '');
    const productImage = getSafely(['images', 0], variantPayload, []);
    const commissionPayload = getSafely(
      ['commission', 'comm_3'],
      variantPayload,
      '',
    );
    const slicedCommission = commissionPayload.toString().slice(0, -3) || 0;
    const variant = item && item.variants.length > 0 ? item.variants[0] : {};

    if (!_.isEmpty(variant)) {
      const image = variant.images[0].image_url;
      this.loadImage(image);
      // if (isEmpty(catalog)) return null;

      return (
        <div>
          <Container>
            <div className="product-card-secondary">
              <div className="d-flex flex-start">
                <div style={{ position: 'relative', height: '104px' }}>
                  <img
                    src={`${config.imageURL}${productImage.image_url}`}
                    className="rounded-lg border"
                    width="104"
                    height="104"
                    style={{ objectFit: 'cover' }}
                    alt="product"
                  />
                  <img
                    src={bookmarkIcon}
                    onClick={this.toggleCatalog}
                    className="product-card-secondary__catalog-icon"
                    alt="product"
                  />
                  {slicedCommission !== 0 && (
                    <>
                      <img
                        src="/images/product/green-shape.svg"
                        className="rounded product-card-secondary__shape"
                        alt="product"
                      />
                      <div className="product-card-secondary__commission">
                        {" "}
                        <span>
                          Hingga{" "}
                          <b>
                            {slicedCommission}
                            rb
                          </b>
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex flex-column pl-3">
                  <div className="product-card-secondary__name">
                    {this.minifyText(item.name)}
                  </div>
                  <div className="product-card-secondary__badge">
                    {variantPayload.satuan === 'set' ? (
                      <span>
                        Paket (isi
                        {item.qty_set}
                        pcs)
                      </span>
                    ) : (
                      <span>Satuan</span>
                    )}
                  </div>
                  <div className="product-card-secondary__price">
                    <div className="d-flex">
                      <span className="currency">Rp</span>
                      <span className="price">
                        {NumberWithCommas(pricePayload.selling_price)}
                      </span>
                    </div>
                  </div>
                  {!isEmpty(pricePayload.original_price)
                    && pricePayload.discount_percentage !== 0 && (
                      <div className="product-card-secondary__badge-discount">
                        <del>
                          <small>
                            Rp
                            {' '}
                            {NumberWithCommas(pricePayload.original_price)}
                          </small>
                        </del>
                        <span>
                          {pricePayload.discount_percentage}
                          %
                        </span>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </Container>

          <Sheet
            isOpen={this.state.visibleCatalog}
            setOpen={this.toggleCatalog}
          >
            <Row type="flex" justify="end" onClick={this.toggleCatalog}>
              <Col>
                <TextSmall color="#633c97" style={{ cursor: 'pointer' }}>
                  Tutup
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">
                  Pilih Varian Produk
                </TextMedium>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <TextSmall increase color="#9b9b9b">
                  Pilih varian yang akan kamu masukkan pada katalog
                </TextSmall>
              </Col>
            </Row>
            <Row style={{ padding: '10px 0px' }}>
              <Block>
                <div>
                  <Block>
                    <Row type="flex" justify="space-between" align="middle">
                      {item.variants.map((items, idx) => (
                        <div
                          className="d-flex justify-content-between py-2 w-100"
                          onClick={() => this.handleSelectCatalog(
                            items.sku,
                            items.prices[0].selling_price,
                            items.satuan,
                            item.qty_set,
                          )}
                        >
                          <div className="pt-2">
                            <div>{items.option1}</div>
                            <div className="boldest">
                              {`Rp ${NumberWithCommas(
                                items.prices[0].selling_price,
                              )} `}
                              {items.satuan === 'set'
                                ? `/ paket (${item.qty_set}pcs)`
                                : items.satuan === 'pcs'
                                  ? '/ pc'
                                  : `${items.satuan}`}
                            </div>
                          </div>
                          <div>
                            <label className="align-self-center mr-4">
                              <input
                                type="radio"
                                name="radio-address"
                                className="custom-radio radio-primary"
                                checked={this.state.catalogSKU === items.sku}
                                onChange={() => this.handleSelectCatalog(
                                  items.sku,
                                  items.prices[0].selling_price,
                                  items.satuan,
                                  item.qty_set,
                                )}
                              />
                              <span className="checkedradio" />
                            </label>
                          </div>
                        </div>
                      ))}
                    </Row>
                  </Block>
                </div>
              </Block>
            </Row>
            {this.state.selectedCatalog ? (
              <button
                className="btn btn-block buy-now text-white bolder background-color-primary tour-next"
                onClick={this.toggleAddPrice}
              >
                LANJUT
              </button>
            ) : (
              <ButtonDisable
                style={{ width: '100%', height: '100%', padding: '12px 24px' }}
                disabled
              >
                LANJUT
              </ButtonDisable>
            )}
          </Sheet>

          <Sheet
            isOpen={this.state.visibleAddPrice}
            setOpen={this.toggleAddPrice}
          >
            <Row type="flex" justify="end" onClick={this.toggleAddPrice}>
              <Col>
                <TextSmall color="#633c97" style={{ cursor: 'pointer' }}>
                  Kembali
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">Ubah Harga</TextMedium>
              </Col>
              <Row style={{ marginTop: '10px' }}>
                <Col span={18}>
                  <TextSmall color="#9b9b9b" increase boldest>
                    Harga Mitra
                    {' '}
                    {this.state.formatSKU === 'set'
                      ? ` (paket isi ${this.state.pieceSKU}pcs)`
                      : this.state.formatSKU === 'pcs'
                        ? ' (pc)'
                        : `(${this.state.formatSKU})`}
                  </TextSmall>
                </Col>
                <Col className="text-right" span={6}>
                  <TextSmall>
                    Rp
                    {' '}
                    {NumberWithCommas(this.state.priceSKU)}
                  </TextSmall>
                </Col>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <TextSmall color="#9b9b9b" increase boldest>
                    Tentukan Harga Jualmu
                  </TextSmall>
                </Col>
                <Col className="text-right" span={12}>
                  <TextSmall>
                    Keuntungan Rp
                    {this.state.alertSetPrice === '<'
                    || this.state.alertSetPrice === '>' ? (
                      <span style={{ color: '#f02b01', paddingLeft: '.25rem' }}>
                        {NumberWithCommas(this.state.calculateMargin)}
                      </span>
                      ) : (
                        <span style={{ color: '#48a900', paddingLeft: '.25rem' }}>
                          {NumberWithCommas(this.state.calculateMargin)}
                        </span>
                      )}
                  </TextSmall>
                </Col>
              </Row>
              <Col style={{ marginTop: '10px' }}>
                <Row type="flex" align="middle">
                  <Col span={2}>
                    <div>
                      <TextSmall increase boldest>
                        Rp
                      </TextSmall>
                    </div>
                  </Col>
                  <Col span={22}>
                    <NumberFormat
                      className="form-set-margin border-top-0 border-right-0 border-left-0 no-box-shadow font-size-s"
                      thousandSeparator="."
                      // type='tel'
                      maxLength={11}
                      decimalSeparator=","
                      placeholder={this.state.setPrice}
                      value={this.state.setPrice}
                      onChange={(e) => this.handleChangePriceMargin(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              {this.state.alertSetPrice === '<' ? (
                <Col>
                  <TextSmall color="#f02b01">
                    Harga jual tidak bisa lebih rendah dari harga Woobiz
                  </TextSmall>
                </Col>
              ) : this.state.alertSetPrice === '>' ? (
                <Col>
                  <TextSmall color="#f02b01">
                    Harga jual tidak bisa lebih dari Rp.
                    {' '}
                    {NumberWithCommas(this.state.priceSKU * 3)}
                  </TextSmall>
                </Col>
              ) : null}
            </Row>
            {this.state.alertSetPrice === '=' ? (
              <button
                className="btn btn-block buy-now text-white bolder background-color-primary"
                onClick={this.toggleChooseCatalog}
              >
                SIMPAN
              </button>
            ) : (
              <ButtonDisable
                className="tour-next"
                style={{ width: '100%', height: '100%', padding: '12px 24px' }}
                disabled
              >
                SIMPAN
              </ButtonDisable>
            )}
          </Sheet>

          <Sheet
            isOpen={this.state.visibleChooseCatalog}
            setOpen={this.toggleChooseCatalog}
          >
            <Row type="flex" justify="end" onClick={this.toggleChooseCatalog}>
              <Col>
                <TextSmall
                  color="#633c97"
                  primary
                  style={{ cursor: 'pointer' }}
                >
                  Kembali
                </TextSmall>
              </Col>
            </Row>
            <Row
              style={{
                padding: '10px 0px',
                borderBottom: '1px solid rgba(204, 204, 204, .3)',
              }}
            >
              <Col>
                <TextMedium className="montserrat">Daftar Katalog</TextMedium>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <TextSmall increase>
                  Tentukan dimana kamu akan menyimpan produk ini
                </TextSmall>
              </Col>
            </Row>
            {!_.isEmpty(catalog) ? (
              !_.isEmpty(catalog) ? (
                <>
                  <div>
                    {catalog.map((item, idx) => (
                      <Row
                        // className="tour-select-catalog"
                        style={{ marginTop: '1rem', cursor: 'pointer' }}
                        type="flex"
                        justify="space-around"
                        align="middle"
                        onClick={() => this.toggleProductCatalog(idx)}
                      >
                        <Col span={22} className="text-left">
                          <TextMedium bold>{item.name}</TextMedium>
                        </Col>
                        <Col className="gutter-row text-right" span={2}>
                          {!_.isEmpty(this.state.dataCheck) ? (
                            this.state.dataCheck[idx].checkCatalog ? (
                              <img
                                src={bookmarkActive}
                                width="20px"
                                alt="..."
                              />
                            ) : (
                              <img
                                src={bookmarkInactive}
                                width="20px"
                                alt="..."
                              />
                            )
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div style={{ marginTop: '24px' }}>
                    {this.state.wasAddProductCatalog ? (
                      <button
                        className="btn btn-block buy-now text-white bolder background-color-primary save-catalog"
                        onClick={this.addProductCatalog}
                      >
                        SELESAI
                      </button>
                    ) : (
                      <ButtonDisable
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: '12px 24px',
                        }}
                        disabled
                      >
                        SELESAI
                      </ButtonDisable>
                    )}
                  </div>
                </>
              ) : (
                <div style={{ padding: '0px 24px', marginTop: '24px' }}>
                  <Col className="text-center">
                    <img src={emptyCatalog} width="91" />
                  </Col>
                  <Col className="text-center" style={{ marginBottom: '24px' }}>
                    <TextMedium boldest>Katalog anda masih kosong!</TextMedium>
                  </Col>
                  <button
                    className="btn btn-block buy-now text-white bolder background-color-primary"
                    onClick={() => (window.location.href = '/store')}
                  >
                    Buat Katalog Baru
                  </button>
                </div>
              )
            ) : (
              <div style={{ padding: '0px 24px', marginTop: '24px' }}>
                <button
                  className="btn btn-block buy-now text-white bolder background-color-primary"
                  onClick={() => (window.location.href = '/store')}
                >
                  Buat Katalog Baru
                </button>
              </div>
            )}
          </Sheet>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  checkProductCatalogState: state.checkProductCatalogState,
});

const mapDispatchToProps = (dispatch) => ({
  checkProductCatalogRequest: (data) => dispatch(checkProductCatalogActions.checkProductCatalogRequest(data)),
  createProductCatalogRequest: (id, data) => dispatch(catalogActions.createProductCatalogRequest(id, data)),
  deleteProductCatalogRequest: (id, data) => dispatch(catalogActions.deleteProductCatalogRequest(id, data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCardGrid),
);
