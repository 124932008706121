import React from 'react'
import Tour from '../tour';
import { useEffect, useState } from 'react';
import { ACTIONS, EVENTS } from 'react-joyride';
import localforage from 'localforage';

const steps = [
    {
        target: '.create-catalog-button',
        title: 'Coba buat katalog yuk!',
        content: 'Buat katalogmu untuk mulai berjualan',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        showSkipButton: true,
    },
    {
        target: '.tour-add-catalog',
        title: 'Kasih Nama Katalogmu',
        content: 'Jangan lupa beri nama katalog sesuai keinginanmu ya..',
        disableBeacon: true,
        disableOverlayClose: false,
        spotlightClicks: false,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        disableOverlay: true,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
        isFixed: false
    },
    {
        target: '.collection-card',
        title: 'Lihat Detail Katalog',
        content: 'Saya butuh tambahan kata disini, agak kesulitan secara teknis kalau langsung di redirect...',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
    },
    {
        target: '.tour-add-product',
        title: 'Cari Barang Untuk Katalogmu',
        content: 'Katalogmu udah jadi nih, Yuk mulai cari produk untuk mengisi katalog ini.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
    },
    {
        target: '.my-masonry-grid_column',
        title: 'Yuk.. pilih produknya',
        content: 'Mulai pilih produk untuk dimasukkan ke katalogmu.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 1000000,
            },
        },
    },
    {
        target: '.tour-add-bookmark',
        title: 'Yuk.. lanjutkan',
        content: 'Tahap selanjutnya Kamu akan memilih pada katalog mana produkmu akan diletakkan',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false
    },
    {
        target: '.tour-variant',
        title: 'Pilih varian produk',
        content: 'Pilih variant produk yang akan Kamu tawarkan ke pelangganmu.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.tour-next',
        title: 'Masukkan produk ke katalog',
        content: 'Mulai masukkan produk yang akan Kamu jual ke katalog',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.tour-save-price',
        title: 'Atur Harga Jualmu',
        content: 'Ubah harga jual produk dikatalogmu. Kamu juga bisa langsung tau berapa keuntungan yang kamu peroleh.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        offset: 200,
        hideCloseButton: true,
        hideFooter: false,
        disableOverlay: true,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.tour-select-catalog',
        title: 'Pilih Daftar Katalogmu',
        content: 'Kamu bisa menentukan dikatalog mana produkmu akan dimasukkan. ',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.save-catalog',
        title: 'Pilih Selesai',
        content: 'Untuk menyimpan produk yang telah Kamu masukkan ke dalam katalog.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.collection-card',
        title: 'Lihat Isi Katalogmu',
        content: 'Katalog udah terisi, saatnya lihat isinya.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.change-price',
        title: 'Ubah Harga Jualmu',
        content: 'Kamu bisa ubah harga jual produk ini untuk pelangganmu loh. Jadi kamu bisa dapat untung',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.save-price-button',
        title: 'Atur Harga Jualmu',
        content: 'Ubah harga jual produk dikatalogmu. Kamu juga bisa langsung tau berapa keuntungan yang kamu peroleh.',
        disableBeacon: true,
        disableOverlayClose: false,
        spotlightClicks: false,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        disableOverlay: true,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.main-share',
        title: 'Bagikan Katalog',
        content: 'Sip.. persiapan selesai, saatnya kamu mulai berjualan. Bagikan katalogmu. Makin banyak dibagikan, makin besar kemungkinan cuan kamu dapatkan.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
    },
    {
        target: '.see-store',
        title: 'Lihat Toko',
        content: 'Kamu bisa lihat tampilan Toko Onlinemu. Kamu bisa lihat apa yang pelangganmu lihat ketika tokomu sudah di bagikan.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        showSkipButton: true,
        styles: {
            options: {
                zIndex: 100000000000,
            },
        },
        locale:{
            skip: 'Selesai'
        }
    },
    {
        target: '.see-store',
        title: 'Lihat Toko',
        content: 'Kamu bisa lihat tampilan Toko Onlinemu. Kamu bisa lihat apa yang pelangganmu lihat ketika tokomu sudah di bagikan.',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        spotlightPadding: 0,
        hideCloseButton: true,
        hideFooter: false,
        showSkipButton: true,
        styles: {
            options: {
                zIndex: 100000000000,
            }
        },
    },
];

const TourCatalog = (props) => {
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const [retry, setRetry] = useState(0);
    const revertStep = [1, 10, 9, 8, 7, 6, 13];

    useEffect(() => {
        // localforage.setItem('catalog-tour', { run: false, stepIndex: 5, finished: false })
        // console.log("run updated", run);
    }, [run]);

    useEffect(() => {
        setTimeout(() => {
            if (props.run) {
                localforage.getItem('catalog-tour').then((value) => {
                    const result = value || { run: false, stepIndex: 0, finished: props.autoFinish };
                    if (result.finished) {
                        setRun(false);
                        setStepIndex(0);
                        return;
                    }

                    let activeStepIndex = result.stepIndex;
                    while (revertStep.includes(activeStepIndex)) {
                        activeStepIndex = activeStepIndex - 1;
                    }

                    setStepIndex(activeStepIndex);
                    setRun(true);
                });
            }
        }, 1000);
    }, [props.run]);

    useEffect(() => {
        if (stepIndex != 0) {
            localforage.setItem('catalog-tour', { run, stepIndex, finished: false });
        }
    }, [stepIndex]);

    const nextStep = (e) => {
        if (stepIndex === 14) {
            setStepIndex(stepIndex + 1);
            setTimeout(() => {
                window.location.href = "/store/catalogs";
            }, 500);
            return;
        }

        if (stepIndex === 10) {
            setStepIndex(stepIndex + 1);
            setTimeout(() => {
                window.location.href = "/store/catalogs";
            }, 3000);
            return;
        }

        if (stepIndex === 3 || stepIndex === 4 || stepIndex === 11 || stepIndex === 13) {
            setStepIndex(stepIndex + 1);
            return;
        }

        setTimeout(() => {
            setStepIndex(stepIndex + 1);

            if ((stepIndex + 1) > steps.length) {
                localforage.setItem('catalog-tour', { run, stepIndex, finished: true });
            }
        }, 300);
    }

    const prevStep = () => {
        setStepIndex(stepIndex - 1);

        if ((stepIndex - 1) >= steps.length) {
            localforage.setItem('catalog-tour', { run, stepIndex, finished: true });
        }
    }

    const step1Prev = () => {
        prevStep();
        // document.querySelector(".cancel-button").removeEventListener("click", step1Prev);
    }

    const subscribeClick = (target) => {
        if (stepIndex == 1 || stepIndex == 13) {
            document.querySelector(".cancel-button").addEventListener("click", step1Prev);
        }

        document.querySelector(target).addEventListener("click", nextStep);
    }

    const unsubscribeClick = (target) => {
        const element = document.querySelector(target);
        if (element) {
            element.removeEventListener("click", nextStep);
        }
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, type, status } = data;
        if (EVENTS.STEP_BEFORE == type) {
            subscribeClick(data.step.target);
            setRetry(0);
        }

        if (EVENTS.STEP_AFTER == type) {
            unsubscribeClick(data.step.target);
        }

        if (action === ACTIONS.SKIP || (action === ACTIONS.NEXT && stepIndex == steps.length - 1 && EVENTS.STEP_AFTER)) {
            setRun(false);
            localforage.setItem('catalog-tour', { run, stepIndex, finished: true });
            return;
        }

        if (retry <= 3) {
            if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
                setRun(false);
                setTimeout(() => {
                    setRun(true);
                    setRetry(retry + 1);
                }, 500);
            } else {
                if (stepIndex === 2) {
                    // console.log(stepIndex);
                    setStepIndex(stepIndex + 1);
                    setTimeout(() => {
                        const target = window.document.querySelector(data.step.target + ' a');
                        if (target) {
                            window.location.href = target.getAttribute('href');
                        }
                    }, 200);
                };
            }
        }
    }

    return <Tour run={run} steps={steps} callback={handleJoyrideCallback} locale={{ back: 'Back', close: 'Selesai', last: 'Selesai', next: 'Next', skip: 'Lewati Tutorial' }} stepIndex={stepIndex} continuous={true} scrollToFirstStep={true} scrollOffset={50} showSkipButton />
}

export default TourCatalog;