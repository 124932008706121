import { useQuery } from 'react-query';

interface IResponse {
  data: any;
}
interface IReactQuery {
  queryName: string;
  queryFn: () => Promise<any>;
  queryParams?: any;
  responseType?: IResponse;
  querySettings?: any;
  refetchOnWindowFocus?: boolean;
}

function ReactQuery(props: IReactQuery) {
  const queryResponse = useQuery([props.queryName, props.queryParams], props.queryFn, {
    ...props.querySettings, // pass anything you need
    refetchOnWindowFocus: props.refetchOnWindowFocus,
  });

  return queryResponse;
}

export default ReactQuery;
