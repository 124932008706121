import { put, take, fork, call } from 'redux-saga/effects'
import { END } from 'redux-saga'

import PaymentBankListTransferActions, { PaymentBankListTransferTypes } from '../Redux/PaymentBankListTransferRedux'
import PaymentActions, { PaymentTypes } from '../Redux/PaymentRedux'
import ChangePaymentActions, { ChangePaymentTypes } from '../Redux/ChangePaymentRedux'
import { cartRequestPaymentAPI } from '../Sagas/CartSaga'

export function * paymentListRequest (api, getToken, getCartId) {
  let action = yield take(PaymentBankListTransferTypes.PAYMENT_BANK_LIST_TRANSFER_REQUEST)
  while (action !== END) {
    yield fork(paymentListRequestAPI, api, getToken, getCartId, action)
    action = yield take(PaymentBankListTransferTypes.PAYMENT_BANK_LIST_TRANSFER_REQUEST)
  }
}

export function * paymentListRequestAPI (api, getToken, getCartId, { data }) {
  try {
    // const cartId = yield call(getCartId)
    const token = yield call(getToken)
    let response

    if (!data) {
      response = yield call(api.getPaymentList, token)
    } else {
      response = yield call(api.getPaymentListWithPayGroup, data, token)
    }

    if (response.ok) {
      return yield put(PaymentBankListTransferActions.paymentBankListTransferSuccess(response.data.data))
    } else {
      return yield put(PaymentBankListTransferActions.paymentBankListTransferFailure(response.data.message))
    }
  } catch (err) {
    return yield put(PaymentBankListTransferActions.paymentBankListTransferFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * submitPaymentRequest (api, getToken, getCartId) {
  let action = yield take(PaymentTypes.SUBMIT_PAYMENT_REQUEST)
  while (action !== END) {
    yield fork(submitPaymentRequestAPI, api, getToken, getCartId, action)
    action = yield take(PaymentTypes.SUBMIT_PAYMENT_REQUEST)
  }
}

export function * submitPaymentRequestAPI (api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const mutatedPayload = {
     
          cart_id: { cartId },
          ...data
        
    }
    const response = yield call(api.submitPayment, mutatedPayload, token)
    if (response.ok) {
      yield fork(cartRequestPaymentAPI, api, getToken, getCartId)
      return yield put(PaymentActions.submitPaymentSuccess(response.data.data))
    } else {
      return yield put(PaymentActions.submitPaymentFailure(response.data.error.message))
    }
  } catch (err) {
    return yield put(PaymentActions.submitPaymentFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * submitPaymentWalletRequest (api, getToken, getCartId) {
  let action = yield take(PaymentTypes.SUBMIT_PAYMENT_WALLET_REQUEST)
  while (action !== END) {
    yield fork(submitPaymentWalletRequestAPI, api, getToken, getCartId, action)
    action = yield take(PaymentTypes.SUBMIT_PAYMENT_WALLET_REQUEST)
  }
}

export function * submitPaymentWalletRequestAPI (api, getToken, getCartId, { data }) {
  try {
    const cartId = yield call(getCartId)
    const token = yield call(getToken)
    const mutatedPayload = {
     
          cart_id: { cartId },
          ...data
        
    }
    const response = yield call(api.submitPaymentWallet, mutatedPayload, token)
    if (response.ok) {
      yield fork(cartRequestPaymentAPI, api, getToken, getCartId)
      return yield put(PaymentActions.submitPaymentWalletSuccess(response.data.data))
    } else {
      return yield put(PaymentActions.submitPaymentWalletFailure(response.data.error.message))
    }
  } catch (err) {
    return yield put(PaymentActions.submitPaymentWalletFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}

export function * changePaymentRequest (api, getToken) {
  let action = yield take(ChangePaymentTypes.CHANGE_PAYMENT_REQUEST)
  while (action !== END) {
    yield fork(changePaymentRequestAPI, api, getToken, action)
    action = yield take(ChangePaymentTypes.CHANGE_PAYMENT_REQUEST)
  }
}

export function * changePaymentRequestAPI (api, getToken, { data }) {
  try {
    const token = yield call(getToken)
    const response = yield call(api.changePayment, data, token)
    if (response.ok) {
      return yield put(ChangePaymentActions.changePaymentSuccess(response.data.data))
    } else {
      return yield put(ChangePaymentActions.changePaymentFailure(response.data.error.message))
    }
  } catch (err) {
    return yield put(ChangePaymentActions.changePaymentFailure('Terjadi kesalahan, silahkan ulangi beberapa saat lagi'))
  }
}