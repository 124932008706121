// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  cartRequest: null, // get cart detail - fetch from localforage
  cartSuccess: ['payload'],
  cartFailure: ['err'],
  updateCartRequest: ['data'],
  updateCartSuccess: ['payload'],
  updateCartFailure: ['err'],
  createCartRequest: ['data'],
  createCartSuccess: ['payload'],
  createCartFailure: ['err'],
  removeCartItemRequest: ['data'],
  removeCartItemSuccess: ['payload'],
  removeCartItemFailure: ['err'],
  applyFriendCodeRequest: ['friendCode'],
  applyFriendCodeSuccess: ['payload'],
  applyFriendCodeFailure: ['err'],
  removeFriendCodeRequest: ['friendCode'],
  removeFriendCodeSuccess: ['payload'],
  removeFriendCodeFailure: ['err'],
  removeAllCouponRequest: ['friendCode'],
  removeAllCouponSuccess: ['payload'],
  removeAllCouponFailure: ['err'],
  updateExpeditionRequest: ['brandownerId', 'deliveryType'],
  updateExpeditionSuccess: ['payload'],
  updateExpeditionFailure: ['err'],
  refreshShippingOptionRequest: ['data'],
  refreshShippingOptionSuccess: ['payload'],
  refreshShippingOptionFailure: ['err'],
  updatePaymentRequest: ['data'],
  updatePaymentSuccess: ['payload'],
  updatePaymentFailure: ['err'],
  setMarginRequest: ['data'],
  setMarginSuccess: ['payload'],
  setMarginFailure: ['err']
})

export const CartTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// GET CART
export const cartRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const cartSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const cartFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// UPDATE CART
export const updateCartRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const updateCartSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const updateCartFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// CREATE CART
export const createCartRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const createCartSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const createCartFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// CREATE CART
export const removeCartItemRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const removeCartItemSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const removeCartItemFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

// APPLY FRIEND CODE
export const applyFriendCodeRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const applyFriendCodeSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const applyFriendCodeFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })

// REMOVE FRIEND CODE
export const removeFriendCodeRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const removeFriendCodeSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const removeFriendCodeFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })

// REMOVE ALL COUPON
export const removeAllCouponRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const removeAllCouponSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const removeAllCouponFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })

// UPDATE EXPEDITION
export const updateExpeditionRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const updateExpeditionSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const updateExpeditionFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })

// REFRESH SHIPPING OPTIONS
export const refreshShippingOptionRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const refreshShippingOptionSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const refreshShippingOptionFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err: err
  })


  export const updatePaymentRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const updatePaymentSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const updatePaymentFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

  export const setMarginRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const setMarginSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const setMarginFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CART_REQUEST]: cartRequest,
  [Types.CART_SUCCESS]: cartSuccess,
  [Types.CART_FAILURE]: cartFailure,
  [Types.UPDATE_CART_REQUEST]: updateCartRequest,
  [Types.UPDATE_CART_SUCCESS]: updateCartSuccess,
  [Types.UPDATE_CART_FAILURE]: updateCartFailure,
  [Types.CREATE_CART_REQUEST]: createCartRequest,
  [Types.CREATE_CART_SUCCESS]: createCartSuccess,
  [Types.CREATE_CART_FAILURE]: createCartFailure,
  [Types.REMOVE_CART_ITEM_REQUEST]: removeCartItemRequest,
  [Types.REMOVE_CART_ITEM_SUCCESS]: removeCartItemSuccess,
  [Types.REMOVE_CART_ITEM_FAILURE]: removeCartItemFailure,
  [Types.APPLY_FRIEND_CODE_REQUEST]: applyFriendCodeRequest,
  [Types.APPLY_FRIEND_CODE_SUCCESS]: applyFriendCodeSuccess,
  [Types.APPLY_FRIEND_CODE_FAILURE]: applyFriendCodeFailure,
  [Types.REMOVE_FRIEND_CODE_REQUEST]: removeFriendCodeRequest,
  [Types.REMOVE_FRIEND_CODE_SUCCESS]: removeFriendCodeSuccess,
  [Types.REMOVE_FRIEND_CODE_FAILURE]: removeFriendCodeFailure,
  [Types.REMOVE_ALL_COUPON_REQUEST]: removeAllCouponRequest,
  [Types.REMOVE_ALL_COUPON_SUCCESS]: removeAllCouponSuccess,
  [Types.REMOVE_ALL_COUPON_FAILURE]: removeAllCouponFailure,
  [Types.UPDATE_EXPEDITION_REQUEST]: updateExpeditionRequest,
  [Types.UPDATE_EXPEDITION_SUCCESS]: updateExpeditionSuccess,
  [Types.UPDATE_EXPEDITION_FAILURE]: updateExpeditionFailure,
  [Types.REFRESH_SHIPPING_OPTION_REQUEST]: refreshShippingOptionRequest,
  [Types.REFRESH_SHIPPING_OPTION_SUCCESS]: refreshShippingOptionSuccess,
  [Types.REFRESH_SHIPPING_OPTION_FAILURE]: refreshShippingOptionFailure,
  [Types.UPDATE_PAYMENT_REQUEST]: updatePaymentRequest,
  [Types.UPDATE_PAYMENT_SUCCESS]: updatePaymentSuccess,
  [Types.UPDATE_PAYMENT_FAILURE]: updatePaymentFailure,
  [Types.SET_MARGIN_REQUEST]: setMarginRequest,
  [Types.SET_MARGIN_SUCCESS]: setMarginSuccess,
  [Types.SET_MARGIN_FAILURE]: setMarginFailure
})
