import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
// antd
import { Col, Row, Spin } from 'antd';
import { Block } from '../../../Components/Styled';

// Redux
import { connect } from 'react-redux';
import WooniversityActions from '../../../Redux/WooniversityRedux';
import WooniversityDetailActions from '../../../Redux/WooniversityDetailRedux';

// Illustration
// import arrowBackwardIcon from '../../../static/images/navigations/arrow-backward.svg'
import WooniversityLogo from '../../../static/images/logo/wooniversity.svg';
import Illustration from '../../../static/images/illustrations/proses.png';

// Components
import WooniversityList from '../../../Components/WooniversityList';
import WToolbar from '../../../shared/components/w-toolbar/w-toolbar';

class Wooniversity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wooniversityDetailState: undefined,
      wooniversityState: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let updated = {};
    if (
      !isEqual(
        nextProps.wooniversityDetailState,
        prevState.wooniversityDetailState
      ) &&
      !isEmpty(nextProps.wooniversityDetailState)
    ) {
      updated = {
        ...updated,
        wooniversityDetailState: nextProps.wooniversityDetailState,
      };
    }
    if (
      !isEqual(nextProps.wooniversityState, prevState.wooniversityState) &&
      !isEmpty(nextProps.wooniversityState)
    ) {
      updated = { ...updated, wooniversityState: nextProps.wooniversityState };
    }
    if (!isEmpty(updated)) {
      return updated;
    }

    return null;
  }

  componentDidMount() {
    const body = document.querySelector("body");
    if (body) {
      body.style.backgroundColor = "#fff";
    } else {
      body.style.backgroundColor = "#fff";
    }
    this.props.wooniversityRequest();
    this.props.wooniversityDetailRequest();
  }

  render() {
    const { wooniversityDetailState, wooniversityState } = this.state;
    const wooniversityDetail =
      !wooniversityDetailState.fetching &&
      wooniversityDetailState.success &&
      !isEmpty(wooniversityDetailState.data)
        ? wooniversityDetailState.data
        : {};
    const wooniversityPayload =
      !wooniversityState.fetching &&
      wooniversityState.success &&
      !isEmpty(wooniversityState.data)
        ? wooniversityState.data
        : {};

    if (!isEmpty(wooniversityDetail) && !isEmpty(wooniversityPayload)) {
      return (
        <div>
          <WToolbar backButton showChat showHome fixed>Pelatihan</WToolbar>
          <div className='background-white' style={{ paddingBottom: "75px" }}>
            <Row className='pt-2'>
              <Col span={12}>
                <div className='container'>
                  {/* <img src={arrowBackwardIcon} width='30px' alt='arrow-back-icon' onClick={() => this.history.goBack()} /> */}
                  <img
                    className='pt-2'
                    src={WooniversityLogo}
                    width='100%'
                    alt='logo'
                  />
                  <div className='font-size-xs font-color-tertiary pt-2'>
                    {wooniversityPayload.length} Modul | 13 Video
                  </div>
                  <div className='font-size-xs font-color-tertiary pt-3'>
                    <div>{wooniversityDetail.val}</div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className='text-right'>
                  <img src={Illustration} width='100%' alt='...' />
                </div>
              </Col>
            </Row>
            {wooniversityPayload.map((data, idx) => {
              return <WooniversityList detail={data} key={idx} id={idx} />;
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Block textAlign='center' paddingTop='30%'>
            <Spin />
          </Block>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  wooniversityState: state.wooniversityState,
  wooniversityDetailState: state.wooniversityDetailState,
});

const mapDispatchToProps = (dispatch) => ({
  wooniversityRequest: () =>
    dispatch(WooniversityActions.wooniversityRequest()),
  wooniversityDetailRequest: () =>
    dispatch(WooniversityDetailActions.wooniversityDetailRequest()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wooniversity)
);
