/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import config from 'legacy/config';
import { Flex } from 'shared/components/flex';
import { Text } from 'shared/components/typhography';
import numberFormat from 'utils/number-format';
import Button from 'shared/components/forms/button';
import { updateCartTemporaryRequest } from 'shared/apis/command/cart.command';
import { cartTemporaryDetailQuery } from 'shared/apis/queries/cart.query';
import toast from 'react-hot-toast';
import { InputRadioLabel } from '.';

export function OrderDrawerVariant({ product, toggleVisibility }) {
  const cartQuery = cartTemporaryDetailQuery();
  const cartPayload = cartQuery.data?.data.data;

  const [variant, setVariant] = React.useState();
  const [selectedVariant, setSelectedVariant] = React.useState();
  const [productVariant, setProductVariant] = React.useState();

  const productImage = product.variants[0].images[0].image_url;
  const variantChanged = (value, index) => {
    setSelectedVariant({ ...selectedVariant, [`option${index + 1}`]: value });
  };

  const getVariantSelected = () => {
    const newObj = {};
    (variant || []).forEach((element) => {
      // eslint-disable-next-line no-unused-expressions
      if (selectedVariant) {
        element.option1 && (newObj.option1 = selectedVariant.option1);
        element.option2 && (newObj.option2 = selectedVariant.option2);
        element.option3 && (newObj.option3 = selectedVariant.option3);
      }
    });

    const result = _.find(variant, newObj);
    setProductVariant(result);
  };

  useEffect(() => {
    const option = [];
    product.variants.forEach((element) => {
      option.push({
        option1: element.option1,
        option2: element.option2,
        option3: element.option3,
        sku: element.sku,
        price: element.prices[0]?.selling_price,
        stock: element.stock,
      });
      setVariant(option);
    });
  }, []);

  useEffect(() => {
    if (variant || product) {
      getVariantSelected();
    }
  }, [selectedVariant]);

  const handleExistProduct = () => {
    addToCart(addToCartExistProduct());
  };

  const addToCartExistProduct = () => {
    let qty = 1;
    // If product already in cart
    if (!isEmpty(cartPayload.items || [])) {
      (cartPayload.items[0].details || []).find((item) => {
        if (item.sku === productVariant.sku) {
          qty = item.qty_ordered + 1;
          return qty;
        }
      });
    }
    return qty;
  };

  const addToCart = (qty_ordered) => {
    updateCartTemporaryRequest({
      items: [
        {
          sku: productVariant.sku,
          qty_ordered,
        },
      ],
    })
      .then(() => {
        toggleVisibility();
        cartQuery.refetch();
        toast.success('Berhasil menambahkan ke keranjang');
      })
      .catch((err) => {
        toast.error('Gagal menambahkan ke keranjang');
        toggleVisibility();
      });
  };

  return (
    <div>
      <Flex justify="start">
        <img
          src={`${config.imageURL}${productImage}`}
          width="50"
          height="50"
          style={{ objectFit: 'cover', borderRadius: '5px' }}
          alt={product.name}
        />
        <Flex direction="column" marginLeft="1rem">
          <Text size={14} weight="600">
            {product.name}
          </Text>
          <Text marginTop="1rem" size={12}>
            Harga
          </Text>
          <Text size={16} weight="700">
            Rp
            {' '}
            {numberFormat(productVariant?.price || 0)}
          </Text>
        </Flex>
      </Flex>
      {(product.variantOptions || []).map((item, index) => (
        <div key={index.name}>
          <div className="border-bottom mt-4 mb-4" />
          <InputRadioLabel
            items={item.values}
            name={item.name}
            onChange={(value) => {
              variantChanged(value, index);
            }}
          />
        </div>
      ))}
      <Button
        disabled={productVariant?.stock === 0}
        marginTop="2rem"
        onClick={handleExistProduct}
        color="eggBlue"
        block
      >
        Lanjut
      </Button>
    </div>
  );
}
