// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addProductInit: ['data'],
  addProductSuccess: ['payload'],
  addProductFailure: ['err']
})

export const AjukanProdukTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const addProductInit = (state) =>
  state.merge({
    fetching: true, success: false, err: null
  })

export const addProductSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const addProductFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PRODUCT_INIT]: addProductInit,
  [Types.ADD_PRODUCT_SUCCESS]: addProductSuccess,
  [Types.ADD_PRODUCT_FAILURE]: addProductFailure
})
