import React from "react";
import './styles.scss';

function ScrollWrapper({ children }) {
  return (
    <div className='wrapper'>
      {children}
      <div className='empty' />
    </div>
  );
}

export default ScrollWrapper;
