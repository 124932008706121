import React, { Component } from 'react'
import BottomSheet from '../../../BottomSheet'
import DefaultLayout from '../../../../Layouts/DefaultLayout'
import { Loader } from 'google-maps'
import './FindLocation.scss'

const options = {}
const loader = new Loader('AIzaSyBJ2ZIer5nnhXb6k4-VQKs1BdLVjrSH5L4', options)
// const googleMapsClient = require('@google/maps').createClient({
//     key: 'AIzaSyBJ2ZIer5nnhXb6k4-VQKs1BdLVjrSH5L4'
// });

export class FindLocation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      drawer: false
    }
  }

  componentDidMount () {
    this.setupGoogleMap()
  }

  setupGoogleMap () {
    loader.load().then((google) => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 18,
        disableDefaultUI: true
      })

      this.marker = new google.maps.Marker({
        position: { lat: -34.397, lng: 150.644 },
        map: this.map,
        title: 'Lokasi Anda'
      })

      this.map.addListener('bounds_changed', () => {
        this.marker.setPosition(this.map.getCenter())
        this.closeDrawer()
      })

      this.getcurrentLocation().then((result) => {
        this.map.setCenter(result)
      })
    })
  }

  getcurrentLocation () {
    if (navigator && navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(pos => {
          const coords = pos.coords
          resolve({
            lat: coords.latitude,
            lng: coords.longitude
          })
        })
      })
    }
    return {
      lat: 0,
      lng: 0
    }
  }

  closeDrawer () {
    if (this.state.drawer != false) {
      this.setState({ drawer: false })
    }
  }

  render () {
    return (
      <div className='map-overlayer'>
        <DefaultLayout disableHeader>
          <div id='map' style={{ height: '75vh' }} />

          <BottomSheet onChange={() => { this.setState({ drawer: true }) }} shadowTip={false} overflowHeight={350} visible={this.state.drawer} overlay={false} toggleVisibility={() => { this.closeDrawer() }} marginTop={50}>
            <div style={{ height: '80vh' }}>
              <h5>Tentukan Lokasi</h5>
              <p className='text-muted'>Lokasi mempengaruhi informasi yang akan di tampilkan. Cek informasi detail pengiriman.</p>

              <div className='d-flex location-search-input'>
                <span className='fa fa-map-marker' />
                <input className='lsi__holder' placeholder='Tentukan Lokasi' />
                <span className='fa fa-search' />
              </div>

              <div className='current-location'>
                <span className='fa fa-crosshairs' />
                <div>
                  <div className='cl_explain'>Gunakan lokasi saat ini</div>
                  <p className='cl_current'>Nama tempat saat ini</p>
                </div>
              </div>

              <button className='btn btn-primary btn-block mt-3'>Pilih Lokasi</button>
            </div>
          </BottomSheet>
        </DefaultLayout>
      </div >
    )
  }
}

// GoogleApiWrapper({
//     apiKey: "AIzaSyBJ2ZIer5nnhXb6k4-VQKs1BdLVjrSH5L4"
// })(FindLocation)
