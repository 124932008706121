import { Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'shared/components/forms/button';
import {
  ButtonPrimaryShadow,
  ButtonWhiteShadow,
} from '../../Components/Styled';
import { api, token as getToken } from '../../Redux/Apis';
import { getSafely } from '../../Utils';

const NewCatalogDialog = (props) => {
  const {
    register, errors, formState, getValues, setError, clearErrors,
  } = useForm({
    mode: 'onChange',
  });

  const rule = {
    name: {
      required: {
        value: true,
        message: 'Nama katalog tidak boleh kosong',
      },
    },
  };

  const errorName = getSafely(['name', 'message'], errors);

  const submit = async () => {
    if (!formState.isValid) {
      return;
    }

    const token = await getToken();
    api
      .createCatalog(token, { name: getValues('name') })
      .then((res) => {
        api.createProductCatalog(token, res.data.data.id, {
          products: [
            {
              sku: props.sku,
              margin: props.margin,
            },
          ],
        });
      })
      .then(() => {
        props.onSuccess();
      });
  };

  return (
    <Modal
      visible={props.visible}
      width={320}
      bodyStyle={{ textAlign: 'center', padding: 15 }}
      footer={null}
      zIndex={9999999999}
      closable={false}
    >
      <h2 style={headingStyle}>Buat Katalog</h2>
      <div className="mt-4 px-2 pb-2 text-left">
        <div className="text-left">
          <small className="text-muted">Nama Katalog</small>
        </div>
        <input
          name="name"
          type="text"
          ref={register(rule.name)}
          className="form-control border-top-0 border-right-0 border-left-0 no-box-shadow font-size-s px-0"
          placeholder="Masukkan Nama Katalog"
        />
        {errorName ? <small className="text-danger">{errorName}</small> : null}

        <div className="row mt-5 no-gutters">
          <div className="col-6 pr-1">
            <Button block size="medium" variant="outline" color="eggBlue" onClick={props.onClosed}>BATAL</Button>
          </div>
          <div className="col-6 pl-1">
            <Button
              disabled={!formState.isValid}
              block
              size="medium"
              variant="default"
              color="eggBlue"
              onClick={submit}
            >
              TAMBAH
            </Button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

const headingStyle = {
  fontSize: '14px',
};

export default NewCatalogDialog;
