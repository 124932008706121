import { put, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import BrandProductReducer from './../../Reducers/Category/BrandProductReducer'

export function * brandProductRequest (api, getToken) {
  let action = yield take(BrandProductReducer.Types.BRAND_PRODUCT_REQUEST)
  while (action !== END) {
    const token = yield call(getToken)
    const { brandId, page } = action
    const response = yield call(api.getProductBrand, brandId, page, token)
    if (response.ok) {
      yield put(BrandProductReducer.Creators.brandProductSuccess(response.data.data))
    } else {
      yield put(BrandProductReducer.Creators.brandProductFailure(response.data.message))
    }
    action = yield take(BrandProductReducer.Types.BRAND_PRODUCT_REQUEST)
  }
}
