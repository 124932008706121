import { put, take, fork } from 'redux-saga/effects'
import { END } from 'redux-saga'
import localforage from 'localforage'

// Redux Stuffs
import RouteAuthActions, { RouteAuthTypes } from '../Redux/RouteAuthRedux'

export function * routeAuthRequest () {
  let action = yield take(RouteAuthTypes.ROUTE_AUTH_REQUEST)
  while (action !== END) {
    yield fork(authenticateRoute, action)
    action = yield take(RouteAuthTypes.ROUTE_AUTH_REQUEST)
  }
}

export function * authenticateRoute (data) {
  // kalo mau tes redirection nya, pertama setItem ini dibuka, refresh trs di tutup lagi.
  // kalo uda punya token, berarti dia uda login ibaratnya, jadi dia bsa kemana aja, kecuali halaman login/register/welcome-page
  // sebaliknya, kalau dia ga punya token, dia ga bsa ke pdp/catalog

  const token = yield localforage.getItem('auth_token')
  if (token) {
    // next: harus fetch login?
    yield put(RouteAuthActions.routeAuthSuccess({ auth_token: token }))
  } else {
    yield put(RouteAuthActions.routeAuthSuccess({ auth_token: 'NO_TOKEN' }))
  }
}
