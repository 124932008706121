// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  productCommissionRequest: ['sku', 'qty'],
  productCommissionSuccess: ['payload'],
  productCommissionFailure: ['err']
})

export const ProductDetailCommissionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch
export const request = (state) =>
  state.merge({ fetching: true, success: false })

export const success = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
    err: null
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_COMMISSION_REQUEST]: request,
  [Types.PRODUCT_COMMISSION_SUCCESS]: success,
  [Types.PRODUCT_COMMISSION_FAILURE]: failure
})
