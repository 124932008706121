import { put, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import DisplayCategoryReducer from './../../Reducers/Category/DisplayCategoryReducer'

export function* displayCategoryRequest(api, getToken) {
    let action = yield take(DisplayCategoryReducer.Types.DISPLAY_CATEGORY_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.getDisplayCategory, action.isNative, token)
        if (response.ok) {
            yield put(DisplayCategoryReducer.Creators.displayCategorySuccess(response.data.data))
        } else {
            yield put(DisplayCategoryReducer.Creators.displayCategoryFailure(response.data.message))
        }
        action = yield take(DisplayCategoryReducer.Types.DISPLAY_CATEGORY_REQUEST)
    }
}