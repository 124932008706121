import styled from 'styled-components'

export const SectionWrapper = styled.section`
  font-family: Lato, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
`
export const Block = styled.div`
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  margin-bottom: ${props => props.marginBottom};
  border-radius: ${props => props.borderRadius};
  margin-top: ${props => props.marginTop};
  position: ${props => props.position};
  width: ${props => props.width};
  margin: ${props => props.margin};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  text-align: ${props => props.textAlign};
  cursor: ${props => props.cursor};
  background-color: ${props => props.backgroundColor};
  box-shadow: ${props => props.boxShadow};
  border: ${props => props.border};
  z-index: ${props => props.zIndex};
`
