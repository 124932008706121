import React, { ReactNode } from 'react';
import FlexType, { flexTypePropsHelper } from 'shared/types/flex.type';
import MarginType, { marginTypePropsHelper } from 'shared/types/margin.type';

import { PaddedStyled } from './padded.styled';

type PaddedProps = {
  children?: ReactNode;
  bottom?: string;
  top?: string;
  left?: string;
  right?: string;
  background?: string;
  shadow?: boolean;
  borderRadius?: string;
  borderBottomRadius?: string;
  height?: string;
} & FlexType &
  MarginType;

const Padded = (props: PaddedProps) => (
  <PaddedStyled
    bottom={props.bottom}
    right={props.right}
    height={props.height}
    left={props.left}
    borderRadius={props.borderRadius}
    borderBottomRadius={props.borderBottomRadius}
    top={props.top}
    background={props.background}
    shadow={props.shadow}
    {...flexTypePropsHelper(props)}
    {...marginTypePropsHelper(props)}
  >
    {props.children}
  </PaddedStyled>
);

Padded.defaultProps = {
  children: 'This is chidlren default string',
  top: '20px',
  bottom: '20px',
  left: '20px',
  right: '20px',
  background: '#FFF',
  borderRadius: '',
  borderBottomRadius: '',
  shadow: false,
};

export { Padded };
