import axios, { AxiosResponse } from 'axios';
import tokenBroker from './tokenBroker';

type methodType = 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'GET';

const api = <ApiRequest = any, ApiResponse = any>(
  method: methodType,
  url: string,
  data?: ApiRequest,
  guestMode = false,
) => {
  let headers = {};

  if (!guestMode) {
    headers = {
      Authorization: `Bearer ${tokenBroker.get()}`,
      'Content-Type': 'application/json',
      'Accept-Language': 'id',
      ...headers,
    };
  }

  axios.interceptors.request.use(
    async (config: any) => {
      const token = await tokenBroker.get();

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: any) => {
      throw error;
    },
  );

  switch (method) {
    case 'GET':
      return axios.get<ApiRequest, AxiosResponse<ApiResponse>>(url, {
        headers,
      });
    case 'POST':
      return axios.post<ApiRequest, AxiosResponse<ApiResponse>>(url, data, {
        headers,
      });
    case 'PATCH':
      return axios.patch<ApiRequest, AxiosResponse<ApiResponse>>(url, data, {
        headers,
      });
    case 'PUT':
      return axios.put<ApiRequest, AxiosResponse<ApiResponse>>(url, data, {
        headers,
      });
    case 'DELETE':
      return axios.delete<ApiRequest, AxiosResponse<ApiResponse>>(url, {
        data,
        headers,
      });
    default:
      return axios.get<ApiRequest, AxiosResponse<ApiResponse>>(url, {
        headers,
      });
  }
};

export type ApiResponse<T> = {
  status: number;
  message: string;
  validation: any;
  data: T;
};

export type PaginatedRequest = {
  page?: number;
  limit?: number;
};

export type PaginatedResponse<T> = {
  data?: T[];
  meta?: {
    current_page?: number;
    from?: number;
    last_page?: number;
    path?: string;
    per_page?: number;
    to?: number;
    total_data?: number;
  };
  links?: {
    self?: string;
    first?: string;
    last?: string;
    prev?: null;
    next?: string;
  };
};

export default api;
