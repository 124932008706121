import React from 'react';
import { withRouter } from 'react-router';
import BottomSheet from '../../Containers/BottomSheet';
import { ButtonPrimary } from '../Styled';

const CatalogNotActivatedDrawer = (props) => (
  <BottomSheet {...props}>
    <div className="text-center">
      <h2 style={{ fontSize: '14px', fontFamily: 'Montserrat' }}>
        Toko Belum Aktif
      </h2>
    </div>
    <p style={{ fontSize: '12px' }} className="mb-0 text-center">Sebelum mulai jualan, Yuk aktifin Tokomu sekarang!</p>
    <div className="d-flex mt-3">
      <ButtonPrimary className="ml-1" onClick={() => { props.history.push('/store'); }}>Aktifkan</ButtonPrimary>
    </div>
  </BottomSheet>
);

export default withRouter(CatalogNotActivatedDrawer);
