// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  productSpecialRequest: ['page', 'limit', 'qty', 'sort'],
  productSpecialSuccess: ['payload'],
  productSpecialFailure: ['err']
})

export const ProductSpecialTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const request = (state) =>
  state.merge({ fetching: true, success: false })

export const success = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true,
    err: null
  })

export const failure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_SPECIAL_REQUEST]: request,
  [Types.PRODUCT_SPECIAL_SUCCESS]: success,
  [Types.PRODUCT_SPECIAL_FAILURE]: failure
})
