// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const actionTypes = createActions({
  allCategoryRequest: null,
  allCategorySuccess: ['payload'],
  allCategoryFailure: ['err']
})

const { Types } = actionTypes

export default actionTypes

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const allCategoryRequest = (state) =>
  state.merge({ fetching: true, success: false })

export const allCategorySuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const allCategoryFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ALL_CATEGORY_REQUEST]: allCategoryRequest,
  [Types.ALL_CATEGORY_SUCCESS]: allCategorySuccess,
  [Types.ALL_CATEGORY_FAILURE]: allCategoryFailure
})
