import { put, take, call, fork, all } from 'redux-saga/effects'
import { END } from 'redux-saga'
import categoryProductReducer from '../../Reducers/Category/categoryProductReducer'
import { api, token as getToken } from '../../Apis'

export function* categoryProductRequest(api, getToken) {
    let action = yield take(categoryProductReducer.Types.CATEGORY_PRODUCT_REQUEST)
    while (action !== END) {
        const token = yield call(getToken)
        const response = yield call(api.getProductByCategory, action.categoryId, action.page, token)
        if (response.ok) {
            yield put(categoryProductReducer.Creators.categoryProductSuccess(response.data.data))
        } else {
            yield put(categoryProductReducer.Creators.categoryProductFailure(response.data.message))
        }
        action = yield take(categoryProductReducer.Types.CATEGORY_PRODUCT_REQUEST)
    }
}

export function* productByCategorySaga() {
    yield all([
        fork(categoryProductRequest, api, getToken),
    ])
}