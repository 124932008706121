import styled from 'styled-components'

export const Divider = styled.div`
  width: 100%;
  height: 6px;
  background-color: #f4f4f4;
`

export const DividerCart = styled.div`
  width: 100%;
  height: 7px;
  background-color: #f4f4f4;
`

export const DividerLight = styled.div`
  width: 100%;
  height: 1px;
  background-color:  ${props => props.primary ? '#e7ebf9' : '#f4f4f4'} ;
`
export const DividerBold = styled.div`
  width: 100%;
  height: 9px;
  background-color: #f4f4f4;
`
export const DividerVertical = styled.div`
  border-left: 1px solid #e5e5e5;
  height: 25px;
`
