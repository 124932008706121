// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userCommissionHistoryDebitRequest: null,
  userCommissionHistoryDebitSuccess: ['payload'],
  userCommissionHistoryDebitFailure: ['err']
})

export const UserCommissionDebitHistoryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

// Fetch debit history

export const userCommissionHistoryDebitRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const userCommissionHistoryDebitSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const userCommissionHistoryDebitFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_COMMISSION_HISTORY_DEBIT_REQUEST]: userCommissionHistoryDebitRequest,
  [Types.USER_COMMISSION_HISTORY_DEBIT_SUCCESS]: userCommissionHistoryDebitSuccess,
  [Types.USER_COMMISSION_HISTORY_DEBIT_FAILURE]: userCommissionHistoryDebitFailure
})
