// @flow
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  init: null,
  resendOtpRequest: ['phoneNumber'],
  resendOtpSuccess: ['payload'],
  resendOtpFailure: ['err']
})

export const ResendOtpTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  data: {},
  success: false,
  err: null
})

/* ------------- Reducers ------------- */
// we're attempting to fetch

export const init = (state) =>
  state.merge({
    fetching: false,
    data: {},
    success: false,
    err: null
  })

// resendOtp
export const resendOtpRequest = (state) =>
  state.merge({ fetching: true, success: false, err: null })

export const resendOtpSuccess = (state, { payload }) =>
  state.merge({
    fetching: false,
    data: payload,
    success: true
  })

export const resendOtpFailure = (state, { err }) =>
  state.merge({
    fetching: false,
    success: false,
    err
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT]: init,
  [Types.RESEND_OTP_REQUEST]: resendOtpRequest,
  [Types.RESEND_OTP_SUCCESS]: resendOtpSuccess,
  [Types.RESEND_OTP_FAILURE]: resendOtpFailure
})
