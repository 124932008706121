import localforage from 'localforage';
import baseUrl from '../baseUrl';
import wrapperApi from '../index';

type IUpdateCart = {
  items: Array<{
    sku: string;
    qty_ordered: number;
  }>
}

export const updateCartTemporaryRequest = async (data: IUpdateCart) => {
  const response = await wrapperApi(
    'PUT',
    baseUrl('v2/cart/temporary/update'), data,
  );
  return response;
};

export const getCartTemporaryRequest = async () => {
  // const cartID = await localforage.getItem('cart_id');
  const response = await wrapperApi(
    'GET',
    baseUrl('v2/cart/temporary/detail'),
  );
  return response;
};
