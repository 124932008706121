import styled from 'styled-components'

export const PelatihanCard = styled.div`
  width: 100%;
  height: 220px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`
export const BankCard = styled.div`
  width: 100%;
  height: 75px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
`
export const ListItemsCard = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem;
`
