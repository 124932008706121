/* eslint-disable react/prefer-stateless-function */
import React, {
  Component, Suspense, lazy, useEffect,
} from 'react';
import {
  BrowserRouter,
  Switch,
  Redirect,
  withRouter,
  Route,
} from 'react-router-dom';
import localforage from 'localforage';
import config from './config';

import {
  Wooniversity,
  DynamicPage,
  KodeSahabatPage,
} from './Containers/EventPage/EventPage';

// Page Not Found
import { FindLocation } from './Containers/Product/ProductDetail/_components/FindLocation';
import BrandPage from './Containers/BrandPage/BrandPage';
import {
  CommissionGPS,
  Emas,
  PeluangUsaha,
  TentangGPS,
  UndianGPS,
} from './Containers/GPS/components';
import { getUrlParameter } from './Utils';

/**
 *  RINTIZ ROUTE
 */

/** @AUTH_ROUTE_RINTIZ */
const BoardingPage = lazy(() => import('pages/auth/boarding-page'));
const LoginPage = lazy(() => import('pages/auth/login-page'));
const RegisterPage = lazy(() => import('pages/auth/register-page'));
const OtpPage = lazy(() => import('pages/auth/otp-page'));
const WelcomePage = lazy(() => import('pages/auth/welcome-page'));
const MembershipPage = lazy(() => import('pages/auth/membership-page'));

/** @HOME_AUTH_RINTIZ */
const HomePage = lazy(() => import('pages/home-page'));

/** @ACCOUNT_RINTIZ */
const AccountPage = lazy(() => import('legacy/Containers/Account/AccountPage'));
const MembershipListPage = lazy(() => import('pages/account-page/membership-list-page'));
const MembershipSubsPage = lazy(() => import('pages/account-page/membership-subs-page'));

// const AccountPage = lazy(() => import('pages/account-page'));

/**
 * @TRANSACTION_RINTIZ
 */
const TransactionDetailsPage = lazy(() => import('pages/transaction/transaction-details/transaction-details.page'));
const TransactionPage = lazy(() => import('pages/transaction/transaction-list/transaction-page'));
// const TransactionPage = lazy(() => import('./Containers/Transaction/transactionListPage/TransactionPage'));
// const TransactionBrand = lazy(() => import('./Containers/Transaction/TransactionBrand'));

/** @PRODUCT_RINTIZ */
// const ProductDetailPage = lazy(() => import('pages/product-page'));
const ProductDetailPage = lazy(() => import('./Containers/Product/ProductDetail/ProductDetailPage'));

// Category Containers
const CategoryBrand = lazy(() => import('./Containers/Category/CategoryBrand/CategoryBrand'));
const AllCategory = lazy(() => import('./Containers/Category/AllCategory/AllCategory'));

// Product
const CollectionDetail = lazy(() => import('./Containers/Product/DetailCollection/ProductDetailCollection'));
const DetailSpecialProduct = lazy(() => import('./Containers/Product/DetailHome/DetailSpecialProduct'));
const ShowAllProductUnder300 = lazy(() => import('./Containers/Product/DetailHome/ShowAllProductUnder300'));
// const ShowAllProductUnder200 = lazy(() => import('./Containers/Product/DetailHome/ShowAllProductUnder200'));
const ShowAllProductUnder100 = lazy(() => import('./Containers/Product/DetailHome/ShowAllProductUnder100'));
const ShowAllMostShareCollection = lazy(() => import('./Containers/Product/DetailHome/ShowAllMostShareCollection'));
const ShowAllSelectionCollection = lazy(() => import('./Containers/Product/DetailHome/ShowAllSelectionCollection'));
const ShowAllSahabatBrand = lazy(() => import('./Containers/Product/DetailHome/ShowAllSahabatBrand'));

const PageNotFound = lazy(() => import('./Containers/404Page'));
const SingleCategory = lazy(() => import('./Containers/Category/SingleCategory/SingleCategory'));
const CommissionHistory = lazy(() => import('./Containers/User/HistoryCommissionPage/CommissionHistory'));

// Event page
const DetailWooniversity = lazy(() => import('./Containers/EventPage/WooniversityPages/WooniversityList'));

const ViewProduct = lazy(() => import('./Components/ViewProduct'));
const ThankyouPage = lazy(() => import('./Containers/Transaction/ThankyouPage'));
const ThankyouWallet = lazy(() => import('./Containers/Transaction/ThankyouWallet'));
const FailedWallet = lazy(() => import('./Containers/Transaction/FailedWallet'));
const InstructionPage = lazy(() => import('./Containers/Transaction/PaymentInstructionPage'));
const AjukanProduk = lazy(() => import('./Containers/Product/AjukanProduk/AjukanProduk'));

// Guard

const GuardRoute = lazy(() => import('./Containers/GuardRoute'));

// Search
const SearchPage = lazy(() => import('./Containers/SearchPage/SearchPage'));

// Account Routes
const BankAccountList = lazy(() => import('./Containers/User/BankAccountList'));
const AddBankAccount = lazy(() => import('./Containers/User/AddBankAccount'));
const AddAddressPage = lazy(() => import('./Containers/User/AddAddressPage'));
const UserProfile = lazy(() => import('./Containers/User/UserProfile'));
const UserAddressList = lazy(() => import('./Containers/User/UserAddressList'));
const EditAddressPage = lazy(() => import('./Containers/User/EditAddressPage'));
const HistoryCommissionPage = lazy(() => import('./Containers/User/HistoryCommissionPage/HistoryCommissionPage'));
const ApplyFriendCode = lazy(() => import('./Containers/User/ApplyFriendCode'));
const DetailFriendCode = lazy(() => import('./Containers/User/DetailFriendCode'));
const HelpCenter = lazy(() => import('./Containers/Account/HelpCenter'));
const KYCIDCard = lazy(() => import('./Containers/User/KYCIDCard'));
const KYCSelfie = lazy(() => import('./Containers/User/KYCSelfie'));
const PreviewKYC = lazy(() => import('./Containers/User/PreviewKYC'));
const Mailbox = lazy(() => import('./Containers/Inbox/Inbox'));
const MailboxDetail = lazy(() => import('./Containers/Inbox/components/MailboxDetail'));
const WithDrawalCommission = lazy(() => import('./Containers/User/Withdraw'));

// Transaction Routes
const Cart = lazy(() => import('./Containers/Cart/ShoppingCart'));
const CheckoutPage = lazy(() => import('./Containers/Cart/CheckoutPage'));
const PaymentList = lazy(() => import('./Containers/Transaction/PaymentList/PaymentList'));
const CourierList = lazy(() => import('./Containers/Transaction/CourierList/CourierList'));
const DetailCoupon = lazy(() => import('./Containers/Cart/DetailCoupon'));
const EditAddressCart = lazy(() => import('./Containers/Cart/EditAddressCart'));
const TrackDetail = lazy(() => import('./Containers/Transaction/TrackDetail'));
const CheckoutSetMargin = lazy(() => import('./Containers/Cart/CheckoutSetMargin'));
const NoConnection = lazy(() => import('./Containers/NoConnection'));

const StorePage = lazy(() => import('./Containers/Store'));

// Catalog
const Catalogs = lazy(() => import('./Containers/Catalogs/Catalogs'));
const CustomerDetailPage = lazy(() => import('./Containers/Catalogs/CustomerDetail'));
const EditStorePage = lazy(() => import('./Containers/Catalogs/EditStore'));
const CatalogDetailPage = lazy(() => import('./Containers/Catalogs/CatalogDetailPage'));
const PreviewCatalog = lazy(() => import('./Containers/Catalogs/preview-catalog/PreviewCatalog'));
// GPS
const GaransiPastiSuskes = lazy(() => import('./Containers/GPS/GaransiPastiSuskses'));
const ProfileGPS = lazy(() => import('./Containers/GPS/GPS-Profile/ProfileGPS'));

const Continue = withRouter((props) => {
  useEffect(() => {
    localforage.setItem('auth_token', props.match.params.token).then(() => {
      window.location.href = getUrlParameter('to');
    });
  }, []);
  return <></>;
});

export default class Entry extends Component {
  render() {
    return (
      <BrowserRouter
        basename={
          config.browserRouterBasename && config.browserRouterBasename !== ''
            ? config.browserRouterBasename
            : ''
        }
      >
        <Suspense fallback="">
          <Switch>
            <Route path="/continue/:token" component={Continue} />
            {/* <GuardRoute path='/' component={TargetPreferences} accesibleWhenLoggedIn /> */}
            <GuardRoute
              path="/(|home|index|catalog)/"
              component={HomePage}
              accesibleWhenLoggedIn
            />

            <GuardRoute
              path="/no-connections"
              component={NoConnection}
              accesibleWhenLoggedIn
            />

            <GuardRoute
              path="/welcome-page"
              component={WelcomePage}
              accesibleWhenLoggedIn={false}
            />
            <GuardRoute
              path="/product-detail"
              component={ProductDetailPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/product/shipping"
              component={FindLocation}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/product/special-product"
              component={DetailSpecialProduct}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/product/product-under-300"
              component={ShowAllProductUnder300}
              accesibleWhenLoggedIn
            />
            {/* <GuardRoute path='/product/product-under-200' component={ShowAllProductUnder200} accesibleWhenLoggedIn /> */}
            <GuardRoute
              path="/product/product-under-100"
              component={ShowAllProductUnder100}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/product/:sku"
              component={ProductDetailPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/collection/most-share-collection"
              component={ShowAllMostShareCollection}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/collection/selection-collection"
              component={ShowAllSelectionCollection}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/collection/detail/:slug"
              component={CollectionDetail}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/sahabat-brand"
              component={ShowAllSahabatBrand}
              accesibleWhenLoggedIn
            />
            {/* <GuardRoute path='/view' component={ProductDetailPage} /> */}
            {/* <GuardRoute path='/register/create-password' component={CreatePasswordPage} accesibleWhenLoggedIn={false} /> */}

            {/**
             * @Route_Auth
             */}
            <GuardRoute
              path="/register"
              component={RegisterPage}
              accesibleWhenLoggedIn={false}
            />
            <GuardRoute
              path="/login"
              component={LoginPage}
              accesibleWhenLoggedIn={false}
            />
            <GuardRoute
              path="/boarding-slider"
              component={BoardingPage}
              accesibleWhenLoggedIn={false}
            />

            <GuardRoute
              path="/verify-method"
              component={OtpPage}
              accesibleWhenLoggedIn={false}
            />

            <GuardRoute
              path="/membership"
              component={MembershipPage}
              accesibleWhenLoggedIn
            />
            {/**
             * @Route_Auth END
             */}

            {/**
             * @Transaction_Routes
             */}
            <GuardRoute
              path="/transaction/:id"
              component={TransactionDetailsPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/transaction"
              component={TransactionPage}
              accesibleWhenLoggedIn
            />

            {/**
             * @Transaction_Routes_end
             */}

            <GuardRoute
              path="/search"
              component={SearchPage}
              accesibleWhenLoggedIn
              animated
            />
            <GuardRoute
              path="/shopping-cart"
              component={Cart}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/checkout"
              component={CheckoutPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/courier-list"
              component={CourierList}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/payment-list"
              component={PaymentList}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/set-margin"
              component={CheckoutSetMargin}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/coupon"
              component={DetailCoupon}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/edit-address-checkout"
              component={EditAddressCart}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/brand"
              component={BrandPage}
              accesibleWhenLoggedIn
            />

            {/* Account */}
            <GuardRoute
              path="/account/bank-list"
              component={BankAccountList}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/add-bank-account"
              component={AddBankAccount}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/edit-profile"
              component={UserProfile}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/address-list"
              component={UserAddressList}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/add-address"
              component={AddAddressPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/edit-address"
              component={EditAddressPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/apply-friend-code"
              component={ApplyFriendCode}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/detail-friend-code"
              component={DetailFriendCode}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/kyc-id-card"
              component={KYCIDCard}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/kyc-selfie"
              component={KYCSelfie}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/preview-kyc"
              component={PreviewKYC}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/mailbox/mailbox-detail"
              component={MailboxDetail}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/mailbox"
              component={Mailbox}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/commission/history"
              component={HistoryCommissionPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/membership/history"
              component={MembershipListPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account/membership/subscribe"
              component={MembershipSubsPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/account"
              component={AccountPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/pusat-bantuan"
              component={HelpCenter}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/ajukan-produk"
              component={AjukanProduk}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/withdrawal"
              component={WithDrawalCommission}
              accesibleWhenLoggedIn
            />
            {/* <GuardRoute path='/favourite-products' component={FavouriteProduct} accesibleWhenLoggedIn /> */}

            {/* Onboard */}
            <GuardRoute
              path="/boarding-slider"
              component={BoardingPage}
              accesibleWhenLoggedIn={false}
            />

            {/* PDP View */}
            <GuardRoute
              path="/product-view"
              component={ViewProduct}
              accesibleWhenLoggedIn={false}
            />

            {/* Transaction */}
            <GuardRoute
              path="/transaction/:filter"
              component={TransactionPage}
              accesibleWhenLoggedIn
            />
            {/* <GuardRoute
              path="/transaction-detail"
              component={TransactionDetail}
              accesibleWhenLoggedIn
            /> */}
            <GuardRoute
              path="/thank-you"
              component={ThankyouPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/thankyou"
              component={ThankyouWallet}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/failed"
              component={FailedWallet}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/payment-transaction"
              component={InstructionPage}
              accesibleWhenLoggedIn
            />

            {/* <GuardRoute path='/transaction-brand-detail' component={TransactionBrandDetail} accesibleWhenLoggedIn /> */}
            <GuardRoute
              path="/track-detail"
              component={TrackDetail}
              accesibleWhenLoggedIn
            />
            {/* Category Routes */}
            <GuardRoute
              path="/categories/brand/:id/:fakeSlug"
              component={CategoryBrand}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/categories/:id/:fakeSlug"
              component={SingleCategory}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/categories"
              component={AllCategory}
              accesibleWhenLoggedIn
            />

            {/* Event page routes */}
            <GuardRoute
              path="/wooniversity/:id"
              component={DetailWooniversity}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/wooniversity"
              component={Wooniversity}
              accesibleWhenLoggedIn
            />
            {/* <GuardRoute
              path="/gratis-ongkir"
              component={GratisOngkirPage}
              accesibleWhenLoggedIn
            /> */}
            {/* <GuardRoute path='/extra-komisi' component={ExtraKomisiPage} accesibleWhenLoggedIn /> */}

            <GuardRoute
              path="/event/:slug"
              component={DynamicPage}
              accesibleWhenLoggedIn
            />

            {/* Social Share */}
            <GuardRoute
              path="/kode-sahabat"
              component={KodeSahabatPage}
              accesibleWhenLoggedIn
            />

            {/* History Commission Routes */}
            <GuardRoute
              path="/history-commission"
              component={HistoryCommissionPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/commission"
              component={CommissionHistory}
              accesibleWhenLoggedIn
            />

            {/* Catalog */}
            <GuardRoute
              path="/store/catalogs/preview"
              component={PreviewCatalog}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/store/catalogs/:id"
              component={CatalogDetailPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/store/customers/:id"
              component={CustomerDetailPage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/store/edit"
              component={EditStorePage}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/store/catalogs"
              component={Catalogs}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/store"
              component={StorePage}
              accesibleWhenLoggedIn
            />

            {/* GPS */}
            <GuardRoute
              path="/gps/emas"
              component={Emas}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps/detail"
              component={TentangGPS}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps/commission"
              component={CommissionGPS}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps/undian"
              component={UndianGPS}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps/peluang-usaha"
              component={PeluangUsaha}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps/profile/:id"
              component={ProfileGPS}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/gps"
              component={GaransiPastiSuskes}
              accesibleWhenLoggedIn
            />
            <GuardRoute
              path="/404"
              component={PageNotFound}
              accesibleWhenLoggedIn={false}
            />
            {/* 404 */}
            <GuardRoute
              path="/404"
              component={PageNotFound}
              accesibleWhenLoggedIn
            />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}
