import UpperCase, { CapitalFirstChar } from './UpperCase';
import NumberWithCommas from './NumberWithCommas';
import groupingCartItemsPerBrand from './GroupingCartItemsPerBrand';
import ReplaceFirstFiveChar from './ReplaceFirstFiveChar';
import ChangeStatusAndColor from './ChangeStatusAndColor';
import { getDeadline, SplitDateAndTime, getDateFormat } from './SplitDateAndTime';
import { ChangeImageAndName, ChangeBankName } from './ChangeImageAndName';
import InstructionImagePayment from './InstructionImagePayment';
// import ReplacePhoneNumber from './ReplacePhoneNumber'
import ReplaceChar from './ReplaceChar';
import getUrlParameter from './GetUrlParameter';
import getSafely from './getSafely';

import isEmpty from './isEmpty';

export {
  UpperCase,
  NumberWithCommas,
  groupingCartItemsPerBrand,
  ReplaceFirstFiveChar,
  ChangeStatusAndColor,
  SplitDateAndTime,
  ChangeImageAndName,
  InstructionImagePayment,
  getDateFormat,
  getDeadline,
  getUrlParameter,
  ChangeBankName,
  getSafely,
  CapitalFirstChar,
  isEmpty,
  ReplaceChar,
};

export const BANK_LIST = [
  'BCA', 'MANDIRI', 'BNI', 'BRI',
  'CIMB', 'PERMATA', 'DANAMON', 'DKI',
];

export const detectFileType = (str) => {
  if (typeof str !== 'string') return false;
  if (str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi)) {
    return 1;
  }

  if (str.match(/\w+\.(mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|webm)$/gi)) {
    return 2;
  }
};
